import React from 'react';
import clsx from 'clsx';
import Select from 'react-select';



const SettingsSelect = (props) => {

    let helperText = null;

    const customStyles = {
        option: (styles, state) => ({
            ...styles,
            color: '#000',
            backgroundColor: '#fff',
            padding: '20px 15px',
            fontWeight: 500,
            '&:hover': {backgroundColor: '#E3E7EB'},
        }),
        control: (styles) => ({
            ...styles,
            border: '1px solid #394453',
            marginTop:10,
            height: 48,
            '&:hover': {border: '2px solid #07689F'},
            '&:active': {border: '1px solid #07689F'}
        }),
        input: (styles) => ({
            ...styles,
            paddingLeft: '7px'
        }),
        indicatorSeparator: (styles) => ({
            ...styles,
            backgroundColor: '#002D47',
            '&:hover': {backgroundColor: '#07689F'}
        }),
        dropdownIndicator: (styles) => ({
            ...styles,
            color: '#002D47',
            '&:hover': {color: '#07689F'}
        }),
        menu: (styles) => ({
            ...styles,
            border: '1px solid #394453',
            padding: '0px',
            margin: '0px'
        }),
        singleValue: (styles, state) => {
            return {
                ...styles,
                fontWeight: 500,
                fontSize: '16px',
                overflow: 'auto',
                padding: '5px', 
                color: '#000'
            };
        }
    }

    if (props.error !== null) {
        if (props.helperText !== null || props.helperText !== '') {
            helperText = (
                <p className={clsx({'SmallText': true, 'RedTint': props.error || false})} style={{margin: '5px'}}>{props.helperText}</p>
            );
        }
    }

    return (
        <div className={props.containerClassName || 'SettingsTextFieldContainer'} style={props.containerStyle || {}}>
            <label className={clsx({'SettingsTextFieldLabel': true, 'DarkBlueTint':true})}>
                {props.label || ''}
            </label>
            <Select 
                value={props.value} 
                defaultValue={props.defaultValue}
                options={props.options}
                onChange={selectedOption =>props.onChange(selectedOption)} 
                disabled={props.disabled || false}
                styles={customStyles}
                isSearchable={props.isSearchable || false}
                tabIndex={props.tabIndex || null}
            />
            {helperText}
        </div>
    );
};

export default SettingsSelect;