export default {
    zoneOptions: [
        { label: 'America/New_York', value: 'EST' },
        { label: 'America/Detroit', value: 'LMT' },
        { label: 'America/Kentucky/Louisville', value: 'CST' },
        { label: 'America/Kentucky/Monticello', value: 'CST' },
        { label: 'America/Indiana/Indianapolis', value: 'EST' },
        { label: 'America/Indiana/Vincennes', value: 'CST' },
        { label: 'America/Indiana/Winamac', value: 'CST' },
        { label: 'America/Indiana/Marengo', value: 'EST' },
        { label: 'America/Indiana/Petersburg', value: 'EST' },
        { label: 'America/Indiana/Vevay', value: 'EST' },
        { label: 'America/Chicago', value: 'CST' },
        { label: 'America/Indiana/Tell_City', value: 'CST' },
        { label: 'America/Indiana/Knox', value: 'CST' },
        { label: 'America/Menominee', value: 'CST' },
        { label: 'America/North_Dakota/Center', value: 'CST' },
        { label: 'America/North_Dakota/New_Salem', value: 'CST' },
        { label: 'America/North_Dakota/Beulah', value: 'CST' },
        { label: 'America/Denver', value: 'MST' },
        { label: 'America/Boise', value: 'MST' },
        { label: 'America/Phoenix', value: 'MST' },
        { label: 'America/Los_Angeles', value: 'PST' },
        { label: 'America/Anchorage', value: 'AKST' },
        { label: 'America/Juneau', value: 'AKST' },
        { label: 'America/Sitka', value: 'AKST' },
        { label: 'America/Metlakatla', value: 'AKST' },
        { label: 'America/Yakutat', value: 'AKST' },
        { label: 'America/Nome', value: 'AKST' },
        { label: 'America/Adak', value: 'AKST' },
        { label: 'Pacific/Honolulu', value: 'HST' },
        { label: 'America/Mexico_City', value: 'CST' },
        { label: 'America/Cancun', value: 'EST' },
        { label: 'America/Merida', value: 'CST' },
        { label: 'America/Monterrey', value: 'CST' },
        { label: 'America/Matamoros', value: 'CST' },
        { label: 'America/Mazatlan', value: 'MST' },
        { label: 'America/Chihuahua', value: 'mST' },
        { label: 'America/Ojinaga', value: 'MST' },
        { label: 'America/Hermosillo', value: 'MST' },
        { label: 'America/Tijuana', value: 'PST' },
        { label: 'America/Bahia_Banderas', value: 'CST' }
    ]
}