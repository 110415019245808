import React, { Fragment } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import { useSelector } from 'react-redux';
import _ from 'lodash';


const AuthMenu = (props) => {
    const auth = useSelector(state => state.auth.userInfo.auth);

    let fullName = 'No Name';
    let roleName = 'No Role';

    if (auth !== null) {
        if (auth.user_full_name ) {
            fullName = auth.user_full_name;
        };
    
        if (auth.role_name) {
            roleName = auth.role_name;
        };
    }

    const handleUserSettingsArea = () => {
        let menuArea = null;

        if (auth && !_.isEmpty(auth)) {
            if (auth.role_permissions.includes('basic_access')) {
                menuArea = (
                    <MenuItem onClick={props.handleProfile}>
                        <span style={{ color: '#07689F', fontWeight: 600, fontFamily: "Open Sans" }}>User Settings</span>
                    </MenuItem>
                );
            }
        }

        return menuArea;
    }

    return (
        <Fragment>
            <Menu
                id="menu-appbar"
                anchorEl={props.anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ vertical: "top", horizontal: "center" }}
                keepMounted
                open={Boolean(props.anchorEl)}
                onClose={props.handleClose}
            >
                <MenuItem onClick={props.handleClose} style={{ opacity: 1, color: '#000' }} disabled>
                    <List disablePadding>
                        <ListItemText style={{ opacity: 1 }}>
                            <span style={{color: '#000', fontWeight: 600, fontFamily: "Open Sans"}}>{fullName}</span>
                        </ListItemText >
                        <ListItemText style={{ opacity: 1 }}>
                        <span style={{color: '#444', fontWeight: 500, fontSize: '14px', fontFamily: "Open Sans"}}>{roleName}</span>
                        </ListItemText>
                    </List>
                </MenuItem>
                {/* <MenuItem onClick={props.handleProfile}>
                    <span style={{ color: '#07689F', fontWeight: 600 }}>User Settings</span>
                </MenuItem> */}
                {handleUserSettingsArea()}
                <MenuItem onClick={props.handleSignOut} >
                    <span style={{ color: '#07689F', fontWeight: 600, fontFamily: "Open Sans" }}>Sign Out</span>
                </MenuItem>
            </Menu>
        </Fragment>
    );
}

export default AuthMenu;