// Import Modules
import React, { Fragment } from 'react';
import FormTextField from "../MaterialUI/FormTextField";
import SignUp1Button from '../Buttons/SignUp1Button';
import PulseLoader from 'react-spinners/PulseLoader';

const SignUpForm1 = (props) => {

    // Hnadle Error Area
    let errorComponent = null;

    if (props.errorValue) {
        errorComponent = (
            <p className="ErrorBody" style={{paddingTop: '15px'}}>
                {(props.errorValue !== null) ? props.errorValue.message : null}
            </p>
        );
    }

    // Handle Loading Animation
    let loadingArea = null;

    if (props.isLoading) {
        loadingArea = (
            <PulseLoader
                css={`margin-top: 20px;`}
                size={18}
                sizeUnit={"px"}
                margin="5px"
                color={'#0A3F79'}
                loading={true}
            />
        );
    }

    return (
        <Fragment>
            {loadingArea}
            <form
                className="SignUpForm"
                noValidate
                autoComplete='off'
            >
                {errorComponent}
                <div className='inputBoxes'>
                    <FormTextField
                        label='Email'
                        value={props.emailValue}
                        onChange={event => props.onEmailChange(event)}
                        type='email'
                        variant="outlined"
                        fullWidth
                        margin='normal'
                        required
                        autoComplete="false"
                    />
                    <FormTextField
                        label='Password'
                        value={props.passwordValue}
                        onChange={event => props.onPasswordChange(event)}
                        onKeyPress={(event) => props.passwordKeyPressChecker(event)}
                        onSubmit={props.handleSubmit}
                        type='password'
                        variant="outlined"
                        fullWidth
                        autoComplete='false'
                        margin='normal'
                        required
                    />
                </div>

                <SignUp1Button
                    handleSubmit={props.handleSubmit}
                    isInvalid={props.isInvalid}
                />

            </form>
        </Fragment>
    );
};

export default SignUpForm1;