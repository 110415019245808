import React from 'react';

const LogInButton = (props) => {

    let isDisabled = props.isInvalid;

    if (props.isLoading) {
        if (props.isLoading) {
            isDisabled = true;
        }
    }

    const submitClassName = isDisabled ? ["LogInButton", "NotCompleted"] : ["LogInButton", "Completed"];

    return (
        <button
            className={submitClassName.join(' ')}
            onClick={props.handleSubmit}
            type='button'
            disabled={isDisabled}

        >
            <p className="LargeButtonText">
                Log In
            </p>
        </button>
    );
};

export default LogInButton;