import React from 'react';
import clsx from 'clsx';

const SettingsTextField = (props) => {

    let helperText = null;

    if (props.error !== null) {
        if (props.helperText !== null || props.helperText !== '') {
            helperText = (
                <p className={clsx({'SmallText': true, 'RedTint': props.error || false})} style={{margin: '5px'}}>{props.helperText}</p>
            );
        }
    }

    return (
        <div className={props.containerClassName || 'SettingsTextFieldContainer'} style={props.containerStyle || {}}>
            <label className={clsx({'SettingsTextFieldLabel': true, 'DarkBlueTint':true})}>
                {props.label || ''}
            </label>
            <input 
                type={props.type || 'text'} 
                value={props.value} 
                onChange={props.onChange} 
                disabled={props.disabled || false}
                className={props.inputLabelClassName || 'SettingsTextFieldInput'}
                style={props.inputStyle || {}}
                placeholder={props.placeholder || ''}
                tabIndex={props.tabIndex || null}
            />
            {helperText}
        </div>
    );
};

export default SettingsTextField;