import React from 'react';
import clsx from 'clsx';


const ScreenHeaderMoreButton = (props) => {

    let textArea = null;

    if (props.layoutWidth >= 600) {
        textArea = (
            <p 
                className={clsx(
                    'BodyText', 
                    'BlueTint', 
                    'SemiBoldText', 
                    'TextAlignLeft'
                )} 
                style={{
                    margin: 'auto', 
                    marginRight: '10px'
                }}
            >
                {props.moreText || ''}
            </p>
        );
    }

    return (
        <button
            className={clsx({
                'ScreenHeaderMoreButton' : true
            })}
            onClick={props.onMoreClicked}
            type='button'
            disabled={props.isInvalid}

        >
            {textArea}
            {props.moreIcon || null}
        </button>
    );
};

export default ScreenHeaderMoreButton;