// Import Modules
import React, { useState, useEffect } from 'react';
// import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import _ from 'lodash';


// Import Custom Components
import ScreenHeader from '../../../Components/UI/ScreenHeader';
import ErrorScreen from '../Other/ErrorScreen';


const AccountsScreen = (props) => {

    // Get / Initialize Variables
    const auth = useSelector(state => state.auth.userInfo.auth);
    const layoutWidth = useSelector(state => state.ui.layout.layoutWidth);
    const [isFirstLoaded, setIsFirstLoaded] = useState(false);
    const [newAccountActionStatus, setNewAccountActionStatus] = useState(false);
    // const history = useHistory();


    // Setup Users Screen
    useEffect(()=> {
        if (!isFirstLoaded) {
            if (auth !== null && auth !== undefined && !_.isEmpty(auth)) { 
                if (auth.role_permissions.includes('customer_create')) {
                    setNewAccountActionStatus(true);
                }
                setIsFirstLoaded(true);
            }
        }
    }, [isFirstLoaded, auth]);



    // Client-Side Authorization Checks
    if (auth === null || auth === undefined || _.isEmpty(auth)) {
        return null;
    }
    
    if (!auth.role_permissions.includes('customers')) {
        return <ErrorScreen />;
    }


    // Functions Area

    const onNewAccountClicked = () => {
        console.log('New Account Clicked');
    };

    const onMoreOptionsClicked = () => {
        console.log('Account Screen Options Clicked');
    };


    // Return valid users screen depending on desktop or mobile
    return (
        <div className="BaseScreen">
            <ScreenHeader
                title='Accounts'
                actionText='New Account'
                onActionClicked={onNewAccountClicked}
                onMoreClicked={onMoreOptionsClicked}
                isActionDisabled={!newAccountActionStatus}
                isMoreDisabled={false}
                layoutWidth={layoutWidth}
            />
        </div>
    );
};

export default AccountsScreen;
