import React, {useState, Fragment} from 'react';
import Box from './Box';
import clsx from 'clsx';
import _ from 'lodash';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const ActionBox = (props) => {

    const [anchorEl, setAnchorEl] = useState(null);

    const onHeaderButtonClicked = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const onHeaderButtonClosed = () => {
        setAnchorEl(null);
    };


    const createMenuArea = () => {

        if (props.menuData === null || _.isEmpty(props.menuData)) {
            return null;
        }

        return (
            <Fragment>
                <button className='ActionBox_HeaderButton' onClick={(event)=> onHeaderButtonClicked(event)}>
                    <p className={clsx('ButtonText', 'WhiteTint', 'SemiBoldText', 'TextAlignCenter')} style={{'marginTop': 'auto', 'marginBottom': 'auto'}}>
                        {props.actionText || ""}
                    </p>
                    <ExpandMoreIcon style={{ color: 'white', fontSize: '28', fontWeight: '600' }} size='medium' />
                </button>
                <Menu
                    id="menu-appbar"
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                    transformOrigin={{ vertical: "top", horizontal: "left" }}
                    keepMounted
                    onClose={onHeaderButtonClosed}
                >
                    {props.menuData.map((menuItem) => {
                        return (
                            <MenuItem 
                                key={menuItem.text} 
                                onClick={()=> {  
                                    onHeaderButtonClosed();
                                    menuItem.onItemClicked();
                                }}
                            >
                                {menuItem.text}
                            </MenuItem>
                        );
                    })}
                </Menu>
            </Fragment>
        );
    };

    return (
        <Box className='ActionBox' style={props.boxStyle || {}}>
            <div className='ActionBox_Header'>
                <p className={clsx('SubTitle', 'DarkBlueTint', 'SemiBoldText', 'TextAlignLeft')} style={{marginTop: 'auto', marginBottom: 'auto'}}>
                    {props.title || ''}
                </p>
                {createMenuArea()}
            </div>
            <div className='SettingsBoxLine'/>
            {props.children}
        </Box>
    );
};

export default ActionBox;