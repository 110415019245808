import gql from 'graphql-tag';

const NEW_USER = gql`
  mutation NewUser($newUserInput:NewUserInput!){
    newUser(newUserInput: $newUserInput) {
      id
      user_id
      url_id
      company_id
      email
      phone
      notes
      full_name
      display_name
      first_name
      last_name
      primary_address
      active
      account_status
      working_status
      profile_image_key
      profile_image_url
      preferred_communication_method
      created_at
      last_updated_at
      time_zone

      role {
        id
        name
        description
        url_id
        active
        created_at
        last_updated_at
      }
    }
  }
`;


const UPDATE_USER = gql`
  mutation UpdateUser($updatedUserInput: UpdateUserInput!){
    updateUser(updatedUserInput: $updatedUserInput) {
      id
      user_id
      url_id
      company_id
      email
      phone
      notes
      full_name
      display_name
      first_name
      last_name
      primary_address
      active
      account_status
      working_status
      profile_image_key
      profile_image_url
      preferred_communication_method
      preferred_language
      created_at
      last_updated_at
      time_zone
    }
  }
`;

const UPDATE_USER_ROLE = gql`
  mutation UpdateUserRole($id: ID!, $company_id: Int!, $role_id: String){
    updateUserRole(id: $id, company_id: $company_id, role_id: $role_id) {
      id
      user_id
      url_id
      company_id
      email
      phone
      notes
      full_name
      display_name
      first_name
      last_name
      primary_address
      active
      account_status
      working_status
      profile_image_key
      profile_image_url
      preferred_communication_method
      preferred_language
      created_at
      last_updated_at
      time_zone

      role {
        id
        name
        description
        url_id
        active
        created_at
        last_updated_at
      }
    }
  }
`;



export { NEW_USER, UPDATE_USER, UPDATE_USER_ROLE };