import React from 'react';
import clsx from 'clsx';
import FontAwesome from 'react-fontawesome';

const SettingsRedOutlinedButton = (props) => {

    return (
        <button
            className={ clsx({ 
                "BackgroundColor--None": true, 
                "BorderRadius--All--5": true, 
                "BorderHover--All--Red": true,
                "Width--Full-40": true,
                "Margin--Vertical--10": true,
                "Margin--Horizontal--15": true,
                "Padding--Vertical--0": true,
                "Padding--Horizontal--20": true,
                'DisabledButton': (props.disabled), 
                'NotDisabledButton': (!props.disabled) 
            })}
            onClick={props.onClick}
            disabled={props.disabled}
            type='button'
        >
            <div className={clsx('Display--Flex', 'Flex--SpaceBetween', 'Width--Full')}>
                <p 
                    className={clsx({ 
                        'BodyText': true, 
                        'BoldText': true, 
                        'TextAlignLeft': true,
                        'Margin--Vertical--15': true,
                        'Margin--Horizontal--0': true,
                        'RedTint': !props.disabled, 
                        'GreyTint': props.disabled
                    })} 
                >
                        {props.label}
                </p>
                <FontAwesome 
                    name='angle-right'
                    hidden={false}
                    size="3x"
                    style={{color: props.color || '#DA3A3B'}}
                />
            </div>
            
        </button>
    )
};

export default SettingsRedOutlinedButton;