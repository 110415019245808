import { combineReducers } from 'redux';
import UserInfoReducer from './UserInfoReducer';
import LogInReducer from './LogInReducer';
import SignUpReducer from './SignUpReducer';
import ForgotPasswordReducer from './ForgotPasswordReducer';

export default combineReducers({
    userInfo: UserInfoReducer,
    login: LogInReducer,
    signup: SignUpReducer,
    forgotPassword: ForgotPasswordReducer
});