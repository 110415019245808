import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import * as ROUTES from '../../../Constants/routes';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';

function PreAuthHeader(props) {
    const pathName = props.location.pathname;

    return (
        <div className="pre-auth-header-container">
            <div className="pre-auth-header-left-area" >
                <p className={clsx('SubTitle', "BoldText", "WhiteTint", "Margin--Left--10")}>
                    ProHelper
                </p>
            </div>

            <div className="pre-auth-header-right-area">
                <LinkContainer to={ROUTES.LOG_IN} exact>
                    <button 
                        className={clsx({
                            "pre-auth-header-text": true, 
                            "pre-auth-header-text-active": (pathName === ROUTES.LOG_IN)
                        })}
                    >
                        Log In
                    </button>
                </LinkContainer>

                <LinkContainer to={ROUTES.SIGN_UP} exact>
                    <button 
                        className={clsx({
                            "pre-auth-header-text": true, 
                            "pre-auth-header-text-active": (pathName === ROUTES.SIGN_UP)
                        })}
                    >
                        Sign Up
                    </button>
                </LinkContainer>
            </div>
        </div>
    );
}

export default withRouter(PreAuthHeader);