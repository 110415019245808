import React from 'react';
import Box from './Box';


const LegalBox = (props) => {

    return (
        <Box className='LegalBox'>
            {props.children}
        </Box>
    );
};

export default LegalBox;