import React from 'react';
import _ from 'lodash';
import clsx from 'clsx';
import moment from 'moment';
import capitalize from 'capitalize';

const UserDetailsView = (props) => {


    return (
        <div className='UserDetailAccountForm_Container'>
            <div className="UserDetailAccountForm_TopArea">
                <div className="UserDetailAccountForm_TopAreaLeftSection">
                    <img
                        src={props.data.profile_image_url || process.env.PUBLIC_URL + '/profile_image.png'}
                        className='UserDetailAccountForm_Image'
                        alt = {props.data.full_name ? props.data.full_name + ' Profile Image' : 'Profile Image'}
                    />
                </div>
                <div className='UserDetailAccountForm_TopAreaRightSection' style={{marginTop: 'auto', marginBottom: 'auto'}}>
                    <p className={clsx('SubTitle', 'BoldText', 'TextAlignLeft', 'DarkBlueTint')} >
                        {(_.upperFirst(props.data.first_name || '') + " " + _.upperFirst(props.data.last_name || ''))}
                    </p>
                    <p className={clsx('BodyText', 'NormalText', 'TextAlignLeft', 'DarkGreyTint')} >
                        {props.data.email || ''}
                    </p>
                </div>
            </div>
            <div className='UserDetailAccountForm_ContentsArea'>
                <p className={clsx('SubTitleSmall', 'BoldText', 'TextAlignLeft', 'DarkGreyTint')}>
                    Account Status
                </p>
                <p className={clsx('BodyText', 'NormalText', 'TextAlignLeft', 'DarkGreyTint')}>
                    {capitalize.words(_.toLower(props.data.account_status || ''))}
                </p>
                <div className='Spacer--10' />
                <p className={clsx('SubTitleSmall', 'BoldText', 'TextAlignLeft', 'DarkGreyTint')}>
                    Working Status
                </p>
                <p className={clsx('BodyText', 'NormalText', 'TextAlignLeft', 'DarkGreyTint')}>
                    {capitalize.words(_.toLower(props.data.working_status || ''))}
                </p>
                <div className='Spacer--10' />
                <p className={clsx('SubTitleSmall', 'BoldText', 'TextAlignLeft', 'DarkGreyTint')}>
                    Last Active
                </p>
                <p className={clsx('BodyText', 'NormalText', 'TextAlignLeft', 'DarkGreyTint')}>
                    {moment(new Date(props.data.last_active), "YYYYMMDD").fromNow() || ''}
                </p>
                <div className='Spacer--10' />
                <p className={clsx('SubTitleSmall', 'BoldText', 'TextAlignLeft', 'DarkGreyTint')}>
                    Created
                </p>
                <p className={clsx('BodyText', 'NormalText', 'TextAlignLeft', 'DarkGreyTint')}>
                    {moment(new Date(props.data.created_at), "YYYYMMDD").format('M-DD-YYYY') || ''}
                </p>
            </div>
        </div>
    );
};

export default UserDetailsView;
