import React, { Fragment, useState } from 'react';
import clsx from 'clsx';
import Popover from '@material-ui/core/Popover';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';


const HelpPopOver = (props) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Fragment>
            <button 
                className={clsx('BackgroundColor--None', 'Border--None', 'Border--All--None', 'Padding--Vertical--0', 'Padding--Horizontal--0',  'Pointer--Cursor')} 
                onClick={(event) => handleClick(event)}
            >
                <HelpOutlineIcon style={{ color: '#000000', fontSize: '20', fontWeight: '600' }} />
            </button>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <p className={clsx('Padding--Horizontal--10', 'Padding--Vertical--10')} style={{maxWidth: '400px', lineHeight: 'normal'}}>
                    {props.helpText || ''}
                </p>
            </Popover>
        </Fragment>
    );
};

export default HelpPopOver;