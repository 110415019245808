import React from 'react';
import clsx from 'clsx';
import moment from 'moment';
import capitalize from 'capitalize';

const RoleDetailInfoView = (props) => {
    if (!props.data) {
        return (
            <div className='RoleDetailInfoView'>
                <p className={clsx('SubTitleSmall', 'NormalText', 'TextAlignCenter', 'DarkGreyTint')}>
                    No Role Available.
                </p>
            </div>
        );
    }

    const handlePermissionsText = () => {
        let permissionAccessText = 'No permissions available.';

        if (props.data.permission_categories !== null) {
            permissionAccessText = '';
            let permissionCategoriesLength = props.data.permission_categories.length;
            props.data.permission_categories.forEach((value, index) => {
                permissionAccessText += value;

                if (index !== permissionCategoriesLength - 1) {
                    permissionAccessText += ', ';
                }
            });
        }

        return permissionAccessText;
    }


    return (
        <div className={clsx('Padding--Horizontal--20', 'Padding--Vertical--15')}>
            <p className={clsx('SubTitle', 'BoldText', 'TextAlignLeft', 'DarkBlueTint')}>
                {props.data.name || 'No name available.'}
            </p>
            <p className={clsx('BodyText', 'NormalText', 'TextAlignLeft', 'DarkGreyTint')}>
                {props.data.description || 'No description available.'}
            </p>
            <div className='Spacer--20' />
            <div className='SettingsBoxLine' />
            <div className='Spacer--15' />
            <p className={clsx('SubTitleSmall', 'BoldText', 'TextAlignLeft', 'DarkGreyTint')}>
                Permission Access
            </p>
            <p className={clsx('BodyText', 'NormalText', 'TextAlignLeft', 'DarkGreyTint')}>
                {handlePermissionsText()}
            </p>
            <div className='Spacer--10' />
            <p className={clsx('SubTitleSmall', 'BoldText', 'TextAlignLeft', 'DarkGreyTint')}>
                Last Updated
            </p>
            <p className={clsx('BodyText', 'NormalText', 'TextAlignLeft', 'DarkGreyTint')}>
                {capitalize.words(moment(new Date(props.data.last_updated_at), "YYYYMMDD").fromNow()) || 'No last updated date available.'}
            </p>
            <div className='Spacer--10' />
            <p className={clsx('SubTitleSmall', 'BoldText', 'TextAlignLeft', 'DarkGreyTint')}>
                Created
            </p>
            <p className={clsx('BodyText', 'NormalText', 'TextAlignLeft', 'DarkGreyTint')}>
                {moment(new Date(props.data.created_at), "YYYYMMDD").format('M-DD-YYYY') || 'No created date available.'}
            </p>
        </div>
    );
};

export default RoleDetailInfoView;
