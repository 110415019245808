import gql from 'graphql-tag';

const GET_ROLE_URL = gql`
  query GetRoleURL($url_id:String!, $company_id:Int!){
    getRoleURL(url_id: $url_id, company_id: $company_id) {
        id
        company_id
        url_id
        active
        name
        description
        created_at
        last_updated_at
        user_amount
        permissions_count
        permission_categories

        permissions {
            id
            name
            description
            active
            identifier
            screen_access
            action_access
            created_at
            last_updated_at
            category
            sub_category
            action_name
            action_order
            group_name
            group_identifier
        }

        users {
            id
            user_id
            url_id
            company_id
            email
            phone
            notes
            full_name
            display_name
            first_name
            last_name
            primary_address
            active
            account_status
            working_status
            profile_image_key
            profile_image_url
            preferred_communication_method
            created_at
            last_updated_at
            time_zone

            role {
              id
              name
              description
              url_id
              active
              permission_categories
              permissions_count
              permission_categories
              created_at
              last_updated_at
            }
        }
    }
  }
`;


const GET_ROLES = gql`
  query GetRoles($company_id:Int!){
    getRoles(company_id: $company_id) {
        id
        company_id
        url_id
        active
        name
        description
        created_at
        last_updated_at
        user_amount
        permissions_count
        permission_categories
    }
  }
`;



export { GET_ROLE_URL, GET_ROLES };