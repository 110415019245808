// Import Modules
import React from 'react';
import ForgotPasswordForm from '../Forms/ForgotPasswordForm1';
import Box from './Box';
import clsx from 'clsx';


const ForgotPasswordBox1 = (props) => {

    return (
        <Box className='ForgotPasswordBox'>
            <p className={clsx('Title', 'DarkBlueTint', 'Bold')}>
                Forgot Password
            </p>
            <p className={clsx('BodyText', 'DarkBlueTint')} style={{marginTop: '15px', paddingLeft: '10%', paddingRight: '10%'}}>
                Please enter your email address to receive a verification code.
            </p>
            <ForgotPasswordForm
                emailValue={props.email}
                onEmailChange={(event) => { props.onEmailChange(event) }}
                isInvalid={props.isInvalid}
                handleSubmit={props.handleSubmit}
                errorValue={props.errorValue}
                emailKeyPressChecker={(event) => props.emailKeyPressChecker(event)}
            />
        </Box>
    );
};

export default ForgotPasswordBox1;