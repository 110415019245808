// Import Modules
import React, { Fragment, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import clsx from 'clsx';

// Import Custom Components
import ErrorScreen from '../../Other/ErrorScreen';
import DataTableContainer from '../../../../Components/Containers/DataTableContainer';
import DataListContainer from '../../../../Components/Containers/DataListContainer';
import BoxSearch from '../../../../Components/Other/BoxSearch';
import BoxSortSelect from '../../../../Components/Other/BoxSortSelect';
import ScreenHeader from '../../../../Components/UI/ScreenHeader';
import BoxHeader from '../../../../Components/UI/BoxHeader';
import SortingOptions from '../../../../Constants/SortingOptions';
import RolesTableView from '../../../../Components/Views/RolesTableView';
import RolesListView from '../../../../Components/Views/RolesListView';
import * as ROUTES from '../../../../Constants/routes';
import RolesDataContainer from '../../../../Components/DataContainers/RolesDataContainer';



// Users Screen Desktop View (Table)
const RolesDesktopView = (props) => {

    let topContents = (
        <Fragment>
            <BoxHeader 
                text={(props.data.length || 0) + ' Roles'}
            />
        </Fragment>
    );

    let leftContents = (
        <Fragment>
            <BoxSearch
                searchValuePlaceholder='Search roles'
                containerStyle={{}}
                searchValue={props.searchValue}
                isSearchFocused={props.isSearchFocused  || false}
                onSearchFocused={props.onSearchFocused}
                onSearchValueChange={(event) => props.onSearchValueChange(event)}
                onSearchFocusedOut={props.onSearchFocusedOut}
                onClearButtonClicked={props.onClearButtonClicked}
                onSearchButtonClicked={props.onSearchButtonClicked}
            />
            
        </Fragment>
    );

    let rightContents = (
        <Fragment>
            <BoxSortSelect 
                value={props.sortValue}
                defaultValue={props.sortDefaultValue}
                options={props.sortOptions}
                onChange={(event) => props.onSortChanged(event)}
            />
        </Fragment>
    );

    return (
        <DataTableContainer 
            topContents={topContents}
            leftContents={leftContents}
            rightContents={rightContents}
        >
            <RolesTableView 
                data={props.data}
                onRoleClicked={(role) => props.onRoleClicked(role)}
                loadMore={props.loadMore}
                loading={props.loading}
                error={props.error}
            />
        </DataTableContainer>
    );
};

// Users Screen Mobile View (List)
const RolesMobileView = (props) => {

    let leftContents = (
        <Fragment>
            <p 
                className={clsx('BodyText', 'TextAlignCenter', 'SemiBoldText', 'DarkBlueTint')} 
                style={{'margin': 'auto', 'marginBottom': '10px', 'marginTop': '0px'}}
            >
                {props.data.length || 0} Roles
            </p>
            <BoxSearch
                searchValuePlaceholder='Search roles'
                containerStyle={{}}
                searchValue={props.searchValue}
                isSearchFocused={props.isSearchFocused  || false}
                onSearchFocused={props.onSearchFocused}
                onSearchFocusedOut={props.onSearchFocusedOut}
                onSearchValueChange={(event) => props.onSearchValueChange(event)}
                onClearButtonClicked={props.onClearButtonClicked}
                onSearchButtonClicked={props.onSearchButtonClicked}
            />
        </Fragment>
    );


    let rightContents = (
        <Fragment>
            <BoxSortSelect 
                value={props.sortValue}
                defaultValue={props.sortDefaultValue}
                options={props.sortOptions}
                onChange={(event) => props.onSortChanged(event)}
            />
        </Fragment>
    );


    return (
        <DataListContainer 
            leftContents={leftContents}
            rightContents={rightContents}
        >
            <RolesListView 
                data={props.data}
                loadMore={props.loadMore}
                onRoleClicked={(role) => props.onRoleClicked(role)}
                loading={props.loading}
                error={props.error}
            />
        </DataListContainer>
    );

};

const RolesScreen = (props) => {

    // Get / Initialize Variables
    const auth = useSelector(state => state.auth.userInfo.auth);
    const layoutWidth = useSelector(state => state.ui.layout.layoutWidth);
    const [isFirstLoaded, setIsFirstLoaded] = useState(false);
    const [newRoleActionStatus, setNewRoleActionStatus] = useState(false);
    const [searchFocused, setSearchFocused] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [sortValue, setSortValue] = useState({ label: 'Role Name: A-Z', value: 'role_name_asc' });
    const [dataContainerLoading, setDataContainerLoading] = useState(true);
    const [dataContainerError, setDataContainerError] = useState(null);
    const [rawRolesList, setRawRolesList] = useState([]);
    const [sortedRolesList, setSortedRolesList] = useState({});
    const history = useHistory();


    // Setup Roles Screen
    useEffect(()=> {
        if (!isFirstLoaded) {
            if (auth !== null && auth !== undefined && !_.isEmpty(auth)) { 
                if (auth.role_permissions.includes('team_role_create')) {
                    setNewRoleActionStatus(true);
                }
                if (sortedRolesList === {}) {
                    setSortedRolesList(createSortedList(rawRolesList, '', { label: 'Role Name: A-Z', value: 'role_name_asc' }));
                }
                setIsFirstLoaded(true);
            }
        }
    
    }, [isFirstLoaded, auth, rawRolesList, sortedRolesList]);


    // Client-Side Authentication Checks
    if (auth === null || auth === undefined || _.isEmpty(auth)) {
        return null;
    }

    if (!auth.role_permissions.includes('team_roles')) {
        return <ErrorScreen />;
    }


    // Functions Area
    const onRoleClicked = (role) => {
        history.push(ROUTES.TEAM_ROLE_DETAIL_PREFIX + role.url_id);
    };

    const onNewRoleClicked = () => {
        history.push(ROUTES.TEAM_ROLES_NEW);
    };

    const onMoreOptionsClicked = () => {
        console.log('Roles Screen Options Clicked');
    };


    const onLoadMoreRoles = () => {

    };

    const onSearchFocused = () => {
        setSearchFocused(true);
    };


    const onSearchValueChange = (event) => {
        setSearchValue(event.target.value);
        setSortedRolesList(createSortedList(rawRolesList, event.target.value, sortValue));
    };

    const onSearchFocusedOut = () => {
        if (searchValue === '') {
            setSearchFocused(false);
        }
    };

    const onClearButtonClicked = () => {
        setSearchValue('');
        setSearchFocused(false);
        setSortedRolesList(createSortedList(rawRolesList, '', sortValue));
    };

    const onSearchButtonClicked = () => {

    };

    const onSortChanged = (selectedOption) => {
        setSortValue(selectedOption);
        setSortedRolesList(createSortedList(rawRolesList, searchValue, selectedOption));
    };

    const createSortedList = (rawData, searchText, sortOption) => {
        // Load Data
        let newSortedList = [ ...rawData ];

        // Check to Filter Results
        if (searchText !== '') {
            // Create new filtered list
            newSortedList = newSortedList.filter((value, index, arr) => {
                let result = false;

                if (value.name) {
                    if (value.name.toLowerCase().includes(searchText.toLowerCase())) {
                        result = true;
                    }
                }

                return result;
            });
        }


        // Sort List

        switch (sortOption.value) {
            case 'role_name_asc':
                newSortedList.sort((a, b) => {
                    if (a.name < b.name) { return -1; }
                    if (a.name > b.name) { return 1; }
                    return 0;
                });
                break;
            case 'role_name_dec':
                newSortedList.sort((a, b) => {
                    if (a.name > b.name) { return -1; }
                    if (a.name < b.name) { return 1; }
                    return 0;
                });
                break;
            case 'num_users_dec':
                newSortedList.sort((a, b) => {
                    if (a.user_amount < b.user_amount) { return -1; }
                    if (a.user_amount > b.user_amount) { return 1; }
                    return 0;
                });
                break;
            case 'num_permissions_dec':
                newSortedList.sort((a, b) => {
                    if (a.permissions_count > b.permissions_count) { return -1; }
                    if (a.permissions_count < b.permissions_count) { return 1; }
                    return 0;
                });
                break;
            case 'last_updated_dec':
                newSortedList.sort((a, b) => {
                    if (a.last_updated_at > b.last_updated_at) { return -1; }
                    if (a.last_updated_at < b.last_updated_at) { return 1; }
                    return 0;
                });
                break;
            case 'created_at_dec':
                newSortedList.sort((a, b) => {
                    if (a.created_at > b.created_at) { return -1; }
                    if (a.created_at < b.created_at) { return 1; }
                    return 0;
                });
                break;
                default:
                    break;
        }


        return newSortedList;
    };

    const onDataContainerUpdated = (newRolesData) => {
        setRawRolesList(newRolesData);
        setSortedRolesList(createSortedList(newRolesData, searchValue, sortValue));
    };


    // Return valid roles screen depending on desktop or mobile
    return (
        <div className="BaseScreen">
            <RolesDataContainer
                setLoading={(loading)=> setDataContainerLoading(loading)}
                setError={(error)=> setDataContainerError(error)}
                setData={(data) => onDataContainerUpdated(data)}
                auth={auth}
            >
                <ScreenHeader 
                    title='Roles'
                    searchValuePlaceholder='Search roles by name'
                    actionText='New Role'
                    onActionClicked={onNewRoleClicked}
                    isActionDisabled={!newRoleActionStatus}
                    onMoreClicked={onMoreOptionsClicked}
                    isMoreDisabled={false}
                    layoutWidth={layoutWidth}
                />
                {(layoutWidth < 700) ?
                    <RolesMobileView
                        onNewRoleClicked={onNewRoleClicked}
                        onRoleClicked={(role) => onRoleClicked(role)}
                        data={sortedRolesList}
                        loading={dataContainerLoading}
                        error={dataContainerError}
                        loadMore={onLoadMoreRoles}
                        searchValue={searchValue}
                        isSearchFocused={searchFocused}
                        onSearchFocused={onSearchFocused}
                        onSearchValueChange={(event) => onSearchValueChange(event)}
                        onSearchFocusedOut={onSearchFocusedOut}
                        onClearButtonClicked={onClearButtonClicked}
                        onSearchButtonClicked={onSearchButtonClicked}
                        sortValue={sortValue}
                        sortDefaultValue={{ label: 'Role Name: A-Z', value: 'role_name_asc' }}
                        sortOptions={SortingOptions.RolesSortingOptions}
                        onSortChanged={(selectedOption) => onSortChanged(selectedOption)}
                    />
                :
                    <RolesDesktopView 
                        onNewRoleClicked={onNewRoleClicked}
                        onRoleClicked={(role) => onRoleClicked(role)}
                        data={sortedRolesList}
                        loading={dataContainerLoading}
                        error={dataContainerError}
                        loadMore={onLoadMoreRoles}
                        searchValue={searchValue}
                        isSearchFocused={searchFocused}
                        onSearchFocused={onSearchFocused}
                        onSearchValueChange={(event) => onSearchValueChange(event)}
                        onSearchFocusedOut={onSearchFocusedOut}
                        onClearButtonClicked={onClearButtonClicked}
                        onSearchButtonClicked={onSearchButtonClicked}
                        sortValue={sortValue}
                        sortDefaultValue={{ label: 'Role Name: A-Z', value: 'role_name_asc' }}
                        sortOptions={SortingOptions.RolesSortingOptions}
                        onSortChanged={(selectedOption) => onSortChanged(selectedOption)}
                    />
                }
            </RolesDataContainer>
        </div>
    );
};

export default RolesScreen;
