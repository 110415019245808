// Import Modules
import React, { Fragment, useState, useEffect } from 'react';
import { AsYouType, parsePhoneNumberFromString } from 'libphonenumber-js'
import Masonry from 'react-masonry-css';
import PulseLoader from 'react-spinners/PulseLoader';
import { useSelector } from 'react-redux';
import clsx from 'clsx';


import SettingsTextField from "../MaterialUI/SettingsTextField";
import SettingsTextFieldMultiLine from "../MaterialUI/SettingsTextFieldMultiLine";
import EditSettingsBoxButton from '../Buttons/EditSettingsBoxButton';


const ContactDetailsForm = (props) => {
    // React Hooks State Management
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [displayName, setDisplayName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [notes, setNotes] = useState('');


    // Get Auth for ID
    const auth = useSelector(state => state.auth.userInfo.auth);
    const layoutWidth = useSelector(state => state.ui.layout.layoutWidth);

   

    useEffect(()=> {
        if (props.data) {
            if (props.data.email) {
                setEmailAddress(props.data.email);
            }

            if (props.data.first_name) {
                setFirstName(props.data.first_name);
            }

            if (props.data.last_name) {
                setLastName(props.data.last_name);
            }

            if (props.data.display_name) {
                setDisplayName(props.data.display_name);
            }

            if (props.data.phone) {
                let libAsYouType = new AsYouType('US');
                setPhoneNumber(libAsYouType.input(props.data.phone));
            }

            if (props.data.notes) {
                setNotes(props.data.notes);
            }
        }
        
    }, [props.data]);


    // Handle Loading Animation
    let loadingArea = null;

    if (props.loading) {
        loadingArea = (
            <PulseLoader
                css={`margin-top: 20px; margin-bottom: 10px;`}
                size={20}
                sizeUnit={"px"}
                margin="5px"
                color={'#0A3F79'}
                loading={true}
            />
        );
    }

    // Handle Error Text
    let errorComponent = null;

    if (props.error) {
        errorComponent = (
            <p className={clsx("ErrorBody", "Padding--Vertical--10")}>
                {props.error.message}
            </p>
        );
    }

    // Handle Form Actions
    const onEmailChange = (emailAddress) => {
        setEmailAddress(emailAddress);
    };

    const onFirstNameChange = (firstName) => {
        setFirstName(firstName);
    };

    const onLastNameChange = (lastName) => {
        setLastName(lastName);
    };

    const onDisplayNameChange = (displayName) => {
        setDisplayName(displayName);
    };

    const onPhoneNumberChange = (phoneNumber) => {
        let libAsYouType = new AsYouType('US');
        setPhoneNumber(libAsYouType.input(phoneNumber));
    };

    const onSubmit = () => {

        let contactInput = {
            id: props.data.user_id,
            company_id: auth.company_id,
            first_name: undefined,
            last_name: undefined,
            full_name: undefined,
            display_name: undefined,
            phone: undefined,
            notes: undefined,
            primary_address: undefined,
            preferred_language: undefined,
            time_zone: undefined,
            role_id: undefined,
            last_updated_by_id: auth.user_id.toString()
        };

        if (props.data) {
            if (props.data.first_name !== firstName) {
                contactInput.first_name = firstName;
            }
            if (props.data.last_name !== lastName) {
                contactInput.last_name = lastName;
            }
            if (props.data.display_name !== displayName) {
                contactInput.display_name = displayName;
            }

            if (phoneNumber) {
                let inputPhoneNumber = parsePhoneNumberFromString(phoneNumber, 'US');
                if (props.data.phone !== inputPhoneNumber.nationalNumber) {
                    contactInput.phone = inputPhoneNumber.nationalNumber;
                }
            }

            if (props.data.notes) {
                if (props.data.notes !== notes) {
                    contactInput.notes = notes;
                    if (notes === '') {
                        contactInput.notes = '';
                    }
                }else if (notes === '') {
                    contactInput.notes = null;
                }
            }else {
                contactInput.notes = notes;
            }

        }

        props.onUpdateUser(contactInput);

    };

    const checkIsValid = () => {
        let result = false;

        if (props.data) {
            if (props.data.first_name !== firstName && firstName !== '') {
                return true;
            }
            if (props.data.last_name !== lastName && lastName !== '') {
                return true;
            }

            if (props.data.display_name !== displayName && displayName !== '') {
                return true;
            }

            let inputPhoneNumber = parsePhoneNumberFromString(phoneNumber, 'US');
            if (inputPhoneNumber) {
                if (props.data.phone !== inputPhoneNumber.nationalNumber) {
                    if (inputPhoneNumber.isValid()) {
                        return true;
                    } else if (inputPhoneNumber === '') {
                        return true;
                    }
                }
            }
            

            if ((props.data.notes || '') !== notes) {
                return true;
            }
        }

        return result;
    }


    return (
        <Fragment>
            <form
                className="BasicUserInformationForm"
                noValidate
                autoComplete='off'
            >
                <div className='SettingsInputFormArea'>
                    {loadingArea}
                    {errorComponent}
                    <SettingsTextField
                        label='Email'
                        value={emailAddress}
                        onChange={event => onEmailChange(event.target.value)}
                        type='text'
                        disabled={true}
                    />
                    <Masonry
                        breakpointCols={{ default: 2, 1100: 2, 900: 1, 500: 1 }}
                        className="settings-masonry-grid"
                        columnClassName="settings-masonry-grid_column"
                    >
                        <SettingsTextField
                            label='First Name*'
                            value={firstName}
                            onChange={event => onFirstNameChange(event.target.value)}
                            type='text'
                            containerStyle={{marginRight: ((layoutWidth || 0) <= 1000) ? '0px' : '5px'}}
                            placeholder='Enter First Name'
                            tabIndex={1}
                        />
                        <SettingsTextField
                            label='Last Name*'
                            value={lastName}
                            onChange={event => onLastNameChange(event.target.value)}
                            type='text'
                            containerStyle={{marginLeft: ((layoutWidth || 0) <= 1000) ? '0px' : '5px'}}
                            placeholder='Enter Last Name'
                            tabIndex={2}
                        />
                    </Masonry>
                    <SettingsTextField
                        label='Display Name*'
                        value={displayName}
                        onChange={event => onDisplayNameChange(event.target.value)}
                        type='text'
                        placeholder='Enter Display Name'
                        tabIndex={3}
                    />
                    <SettingsTextField
                        label='Phone Number'
                        value={phoneNumber}
                        onChange={event => onPhoneNumberChange(event.target.value)}
                        type='text'
                        placeholder='Enter Phone Number'
                        tabIndex={4}
                    />
                    <SettingsTextFieldMultiLine
                        label='Notes'
                        value={notes}
                        onChange={event => setNotes(event.target.value)}
                        placeholder='Enter Notes'
                        tabIndex={5}
                    />
                </div>
                <EditSettingsBoxButton 
                    isDisabled={!checkIsValid()}
                    handleSubmit={onSubmit}
                />
            </form>
        </Fragment>
    );
};

export default ContactDetailsForm;
