import React, { Fragment, useState } from 'react';
import SettingsSelect from "../MaterialUI/SettingsSelect";
import EditSettingsBoxButton from '../Buttons/EditSettingsBoxButton';
import TimeZones from '../../Constants/TimeZones';
import clsx from 'clsx';
import PulseLoader from 'react-spinners/PulseLoader';
import { useSelector } from 'react-redux';


const languageOptions = [
    { value: 'EN', label: 'English' }
];

const UserPreferencesInfoForm = (props) => {

    // React Hooks State Management
    const auth = useSelector(state => state.auth.userInfo.auth);
    const [language, setLanguage] = useState({value:'EN', label: 'English'});
    const [timeZone, setTimeZone] = useState({value: 'PST', label:'America/Los_Angeles'});

    // Handle Error Text
    let errorComponent = null;

    if (props.error) {
        errorComponent = (
            <p className={clsx("ErrorBody", "Padding--Vertical--10")} >
                {props.error.message || ''}
            </p>
        );
    }

    // Handle Loading Area
    let loadingArea = null;

    if (props.loading) {
        loadingArea = (
            <PulseLoader
                css={`margin-top: 20px; margin-bottom: 10px;`}
                size={20}
                sizeUnit={"px"}
                margin="5px"
                color={'#0A3F79'}
                loading={true}
            />
        );
    }

    // Handle Form Actions
    const onLanguageChange = (selectedOption) => {
        setLanguage(selectedOption);
    };

    const onTimeZoneChange = (timeZone) => {
        setTimeZone(timeZone);
    };



    const onSubmit = () => {
        let contactInput = {
            id: props.data.user_id,
            company_id: auth.company_id,
            first_name: null,
            last_name: null,
            full_name: null,
            display_name: null,
            phone: null,
            notes: null,
            primary_address: null,
            preferred_language: language.value,
            time_zone: timeZone.label,
            last_updated_by_id: auth.user_id.toString()
        };

        props.onUpdateUser(contactInput);
    };
    

    const checkIsValid = () => {
        let result = false;
        
        if (props.data) {
            if ((props.data.preferred_language.toLowerCase() !== language.value.toLowerCase()) || (props.data.time_zone !== timeZone.label) ) {
                result = true;
            }
        }

        return result;
    }


    return (
        <Fragment>
            {loadingArea}
            {errorComponent}
            <form
                className="ExtraUserInformationForm"
                noValidate
                autoComplete='off'
            >
                <div className='SettingsInputFormArea'>
                    <SettingsSelect
                        label='Language'
                        value={language}
                        onChange={selectedOption =>onLanguageChange(selectedOption)} 
                        options={languageOptions}
                        isSearchable={false}
                        tabIndex={21}
                    />
                    <SettingsSelect
                        label='Time Zone'
                        value={timeZone}
                        onChange={selectedOption =>onTimeZoneChange(selectedOption)} 
                        options={TimeZones.zoneOptions}
                        isSearchable={true}
                        tabIndex={22}
                    />
                </div>
                <EditSettingsBoxButton 
                    isDisabled={!checkIsValid()}
                    handleSubmit={onSubmit}
                />
            </form>
        </Fragment>
    );
};

export default UserPreferencesInfoForm;
