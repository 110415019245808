// Import Modules
import React, { Component } from 'react';
import { createMuiTheme, withStyles } from '@material-ui/core/styles';
import { Auth } from 'aws-amplify';
import { connect } from 'react-redux'; 
import { withRouter } from 'react-router-dom';
import SizeMe from 'react-sizeme';
import clsx from 'clsx';


// Import Custom Classes
import AuthNavigator from './AuthNavigator';
import AuthHeader from './AuthHeader';
import * as ROUTES from '../../../Constants/routes';
import * as UserInfoActionTypes from '../../../Redux/ActionTypes/Auth/UserInfoActionTypes';
import * as LayoutActionTypes from '../../../Redux/ActionTypes/UI/LayoutActionTypes';
import SetupAccountCheck from './SetupAccountCheck';


let theme = createMuiTheme({
  palette: {
    primary: {
      light: '#63ccff',
      main: '#07689F',
      dark: '#07689F',
    },
  },
  typography: {
    fontFamily: "Open Sans",
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  shape: {
    borderRadius: 8
    ,
  },
  mixins: {
    toolbar: {
      minHeight: 58,
    },
  },
});

theme = {
  ...theme,
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: '#DFDFDF',
      },
      paperAnchorDockedLeft: {
        borderRightColor: "#07689F"
      }
    },
    MuiButton: {
      label: {
        textTransform: 'none',
      },
      contained: {
        boxShadow: 'none',
        '&:active': {
          boxShadow: 'none',
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4
      },
    },
    MuiListItemText: {
      primary: {
        fontWeight: theme.typography.fontWeightMedium,
        color: '#444',
      }
      
    },
    MuiListItemIcon: {
      root: {
        color: '#444',
        marginRight: 0,
        '& svg': {
          fontSize: 20,
        },
      },
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32,
      },
    },
    MuiListItem: {
      root: {
        color: '#000'
      }
    },
    
  },
};

const drawerWidth = 220;

const styles = {
  root: {
    display: 'flex',
    minHeight: '100vh'
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0
    },
  },
  app: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  main: {
    flex: 1,
    background: '#E3E7EB',
  },
  footer: {
    padding: theme.spacing(2),
    background: '#E3E7EB',
  }
};

class AuthLayout extends Component {

  // state={
  //   isOpen: false,
  //   desktopOpen: false,
  //   mobileOpen: false,
  //   goToProfile: false,
  //   backdropOpen: false
  // }

  constructor(props) {
    super(props);
    this.handleSignOut = this.handleSignOut.bind(this);
    this.handleProfile = this.handleProfile.bind(this);

    this.state = {
      isOpen: false,
      desktopOpen: false,
      mobileOpen: false,
      goToProfile: false,
      backdropOpen: false
    }

    // Automatically show desktop
    if (this.props.size.width) {
      if (this.props.size.width >= 1330) {
        this.state.isOpen = true;
        this.state.desktopOpen = true;
      }
    }

  }


  componentDidUpdate() {
    if (this.props.auth !== {}) {
      if (this.props.auth.company_status === 'setup') {
        if (this.state.backdropOpen === false) {
          this.setState({...this.state, backdropOpen: true});
        }
      }
    }

    if (this.props.layoutWidth < 1330 && this.props.size.width >= 1330) {
      this.setState({...this.state, isOpen: true, desktopOpen: true, mobileOpen: false})
    }

    if (this.props.layoutWidth >= 1330 && this.props.size.width < 1330) {
      this.setState({...this.state, isOpen: false, desktopOpen: false, mobileOpen: false})
    }

    this.props.setLayoutWidth(this.props.size.width);
  }

  setMobileOpen() {
    this.setState({...this.state, mobileOpen: !this.state.mobileOpen})
  }

  handleMenuToggle = () => {
    const width = this.props.size.width;
    let newDesktopOpen = false;
    let newMobileOpen = false;

    if (width >= 1330) {
      newDesktopOpen = !this.state.isOpen;
      newMobileOpen = false;
    }else if (width < 1330 && width >= 800) {
      newDesktopOpen = false;
      newMobileOpen = !this.state.isOpen;
    }else {
      newDesktopOpen = false;
      newMobileOpen = !this.state.isOpen;
    }

    this.setState({...this.state, isOpen: !this.state.isOpen, desktopOpen: newDesktopOpen, mobileOpen: newMobileOpen});
  }

  getSidebarCase = () => {
    let result = "full";

    if (this.props.size.width >= 1330) {
      if (this.state.desktopOpen) {
        result = "full";
      }else {
        result = "medium";
      }
    }else if (this.props.size.width < 1330 && this.props.size.width > 800) {
      result = "medium";
      if (this.state.mobileOpen) {
        result = "popover-medium";
      }
    } else if (this.props.size.width <= 800 && this.props.size.width > 0) {
      result = "mobile";

      if (this.state.mobileOpen) {
        result = "popover-mobile";
      }
    }

    return result;
  }

  async handleSignOut() {
    try {
        await Auth.signOut();
        this.props.updateLoginStatus(false);
        this.props.removeUser();
        this.props.removeAuth();
        this.props.removeAuthCategories();
        this.props.removeSettingsCategories();
        this.props.history.push(ROUTES.LOG_IN);

    }catch(err) {
        console.log(err);
    }
  }

  handleProfile = () => {
    this.props.history.push(ROUTES.SETTINGS_USER);
  }



  render() {
    return (
      <div className={"auth-root"}>

        <AuthHeader
          handleSignOut={this.handleSignOut}
          onMenuToggle={this.handleMenuToggle}
          handleProfile={this.handleProfile}
          layoutWidth={this.props.size.width}
          desktopOpen={this.state.desktopOpen}
          mobileOpen={this.state.mobileOpen}
        />
        <div className="auth-body">
          <AuthNavigator
            PaperProps={{ style: { width: drawerWidth } }}
            open={this.state.mobileOpen}
            layoutWidth={this.props.size.width}
            desktopOpen={this.state.desktopOpen}
            mobileOpen={this.state.mobileOpen}
            onClose={this.handleDrawerToggle}
            onMenuToggle={this.handleMenuToggle}
          />
          <main 
            className={
              clsx({
                "auth-main": true, 
                "auth-full": this.getSidebarCase() === "full",
                "auth-medium": this.getSidebarCase() === "medium",
                "auth-mobile": this.getSidebarCase() === "mobile",
                "auth-popover-medium": this.getSidebarCase() === "popover-medium",
                "auth-popover-mobile": this.getSidebarCase() === "popover-mobile"
              })}
          >
            <SetupAccountCheck>
              {this.props.children}
            </SetupAccountCheck>
          </main>
        </div>
      </div>
    );
  };
}


const mapStateToProps = state => {
  return {
      user: state.auth.userInfo.user,
      auth: state.auth.userInfo.auth,
      layoutWidth: state.ui.layout.layoutWidth
  };
};

const mapDispatchToProps = dispatch => {
  return {
      updateLoginStatus: (isLoggedIn) => dispatch({ type: UserInfoActionTypes.UPDATE_LOGIN_STATUS, payload: { isLoggedIn: isLoggedIn } }),
      setLayoutWidth: (layoutWidth) => dispatch({type: LayoutActionTypes.SET_LAYOUT_WIDTH, payload: {layoutWidth: layoutWidth}}),
      removeUser: () => dispatch({ type: UserInfoActionTypes.REMOVE_USER}),
      removeAuth: () => dispatch({type: UserInfoActionTypes.REMOVE_AUTH}),
      removeAuthCategories: () => dispatch({ type: LayoutActionTypes.SET_AUTH_NAVIGATOR_CATEGORIES, payload: { authNavigatorCategories: []}}),
      removeSettingsCategories: () => dispatch({ type: LayoutActionTypes.SET_SETTINGS_CATEGORIES, payload: { settingsCategories: []}})
  };
};


export default SizeMe()(connect(mapStateToProps, mapDispatchToProps)((withStyles(styles)(withRouter((AuthLayout))))));