// Import Modules
import React, { Fragment, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';


// Import Custom Components
import { GET_USER_URL } from '../../graphql/UserQueries';

const UserDataContainer = (props) => {

    /**
     * Props:
     * setLoading(loading) : Function
     * setError(error) : Function
     * setData(data) : Function
     * auth: Auth Object
     * url_id: String
    */


    // Handle Get User URL GraphQL Query
    const { loading, error, data } = useQuery(GET_USER_URL, {
        variables: { url_id: props.url_id, company_id: props.auth.company_id }
    });


    useEffect(() => {
        const { setLoading, setError, setData } = props;
        setLoading(loading);
        setError(error);

        if (data) {
            setData(data.getUserURL);
        }
        // eslint-disable-next-line
    }, [loading, error, data])


    return (
        <Fragment>
            {props.children}
        </Fragment>
    );
};

export default UserDataContainer;