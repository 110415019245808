// Import Modules
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'; 
import clsx from 'clsx';
import Masonry from 'react-masonry-css';
import { LinkContainer } from 'react-router-bootstrap';
import _ from 'lodash';



// Import Custom Classes
import ScreenHeader from '../../../Components/UI/ScreenHeader';
import SettingsBox from '../../../Components/Boxes/SettingsBox';
import SettingsList from '../../../Constants/SettingsList';
import ErrorScreen from '../Other/ErrorScreen';
import PermissionsHelper from '../../../Helpers/PermissionsHelper';
import * as LayoutActionTypes from '../../../Redux/ActionTypes/UI/LayoutActionTypes';

const SettingsScreen = () => {

    const auth = useSelector(state => state.auth.userInfo.auth);
    const layoutWidth = useSelector(state => state.ui.layout.layoutWidth);
    const settingsCategories = useSelector(state => state.ui.layout.settingsCategories);
    const permissions_helper = new PermissionsHelper();
    const dispatch = useDispatch();
    const [isFirstLoaded, setIsFirstLoaded] = useState(false);


    useEffect(() => {
        if (!isFirstLoaded) {
            if (auth !== null && auth !== undefined && !_.isEmpty(auth)) {
                if (_.isEmpty(settingsCategories)) {
                    dispatch({ type: LayoutActionTypes.SET_SETTINGS_CATEGORIES, payload: { settingsCategories: permissions_helper.CreateSettingsCategories(SettingsList.categories, auth) } })
                    setIsFirstLoaded(true);
                }
            }
        }
    }, [auth, isFirstLoaded, settingsCategories,permissions_helper, dispatch]);

    // Client-Side Authorization Checks
    if (auth === null || auth === undefined || _.isEmpty(auth)) {
        return null;
    }

    if (!(auth.role_permissions.includes('settings_company') || auth.role_permissions.includes('settings_features') || auth.role_permissions.includes('settings_prohelper') || auth.role_permissions.includes('settings_data') || auth.role_permissions.includes('settings_owner'))) {
        return <ErrorScreen />;
    }

    return (
        <div className="BaseScreen" >
            <ScreenHeader
                title='Settings'
                isActionDisabled={true}
                isMoreDisabled={true}
                layoutWidth={layoutWidth}
            />
            <Masonry
                breakpointCols={{ default: 2, 1100: 2, 900: 1, 500: 1 }}
                className="screen-detail-masonry-grid"
                columnClassName="screen-detail-masonry-grid_column"
            >
                {settingsCategories.map(({ categoryTitle, categoryIdentifier, data }) => (
                    <div className={clsx('Width--Full-20', 'Margin--Horizontal--Auto', 'Margin--Bottom--20')} key={categoryIdentifier}>
                        <SettingsBox title={categoryTitle} >
                            <div className={clsx('Padding--Top--5', 'Padding--Bottom--20')}>
                                {data.map(({ screenTitle, screenDescription, route }) => (
                                    <LinkContainer to={route} style={{ paddingLeft: '20px', paddingRight: '20px', paddingTop: '15px' }} key={screenTitle} >
                                        <button className={clsx('HiddenButton', 'Pointer--Cursor')} >
                                            <p className={clsx('SubTitleSmall', 'SemiBoldText', 'TextAlignLeft', 'BlueTint')} style={{ marginBottom: '8px' }}>
                                                {screenTitle}
                                            </p>
                                            <p className={clsx('BodyText', 'TextAlignLeft')}>
                                                {screenDescription}
                                            </p>
                                        </button>
                                    </LinkContainer>
                                ))}
                            </div>
                        </SettingsBox>
                    </div>
                ))}
            </Masonry>
        </div>
    );
}

export default SettingsScreen;
