// Import Modules
import React, { Fragment, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ErrorScreen from '../../Other/ErrorScreen';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';


// Import Custom Components
import DataTableContainer from '../../../../Components/Containers/DataTableContainer';
import DataListContainer from '../../../../Components/Containers/DataListContainer';
import BoxSearch from '../../../../Components/Other/BoxSearch';
import BoxSortSelect from '../../../../Components/Other/BoxSortSelect';
import UsersListView from '../../../../Components/Views/UsersListView';
import UsersTableView from '../../../../Components/Views/UsersTableView';
import ScreenHeader from '../../../../Components/UI/ScreenHeader';
import BoxHeader from '../../../../Components/UI/BoxHeader';
import SortingOptions from '../../../../Constants/SortingOptions';
import * as ROUTES from '../../../../Constants/routes';
import NewUserPopUp from './NewUserPopUp';
import UsersDataContainer from '../../../../Components/DataContainers/UsersDataContainer';
import SuccessAlert from '../../../../Components/MaterialUI/SuccessAlert';

// Users Screen Desktop View (Table)
const UsersDesktopView = (props) => {

    let topContents = (
        <Fragment>
            <BoxHeader 
                text={(props.data.length || 0) + ' Users'}
            />
        </Fragment>
    );

    let leftContents = (
        <Fragment>
            <BoxSearch
                searchValuePlaceholder='Search users'
                containerStyle={{}}
                searchValue={props.searchValue}
                isSearchFocused={props.isSearchFocused  || false}
                onSearchFocused={props.onSearchFocused}
                onSearchValueChange={(event) => props.onSearchValueChange(event)}
                onSearchFocusedOut={props.onSearchFocusedOut}
                onClearButtonClicked={props.onClearButtonClicked}
                onSearchButtonClicked={props.onSearchButtonClicked}
            />
        </Fragment>
    );

    let rightContents = (
        <Fragment>
            <BoxSortSelect 
                value={props.sortValue}
                defaultValue={props.sortDefaultValue}
                options={props.sortOptions}
                onChange={(event) => props.onSortChanged(event)}
            />
        </Fragment>
    );

    return (
        <DataTableContainer 
            topContents={topContents}
            leftContents={leftContents}
            rightContents={rightContents}
            data={props.data}
        >
            <UsersTableView 
                loading={props.loading}
                error={props.error}
                data={props.data}
                loadMore={props.loadMore}
                onUserClicked={(user) => props.onUserClicked(user)}
            />
        </DataTableContainer>
    );
};

// Users Screen Mobile View (List)
const UsersMobileView = (props) => {

    let topContents = (
        <Fragment>
            <BoxHeader 
                text={(props.data.length || 0) + ' Users'}
            />
        </Fragment>
    );

    let leftContents = (
        <Fragment>
            <BoxSearch
                searchValuePlaceholder='Search users'
                containerStyle={{}}
                searchValue={props.searchValue}
                isSearchFocused={props.isSearchFocused  || false}
                onSearchFocused={props.onSearchFocused}
                onSearchFocusedOut={props.onSearchFocusedOut}
                onSearchValueChange={(event) => props.onSearchValueChange(event)}
                onClearButtonClicked={props.onClearButtonClicked}
                onSearchButtonClicked={props.onSearchButtonClicked}
            />
        </Fragment>
    );


    let rightContents = (
        <Fragment>
            <BoxSortSelect 
                value={props.sortValue}
                defaultValue={props.sortDefaultValue}
                options={props.sortOptions}
                onChange={(event) => props.onSortChanged(event)}
            />
        </Fragment>
    );

    return (
        <DataListContainer 
            topContents={topContents}
            leftContents={leftContents}
            rightContents={rightContents}
        >
            <UsersListView 
                loading={props.loading}
                error={props.error}
                data={props.data}
                loadMore={props.loadMore}
                onUserClicked={(user) => props.onUserClicked(user)}
            />
        </DataListContainer>
    );
};

const UsersScreen = (props) => {

    // Get / Initialize Variables
    const auth = useSelector(state => state.auth.userInfo.auth);
    const layoutWidth = useSelector(state => state.ui.layout.layoutWidth);
    const [isFirstLoaded, setIsFirstLoaded] = useState(false);
    const [searchFocused, setSearchFocused] = useState(false);
    const [dataContainerLoading, setDataContainerLoading] = useState(false);
    const [dataContainerError, setDataContainerError] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [rawUsersList, setRawUsersList] = useState([]);
    const [sortedUsersList, setSortedUsersList] = useState([]);
    const [newUserActionStatus, setNewUserActionStatus] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [sortValue, setSortValue] = useState({ label: 'First Name: A-Z', value: 'first_name_asc' });
    const [newUserPopUpOpen, setNewUserPopUpOpen] = useState(false);
    const history = useHistory();


    // Setup Users Screen
    useEffect(()=> {
        if (!isFirstLoaded) {
            if (auth !== null && auth !== undefined && !_.isEmpty(auth)) { 
                if (auth.role_permissions.includes('team_user_create')) {
                    setNewUserActionStatus(true);
                }

                if (sortedUsersList === {}) {
                    setSortedUsersList(createSortedList(rawUsersList, '', sortValue));
                }
                setIsFirstLoaded(true);
            }
        }
    }, [isFirstLoaded, auth, sortValue, rawUsersList, sortedUsersList]);



    // Client-Side Authorization Checks
    if (auth === null || auth === undefined || _.isEmpty(auth)) {
        return null;
    }
    
    if (!auth.role_permissions.includes('team_users')) {
        return <ErrorScreen />;
    }


    // Functions Area
    const onUserClicked = (user) => {
        history.push(ROUTES.TEAM_USER_DETAIL_PREFIX + user.url_id);
    };

    const onNewUserClicked = () => {
        setNewUserPopUpOpen(true);
    };

    const onLoadMoreUsers = () => {

    };

    const onSearchFocused = () => {
        setSearchFocused(true);
    };

    const onSearchValueChange = (event) => {
        setSearchValue(event.target.value);
        setSortedUsersList(createSortedList(rawUsersList, event.target.value, sortValue));
    };

    const createSortedList = (rawData, searchText, sortOption) => {
        // Load Data
        let newSortedList = [ ...rawData ];

        // Check to Filter Results
        if (searchText !== '') {
            // Create new filtered list
            newSortedList = newSortedList.filter((value, index, arr) => {
                let result = false;

                if (value.full_name) {
                    if (value.full_name.toLowerCase().includes(searchText.toLowerCase())) {
                        result = true;
                    }
                }

                if (value.email) {
                    if (value.email.toLowerCase().includes(searchText.toLowerCase())) {
                        result = true;
                    }
                }

                if (value.role) {
                    if (value.role.name.toLowerCase().includes(searchText.toLowerCase())) {
                        result = true;
                    }
                }

                return result;
            });
        }


        // Sort List

        switch (sortOption.value) {
            case 'first_name_asc':
                newSortedList.sort((a, b) => {
                    if (a.first_name < b.first_name) { return -1; }
                    if (a.first_name > b.first_name) { return 1; }
                    return 0;
                });
                break;
            case 'first_name_dec':
                newSortedList.sort((a, b) => {
                    if (a.first_name > b.first_name) { return -1; }
                    if (a.first_name < b.first_name) { return 1; }
                    return 0;
                });
                break;
            case 'last_name_asc':
                newSortedList.sort((a, b) => {
                    if (a.last_name < b.last_name) { return -1; }
                    if (a.last_name > b.last_name) { return 1; }
                    return 0;
                });
                break;
            case 'last_name_dec':
                newSortedList.sort((a, b) => {
                    if (a.last_name > b.last_name) { return -1; }
                    if (a.last_name < b.last_name) { return 1; }
                    return 0;
                });
                break;
            case 'account_status_dec':
                newSortedList.sort((a, b) => {
                    if (a.active > b.active) { return -1; }
                    if (a.active < b.active) { return 1; }
                    return 0;
                });
                break;
            case 'last_updated_dec':
                newSortedList.sort((a, b) => {
                    if (a.last_updated_at > b.last_updated_at) { return -1; }
                    if (a.last_updated_at < b.last_updated_at) { return 1; }
                    return 0;
                });
                break;
            case 'created_at_dec':
                newSortedList.sort((a, b) => {
                    if (a.created_at > b.created_at) { return -1; }
                    if (a.created_at < b.created_at) { return 1; }
                    return 0;
                });
                break;
            default:
                break;
        }


        return newSortedList;
    };

    const onSearchFocusedOut = () => {
        if (searchValue === '') {
            setSearchFocused(false);
        }
    };

    const onClearButtonClicked = () => {
        setSearchValue('');
        setSortedUsersList(createSortedList(rawUsersList, '', sortValue));
        setSearchFocused(false);
    };

    const onSearchButtonClicked = () => {

    };

    const onSortChanged = (selectedOption) => {
        setSortValue(selectedOption);
        setSortedUsersList(createSortedList(rawUsersList, '', selectedOption));
    };

    const onNewUserPopUpClosed = () => {
        // Need to clear past entries.
        setNewUserPopUpOpen(false);
    };

    const onMoreOptionsClicked = () => {
        console.log('Users Screen Options Clicked');
    };

    const onNewUserPopUpNewUser = (user) => {
        setNewUserPopUpOpen(false);
        setSnackbarMessage('New User Created Successfully.');
        setSnackbarOpen(true);
    };

    const onDataContainerUpdated = (newUserData) => {
        setRawUsersList(newUserData);
        setSortedUsersList(createSortedList(newUserData, searchValue, sortValue));
    };

    const handleSuccessSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };

    // Return valid users screen depending on desktop or mobile
    return (
        <div className="BaseScreen">
            <UsersDataContainer
                setLoading={(loading)=> setDataContainerLoading(loading)}
                setError={(error)=> setDataContainerError(error)}
                setData={(data) => onDataContainerUpdated(data)}
                auth={auth}
            >
                <NewUserPopUp
                    open={newUserPopUpOpen}
                    onClose={onNewUserPopUpClosed}
                    onNewUserCreated={(user) => { onNewUserPopUpNewUser(user) }}
                />
                <ScreenHeader
                    title='Users'
                    actionText='New User'
                    onActionClicked={onNewUserClicked}
                    isActionDisabled={!newUserActionStatus}
                    onMoreClicked={onMoreOptionsClicked}
                    isMoreDisabled={false}
                    layoutWidth={layoutWidth}
                />
                {(layoutWidth < 700) ?
                    <UsersMobileView
                        onNewUserClicked={onNewUserClicked}
                        onUserClicked={(user) => onUserClicked(user)}
                        data={sortedUsersList}
                        loading={dataContainerLoading}
                        error={dataContainerError}
                        loadMore={onLoadMoreUsers}
                        searchValue={searchValue}
                        isSearchFocused={searchFocused}
                        onSearchFocused={onSearchFocused}
                        onSearchFocusedOut={onSearchFocusedOut}
                        onSearchValueChange={onSearchValueChange}
                        onClearButtonClicked={onClearButtonClicked}
                        onSearchButtonClicked={onSearchButtonClicked}
                        sortValue={sortValue}
                        sortDefaultValue={{ label: 'First Name: A-Z', value: 'first_name_asc' }}
                        sortOptions={SortingOptions.UsersSortingOptions}
                        onSortChanged={(event) => onSortChanged(event)}
                    />
                    :
                    <UsersDesktopView
                        onNewUserClicked={onNewUserClicked}
                        onUserClicked={(user) => onUserClicked(user)}
                        data={sortedUsersList}
                        loading={dataContainerLoading}
                        error={dataContainerError}
                        loadMore={onLoadMoreUsers}
                        searchValue={searchValue}
                        isSearchFocused={searchFocused}
                        onSearchFocused={onSearchFocused}
                        onSearchValueChange={(event) => onSearchValueChange(event)}
                        onSearchFocusedOut={onSearchFocusedOut}
                        onClearButtonClicked={onClearButtonClicked}
                        onSearchButtonClicked={onSearchButtonClicked}
                        sortValue={sortValue}
                        sortDefaultValue={{ label: 'First Name: A-Z', value: 'first_name_asc' }}
                        sortOptions={SortingOptions.UsersSortingOptions}
                        onSortChanged={(event) => onSortChanged(event)}
                    />
                }
            </UsersDataContainer>
            <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    open={snackbarOpen}
                    autoHideDuration={5000}
                    onClose={handleSuccessSnackbarClose}
                    action={
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleSuccessSnackbarClose}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    }
                    variant="Success"
                >
                    <SuccessAlert onClose={handleSuccessSnackbarClose} severity="success">
                        {snackbarMessage}
                    </SuccessAlert>
                </Snackbar>
        </div>
    );
};

export default UsersScreen;
