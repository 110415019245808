// Import Modules
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import clsx from 'clsx';
import EditIcon from '@material-ui/icons/Edit';
import { useMutation } from '@apollo/react-hooks';
import PulseLoader from 'react-spinners/PulseLoader';


// Import Custom Components
import ErrorScreen from '../../Other/ErrorScreen';
import ScreenDetailHeader from '../../../../Components/UI/ScreenDetailHeader';
import * as ROUTES from '../../../../Constants/routes';
import ActionBox from '../../../../Components/Boxes/ActionBox';
import SettingsTextField from '../../../../Components/MaterialUI/SettingsTextField';
import SettingsTextFieldMultiLine from '../../../../Components/MaterialUI/SettingsTextFieldMultiLine';
import BoxFooterButton from '../../../../Components/Buttons/BoxFooterButton';
import PermissionCategoriesContainer from '../../../../Components/Containers/PermissionCategoriesContainer';
import PermissionsHelper from '../../../../Helpers/PermissionsHelper';
import ProHelperPermissions from '../../../../Constants/ProHelperPermissions';
import RoleDataContainer from '../../../../Components/DataContainers/RoleDataContainer';
import { UPDATE_ROLE } from '../../../../graphql/RoleMutations';


const EditRoleScreen = (props) => {

    // Clientside Permissions validation
    const auth = useSelector(state => state.auth.userInfo.auth);
    const [roleName, setRoleName] = useState('');
    const [roleDescription, setRoleDescription] = useState('');
    const [roleData, setRoleData] = useState(null);
    const [dataContainerLoading, setDataContainerLoading] = useState(false);
    const [dataContainerError, setDataContainerError] = useState(undefined);
    const layoutWidth = useSelector(state => state.ui.layout.layoutWidth);
    const history = useHistory();
    const { id } = useParams();

    const permissionsHelper = new PermissionsHelper();
    const [permissionCategories, setPermissionCategories] = useState([]);

    // Setup Edit Role Screen
    useEffect(()=> {
        if (auth !== null && auth !== undefined && !_.isEmpty(auth)) { 
            if (roleData) {
                setRoleName(roleData.name);
                setRoleDescription(roleData.description);
                setPermissionCategories(permissionsHelper.CreatePermissionCatelog(
                    ProHelperPermissions.companyPermissions, 
                    {open_categories: {'Settings': true, 'Customers': true, 'Work': true, 'Schedule': true, 'Billing': true, 'Team': true, 'Reports': true}, role: roleData}
                ));
            }

            if (permissionCategories === []) {
                permissionsHelper.CreatePermissionCatelog(
                    ProHelperPermissions.companyPermissions, 
                    {open_categories: {'Settings': true, 'Customers': true, 'Work': true, 'Schedule': true, 'Billing': true, 'Team': true, 'Reports': true}, role: roleData}
                )
            }
        }
        
    }, [auth, roleData, permissionsHelper, permissionCategories]);

    const [updateRoleAction, { loading: mutationLoading, error: mutationError }] = useMutation(UPDATE_ROLE);

    if (auth === null || auth === undefined || _.isEmpty(auth) || _.isEmpty(auth.role_permissions)) {
        return null;
    }

    if (!auth.role_permissions.includes('team_role_create')) {
        return <ErrorScreen />;
    }

    const onSetRoleName = (newRoleName) => {
        setRoleName(newRoleName);
    };

    const onSetRoleDescription = (newRoleDescription) => {
        setRoleDescription(newRoleDescription);
    };

    const onCancelClicked = () => {
        
    };

    const onFinishEditRoleClicked = () => {
        let updateRoleInput = {
            role_id: roleData.id,
            role_name: undefined, 
            role_description: undefined, 
            permissions: permissionsHelper.GetSelectedPermissionIDs(permissionCategories), 
            user_id: auth.id,  
            company_id: auth.company_id
        }

        if (roleName !== '' && roleData.name !== roleName) {
            updateRoleInput.role_name = roleName;
        }

        if (roleDescription !== '' && roleData.description !== roleDescription) {
            updateRoleInput.role_description = roleDescription;
        }else if (roleDescription === '') {
            updateRoleInput.role_description = '';
        }

        updateRoleAction({variables: {updateRoleInput: updateRoleInput}})
            .then((result) => {
                history.goBack();
            })
            .catch((error) => {

            });
    };

    const onBackClicked = () => {
        history.push(ROUTES.TEAM_ROLE_DETAIL_PREFIX + id);
    };

    const onPermissionsCategoryClicked = (permissionCategory) => {
        const updateObject = {
            action: 'updated_category',
            value: permissionCategory
        };

        let newPermissionsCategories = permissionsHelper.UpdatePermissionCatelog(permissionCategories, updateObject);

        setPermissionCategories(newPermissionsCategories);
    };

    const onPermissionButtonClicked = (permission) => {
        const updateObject = {
            action: 'updated_permission',
            value: permission
        };

        let newPermissionsCategories = permissionsHelper.UpdatePermissionCatelog(permissionCategories, updateObject);

        setPermissionCategories(newPermissionsCategories);
    };

    const checkIsValid = () => {
        let result = false;

        if (roleName !== '') {
            let selectedPermissions = permissionsHelper.GetSelectedPermissionIDs(permissionCategories);
            let old_role_permission_ids = roleData.permissions.map((permission) => parseInt(permission.id));
            if ((roleName !== roleData.name || roleDescription !== roleData.description || _.xor(old_role_permission_ids, selectedPermissions).length > 0) && selectedPermissions.length > 0) {
                result = true;
            }
        }

        return result;
    };

    const onRoleUpdated = (role) => {
        setRoleData(role);

        let newPermissionsCategories = [];

        if (permissionCategories !== []) {
            newPermissionsCategories = permissionsHelper.CreatePermissionCatelog(
                ProHelperPermissions.companyPermissions, 
                {open_categories: permissionsHelper.GetOpenCategories(permissionCategories), role: role}
            );
        }else {
            newPermissionsCategories = permissionsHelper.CreatePermissionCatelog(
                ProHelperPermissions.companyPermissions, 
                {open_categories: {'Settings': true, 'Customers': true, 'Work': true, 'Schedule': true, 'Billing': true, 'Team': true, 'Reports': true}, role: roleData}
            );
        }

        setPermissionCategories(newPermissionsCategories);
    };


    const handleLoadingArea = () => {
        if (dataContainerLoading || mutationLoading) {
            return (
                <PulseLoader
                    css={`margin-bottom: 20px;`}
                    size={20}
                    sizeUnit={"px"}
                    margin="5px"
                    color={'#0A3F79'}
                    loading={true}
                />
            );
        } else {
            return null;
        }
    };

    const handleErrorArea = () => {
        if (dataContainerError) {
            let splitMessage = dataContainerError.message.split(':');
            let errorMessage  = 'Error:' + splitMessage[splitMessage.length - 1];
            return (
                <p className={clsx('Margin--Bottom--20', 'Margin--Horizontal--20', 'TextAlignCenter', 'SubTitle', 'SemiBoldText', 'RedTint')}>
                    {errorMessage}
                </p>
            );
        } else if (mutationError) {
            let splitMessage = mutationError.message.split(':');
            let errorMessage  = 'Error:' + splitMessage[splitMessage.length - 1];
            return (
                <p className={clsx('Margin--Bottom--20', 'Margin--Horizontal--20', 'TextAlignCenter', 'SubTitle', 'SemiBoldText', 'RedTint')}>
                    {errorMessage}
                </p>
            );
        } else {
            return null;
        }
    };

    
    return (
        <div className={clsx('BaseScreen')}>
            <RoleDataContainer
                setLoading={(loading) => setDataContainerLoading(loading)}
                setError={(error) => setDataContainerError(error)}
                setData={(data) => onRoleUpdated(data)}
                auth={auth}
                url_id={id}
            >
                <ScreenDetailHeader
                    screenTitle='Edit Role'
                    backText='Roles'
                    actionText='Edit Role'
                    actionIcon={<EditIcon style={{ color: 'white', fontSize: '22', fontWeight: '600', marginTop: 'auto', marginBottom: 'auto' }} />}
                    onActionClicked={() => { onFinishEditRoleClicked() }}
                    isActionHidden={false}
                    isActionDisabled={!checkIsValid()}
                    isMoreDisabled={true}
                    layoutWidth={layoutWidth}
                    onBackClicked={onBackClicked}
                />
                {handleLoadingArea()}
                {handleErrorArea()}
                {(!_.isEmpty(roleData) || roleData === undefined) ?

                <div className={clsx('Padding--Horizontal--20', 'Margin--Bottom--20')}>
                    <ActionBox
                        title='Role Details'
                    >
                        <div className={clsx('Padding--Horizontal--20')}>
                            <SettingsTextField
                                label='Role Name'
                                value={roleName}
                                onChange={event => onSetRoleName(event.target.value)}
                                type='text'
                                containerStyle={{}}
                                placeholder='Enter Role Name'
                                tabIndex={1}
                            />
                            <SettingsTextFieldMultiLine
                                label='Role Description'
                                value={roleDescription}
                                onChange={event => onSetRoleDescription(event.target.value)}
                                type='text'
                                containerStyle={{}}
                                placeholder='Enter Role Description (Optional)'
                                tabIndex={2}
                            />
                            <div className='Spacer--20' />
                        </div>

                    </ActionBox>

                    <div className='Spacer--20' />

                    <ActionBox
                        title='Permissions'
                    >
                        <PermissionCategoriesContainer
                            data={permissionCategories}
                            onCategoryClick={(permissionCategory) => onPermissionsCategoryClicked(permissionCategory)}
                            onPermissionButtonClicked={(permission) => onPermissionButtonClicked(permission)}
                            isEditable={true}
                        />
                    </ActionBox>

                    <div className='Spacer--20' />

                    <div className={clsx(
                        'BackgroundColor--WhiteTint',
                        'BorderRadius--All--10',
                        'Border--All--1',
                        'BorderColor--DarkBlueTint',
                        'BoxShadow--1',
                        'TextAlignLeft',
                        'Padding--Horizontal--20',
                        'Padding--Vertical--15',
                        'Display--Flex',
                        'Flex--End')}
                    >
                        <BoxFooterButton
                            title='Cancel'
                            titleClasses={['DarkBlueTint', 'SemiBoldText']}
                            buttonStyles={{ backgroundColor: 'white', minHeight: '44px' }}
                            onClick={onCancelClicked}
                            isDisabled={false}
                        />
                        <BoxFooterButton
                            title='Edit Role'
                            titleClasses={['WhiteTint', 'BoldText']}
                            buttonStyles={{ marginLeft: '8px' }}
                            onClick={onFinishEditRoleClicked}
                            icon={<EditIcon style={{ color: 'white', fontSize: '22', fontWeight: '600', marginTop: 'auto', marginBottom: 'auto', marginLeft: "5px", padding: '0px' }} />}
                            isDisabled={!checkIsValid()}
                        />
                    </div>
                </div>
                : null}
            </RoleDataContainer>
        </div>
    );
};

export default EditRoleScreen;
