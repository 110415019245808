// Import Modules
import React from 'react';
import clsx from 'clsx';
import _ from 'lodash';
import { useSelector } from 'react-redux';

// Import Custom Classes
import LegalBox from '../../Components/Boxes/LegalBox';
import SettingsBreadcrumbs from '../../Components/UI/SettingsBreadcrumbs';
import * as ROUTES from '../../Constants/routes';


const TOSScreen = (props) => {

    // Get Auth for Breadcrumbs
    const auth = useSelector(state => state.auth.userInfo.auth);

    return (
        <div className="BaseScreen">
            <SettingsBreadcrumbs
                screenTitle='Terms of Service'
                screenDescription='View our terms of service to learn more about our platform guidelines.'
                route={ROUTES.TOS}
                fromScreenTitle={!_.isEmpty(auth) ? 'User Settings' : 'Sign Up'}
                fromScreenRoute={!_.isEmpty(auth) ? ROUTES.SETTINGS_USER : ROUTES.SIGN_UP}
            />

            <LegalBox>
                <p className={clsx('SubTitle', 'DarkBlueTint', 'TextAlignLeft', 'SemiBoldText')} >
                    Sub Title
                </p>
                <p className={clsx('BodyText', 'DarkBlueTint', 'TextAlignLeft')} style={{ paddingTop: '15px' }}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    Fringilla phasellus faucibus scelerisque eleifend donec pretium vulputate sapien. Aliquet enim tortor at auctor.
                    Sed euismod nisi porta lorem mollis. Erat nam at lectus urna duis convallis convallis. Cras ornare arcu dui vivamus.
                    Integer eget aliquet nibh praesent tristique. Integer feugiat scelerisque varius morbi enim nunc.
                    Pellentesque diam volutpat commodo sed egestas.
                </p>
            </LegalBox>
        </div>
    );
};
export default TOSScreen;