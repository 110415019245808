// Import Modules
import React, { Fragment, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';


// Import Custom Components
import { GET_USER } from '../../graphql/UserQueries';

const UserDataContainer = (props) => {

    /**
     * Props:
     * setLoading(loading) : Function
     * setError(error) : Function
     * setData(data) : Function
     * auth: Auth Object
     * id: String
    */

    // Handle Get User GraphQL Query
    const { loading, error, data } = useQuery(GET_USER, {
        variables: { id: props.id }
    });


    useEffect(() => {
        const { setLoading, setError, setData } = props;
        setLoading(loading);
        setError(error);

        if (data) {
            setData(data.getUser);
        }
        // eslint-disable-next-line
    }, [loading, error, data])


    return (
        <Fragment>
            {props.children}
        </Fragment>
    );
};

export default UserDataContainer;