// Import Modules
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import clsx from 'clsx';
import Masonry from 'react-masonry-css';
import { useHistory, useParams } from 'react-router-dom';
import PulseLoader from 'react-spinners/PulseLoader';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

// Import Custom Components
import ErrorScreen from '../../Other/ErrorScreen';
import ScreenDetailHeader from '../../../../Components/UI/ScreenDetailHeader';
import * as ROUTES from '../../../../Constants/routes';
import ActionBox from '../../../../Components/Boxes/ActionBox';
import UserDetailsView from '../../../../Components/Views/UserDetailsView';
import ContactDetailsView from '../../../../Components/Views/ContactDetailsView';
import UserRoleDetailsView from '../../../../Components/Views/UserRoleDetailsView';
import UserDataContainer from '../../../../Components/DataContainers/UserDataContainer';
import ChangeUserRolePopUp from './ChangeUserRolePopUp';
import SuccessAlert from '../../../../Components/MaterialUI/SuccessAlert';
import ResetPasswordPopUp from './ResetPasswordPopUp';
import UserActivationPopUp from './UserActivationPopUp';

const UserDetailScreen = (props) => {

    // Get / Initialize Variables
    const auth = useSelector(state => state.auth.userInfo.auth);
    const layoutWidth = useSelector(state => state.ui.layout.layoutWidth);
    const [editUserActionStatus, setEditUserActionStatus] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [dataContainerLoading, setDataContainerLoading] = useState(false);
    const [dataContainerError, setDataContainerError] = useState(null);
    const [changeRolePopUpOpen, setChangeRolePopUpOpen] = useState(false);
    const [resetPasswordPopUpOpen, setResetPasswordPopUpOpen] = useState(false);
    const [userActivationPopUpOpen, setUserActivationPopUpOpen] = useState(false);
    const [userData, setUserData] = useState({});
    const history = useHistory();
    const { id } = useParams();


    // Setup User Detail Screen
    useEffect(()=> {
        if (auth !== null && auth !== undefined && !_.isEmpty(auth)) { 
            if (auth.role_permissions.includes('team_user_update')) {
                if (!_.isEmpty(userData)) {
                    setEditUserActionStatus(true);
                    
                }
            }
        }
        
    }, [auth, userData]);


    // Client-Side Authorization Checks
    if (auth === null || auth === undefined || _.isEmpty(auth)) {
        return null;
    }


    if (!auth.role_permissions.includes('team_user_read')) {
        return <ErrorScreen />;
    }


    const userMenuActions = [
        {
            text: 'Change Role',
            onItemClicked: () => {
                setChangeRolePopUpOpen(true)
            }
        },
        {
            text: 'Reset Password',
            onItemClicked: () => {
                setResetPasswordPopUpOpen(true);
            }
        },
        {
            text: userData.active ? 'Deactivate User' : 'Activate User',
            onItemClicked: () => {
                setUserActivationPopUpOpen(true);
            }
        }
    ];


    const onBackClicked = () => {
        history.push(ROUTES.TEAM_USERS);
    };

    const onEditUserClicked = () => {
        history.push(ROUTES.TEAM_USER_DETAIL_PREFIX + id + '/edit');
    };

    const onRoleClicked = (role) => {
        history.push(ROUTES.TEAM_ROLE_DETAIL_PREFIX + role.url_id);
    };

    const onChangeUserRoleClosed = () => {
        setChangeRolePopUpOpen(false);
    };

    const onChangeUserRoleSuccess = () => {
        setChangeRolePopUpOpen(false);
        setSnackbarMessage("User's Role Changed Successfully.");
        setSnackbarOpen(true);
    };

    const handleLoadingArea = () => {
        if (dataContainerLoading) {
            return (
                <PulseLoader
                    css={`margin-top: 20px;`}
                    size={20}
                    sizeUnit={"px"}
                    margin="5px"
                    color={'#0A3F79'}
                    loading={true}
                />
            );
        } else {
            return null;
        }
    };

    const handleErrorArea = () => {
        if (dataContainerError) {
            let splitMessage = dataContainerError.message.split(':');
            let errorMessage  = 'Error:' + splitMessage[splitMessage.length - 1];
            return (
                <p className={clsx('Margin--Vertical--20', 'Margin--Horizontal--Auto', 'TextAlignCenter', 'SubTitle', 'SemiBoldText', 'RedTint')}>
                    {errorMessage}
                </p>
            );
        } else {
            return null;
        }
    };

    const handleSuccessSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };

    const onResetPasswordPopUpClosed = () => {
        setResetPasswordPopUpOpen(false);
    };

    const onUserActivationPopUpClosed = () => {
        setUserActivationPopUpOpen(false);
    };
    
    return (
        <div className='BaseScreen' style={{ paddingBottom: '20px' }}>
            <UserDataContainer
                setLoading={(loading) => setDataContainerLoading(loading)}
                setError={(error) => setDataContainerError(error)}
                setData={(data) => setUserData(data)}
                auth={auth}
                url_id={id}
            >
                <ChangeUserRolePopUp
                    data={userData}
                    open={changeRolePopUpOpen}
                    onClose={onChangeUserRoleClosed}
                    onChangeUserRole={onChangeUserRoleSuccess}
                />
                <ResetPasswordPopUp 
                    open={resetPasswordPopUpOpen}
                    data={userData}
                    onClose={onResetPasswordPopUpClosed}
                />
                <UserActivationPopUp 
                    open={userActivationPopUpOpen}
                    data={userData}
                    onClose={onUserActivationPopUpClosed}
                />
                <ScreenDetailHeader
                    screenTitle={!_.isEmpty(userData) ? userData.display_name : ''}
                    backText='Users'
                    actionText='Edit User'
                    moreText='More Options'
                    isActionHidden={!editUserActionStatus}
                    isActionDisabled={!editUserActionStatus}
                    isMoreDisabled={!editUserActionStatus}
                    layoutWidth={layoutWidth}
                    onActionClicked={onEditUserClicked}
                    onBackClicked={onBackClicked}
                    menuData={userMenuActions}
                />
                {handleLoadingArea()}
                {handleErrorArea()}
                {(!_.isEmpty(userData) || userData === undefined) ?
                    <Masonry
                        breakpointCols={{ default: 3, 1600: 2,  700: 1}}
                        className="screen-detail-masonry-grid"
                        columnClassName="screen-detail-masonry-grid_column"
                    >
                        <div className={clsx('Width--Full-20', 'Margin--Horizontal--Auto', 'Margin--Bottom--20')}>
                            <ActionBox
                                title={'User Details'}
                            >
                                <UserDetailsView
                                    data={userData}
                                />
                            </ActionBox>
                        </div>
                        <div className={clsx('Width--Full-20', 'Margin--Horizontal--Auto', 'Margin--Bottom--20')}>
                            <ActionBox
                                title={'Contact Details'}
                            >
                                <ContactDetailsView
                                    data={userData}
                                />
                            </ActionBox>
                        </div>
                        <div className={clsx('Width--Full-20', 'Margin--Horizontal--Auto')}>
                            <ActionBox
                                title={'Role Details'}
                            >
                                <UserRoleDetailsView
                                    data={userData.role || {}}
                                    onRoleClicked={(role) => onRoleClicked(role)}
                                />
                            </ActionBox>
                        </div>
                    </Masonry>
                    : null
                }
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    open={snackbarOpen}
                    autoHideDuration={5000}
                    onClose={handleSuccessSnackbarClose}
                    action={
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleSuccessSnackbarClose}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    }
                    variant="Success"
                >
                    <SuccessAlert onClose={handleSuccessSnackbarClose} severity="success">
                        {snackbarMessage}
                    </SuccessAlert>
                </Snackbar>
            </UserDataContainer>

        </div>
    );
};

export default UserDetailScreen;
