import React from 'react';
import _ from 'lodash';
import RolesListCell from '../Cells/RolesListCell';
import InfiniteScroll from 'react-infinite-scroller';
import PulseLoader from 'react-spinners/PulseLoader';
import clsx from 'clsx';


const RolesListView = (props) => {

    const showLoadingAnimation = () => {
        return (
            <PulseLoader
                css={`margin-top: 20px;`}
                size={18}
                sizeUnit={"px"}
                margin="5px"
                color={'#0A3F79'}
                loading={true}
            />
        );
    };

    
    return (
        <div className="UsersListView">
            {props.loading ? showLoadingAnimation() : null}
            {(props.data && !_.isEmpty(props.data)) ?
                <InfiniteScroll
                    pageStart={0}
                    loadMore={props.loadMore}
                    hasMore={false}
                    loader={
                        <PulseLoader
                            key='pulserLoaderKey'
                            css={`margin: 10px;`}
                            size={20}
                            sizeUnit={"px"}
                            margin="5px"
                            color={'#0A3F79'}
                            loading={true}
                        />
                    }
                >
                    {props.data.map((role) => {
                        return (
                            <RolesListCell
                                key={role.id}
                                data={role}
                                onClick={() => props.onRoleClicked(role)}
                            />
                        );
                    })}
                </InfiniteScroll>
                :
                <p className={clsx('Margin--Vertical--20', 'Margin--Horizontal--Auto', 'TextAlignCenter', 'DarkGreyTint', 'SubTitleSmall', 'SemiBoldText')}>
                    No Roles Available.
                </p>
            }
        </div>
    );
};

export default RolesListView;
