import AssignmentIcon from '@material-ui/icons/Assignment';
import WorkIcon from '@material-ui/icons/Work';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import ReceiptIcon from '@material-ui/icons/Receipt';
import StorefrontIcon from '@material-ui/icons/Storefront';
import DraftsIcon from '@material-ui/icons/Drafts';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
import PermContactCalendarOutlinedIcon from '@material-ui/icons/PermContactCalendarOutlined';
import ViewWeekIcon from '@material-ui/icons/ViewWeek';
import SettingsIcon from '@material-ui/icons/Settings';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';
import BusinessIcon from '@material-ui/icons/Business';
import HomeIcon from '@material-ui/icons/Home';
import PaymentIcon from '@material-ui/icons/Payment';
import InboxIcon from '@material-ui/icons/Inbox';
import TimerIcon from '@material-ui/icons/Timer';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import PieChartIcon from '@material-ui/icons/PieChart';
import * as ROUTES from './routes';
import React from 'react';


export default {
    all_auth_categories: [
        {
          id: 'Work',
          children: [
            { id: 'Tasks', icon: <AssignmentIcon/>, route: ROUTES.TASKS },
            { id: 'Jobs', icon: <WorkIcon />, route: ROUTES.JOBS },
            { id: 'Routes', icon: <DirectionsCarIcon/>, route: ROUTES.ROUTES },
            { id: 'Requests', icon: <InboxIcon/>, route: ROUTES.REQUESTS  },
          ],
        },
        {
          id: 'Schedule',
          children: [
            { id: 'Calender', icon: <DateRangeOutlinedIcon />, route: ROUTES.CALENDER  },
            { id: 'Planner', icon: <ViewWeekIcon />, route: ROUTES.PLANNER  },
            { id: 'Boards', icon: <ViewColumnIcon />, route: ROUTES.BOARD  },
          ],
        },
        {
          id: 'Customers',
          children: [
            { id: 'Accounts', icon: <BusinessIcon />, route: ROUTES.ACCOUNTS  },
            { id: 'Contacts', icon: <PermContactCalendarOutlinedIcon />, route: ROUTES.CONTACTS  },
            { id: 'Properties', icon: <HomeIcon />, route: ROUTES.PROPERTIES  },
          ],
        },
        {
          id: 'Billing',
          children: [
            { id: 'All Sales', icon: <MonetizationOnIcon />, route: ROUTES.ALL_SALES },
            { id: 'Invoices', icon: <ReceiptIcon />, route: ROUTES.INVOICES },
            { id: 'Estimates', icon: <DraftsIcon />, route: ROUTES.ESTIMATES  },
            { id: 'Payments', icon: <PaymentIcon />, route: ROUTES.PAYMENTS },
            { id: 'Items', icon: <StorefrontIcon />, route: ROUTES.ITEMS  },
          ],
        },
        {
          id: 'Team',
          children: [
            { id: 'Users', icon: <GroupOutlinedIcon />, route: ROUTES.TEAM_USERS  },
            { id: 'Roles', icon: <LockOutlinedIcon />, route: ROUTES.TEAM_ROLES  },
            { id: 'Time Sheet', icon: <TimerIcon />, route: ROUTES.TEAM_TIMESHEETS  },
          ],
        },
        {
          id: 'Performance',
          children: [
            { id: 'Reports', icon: <PieChartIcon />, route: ROUTES.REPORTS  }
          ],
        },
        {
          id: '',
          children: [
            { id: 'Settings', icon: <SettingsIcon />, route: ROUTES.SETTINGS  },
          ],
        },
      ]
}

