import React from 'react';
import _ from 'lodash';
import clsx from 'clsx';
import moment from 'moment';
import capitalize from 'capitalize';


const RolesListCell = (props) => {

    if (props.data === null || _.isEmpty(props.data)) {
        return null;
    }

    const handlePermissionsText = () => {
        let permissionAccessText = 'No permissions available.';


        if (props.data.permission_categories !== null && props.data.permission_categories !== undefined) {
            permissionAccessText = '';
            let permissionCategoriesLength = props.data.permission_categories.length;
            props.data.permission_categories.forEach((value, index) => {
                permissionAccessText += value;

                if (index !== permissionCategoriesLength - 1) {
                    permissionAccessText += ', ';
                }
            });
        }

        return permissionAccessText;
    };

    return (
        <div className={clsx('Padding--Horizontal--20', 'Padding--Vertical--12')}>
            <button className="RolesListCell" onClick={props.onClick}>
                <div className={clsx('Padding--Vertical--15', 'Padding--Horizontal--15')}>
                    <p className={clsx('SubTitle', 'DarkBlueTint', 'TextAlignLeft', 'BoldText')}>
                        {props.data.name || ''}
                    </p>
                    <p className={clsx('BodyText', 'DarkGreyTint', 'TextAlignLeft', 'NormalText')} >
                        {props.data.description || ''}
                    </p>
                </div>
                <div className='RolesListCell_Divider'/>
                <div className={clsx('Padding--Vertical--15', 'Padding--Horizontal--15')}>
                    <p className={clsx('BodyText', 'DarkGreyTint', 'TextAlignLeft', 'BoldText')}>
                        <span className={clsx('BoldText')}>Permission Acccess: </span>
                        <span className={clsx('NormalText')}>
                            {handlePermissionsText()}
                        </span>
                    </p>

                    <div className='Spacer--10' />

                    <p className={clsx('BodyText', 'DarkGreyTint', 'TextAlignLeft')} >
                        <span className={clsx('BoldText')}>Users: </span>
                        <span className={clsx('NormalText')}>
                            {props.data.user_amount || '0'}
                        </span>
                    </p>

                    <div className='Spacer--10' />

                    <p className={clsx('BodyText', 'DarkGreyTint', 'TextAlignLeft')} >
                        <span className={clsx('BoldText')}>Active: </span>
                        <span className={clsx('NormalText')}>
                            {capitalize.words(props.data.active.toString()) || 'Unknown'}
                        </span>
                    </p>

                    <div className='Spacer--10' />

                    <p className={clsx('BodyText', 'DarkGreyTint', 'TextAlignLeft')} >
                        <span className={clsx('BoldText')}>Last Updated: </span>
                        <span className={clsx('NormalText')}>
                            {capitalize.words(moment(new Date(props.data.last_updated_at), "YYYYMMDD").fromNow()) || 'Last Updated Timestamp not available.'}
                        </span>
                    </p>

                    <div className='Spacer--10' />

                    <p className={clsx('BodyText', 'DarkGreyTint', 'TextAlignLeft', 'NormalText')} >
                        <span className={clsx('BoldText')}>Created: </span>
                        <span className={clsx('NormalText')}>
                            {moment(new Date(props.data.created_at), "YYYYMMDD").format('M-D-YYYY') || 'Created Date not available.'}
                        </span>
                    </p>
                </div>
            </button>
        </div>
    );
};

export default RolesListCell;