import React from 'react';
import clsx from 'clsx';

const ResendVerificationCodeButton = (props) => {

    return (
        <button
            className="ForgotPasswordButton"
            onClick={props.handleSubmit}
            type='button'
        >
            <p className={clsx('TextButton', 'BodyText', 'BlueTint', 'SemiBoldText')}>
                Resend Code
            </p>
        </button>
    );
};

export default ResendVerificationCodeButton;