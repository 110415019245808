// Import Modules
import React, { useState } from 'react';
import Masonry from 'react-masonry-css';
import clsx from 'clsx';
import { LinkContainer } from 'react-router-bootstrap'; 
import { Auth } from 'aws-amplify';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import _ from 'lodash';
import PulseLoader from 'react-spinners/PulseLoader';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MuiAlert from '@material-ui/lab/Alert';
import { styled } from '@material-ui/core/styles';

// Import Custom Classes
import SettingsBreadcrumbs from '../../../../Components/UI/SettingsBreadcrumbs';
import * as ROUTES from '../../../../Constants/routes';
import SettingsBox from '../../../../Components/Boxes/SettingsBox';
import ContactDetailsForm from '../../../../Components/Forms/ContactDetailsForm';
import ContactAddressForm from '../../../../Components/Forms/ContactAddressForm';
import UserPreferencesInfoForm from '../../../../Components/Forms/UserPreferencesInfoForm';
import SettingsOutlinedButton from '../../../../Components/Buttons/SettingsOutlinedButton';
import SettingsRedOutlinedButton from '../../../../Components/Buttons/SettingsRedOutlinedButton';
import * as UserInfoActionTypes from '../../../../Redux/ActionTypes/Auth/UserInfoActionTypes';
import SettingsList from '../../../../Constants/SettingsList';
import ErrorScreen from '../../Other/ErrorScreen';
import PersonalUserDataContainer from '../../../../Components/DataContainers/PersonalUserDataContainer';
import { UPDATE_USER } from '../../../../graphql/UserMutations';

const SuccessAlert = styled(MuiAlert)({
    backgroundColor: '#2D9639',
    fontWeight: '600'
});

function Alert(props) {
    return <SuccessAlert elevation={6} variant="filled" {...props} />;
}


const UserSettingsScreen = (props) => {

    const auth = useSelector(state => state.auth.userInfo.auth);
    const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
    const [dataContainerLoading, setDataContainerLoading] = useState(false);
    const [dataContainerError, setDataContainerError] = useState(null);
    const [userData, setUserData] = useState({});
    const dispatch = useDispatch();
    const history = useHistory();

    const [updateUserAction] = useMutation(UPDATE_USER);

    if (dataContainerError) {
        console.log(dataContainerError);
    }


    // Client-Side Authorization Checks
    if (auth === null || auth === undefined || _.isEmpty(auth)) {
        return null;
    }

    if (!auth.role_permissions.includes('basic_access')) {
        return <ErrorScreen />;
    }

    const handleChangePasswordClicked = () => {
        this.props.history.push(ROUTES.CHANGE_PASSWORD);
    }

    const handleChangeEmailClicked = () => {
        this.props.history.push(ROUTES.CHANGE_EMAIL);
    }

    const handleSuccessSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSuccessSnackbarOpen(false);
    };


    const handleSignOut = () => {
        Auth.signOut()
        .then((result) => {
            dispatch({ type: UserInfoActionTypes.UPDATE_LOGIN_STATUS, payload: { isLoggedIn: false } });
            dispatch({ type: UserInfoActionTypes.REMOVE_USER });
            dispatch({ type: UserInfoActionTypes.REMOVE_AUTH });
            history.push(ROUTES.LOG_IN);
        })
        .catch((error) => {
            console.log(error);
        });
        
      }

    const handleLoadingArea = () => {
        if (dataContainerLoading) {
            return (
                <PulseLoader
                    css={`margin-top: 10px; margin-bottom: 20px;`}
                    size={20}
                    sizeUnit={"px"}
                    margin="5px"
                    color={'#0A3F79'}
                    loading={true}
                />
            );
        }else {
            return null;
        }
    };

    const onContactDetailsUpdateSubmit = (updatedUserInput) => {
        updateUserAction({ variables: { updatedUserInput: updatedUserInput } })
        .then((result) => {
            setSuccessSnackbarOpen(true);
        })
        .catch((error) => {
            console.log(error);
        });
    }

    return (
        <div className="BaseScreen" >
            <PersonalUserDataContainer
                setLoading={(loading) => setDataContainerLoading(loading)}
                setError={(error) => setDataContainerError(error)}
                setData={(data) => setUserData(data)}
                auth={auth}
                id={auth.id}
            >
                <SettingsBreadcrumbs
                    screenTitle='User Settings'
                    screenDescription='Update your personal information, preferences, security settings, and more.'
                    route={ROUTES.SETTINGS_USER}
                    fromScreenTitle='Home'
                    fromScreenRoute={ROUTES.HOME}
                />
                <div className="Spacer--20" />
                {handleLoadingArea()}
                {(!_.isEmpty(userData) || userData === undefined) ?

                <Masonry
                    breakpointCols={{ default: 2, 1600: 2, 700: 1 }}
                    className="screen-detail-masonry-grid"
                    columnClassName="screen-detail-masonry-grid_column"
                >
                    <div className={clsx('Width--Full-20', 'Margin--Horizontal--Auto', 'Margin--Bottom--20')}>
                        <SettingsBox title={'Contact Details'}>
                            <ContactDetailsForm 
                                data={userData}
                                onUpdateUser={(updatedUserInput) => onContactDetailsUpdateSubmit(updatedUserInput)}
                            />
                        </SettingsBox>
                    </div>

                    <div className={clsx('Width--Full-20', 'Margin--Horizontal--Auto', 'Margin--Bottom--20')}>
                        <SettingsBox title={'Contact Address'}>
                            <ContactAddressForm 
                                data={userData}
                                onUpdateUser={(updatedUserInput) => onContactDetailsUpdateSubmit(updatedUserInput)}
                            />
                        </SettingsBox>
                    </div>
                    <div className={clsx('Width--Full-20', 'Margin--Horizontal--Auto', 'Margin--Bottom--20')}>
                        <SettingsBox title={'Preferences'}>
                            <UserPreferencesInfoForm 
                                data={userData}
                                onUpdateUser={(updatedUserInput) => onContactDetailsUpdateSubmit(updatedUserInput)}
                            />
                        </SettingsBox>
                    </div>
                    <div className={clsx('Width--Full-20', 'Margin--Horizontal--Auto', 'Margin--Bottom--20')}>
                        <SettingsBox title={'Other'}>
                            {SettingsList.userSecuritySettingsList.map(({ screenTitle, screenDescription, route }) => (
                                <LinkContainer to={route} style={{ paddingLeft: '20px', paddingRight: '20px', paddingTop: '10px' }} key={screenTitle} >
                                    <button className={clsx('HiddenButton')} >
                                        <p className={clsx('SubTitleSmall', 'SemiBoldText', 'TextAlignLeft', 'BlueTint')} style={{ marginBottom: '8px' }}>
                                            {screenTitle}
                                        </p>
                                        <p className={clsx('BodyText', 'TextAlignLeft')} style={{ marginBottom: '10px' }}>
                                            {screenDescription}
                                        </p>
                                    </button>
                                </LinkContainer>
                            ))}
                        </SettingsBox>
                    </div>
                    <div className={clsx('Width--Full-20', 'Margin--Horizontal--Auto', 'Margin--Bottom--20')}>
                        <SettingsBox title={'Security'}>
                            <div className="Spacer--10" />
                            <SettingsOutlinedButton
                                label='Change Email'
                                onClick={handleChangeEmailClicked}
                                disabled={false}
                            />
                            <SettingsOutlinedButton
                                label='Change Password'
                                onClick={handleChangePasswordClicked}
                                disabled={false}
                            />
                            <SettingsRedOutlinedButton
                                label='Sign Out'
                                onClick={handleSignOut}
                                disabled={false}
                            />
                        </SettingsBox>
                    </div>
                </Masonry>
                : null}
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    open={successSnackbarOpen}
                    autoHideDuration={5000}
                    onClose={handleSuccessSnackbarClose}
                    action={
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleSuccessSnackbarClose}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    }
                    variant="Success"
                >
                    <Alert onClose={handleSuccessSnackbarClose} severity="success">
                        User Updated Successfully.
                    </Alert>
                </Snackbar>
            </PersonalUserDataContainer>
        </div>
    );
}
export default UserSettingsScreen;
