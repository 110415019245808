import React, { Fragment } from 'react';
import clsx from 'clsx';
import AddIcon from '@material-ui/icons/Add';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';


import ScreenHeaderActionButton from '../Buttons/ScreenHeaderActionButton';
import ScreenHeaderMoreButton from '../Buttons/ScreenHeaderMoreButton';

const ScreenHeader = (props) => {

    let actionArea = null;
    let moreArea = null;

    if (!(props.isActionDisabled || false)) {
        actionArea = (
            <ScreenHeaderActionButton
                actionText={props.actionText || 'Edit'}
                actionIcon={props.actionIcon || <AddIcon style={{ color: 'white', fontSize: 'normal', fontWeight: '600' }} size='medium' />}
                onActionClicked={props.onActionClicked}
                layoutWidth={props.layoutWidth}
            />
        );
    }

    if (!(props.isMoreDisabled || false)) {
        moreArea = (
            <Fragment>
                <div className="Spacer--Width--20" />
                <ScreenHeaderMoreButton
                    moreText={props.moreText || 'More Options'}
                    moreIcon={props.moreIcon || <MoreHorizIcon style={{ color: '#07689F', fontSize: 'normal', fontWeight: '600' }} size='medium' />}
                    onMoreClicked={props.onMoreClicked}
                    layoutWidth={props.layoutWidth}
                />
            </Fragment>
        );
    }

    return (
        <div className={clsx({'Padding--Vertical--20':!(props.isActionDisabled && props.isMoreDisabled), 'Padding--Horizontal--20':true, 'Display--Flex':true, 'Flex--SpaceBetween':true})}>
            <div className='ScreenHeaderLeftArea'>
                <p className={clsx('Title', 'DarkBlueTint', 'SemiBoldText', 'TextAlignLeft')} style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                    {props.title || ''}
                </p>
            </div>
            <div className='ScreenHeaderRightArea'>
                {actionArea}
                {moreArea}
            </div>
        </div>
    );
};

export default ScreenHeader;