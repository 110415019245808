export default {
    companySizeOptions: [
        { label: 'Just Me', value: 1 },
        { label: '1-9', value: 2 },
        { label: '10-19', value: 3 },
        { label: '20-49', value: 4 },
        { label: '50-99', value: 5 },
        { label: '100-249', value: 6 },
        { label: '250+', value: 7 }
    ],
    trafficSourceOptions: [
        { label: 'Direct', value: 5 },
        { label: 'Search Engine', value: 2 },
        { label: 'Facebook / Instagram', value: 3 },
        { label: 'Refferal', value: 4 },
        { label: 'Other', value: 1 },
    ],
    industryOptions: [
        { label: 'Pool and Spa Services', value: 2 },
        { label: 'Gardening Services', value: 3 },
        { label: 'Applicance Repair', value: 4 },
        { label: 'Plumbing', value: 5 },
        { label: 'Residential Cleaning', value: 6 },
        { label: 'Tree Care', value: 7 },
        { label: 'General Contracting', value: 8 },
        { label: 'Roofing', value: 9 },
        { label: 'Handyman', value: 10 },
        { label: 'Landscaping', value: 11 },
        { label: 'Pest Control', value: 12 },
        { label: 'Electrician', value: 13 },
        { label: 'HVAC', value: 14 },
        { label: 'Janitorial Services', value: 15 },
        { label: 'Security Services', value: 16 },
        { label: 'Carpet Cleaning', value: 17 },
        { label: 'Other', value: 1 }
    ]
}