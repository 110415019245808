import React from 'react';
import clsx from 'clsx';


const GoBackButton = (props) => {

    
    return (
        <button
            className='GoBackButton'
            onClick={props.handleSubmit}
            type='button'
            style={props.style || {}}
        >
            <p className={clsx("SubTitle", "BoldText", "DarkBlueTint")} style ={{margin: 'auto', padding: '10px 20px', alignSelf: 'center'}}>
                Go Back
            </p>
        </button>
    );
};

export default GoBackButton;