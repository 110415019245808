import React from 'react';
import clsx from 'clsx';


const ErrorSignOutButton = (props) => {

    return (
        <button
            className='ErrorSignOutButton'
            onClick={props.handleSubmit}
            type='button'
            style={props.style || {}}
        >
            <p className={clsx("SubTitle", "BoldText", "WhiteTint")} style ={{margin: 'auto', padding: '10px 20px', alignSelf: 'center'}}>
                Sign Out
            </p>
        </button>
    );
};

export default ErrorSignOutButton;