// Import Modules
import React from 'react';
import clsx from 'clsx';

// Import Custom Classes
import Box from '../../Components/Boxes/Box';
import SignUpForm1 from "../../Components/Forms/SignUpForm1";


const SignUpScreenContents1 = (props) => {

    return (
        <div className="BaseScreen">
            <div className="Spacer--30" />
            <div className='TitleContainer'>
                <p className={clsx('BigTitle', 'DarkBlueTint', 'TextAlignCentered')}>
                    Start Your <span className='GreenTint'>Free Trial</span>
                </p>
                <p className={clsx('SubTitle', 'DarkBlueTint', 'TextAlignCentered')}>
                    Try all of ProHelper's features with no credit card required.
                </p>
            </div>
            <Box className='SignUpBox'>
                <SignUpForm1
                    emailValue={props.emailValue}
                    onEmailChange={(event) => { props.onEmailChange(event) }}
                    passwordValue={props.passwordValue}
                    onPasswordChange={(event) => { props.onPasswordChange(event) }}
                    passwordKeyPressChecker={(event) => { props.passwordKeyPressChecker(event) }}
                    isLoading={props.isLoading}
                    handleSubmit={props.handleSubmit}
                    errorValue={props.errorValue}
                />
            </Box>
        </div >
    );
};
export default SignUpScreenContents1;