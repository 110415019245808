import React, { Fragment } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MenuBookOutlinedIcon from '@material-ui/icons/MenuBookOutlined';
import VideoLibraryOutlinedIcon from '@material-ui/icons/VideoLibraryOutlined';
import CreateIcon from '@material-ui/icons/Create';

const HelpMenu = (props) => {

    return (
        <Fragment>
            <Menu
                id="menu-appbar"
                anchorEl={props.anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ vertical: "top", horizontal: "center" }}
                keepMounted
                open={Boolean(props.anchorEl)}
                onClose={props.handleClose}
            >
                <MenuItem onClick={props.handleClose}>
                    <ListItemIcon style={{ minWidth: 'auto', paddingRight: '15px', color: '#07689F' }}>
                        <VideoLibraryOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText >
                        <span style={{ color: '#07689F', fontWeight: 600 }}>Tutorials</span>
                    </ListItemText>
                </MenuItem>
                <MenuItem onClick={props.handleClose}>
                    <ListItemIcon style={{ minWidth: 'auto', paddingRight: '15px', color: '#07689F' }}>
                        <MenuBookOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText >
                        <span style={{ color: '#07689F', fontWeight: 600 }}>Support</span>
                    </ListItemText>
                </MenuItem>
                <MenuItem onClick={props.handleClose} >
                    <ListItemIcon style={{ minWidth: 'auto', paddingRight: '15px', color: '#07689F' }}>
                        <CreateIcon />
                    </ListItemIcon>
                    <ListItemText >
                        <span style={{ color: '#07689F', fontWeight: 600 }}>Feedback</span>
                    </ListItemText>
                </MenuItem>
            </Menu>
        </Fragment>
    );
}

export default HelpMenu;