// Import Modules
import React, { Component } from 'react';
import * as ROUTES from '../../Constants/routes';
import { connect } from 'react-redux'; 
import { withRouter, Redirect } from 'react-router-dom';
import * as LogInActionTypes from '../../Redux/ActionTypes/Auth/LogInActionTypes';
import * as UserInfoActionTypes from '../../Redux/ActionTypes/Auth/UserInfoActionTypes';
import * as SignUpActionTypes from '../../Redux/ActionTypes/Auth/SignUpActionTypes';
import LogInForm from '../../Components/Forms/LogInForm';
import LogInBox from '../../Components/Boxes/LogInBox';
import SignUpButton from '../../Components/Buttons/SignUpButton';
import { Auth } from 'aws-amplify';
import PulseLoader from 'react-spinners/PulseLoader';
import clsx from 'clsx';



const override = `
    margin-top: 30px;
`;

class LoginScreen extends Component {

    state = {
        isLoading: false
    }

    constructor(props) {
        super(props)
        this.handleLogIn = this.handleLogIn.bind(this);
    }


    componentWillUnmount() {
        this.props.setError(null);
    }

    handleEmailChange = (event) => {
        this.props.setEmail(event.target.value);
    }

    handlePasswordChange = (event) => {
        this.props.setPassword(event.target.value);
    }

    handlePasswordKeyPress = (event) => {
        if(event.keyCode === 13){
            this.handleLogIn()
         }
    }

    async handleLogIn() {
        this.setState({isLoading: true});
        try {
            const user = await Auth.signIn(this.props.email.toLowerCase(), this.props.password);
            this.props.setUser(user);
            this.props.setError(null);
            this.setState({isLoading: false});

            if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                this.props.updateLoginStatus(false);
                this.props.history.push(ROUTES.NEW_PASSWORD);
            }else {
                this.props.updateLoginStatus(true);
                this.props.history.push(ROUTES.HOME);
            }
        } catch (err) {
            this.setState({isLoading: false});
            if (err.code === 'UserNotConfirmedException') {
                this.props.history.push(ROUTES.SIGN_UP);
                this.props.setSignUpEmail(this.props.email.toLowerCase());
                this.props.setSignUpPassword(this.props.password);
                this.props.setIsSignUpSubmitted(true);
                this.props.clearLogInInfo();

            } else if (err.code === 'PasswordResetRequiredException') {
                this.props.setError({message: 'You need to reset your password. Please click the forgot password button.'});
            } else if (err.code === 'NotAuthorizedException') {
                // The error happens when the incorrect password is provided
                this.props.setError({message: 'You entered an incorrect password, Please try again.'});
            } else if (err.code === 'UserNotFoundException') {
                // The error happens when the supplied username/email does not exist in the Cognito user pool
                this.props.setError({message: 'There is no account with this email address, Please try again.'});
            } else {
                this.props.setError({message: 'Unknown error, please try again later.'});
            }
        }
    }

    signUp = () => {
        this.props.history.push(ROUTES.SIGN_UP);
    }

    handleForgotPassword = () => {
        this.props.history.push(ROUTES.FORGOT_PASSWORD);
    }

    passwordKeyPressChecker = (event) => {
        if (event.key === 'Enter') {
            this.handleLogIn();
        }
    }

    render() {

        const isInvalid = this.props.email === '' || this.props.password === '';

        let loadingArea = null;

        if (this.state.isLoading) {
            loadingArea = (
                <PulseLoader
                    css={override}
                    size={18}
                    sizeUnit={"px"}
                    margin="5px"
                    color={'#0A3F79'}
                    loading={true}
                />
            );
        }

        let loggedInRedirect = null;

        if (this.props.isLoggedIn) {
            loggedInRedirect = (
                <Redirect to={ROUTES.HOME} />
            );
        }


        return (
            <div className="BaseScreen">
                {loggedInRedirect}
                <LogInBox>
                    <p className={clsx('Title', 'DarkBlueTint')}>Log In</p>
                    {loadingArea}
                    <LogInForm
                        emailValue={this.props.email}
                        onEmailChange={(event) => { this.handleEmailChange(event) }}
                        passwordValue={this.props.password}
                        onPasswordChange={(event) => { this.handlePasswordChange(event) }}
                        onPasswordKeyPress={(event) => { this.handlePasswordKeyPress(event) }}
                        errorValue={this.props.error}
                        isInvalid={isInvalid}
                        handleSubmit={this.handleLogIn.bind(this)}
                        handleForgotPassword={this.handleForgotPassword}
                        isLoading={this.state.isLoading}
                        passwordKeyPressChecker= {(event) => {this.passwordKeyPressChecker(event)}}
                    />
                </LogInBox>
                <SignUpButton
                    handleSubmit={this.signUp}
                />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        email: state.auth.login.email,
        password: state.auth.login.password,
        error: state.auth.login.error,
        user: state.auth.userInfo.user,
        isLoggedIn: state.auth.userInfo.isLoggedIn
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setEmail: (email) => dispatch({ type: LogInActionTypes.SET_EMAIL, payload: { email: email } }),
        setPassword: (password) => dispatch({ type: LogInActionTypes.SET_PASSWORD, payload: { password: password } }),
        setError: (error) => dispatch({ type: LogInActionTypes.SET_ERROR, payload: { error: error } }),
        setUser: (user) => dispatch({ type: UserInfoActionTypes.UPDATE_USER, payload: { user: user } }),
        updateLoginStatus: (isLoggedIn) => dispatch({ type: UserInfoActionTypes.UPDATE_LOGIN_STATUS, payload: { isLoggedIn: isLoggedIn } }),
        clearLogInInfo: () => dispatch({ type: LogInActionTypes.CLEAR_LOGIN_INFO}),
        setSignUpEmail: (email) => dispatch({ type: SignUpActionTypes.SET_EMAIL, payload: { email: email } }),
        setSignUpPassword: (password) => dispatch({ type: SignUpActionTypes.SET_PASSWORD, payload: { password: password } }),
        setIsSignUpSubmitted: (isSignUpSubmitted) => dispatch({ type: SignUpActionTypes.SUBMIT_SIGN_UP, payload: { isSignUpSubmitted: isSignUpSubmitted } }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LoginScreen));