import React from 'react';
import clsx from 'clsx';


const DashboardButton = (props) => {

    return (
        <button
            className='DashboardButton'
            onClick={props.handleSubmit}
            type='button'
            style={props.style || {}}
        >
            <p className={clsx("SubTitle", "BoldText", "WhiteTint")} style ={{margin: 'auto', padding: '10px 20px', alignSelf: 'center'}}>
                Dashboard
            </p>
        </button>
    );
};

export default DashboardButton;