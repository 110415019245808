import React from 'react';
import _ from 'lodash';
import clsx from 'clsx';
import moment from 'moment';
import capitalize from 'capitalize';

const UsersListCell = (props) => {

    if (props.data === null || _.isEmpty(props.data)) {
        return null;
    }

    return (
        <div className={clsx('Padding--Horizontal--20', 'Padding--Vertical--12')}>
            <button className="UsersListCell" onClick={props.onClick}>
                <div className='UsersListCellProfileArea'>
                    <img
                        src={props.data.profile_image_url || process.env.PUBLIC_URL + '/profile_image.png'}
                        className='UsersListCellProfileImage'
                        alt = {props.data.full_name ? props.data.full_name + ' Profile Image' : 'Profile Image'}
                    />
                </div>
                <div className={clsx('')}>
                    <p className={clsx('TextAlignLeft', 'SubTitle', 'DarkBlueTint', 'BoldText')}>
                        {props.data.full_name || 'No name available.'}
                    </p>
                    <p className={clsx('TextAlignLeft', 'BodyText', 'DarkGreyTint', 'NormalText')} >
                        {props.data.email || 'No email available.'}
                    </p>
                    
                    <div className='Spacer--10' />
                    
                    <p className={clsx('BodyText', 'DarkGreyTint', 'TextAlignLeft')} >
                        <span className={clsx('BoldText')}>Role: </span>
                        <span className={clsx('NormalText')}>
                            {props.data.role ? props.data.role.name : 'No Role Assigned'}
                        </span>
                    </p>
                    
                    <div className='Spacer--10' />
                    
                    <p className={clsx('BodyText', 'DarkGreyTint', 'TextAlignLeft')} >
                        <span className={clsx('BoldText')}>Account Status: </span>
                        <span className={clsx('NormalText')}>
                            {capitalize.words(_.toLower(props.data.account_status || ''))}
                        </span>
                    </p>
                    
                    <div className='Spacer--10' />
                    
                    <p className={clsx('BodyText', 'DarkGreyTint', 'TextAlignLeft')} >
                        <span className={clsx('BoldText')}>Working Status: </span>
                        <span className={clsx('NormalText')}>
                            {capitalize.words(_.toLower(props.data.working_status || ''))}
                        </span>
                    </p>
                    
                    <div className='Spacer--10' />

                    <p className={clsx('BodyText', 'DarkGreyTint', 'TextAlignLeft')} >
                        <span className={clsx('BoldText')}>Last Active: </span>
                        <span className={clsx('NormalText')}>
                            {capitalize.words(moment(new Date(props.data.last_active), "YYYYMMDD").fromNow()) || ''}
                        </span>
                    </p>

                    <div className='Spacer--10' />

                    <p className={clsx('BodyText', 'DarkGreyTint', 'TextAlignLeft')} >
                        <span className={clsx('BoldText')}>Created: </span>
                        <span className={clsx('NormalText')}>
                            {capitalize.words(moment(new Date(props.data.created_at), "YYYYMMDD").fromNow()) || ''}
                        </span>
                    </p>
                </div>
            </button>
        </div>
    );
};

export default UsersListCell;
