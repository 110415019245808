import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import HomeIcon from '@material-ui/icons/Home';
import { LinkContainer } from 'react-router-bootstrap';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

import * as ROUTES from '../../../Constants/routes';
import * as CustomMaterials from '../../MaterialUI/CustomMaterials';


const styles = theme => ({
  categoryHeader: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  categoryHeaderPrimary: {
    color: '#002D47',
    fontWeight: 600,
    fontSize: "1.2rem",
    fontFamily: "Open Sans",
  },
  itemTitle: {
    paddingTop: 2,
    paddingBottom: 2,
    color: '#002D47'
  },
  item: {
    paddingTop: 2,
    paddingBottom: 2,
    fontFamily: "Open Sans",
    color: '#444444',
    '&:hover,&:focus': {
      backgroundColor: 'none',
      color: "#07689F",
      fontWeight: 700
    },
  },
  itemCategory: {
    boxShadow: '0 -1px 0 #979797 inset',
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    fontWeight: 600
  },
  firebase: {
    fontSize: 24,
    fontWeight: 700,
    color: '#fff',
    backgroundColor: "#07689F",
    textAlign: 'center',
    paddingLeft: `24px`,
  },
  itemActiveItem: {
    color: '#07689F',
    fontWeight: 600
  },
  itemPrimary: {
    fontSize: "16px",
    fontFamily: "Open Sans",
    '&:hover,&:focus': {
      color: "#07689F",
      fontWeight: 700
    },
  },
  itemIcon: {
    minWidth: 'auto',
    marginRight: theme.spacing(2),
    '&:hover,&:focus': {
      color: "#07689F"
    },
  },
  divider: {
    marginTop: theme.spacing(1)
  },
  active: {
    color: "#07689F"
  }
});


const AuthNavigator = (props) => {
  const { classes } = props;
  const pathName = props.location.pathname;
  const auth = useSelector(state => state.auth.userInfo.auth);
  const authNavigatorCategories = useSelector(state => state.ui.layout.authNavigatorCategories);

  const checkIsAuthValid = () => {
    let result = false;

    if (auth) {
      if (auth.role_id && auth.role_permissions) {
        result = true;
      }
    }

    return result;
  }

  const getSidebarCase = () => {
    let result = "full";

    if (props.layoutWidth >= 1330) {
      if (props.desktopOpen) {
        result = "full";
      }else {
        result = "medium";
      }
    }else if (props.layoutWidth < 1330 && props.layoutWidth > 800) {
      result = "medium";
      if (props.mobileOpen) {
        result = "popover";
      }
    } else if (props.layoutWidth <= 800 && props.layoutWidth > 0) {
      result = "mobile";

      if (props.mobileOpen) {
        result = "popover";
      }
    }

    return result;
  }


  switch (getSidebarCase()) {
    case "full":
      return (
        <div className={clsx({"auth-navigation-sidebar-container": true, "auth-navigation-sidebar-container-full": true})}>
          <ul className={clsx("sidebar-list")}>
            {checkIsAuthValid() ?
              <Fragment>
                <LinkContainer to={ROUTES.HOME} exact>
                  <li
                    button="true"
                    className={clsx({
                      "sidebar-list-item": true,
                      "sidebar-list-item-active": (pathName === ROUTES.HOME)
                    })}
                    onClick={props.onClose}
                  >
                    <div className={clsx("sidebar-list-item-icon")}>
                      <HomeIcon />
                    </div>
                    <p className={clsx("sidebar-list-item-text")}>
                      Home
                    </p>
                  </li>
                </LinkContainer>

                {authNavigatorCategories.map(({ id, children }) => (
                  <Fragment key={id}>
                    {/* <li>
                      <p className={clsx("")}>
                        {id}
                      </p>
                    </li> */}
                    <ListItem className={classes.categoryHeader}>
                      <ListItemText
                        classes={{ primary: classes.categoryHeaderPrimary }}>
                        {id}
                      </ListItemText>
                    </ListItem>
                    {children.map(({ id: childId, icon, route }) => (
                      <LinkContainer key={childId} to={route} exact>
                        <li 
                          button="true"
                          className={clsx({
                            "sidebar-list-item": true, 
                            "Pointer--Cursor": true,
                            "sidebar-list-item-active": pathName.startsWith(route)
                          })}  
                          onClick={props.onClose}
                        >
                          <div className={clsx("sidebar-list-item-icon")}>
                            {icon}
                          </div>
                          <p className={clsx("sidebar-list-item-text")}>
                            {childId}
                          </p>
                        </li>
                      </LinkContainer>
                    ))}

                  </Fragment>
                ))}
              </Fragment>
              : null
            }
          </ul>
        </div>
      );
    case "medium":
      return (
        <div className={clsx({ "auth-navigation-sidebar-container-medium": true })}>
          <ul className={"sidebar-medium-list"}>
            {checkIsAuthValid() ?
              <Fragment>
                <LinkContainer to={ROUTES.HOME} exact>
                  <button 
                    className={
                      clsx({
                        "sidebar-medium-list-item-box": true, 
                        "sidebar-medium-list-item-box-active": (ROUTES.HOME === pathName) 
                      })} 
                    onClick={props.onClose}
                  >
                    <div className="">
                      <HomeIcon />
                      <p className={clsx("MicroText", "TextAlignCenter")}>
                        Home
                      </p>
                    </div>
                  </button>

                </LinkContainer>

                {authNavigatorCategories.map(({ id, children }) => (
                  <Fragment key={id}>
                    {children.map(({ id: childId, icon, route }) => (
                      <LinkContainer key={childId} to={route} exact>
                        <button 
                          className={
                            clsx({
                              "sidebar-medium-list-item-box": true, 
                              "sidebar-medium-list-item-box-active": pathName.startsWith(route)
                            })} 
                          onClick={props.onClose}
                        >
                          <div className="">
                            {icon}
                            <p className={clsx("MicroText", "TextAlignCenter")}>
                              {childId}
                            </p>
                          </div>
                        </button>
                      </LinkContainer>
                    ))}
                  </Fragment>
                ))}
              </Fragment>
              : null
            }
          </ul>
        </div>
      );

    case "popover":
      return (
        <div
          className={clsx({
            "mobile-sidebar-backdrop": true,
            "mobile-sidebar-backdrop-hidden": !props.mobileOpen,
            "mobile-sidebar-backdrop-show": props.mobileOpen
          })}
        >
          <div
            className={
              clsx({
                "mobile-sidebar-drawer": true,
                "mobile-sidebar-drawer-hidden": !props.mobileOpen,
                "mobile-sidebar-drawer-show": props.mobileOpen
              })}
          >
            <div
              className={clsx({
                "sidebar-list-prohelper-header": true
              })}
            >
              <button
                onClick={props.onMenuToggle}
                className={"sidebar-popover-menu-button"}
              >
                <CustomMaterials.CustomMenuIcon fontSize="large" />
              </button>

              <p 
                className={clsx(
                  'SubTitleSmall', 
                  "BoldText", 
                  "WhiteTint", 
                  "Margin--Left--15", 
                  "Margin--Vertical--Auto"
                )}
              >
                ProHelper
              </p>
            </div>
            <ul className={clsx("sidebar-popover-list")}>
              {checkIsAuthValid() ?
                <Fragment>
                  <LinkContainer to={ROUTES.HOME} exact>
                    <li
                      button="true"
                      className={clsx({
                        "sidebar-list-item": true,
                        "sidebar-list-item-active": (pathName === ROUTES.HOME)
                      })}
                      onClick={props.onMenuToggle}
                    >
                      <div className={clsx("sidebar-list-item-icon")}>
                        <HomeIcon />
                      </div>
                      <p className={clsx("sidebar-list-item-text")}>
                        Home
                    </p>
                    </li>
                  </LinkContainer>

                  {authNavigatorCategories.map(({ id, children }) => (
                    <Fragment key={id}>
                      <ListItem className={classes.categoryHeader}>
                        <ListItemText
                          classes={{ primary: classes.categoryHeaderPrimary }}>
                          {id}
                        </ListItemText>
                      </ListItem>
                      {children.map(({ id: childId, icon, route }) => (
                        <LinkContainer key={childId} to={route} exact>
                          <li
                            button="true"
                            className={clsx({
                              "sidebar-list-item": true,
                              "Pointer--Cursor": true,
                              "sidebar-list-item-active": pathName.startsWith(route)
                            })}
                            onClick={props.onMenuToggle}
                          >
                            <div className={clsx("sidebar-list-item-icon")}>
                              {icon}
                            </div>
                            <p className={clsx("sidebar-list-item-text")}>
                              {childId}
                            </p>
                          </li>
                        </LinkContainer>
                      ))}

                    </Fragment>
                  ))}
                </Fragment>
                : null
              }
            </ul>
          </div>
          <button 
            onClick={props.onMenuToggle} 
            className="mobile-sidebar-backdrop-clickable" 
          />
        </div>
      );

    default: 
        return null;
  }

}

AuthNavigator.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(AuthNavigator));