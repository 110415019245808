// Import Modules
import React, { useState, Fragment } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import clsx from 'clsx';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
// import { useHistory } from 'react-router-dom';

// Import Custom Components
// import NewItemBox from '../../../../Components/Boxes/NewItemBox';
// import SettingsSelect from '../../../../Components/MaterialUI/SettingsSelect';
// import RolesDataContainer from '../../../../Components/DataContainers/RolesDataContainer';
// import { UPDATE_USER_ROLE } from '../../../../graphql/UserMutations';
import AlertConfirmationPopUp from '../../../../Components/UI/AlertConfirmationPopUp';


const DeleleRolePopUp = (props) => {

    // Clientside Permissions validation
    const auth = useSelector(state => state.auth.userInfo.auth);
    const [resetClicked, setResetClicked] = useState(false);
    const [confirmationCheck, setConfirmationCheck] = useState(false);
    // const history = useHistory();
    // const [updateUserRoleAction, { loading: mutationLoading, error: mutationError }] = useMutation(UPDATE_USER_ROLE);

    // useEffect(()=> {
    //     if (props.data.role) { 
    //         setRole({ label: props.data.role.name, value: props.data.role.id });
    //     }
        
    // }, [props.data.role]);


    if (auth === null || auth === undefined || _.isEmpty(auth) || _.isEmpty(auth.role_permissions)) {
        return null;
    }

    if (!(props.open || false)) {
        return null;
    }

    // Functions Area

    const onClose = () => {
        setResetClicked(false);
        setConfirmationCheck(false);
        props.onClose();
    }


    const onFinalClose = () => {
        setResetClicked(false);
        setConfirmationCheck(false);
        props.onClose();
    }

    const checkIsValid = () => {
        let result = false;

        if (confirmationCheck) {
            result = true;
        }

        return result;
    }

    // const onSubmit = () => {
        
    // }

    const createTitle1 = () => {
        if (!_.isEmpty(props.data)) {
            return (
                <p className={clsx('SubTitle', 'WhiteTint', 'NormalText')}>
                    Delete <span className={clsx('BoldText')}>{props.data.name}</span> Role
                </p>
            );
        }else {
            return null;
        }
    };

    const createContent1 = () => {
        if (!_.isEmpty(props.data)) {
            return (
                <Fragment>
                    <p className={clsx('BodyText', 'DarkGreyTint', 'NormalText')}>
                        Deleting this role will remove the role and all permissions associated with it. This action is irreversible. All users previously using this role must be detached before deleting this role.
                    </p>
                    <button className={clsx('Display--Flex', 'Flex--Start', 'Border--None', 'BackgroundColor--None', 'Margin--Top--20', 'Padding--Horizontal--0', 'Padding--Vertical--0')} onClick={() => setConfirmationCheck(!confirmationCheck)}>
                        {confirmationCheck ?
                            <RadioButtonCheckedIcon style={{ color: '#07689F', fontSize: '30', fontWeight: '600' }} /> :
                            <RadioButtonUncheckedIcon style={{ color: '#444444', fontSize: '30', fontWeight: '600' }} />
                        }
                        <p className={clsx('BodyText', 'DarkGreyTint', 'NormalText', 'TextAlignLeft', 'Margin--Horizontal--15', 'Pointer--Cursor')}>
                            By clicking this box, you confirm that you want to <span className={clsx('BoldText')}>Delete</span> the <span className={clsx('BoldText')}>{props.data.name}</span> role.
                        </p>
                    </button>
                </Fragment>
            );

        }else {
            return null;
        }
    };

    const createTitle2 = () => {
        if (!_.isEmpty(props.data)) {
            return (
                <p className={clsx('SubTitle', 'WhiteTint', 'NormalText')}>
                    Delete <span className={clsx('BoldText')}>{props.data.name}</span> Role Successful
                </p>
            );
        }else {
            return null;
        }
    };

    const createContent2 = () => {
        if (props.data) {
            return (
                <p className={clsx('BodyText', 'DarkGreyTint', 'NormalText')}>
                    The <span className={clsx('BoldText')}>{props.data.name}</span> role has been deleted successfully. This role will be removed from the roles screen and cannot be viewed again.
                </p>
            );
        }
    };

    const createActionText = () => {
        if (!resetClicked) {
            if (!_.isEmpty(props.data)) {
                return 'Delete';
            }else {
                return '';
            }
        }else {
            return 'Done';
        }
    };


    return (
        <Fragment>
            <AlertConfirmationPopUp
                open={props.open}
                headerContent={resetClicked ? createTitle2() : createTitle1()}
                headerStyle={{backgroundColor: resetClicked ? '#2D9639' : '#07689F'}}
                description='Auto-generate a new temporary password for this user. The new password will be email to the user and shown to you.'
                contentArea={resetClicked ? createContent2() : createContent1()}
                actionText={createActionText()}
                actionColor={resetClicked ?  '#2D9639' : '#07689F'}
                isActionDisabled={!checkIsValid()}
                isSecondaryDisabled={resetClicked}
                isSecondaryHidden={resetClicked}
                onSecondaryClicked={onClose}
                onActionClicked={!resetClicked ? () => setResetClicked(true) : () => onFinalClose()}
            />
        </Fragment>
    );
};

export default DeleleRolePopUp;
