// Import Modules
import React from 'react';
import clsx from 'clsx';
import { isMobileOnly } from 'react-device-detect';
import { useSelector, useDispatch } from 'react-redux';
import Backdrop from '@material-ui/core/Backdrop';
import { useMutation } from '@apollo/react-hooks';
import PulseLoader from 'react-spinners/PulseLoader';
import { Auth } from 'aws-amplify';

import Box from '../../Boxes/Box';
import ConfirmDeleteButtons from '../../Buttons/ConfirmDeleteButtons';
import { DELETE_ACCOUNT } from '../../../graphql/AuthMutations';
import * as UserInfoActionTypes from '../../../Redux/ActionTypes/Auth/UserInfoActionTypes'; 

const CloseAccountPopUp = (props) => {


    // React Hooks State Management
    // Get Auth for ID
    const auth = useSelector(state => state.auth.userInfo.auth);
    const dispatch = useDispatch();

    let loadingArea = null;


    // Handle Update Contact GraphQL Mutation
    const [deleteAccountAction, { loading: mutationLoading, error: mutationError }] = useMutation(DELETE_ACCOUNT, {
        variables: { id: auth.id, company_id: auth.company_id }
    });

    if (mutationLoading) {
        loadingArea = (
            <PulseLoader
                css={`margin-top: 20px;`}
                size={25}
                sizeUnit={"px"}
                margin="5px"
                color={'#0A3F79'}
                loading={true}
            />
        );
    }

    let errorComponent = null;

    // Show any errors at top of the form
    if (props.error || mutationError) {
        let errorMessage = '';
        if (props.error) {
            errorMessage = props.error;
        }
        if (mutationError) {
            errorMessage = mutationError;
        }
        errorComponent = (
            <p className="ErrorBody" style={{ paddingTop: '15px' }}>
                {errorMessage}
            </p>
        );
    }

    // Handle Form Actions
    const onConfirmClicked = () => {
        deleteAccountAction();
        Auth.signOut()
        .then((result) => {
            dispatch({ type: UserInfoActionTypes.UPDATE_LOGIN_STATUS, payload: { isLoggedIn: false } });
            dispatch({ type: UserInfoActionTypes.REMOVE_USER});
            dispatch({type: UserInfoActionTypes.REMOVE_AUTH});
        })
        .catch((error) => {
            console.log(error);
        });
    };




    return (
        <Backdrop open={!props.hidden || false} style= {{
            zIndex: 1000000000,
            color: '#fff',
            backgroundColor: 'rgba(0, 0, 0, 0.6)'
        }}>
            <Box className='CloseAccountBox' style={{paddingBottom: isMobileOnly ? '20px' : '40px'}}>
                <p className={clsx('Title', 'TextAlignCentered', 'RedTint', 'BoldText')} style={{margin: '20px'}}>
                    Confirm Account Deletion
                </p>
                {loadingArea}
                {errorComponent}
                <p className={clsx('Title', 'TextAlignCentered', 'DarkBlueTint', 'BodyText')} style={{margin: '20px'}}>
                    Are you sure you want to permenantly delete your company account?
                </p>
                <p className={clsx('Title', 'TextAlignCentered', 'DarkBlueTint', 'BodyText')} style={{margin: '10px'}}>
                    You will will lose access to the ProHelper Platform, delete all company-related information, 
                    cancel any existing subscription, and all other company users will lose access permenantly. This action is irreversible.
                </p>
                <ConfirmDeleteButtons
                    onBackClicked={props.onBackClicked}
                    onConfirmDeleteClicked={onConfirmClicked}
                />
            </Box>
        </Backdrop>
    );
};

export default CloseAccountPopUp;
