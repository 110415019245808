// Import Modules
import React, { useState } from 'react';
import clsx from 'clsx';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';


// Import Custom Classes
import ScreenDetailHeader from '../../../../Components/UI/ScreenDetailHeader';
import SettingsBox from '../../../../Components/Boxes/SettingsBox';
import * as ROUTES from '../../../../Constants/routes';
import DeleteSettingsBoxButton from '../../../../Components/Buttons/DeleteSettingsBoxButton';
import ErrorScreen from '../../Other/ErrorScreen';
import CloseAccountPopUp from '../../../../Components/Layout/Auth/CloseAccountPopUp';

const OwnerSettingsScreen = (props) => {


    const auth = useSelector(state => state.auth.userInfo.auth);
    const layoutWidth = useSelector(state => state.ui.layout.layoutWidth);
    const history = useHistory();
    const [closeAccountIsHidden, setCloseAccountIsHidden] = useState(true);

    if (_.isEmpty(auth)) {
        return null;
    }

    if (auth === null) {
        return <ErrorScreen />;
    }

    if (!auth.role_permissions.includes('settings_owner')) {
        return <ErrorScreen />;
    }

    const deleteAccountClicked = () => {
        setCloseAccountIsHidden(false);
    }

    const confirmDeleteAccountClicked = () => {
        
    }

    const exitCloseAccountPopUpClicked = () => {
        setCloseAccountIsHidden(true);
    }

    const onBackClicked = () => {
        history.push(ROUTES.SETTINGS);
    };

    // screenDescription='Customize your company owner contact, adjust important settings, or delete your company permanently.'

    return (
        <div className="BaseScreen" >
            
            <ScreenDetailHeader
                screenTitle='Owner Settings'
                backText='Back'
                isActionHidden={true}
                isActionDisabled={true}
                isMoreHidden={true}
                isMoreDisabled={true}
                layoutWidth={layoutWidth}
                onBackClicked={onBackClicked}
            />

            <div className={clsx('Margin--Horizontal--20')}>
                <SettingsBox title={'Delete Company Account'}>
                    <div className='SettingsBoxTextContainer'>
                        <p className={clsx('BodyText', 'TextAlignLeft', 'DarkBlueTint')}>
                            By deleting your company account, you will lose access to the ProHelper platform, delete all company information, 
                            cancel any existing subscription, and all other company users will lose access. This action is irreversible.
                        </p>
                    </div>
                    <DeleteSettingsBoxButton 
                        isDisabled={false}
                        handleSubmit={deleteAccountClicked}
                    />
                    <CloseAccountPopUp 
                        hidden={closeAccountIsHidden}
                        onBackClicked={exitCloseAccountPopUpClicked}
                        onConfirmDeleteClicked={confirmDeleteAccountClicked}
                    />
                </SettingsBox>
            </div>
        </div>
    );
}


export default OwnerSettingsScreen;