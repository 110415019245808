// Import Modules
import React, { Fragment, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';


// Import Custom Components
import { GET_ROLE_URL } from '../../graphql/RoleQueries';

const RoleDataContainer = (props) => {

    /**
     * Props:
     * setLoading(loading) : Function
     * setError(error) : Function
     * setData(data) : Function
     * auth: Auth Object
     * url_id: String
    */

    // Handle Get Users GraphQL Query
    const { loading, error, data } = useQuery(GET_ROLE_URL, {
        variables: { company_id: props.auth.company_id, url_id: props.url_id }
    });


    useEffect(() => {
        const { setLoading, setError, setData } = props;
        setLoading(loading);
        setError(error);
        if (data) {
            setData(data.getRoleURL);
        }
        // eslint-disable-next-line
    }, [loading, error, data])

    
    return (
        <Fragment>
            {props.children}
        </Fragment>
    );
};

export default RoleDataContainer;