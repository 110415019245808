// Import Modules
import React, { useState, Fragment } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import clsx from 'clsx';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';

// Import Custom Components (Need to Create)
// import NewItemBox from '../../../../Components/Boxes/NewItemBox';
// import SettingsSelect from '../../../../Components/MaterialUI/SettingsSelect';
// import RolesDataContainer from '../../../../Components/DataContainers/RolesDataContainer';
// import { UPDATE_USER_ROLE } from '../../../../graphql/UserMutations';
import AlertConfirmationPopUp from '../../../../Components/UI/AlertConfirmationPopUp';


const UserActivationPopUp = (props) => {

    // Clientside Permissions validation
    const auth = useSelector(state => state.auth.userInfo.auth);
    const [resetClicked, setResetClicked] = useState(false);
    const [confirmationCheck, setConfirmationCheck] = useState(false);
    // const [updateUserRoleAction, { loading: mutationLoading, error: mutationError }] = useMutation(UPDATE_USER_ROLE);

    // useEffect(()=> {
    //     if (props.data.role) { 
    //         setRole({ label: props.data.role.name, value: props.data.role.id });
    //     }
        
    // }, [props.data.role]);


    if (auth === null || auth === undefined || _.isEmpty(auth) || _.isEmpty(auth.role_permissions)) {
        return null;
    }

    if (!(props.open || false)) {
        return null;
    }

    // Functions Area


    const onClose = () => {
        setResetClicked(false);
        setConfirmationCheck(false);
        props.onClose();
    }

    const checkIsValid = () => {
        let result = false;

        if (confirmationCheck) {
            result = true;
        }

        return result;
    }

    // const onSubmit = () => {
        
    // }

    const createTitle1 = () => {
        if (!_.isEmpty(props.data)) {
            if (props.data.active) {
                return (
                    <p className={clsx('SubTitle', 'WhiteTint', 'NormalText')}>
                        Deactivate Account for <span className={clsx('BoldText')}>{props.data.full_name}</span>
                    </p>
                );
            }else {
                return (
                    <p className={clsx('SubTitle', 'WhiteTint', 'NormalText')}>
                        Activate Account for <span className={clsx('BoldText')}>{props.data.full_name}</span>
                    </p>
                );
            }

        }else {
            return null;
        }
    };

    const createContent1 = () => {
        if (!_.isEmpty(props.data)) {
            return (
                <Fragment>
                    <p className={clsx('BodyText', 'DarkGreyTint', 'NormalText')}>
                    {props.data.active ? 'Deactivating ' : 'Activating '}<span className={clsx('BoldText')}>{props.data.full_name}'s</span> account will {props.data.active ? 'restrict all access to the ProHelper platform. Deactived users will remain visible for linked items and in the users area.' : 'allow access to the ProHelper platform.'} This will also {props.data.active ? 'decrease' : 'increase'} the company's subscription seat total by 1 seat.
                    </p>
                    <button className={clsx('Display--Flex', 'Flex--Start', 'Border--None', 'BackgroundColor--None', 'Margin--Top--20', 'Padding--Horizontal--0', 'Padding--Vertical--0')} onClick={() => setConfirmationCheck(!confirmationCheck)}>
                        {confirmationCheck ?
                            <RadioButtonCheckedIcon style={{ color: '#07689F', fontSize: '30', fontWeight: '600' }} /> :
                            <RadioButtonUncheckedIcon style={{ color: '#444444', fontSize: '30', fontWeight: '600' }} />
                        }
                        <p className={clsx('BodyText', 'DarkGreyTint', 'NormalText', 'TextAlignLeft', 'Margin--Horizontal--15', 'Pointer--Cursor')}>
                            By clicking this box, you confirm that you want to <span className={clsx('BoldText')}>{props.data.active ? 'Deactivate' : 'Activate'}</span> <span className={clsx('BoldText')}>{props.data.full_name}'s</span> account.
                        </p>
                    </button>
                </Fragment>
            );

        }else {
            return (
                <p className={clsx('BodyText', 'DarkGreyTint', 'NormalText')}>
                    
                </p>
            );
        }
    };

    const createTitle2 = () => {
        if (!_.isEmpty(props.data)) {
            if (props.data.active) {
                return (
                    <p className={clsx('SubTitle', 'WhiteTint', 'NormalText')}>
                        Deactivate Account Successful for <span className={clsx('BoldText')}>{props.data.full_name}</span>
                    </p>
                );
            }else {
                return (
                    <p className={clsx('SubTitle', 'WhiteTint', 'NormalText')}>
                        Activate Account Successful for <span className={clsx('BoldText')}>{props.data.full_name}</span>
                    </p>
                );
            }
        }else {
            return null;
        }
    };

    const createContent2 = () => {
        if (props.data.active) {
            return (
                <p className={clsx('BodyText', 'DarkGreyTint', 'NormalText')}>
                    {props.data.full_name}'s account has been successfully deactivated. This user cannot access the ProHelper platform anymore and will remain visible in the users area of the ProHelper platform.
                </p>
            );
        }else {
            if (props.data.role) {
                return (
                    <Fragment>
                        <p className={clsx('BodyText', 'DarkGreyTint', 'NormalText')}>
                            <span className={clsx('BoldText')}>{props.data.full_name}'s</span> account has been successfully activated. This user can now access the ProHelper platform and has the role <span className={clsx('BoldText')}>{props.data.role.name}</span> and it's permisison access.
                        </p>
                    </Fragment>
                );
            }else {
                return (
                    <Fragment>
                        <p className={clsx('BodyText', 'DarkGreyTint', 'NormalText')}>
                            <span className={clsx('BoldText')}>{props.data.full_name}'s</span> account has been successfully activated. This user can now access the ProHelper platform and has no role access.
                        </p>
                    </Fragment>
                );
            }
        }
    };

    const createActionText = () => {
        if (!resetClicked) {
            if (!_.isEmpty(props.data)) {
                if (props.data.active) {
                    return 'Deactivate';
                }else {
                    return 'Activate';
                }

            }else {
                return '';
            }
        }else {
            return 'Done';
        }
    };


    return (
        <Fragment>
            <AlertConfirmationPopUp
                open={props.open}
                headerContent={resetClicked ? createTitle2() : createTitle1()}
                headerStyle={{backgroundColor: resetClicked ? '#2D9639' : '#07689F'}}
                description='Auto-generate a new temporary password for this user. The new password will be email to the user and shown to you.'
                contentArea={resetClicked ? createContent2() : createContent1()}
                actionText={createActionText()}
                actionColor={resetClicked ?  '#2D9639' : '#07689F'}
                isActionDisabled={!checkIsValid()}
                isSecondaryDisabled={resetClicked}
                isSecondaryHidden={resetClicked}
                onSecondaryClicked={props.onClose}
                onActionClicked={!resetClicked ? () => setResetClicked(true) : () => onClose()}
            />
        </Fragment>
    );
};

export default UserActivationPopUp;
