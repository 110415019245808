import React, { Fragment } from 'react';
import _ from 'lodash';
import clsx from 'clsx';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import moment from 'moment';

const ContactDetailsView = (props) => {
    

    const handlePhoneArea = () => {
        let userPhoneNumber = '';

        if (props.data.phone !== '' && !_.isEmpty(props.data.phone)) {
            userPhoneNumber = parsePhoneNumberFromString(props.data.phone, 'US').formatNational();

            return (
                <p className={clsx('BodyText', 'NormalText', 'TextAlignLeft', 'BlueTint')}>
                    <a href={"tel:+1" + props.data.phone} className={clsx('BlueTint')} target="_blank" rel="noopener noreferrer">
                        {userPhoneNumber}
                    </a>
                </p>
            );
        }else {
            return (
                <p className={clsx('BodyText', 'NormalText', 'TextAlignLeft', 'DarkGreyTint')} >
                    No phone number available.
                </p>
            );
        }

    };

    const handleAddressArea = () => {
        if (props.data.primary_address && !_.isEmpty(props.data.primary_address)) {
            let full_address_text = "";
            if (props.data.primary_address.line1) {
                full_address_text += props.data.primary_address.line1;
            }
            if (props.data.primary_address.line2) {
                full_address_text += " " + props.data.primary_address.line2;
            }

            if (props.data.primary_address.city) {
                full_address_text += ", " + props.data.primary_address.city;
            }

            if (props.data.primary_address.state) {
                full_address_text += " " + props.data.primary_address.state;
            }

            if (props.data.primary_address.zipCode) {
                full_address_text += " " + props.data.primary_address.zipCode;
            }

            let new_full_address_text = full_address_text.split(" ").join('+');

            return (
                <Fragment>
                    <a href={"https://www.google.com/maps/search/" + new_full_address_text} className={clsx('BodyText', 'NormalText', 'TextAlignLeft', 'BlueTint', 'ForceSelectText')} target="_blank" rel="noopener noreferrer" >
                        <p className={clsx('BodyText', 'NormalText', 'TextAlignLeft', 'BlueTint')}>
                            {props.data.primary_address.line1}
                        </p>
                        <p className={clsx('BodyText', 'NormalText', 'TextAlignLeft', 'BlueTint')}>
                            {props.data.primary_address.line2}
                        </p>
                        <p className={clsx('BodyText', 'NormalText', 'TextAlignLeft', 'BlueTint')}>
                            {props.data.primary_address.city + ', ' + props.data.primary_address.state + ' ' + props.data.primary_address.zipCode + ', ' + props.data.primary_address.country}
                        </p>
                    </a>
                </Fragment>
            );
        }else {
            return (
                <p className={clsx('BodyText', 'NormalText', 'TextAlignLeft', 'DarkGreyTint')}>
                    No address available.
                </p>
            ); 
        }
    };

    const handleNotesArea = () => {
        if (props.data.notes !== null && !_.isEmpty(props.data.notes)) {
            return (
                <p className={clsx('BodyText', 'NormalText', 'TextAlignLeft', 'DarkGreyTint')}>
                    {props.data.notes}
                </p>
            );
        }else {
            return (
                <p className={clsx('BodyText', 'NormalText', 'TextAlignLeft', 'DarkGreyTint')}>
                    No notes available.
                </p>
            ); 
        }
    };

    const handleEmailArea = () => {
        if (props.data.email) {
            return (
                <p className={clsx('BodyText', 'NormalText', 'TextAlignLeft', 'BlueTint')}>
                    <a href={"mailto:" + props.data.email.toLowerCase()} className={clsx('BlueTint')} target="_blank" rel="noopener noreferrer">
                        {props.data.email}
                    </a>
                </p>
            );
        }else {
            return (
                <p className={clsx('BodyText', 'NormalText', 'TextAlignLeft', 'DarkGreyTint')}>
                    No email available.
                </p>
            ); 
        }
    };

    const handleLastUpdatedArea = () => {
        if (props.data.last_updated_at) {
            return (
                <p className={clsx('BodyText', 'NormalText', 'TextAlignLeft', 'DarkGreyTint')}>
                    {moment(new Date(props.data.last_updated_at), "YYYYMMDD").fromNow() || ''}
                </p>
            );
        }else {
            return (
                <p className={clsx('BodyText', 'NormalText', 'TextAlignLeft', 'DarkGreyTint')}>
                    No date available.
                </p>
            ); 
        }
    };

    return (
        <div className='UserDetailContactForm_Container'>
            <p className={clsx('SubTitleSmall', 'BoldText', 'TextAlignLeft', 'DarkGreyTint')}>
                Email
            </p>
            {handleEmailArea()}
            <div className='UserDetailRoleForm_ContainerSpacer' />
            <p className={clsx('SubTitleSmall', 'BoldText', 'TextAlignLeft', 'DarkGreyTint')}>
                Phone Number
            </p>
            {handlePhoneArea()}
            <div className='UserDetailRoleForm_ContainerSpacer' />
            <p className={clsx('SubTitleSmall', 'BoldText', 'TextAlignLeft', 'DarkGreyTint')}>
                Address
            </p>
            {handleAddressArea()}
            <div className='UserDetailRoleForm_ContainerSpacer' />
            <p className={clsx('SubTitleSmall', 'BoldText', 'TextAlignLeft', 'DarkGreyTint')}>
                Notes
            </p>
            {handleNotesArea()}
            <div className='UserDetailRoleForm_ContainerSpacer' />
            <p className={clsx('SubTitleSmall', 'BoldText', 'TextAlignLeft', 'DarkGreyTint')}>
                Last Updated
            </p>
            {handleLastUpdatedArea()}
        </div>
    );
};

export default ContactDetailsView;
