// Import Modules
import React from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import clsx from 'clsx';

import BoxFooterButton from '../Buttons/BoxFooterButton';

const AlertConfirmationPopUp = (props) => {

    // Initalize variables
    const auth = useSelector(state => state.auth.userInfo.auth);



    // Client-side Checks
    if (auth === null || auth === undefined || _.isEmpty(auth) || _.isEmpty(auth.role_permissions)) {
        return null;
    }

    if (!(props.open || false)) {
        return null;
    }



    // Functions Area

    // const checkIsValid = () => {
    //     let result = false;
        
    //     return result;
    // }

    // const onSubmit = () => {
        
    // }


    return (
        <div className='CustomBackDrop' tabIndex={-1} >
            <div className="AlertConfirmationPopUpBox">
                <div className={clsx('TextAlignLeft', 'Padding--Horizontal--20', 'Padding--Vertical--15 ', 'Border--Bottom--1')} style={props.headerStyle || {}}>
                    {props.headerContent}
                </div>
                <div className={clsx('TextAlignLeft', 'Padding--Horizontal--20', 'Padding--Vertical--20')}>
                    {props.contentArea}
                </div>
                <div className={clsx('TextAlignRight', 'Display--Flex', 'Flex--End', 'Padding--Horizontal--20', 'Padding--Bottom--20')}>
                    <BoxFooterButton 
                        title={props.cancelText || 'Cancel'}
                        titleClasses={['DarkBlueTint', 'SemiBoldText']}
                        buttonStyles={{backgroundColor: 'white'}}
                        onClick={props.onSecondaryClicked}
                        isDisabled={props.isSecondaryDisabled}
                        isHidden={props.isSecondaryHidden}
                    />
                    <BoxFooterButton 
                        title={props.actionText || ''}
                        titleClasses={['WhiteTint', 'BoldText']}
                        buttonStyles={{marginLeft: '8px', backgroundColor: !props.isActionDisabled ? (props.actionColor ? props.actionColor : '#07689F') : '#979797'}}
                        onClick={props.onActionClicked}
                        isDisabled={props.isActionDisabled || false}
                        isHidden={props.isActionHidden}

                    />
                </div>
            </div>
        </div>
    );
};

export default AlertConfirmationPopUp;
