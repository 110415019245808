// Import Modules
import React, { useState } from 'react';
import ErrorScreen from '../../Other/ErrorScreen';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import clsx from 'clsx';
import Masonry from 'react-masonry-css';
import { useHistory, useParams } from 'react-router-dom';
import PulseLoader from 'react-spinners/PulseLoader';
import { useMutation } from '@apollo/react-hooks';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
// import MuiAlert from '@material-ui/lab/Alert';
// import { styled } from '@material-ui/core/styles';


// Import Custom Components
import ScreenDetailHeader from '../../../../Components/UI/ScreenDetailHeader';
import UserDataContainer from '../../../../Components/DataContainers/UserDataContainer';
import SettingsBox from '../../../../Components/Boxes/SettingsBox';
import ContactDetailsForm from '../../../../Components/Forms/ContactDetailsForm';
import ContactAddressForm from '../../../../Components/Forms/ContactAddressForm';
import { UPDATE_USER } from '../../../../graphql/UserMutations';
import SuccessAlert from '../../../../Components/MaterialUI/SuccessAlert';

// const SuccessAlert = styled(MuiAlert)({
//     backgroundColor: '#2D9639',
//     fontWeight: '600'
// });

// function Alert(props) {
//     return <SuccessAlert elevation={6} variant="filled" {...props} />;
// }


const EditUserScreen = (props) => {

    // Get / Initialize Variables
    const auth = useSelector(state => state.auth.userInfo.auth);
    const layoutWidth = useSelector(state => state.ui.layout.layoutWidth);
    const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
    const [dataContainerLoading, setDataContainerLoading] = useState(false);
    const [dataContainerError, setDataContainerError] = useState(null);
    const [userData, setUserData] = useState({});
    const history = useHistory();
    const { id } = useParams();

    const [updateUserAction] = useMutation(UPDATE_USER);


    // Client-Side Authorization Checks
    if (auth === null || auth === undefined || _.isEmpty(auth)) {
        return null;
    }

    if (!auth.role_permissions.includes('team_user_update')) {
        return <ErrorScreen />;
    }

    const handleSuccessSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSuccessSnackbarOpen(false);
    };

    const onBackClicked = (user) => {
        history.goBack();
    };


    const onContactDetailsUpdateSubmit = (updatedUserInput) => {
        updateUserAction({ variables: { updatedUserInput: updatedUserInput } })
        .then((result) => {
            setSuccessSnackbarOpen(true);
        })
        .catch((error) => {
            console.log(error);
        });
    }



    const handleLoadingArea = () => {
        if (dataContainerLoading) {
            return (
                <PulseLoader
                    css={`margin-top: 20px;`}
                    size={20}
                    sizeUnit={"px"}
                    margin="5px"
                    color={'#0A3F79'}
                    loading={true}
                />
            );
        } else {
            return null;
        }
    };

    const handleErrorArea = () => {
        if (dataContainerError) {
            let splitMessage = dataContainerError.message.split(':');
            let errorMessage  = 'Error:' + splitMessage[splitMessage.length - 1];
            return (
                <p className={clsx('Margin--Vertical--20', 'Margin--Horizontal--Auto', 'TextAlignCenter', 'SubTitle', 'SemiBoldText', 'RedTint')}>
                    {errorMessage}
                </p>
            );
        } else {
            return null;
        }
    };


    return (
        <div className='BaseScreen' style={{ paddingBottom: '20px' }}>
            <UserDataContainer
                setLoading={(loading) => setDataContainerLoading(loading)}
                setError={(error) => setDataContainerError(error)}
                setData={(data) => setUserData(data)}
                auth={auth}
                url_id={id}
            >
                <ScreenDetailHeader
                    screenTitle={!_.isEmpty(userData) ? (_.upperFirst(userData.first_name) + " " + _.upperFirst(userData.last_name)) : ''}
                    backText='Back'
                    isActionHidden={true}
                    isActionDisabled={true}
                    isMoreDisabled={true}
                    layoutWidth={layoutWidth}
                    onBackClicked={onBackClicked}
                />
                {handleLoadingArea()}
                {handleErrorArea()}
                {(!_.isEmpty(userData) || userData !== undefined) ?
                    <Masonry
                        breakpointCols={{ default: 2, 1600: 2, 700: 1 }}
                        className="screen-detail-masonry-grid"
                        columnClassName="screen-detail-masonry-grid_column"
                    >
                        <div className={clsx('Width--Full-20', 'Margin--Horizontal--Auto', 'Margin--Bottom--20')}>
                            <SettingsBox title={'Contact Details'}>
                                <ContactDetailsForm
                                    data={userData}
                                    onUpdateUser={(updatedUserInput) => onContactDetailsUpdateSubmit(updatedUserInput)}
                                />
                            </SettingsBox>
                        </div>

                        <div className={clsx('Width--Full-20', 'Margin--Horizontal--Auto', 'Margin--Bottom--20')}>
                            <SettingsBox title={'Contact Address'}>
                                <ContactAddressForm
                                    data={userData}
                                    onUpdateUser={(updatedUserInput) => onContactDetailsUpdateSubmit(updatedUserInput)}
                                />
                            </SettingsBox>
                        </div>
                    </Masonry>
                    : null
                }
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    open={successSnackbarOpen}
                    autoHideDuration={5000}
                    onClose={handleSuccessSnackbarClose}
                    action={
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleSuccessSnackbarClose}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    }
                    variant="Success"
                >
                    <SuccessAlert onClose={handleSuccessSnackbarClose} severity="success">
                        User Updated Successfully.
                    </SuccessAlert>
                </Snackbar>
            </UserDataContainer>

        </div>
    );
};

export default EditUserScreen;
