// Import Modules
import React, { useState, useEffect, Fragment }  from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import clsx from 'clsx';
import Masonry from 'react-masonry-css';
import PulseLoader from 'react-spinners/PulseLoader';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

// Import Custom Components
import ErrorScreen from '../../Other/ErrorScreen';
import ScreenDetailHeader from '../../../../Components/UI/ScreenDetailHeader';
import * as ROUTES from '../../../../Constants/routes';
import ActionBox from '../../../../Components/Boxes/ActionBox';
import RoleDetailInfoView from '../../../../Components/Views/RoleDetailInfoView';
import RoleDetailUsersView from '../../../../Components/Views/RoleDetailUsersView';
import PermissionCategoriesContainer from '../../../../Components/Containers/PermissionCategoriesContainer';
import PermissionsHelper from '../../../../Helpers/PermissionsHelper';
import ProHelperPermissions from '../../../../Constants/ProHelperPermissions';
import RoleDataContainer from '../../../../Components/DataContainers/RoleDataContainer';
import DeleteRolePopUp from './DeleteRolePopUp';
import ChangeUserRolePopUp from '../Users/ChangeUserRolePopUp';
import SuccessAlert from '../../../../Components/MaterialUI/SuccessAlert';

const RoleDetailScreen = (props) => {

    // Clientside Permissions validation
    // Get / Initialize Variables
    const auth = useSelector(state => state.auth.userInfo.auth);
    const layoutWidth = useSelector(state => state.ui.layout.layoutWidth);
    const [editRoleActionStatus, setEditRoleActionStatus] = useState(false);
    const [roleData, setRoleData] = useState({});
    const [dataContainerLoading, setDataContainerLoading] = useState(false);
    const [deleteRolePopUpOpen, setDeleteRolePopUpOpen] = useState(false);
    const [dataContainerError, setDataContainerError] = useState(undefined);
    const [changeUserRolePopUpOpen, setChangeUserRolePopUpOpen] = useState(false);
    const [changeUserData, setChangeUserData] = useState({});
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const history = useHistory();
    const { id } = useParams();

    const permissionsHelper = new PermissionsHelper();
    const [permissionCategories, setPermissionCategories] = useState([]);

    // Setup Role Detail Screen
    useEffect(()=> {
        if (auth !== null && auth !== undefined && !_.isEmpty(auth)) { 
            if (auth.role_permissions.includes('team_role_update')) {
                if (!_.isEmpty(roleData)) {
                    setEditRoleActionStatus(true);
                    
                    setPermissionCategories(permissionsHelper.CreatePermissionCatelog(
                        roleData.permissions, 
                        {open_categories: {'Settings': true, 'Customers': true, 'Work': true, 'Schedule': true, 'Billing': true, 'Team': true, 'Reports': true}, role: roleData}
                    ));
                }
            }
        }
        
    }, [auth, roleData, permissionsHelper]);

    // Client-Side Authorization Checks
    if (auth === null || auth === undefined || _.isEmpty(auth)) {
        return null;
    }

    if (!auth.role_permissions.includes('team_role_read')) {
        return <ErrorScreen />;
    }


    const onUserClicked = (user) => {
        history.push(ROUTES.TEAM_USER_DETAIL_PREFIX + user.url_id);
    };

    const onBackClicked = (user) => {
        history.push(ROUTES.TEAM_ROLES);
    };

    const onLoadMoreRolesUsers = () => {

    };

    const onEditRoleClicked = () => {
        history.push(ROUTES.TEAM_ROLE_DETAIL_PREFIX + id + '/edit');
    }

    const onDeleteRolePopUpClosed = () => {
        setDeleteRolePopUpOpen(false);
    };

    const onChangeUserRoleClicked = (user) => {
        setChangeUserData(user);
        setChangeUserRolePopUpOpen(true);
    };

    const onChangeUserRoleClosed = () => {
        setChangeUserData({});
        setChangeUserRolePopUpOpen(false);
    };


    const onChangeUserRoleSuccess = () => {
        setChangeUserData({});
        setChangeUserRolePopUpOpen(false);
        setSnackbarMessage("Successfully changed user's role");
        setSnackbarOpen(true);
    };


    const handleSuccessSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };



    
    const roleDetailMenuActions = [
        {
            text: 'Duplicate Role',
            onItemClicked: () => {
                history.push(ROUTES.TEAM_ROLES_NEW + "?duplicate=" + id);
            }
        },
        {
            text: 'Delete Role',
            onItemClicked: () => {
                setDeleteRolePopUpOpen(true);
            }
        }
    ];

    const onPermissionsCategoryClicked = (permissionCategory) => {
        const updateObject = {
            action: 'updated_category',
            value: permissionCategory
        };

        let newPermissionsCategories = permissionsHelper.UpdatePermissionCatelog(permissionCategories, updateObject);

        setPermissionCategories(newPermissionsCategories);
    };

    const onPermissionButtonClicked = (permission) => {
        const updateObject = {
            action: 'updated_permission',
            value: permission
        };

        let newPermissionsCategories = permissionsHelper.UpdatePermissionCatelog(permissionCategories, updateObject);

        setPermissionCategories(newPermissionsCategories);
    };

    
    const handleLoadingArea = () => {
        if (dataContainerLoading) {
            return (
                <PulseLoader
                    css={`margin-top: 20px;`}
                    size={20}
                    sizeUnit={"px"}
                    margin="5px"
                    color={'#0A3F79'}
                    loading={true}
                />
            );
        } else {
            return null;
        }
    };

    const handleErrorArea = () => {
        if (dataContainerError) {
            let splitMessage = dataContainerError.message.split(':');
            let errorMessage  = 'Error:' + splitMessage[splitMessage.length - 1];
            return (
                <p className={clsx('Margin--Vertical--20', 'Margin--Horizontal--Auto', 'TextAlignCenter', 'SubTitle', 'SemiBoldText', 'RedTint')}>
                    {errorMessage}
                </p>
            );
        } else {
            return null;
        }
    };

    

    const onRoleUpdated = (role) => {
        setRoleData(role);

        let newPermissionsCategories = [];

        if (permissionCategories !== []) {
            newPermissionsCategories = permissionsHelper.CreatePermissionCatelog(
                ProHelperPermissions.companyPermissions, 
                {open_categories: permissionsHelper.GetOpenCategories(permissionCategories), role: role}
            );
        }else {
            newPermissionsCategories = permissionsHelper.CreatePermissionCatelog(
                ProHelperPermissions.companyPermissions, 
                {open_categories: {'Settings': true, 'Customers': true, 'Work': true, 'Schedule': true, 'Billing': true, 'Team': true, 'Reports': true}, role: roleData}
            );
        }

        setPermissionCategories(newPermissionsCategories);
    };

    // const handleCategoryTitle = () => {
    //     let categoryTitle = '';
    //     let categoryTitleSuffix = '';
    //     let categoryPermissionsSelected = 0;
    //     let categoryPermissionsAvailable = 0;

    //     if (!_.isEmpty(roleData.permissions)) {

    //     }




    //     if (props.data.category !== undefined) {
    //         categoryTitle = props.data.category;
    //     }

    //     if (!_.isEmpty(props.data.items)) {
    //         props.data.items.forEach((permissionGroup) => {
    //             if (!_.isEmpty(permissionGroup.permissions)) {
    //                 permissionGroup.permissions.forEach((permission) => {
    //                     categoryPermissionsAvailable += 1;
    
    //                     if (permission.selected === true) {
    //                         categoryPermissionsSelected += 1;
    //                     }
    //                 });
    //             }
    
    //         });
    //     }
        
    //     categoryTitleSuffix = ' (' + categoryPermissionsSelected + '/' + categoryPermissionsAvailable + ')';


    //     return (
    //         <Fragment>
    //             <p
    //                 className={clsx(
    //                     'Padding--Vertical--0',
    //                     'Padding--Horizontal--0',
    //                     'Margin--Vertical--Auto',
    //                     'Margin--Horizontal--0',
    //                     'TextAlignLeft',
    //                     'SubTitle',
    //                     'SemiBoldText',
    //                     'DarkGreyTint')}
    //             >
    //                 Permissions
    //                 <span className={clsx('SubTitleSmall', 'NormalText')}>
    //                     {categoryTitleSuffix}
    //                 </span>
    //             </p>
    //         </Fragment>
    //     );
    // };


    return (
        <div className='BaseScreen' style={{ paddingBottom: '20px' }}>
            <RoleDataContainer
                setLoading={(loading) => setDataContainerLoading(loading)}
                setError={(error) => setDataContainerError(error)}
                setData={(data) => onRoleUpdated(data)}
                auth={auth}
                url_id={id}
            >
                <ChangeUserRolePopUp
                    data={changeUserData}
                    open={changeUserRolePopUpOpen}
                    onClose={onChangeUserRoleClosed}
                    onChangeUserRole={onChangeUserRoleSuccess}
                    source='role_detail'
                />
                <DeleteRolePopUp 
                    open={deleteRolePopUpOpen}
                    data={roleData}
                    onClose={onDeleteRolePopUpClosed}
                />
                <ScreenDetailHeader
                    screenTitle={!_.isEmpty(roleData) ? roleData.name : ''}
                    backText='Roles'
                    actionText='Edit Role'
                    moreText='More Actions'
                    menuData={roleDetailMenuActions}
                    onActionClicked={onEditRoleClicked}
                    isActionHidden={!editRoleActionStatus}
                    isActionDisabled={false}
                    isMoreDisabled={!editRoleActionStatus}
                    layoutWidth={layoutWidth}
                    onBackClicked={onBackClicked}
                />
                {handleLoadingArea()}
                {handleErrorArea()}
                {(!_.isEmpty(roleData) || roleData === undefined) ?
                    <Fragment>
                        <Masonry
                            breakpointCols={{ default: 2, 700: 2, 600: 1 }}
                            className="screen-detail-masonry-grid"
                            columnClassName="screen-detail-masonry-grid_column"
                        >
                            <div className={clsx('Width--Full-20', 'Margin--Horizontal--Auto', 'Margin--Bottom--20')}>
                                <ActionBox
                                    title={'Role Details'}
                                >
                                    <RoleDetailInfoView
                                        data={roleData}
                                    />
                                </ActionBox>
                            </div>
                            <div className={clsx('Width--Full-20', 'Margin--Horizontal--Auto', 'Margin--Bottom--20')}>
                                <ActionBox
                                    title={'Users - ' + roleData.user_amount}
                                >
                                    <RoleDetailUsersView
                                        auth={auth}
                                        data={roleData.users}
                                        onUserClicked={(user) => onUserClicked(user)}
                                        onChangeUserRoleClicked={(user) => onChangeUserRoleClicked(user)}
                                        loadMore={onLoadMoreRolesUsers}
                                    />
                                </ActionBox>
                            </div>

                        </Masonry>

                        <div className={clsx('Padding--Horizontal--20x')} >
                            <div className={clsx(
                                'BackgroundColor--WhiteTint',
                                'BorderRadius--All--10',
                                'Border--All--1',
                                'BorderColor--DarkBlueTint',
                                'BoxShadow--1',
                                'TextAlignLeft')}
                            >
                                <div className={clsx('Padding--Horizontal--20', 'Padding--Vertical--15')}>
                                    <p className={clsx('SubTitle', 'DarkBlueTint', 'SemiBoldText', 'TextAlignLeft', 'Margin--Vertical--Auto')}>
                                        Permissions
                                    </p>
                                </div>
                                <div className='SettingsBoxLine' />
                                <PermissionCategoriesContainer
                                    data={permissionCategories}
                                    onCategoryClick={(permissionCategory) => onPermissionsCategoryClicked(permissionCategory)}
                                    onPermissionButtonClicked={(permission) => onPermissionButtonClicked(permission)}
                                    isEditable={false}
                                />

                            </div>
                        </div>
                    </Fragment>
                :
                    null
                }
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    open={snackbarOpen}
                    autoHideDuration={5000}
                    onClose={handleSuccessSnackbarClose}
                    action={
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleSuccessSnackbarClose}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    }
                    variant="Success"
                >
                    <SuccessAlert onClose={handleSuccessSnackbarClose} severity="success">
                        {snackbarMessage}
                    </SuccessAlert>
                </Snackbar>
            </RoleDataContainer>
        </div>
    );
};

export default RoleDetailScreen;
