import _ from 'lodash';


export default class PermissionsHelper {


    CreatePermissionCatelog(PermissionsData, CatelogOptions) {

        let result = null;

        if (PermissionsData === null || PermissionsData === undefined) {
            return null;
        }

        let permissionsGroups = {};
        let permissionsCategories = {};

        // Create new list copy
        PermissionsData.forEach((permission) => {
            let newPermission = {...permission};
            newPermission.selected = false;

            if (!_.isEmpty(CatelogOptions.role)) {
                newPermission.selected =  Boolean(CatelogOptions.role.permissions.find((role_permission) => {
                    return role_permission.id.toString() === newPermission.id.toString();
                }))
            }

            if (_.isEmpty(newPermission.group_identifier) !== '' && newPermission.group_identifier !== null && newPermission.group_identifier !== undefined) {
                
                if (permissionsGroups[newPermission.group_identifier] === undefined) {
                    permissionsGroups[newPermission.group_identifier] = {
                        group_identifier: newPermission.group_identifier,
                        group_name: newPermission.group_name || '',
                        category: newPermission.category || '',
                        sub_category: newPermission.sub_category || '',
                        permissions: [newPermission]
                    }
                }else {
                    if (permissionsGroups[newPermission.group_identifier].permissions !== undefined) {
                        permissionsGroups[newPermission.group_identifier].permissions.push(newPermission);
                    }
                    
                }
            }
        });

        if (!_.isEmpty(permissionsGroups)) {
            Object.values(permissionsGroups).forEach((permissionGroup) => {
                if (permissionsCategories[permissionGroup.category] === undefined) {
                    permissionsCategories[permissionGroup.category] = [permissionGroup];
                }else {
                    if (permissionsCategories[permissionGroup.category]) {
                        permissionsCategories[permissionGroup.category].push(permissionGroup);
                    }
                }
            });
        }
        

        if (!_.isEmpty(permissionsCategories)) {
            let idealPermissions = [];

            if (!_.isEmpty(permissionsCategories.Settings)) {
                let categoryOpen = false;

                if (!_.isEmpty(CatelogOptions)) {
                    if (!_.isEmpty(CatelogOptions.open_categories)) {
                        categoryOpen = CatelogOptions.open_categories.Settings;
                    }
                }

                idealPermissions.push({
                    category: permissionsCategories.Settings[0].category,  
                    open: categoryOpen,
                    items: permissionsCategories.Settings
                });
            }

            if (!_.isEmpty(permissionsCategories.Work)) {
                let categoryOpen = false;

                if (!_.isEmpty(CatelogOptions)) {
                    if (!_.isEmpty(CatelogOptions.open_categories)) {
                        categoryOpen = CatelogOptions.open_categories.Work;
                    }
                }

                idealPermissions.push({
                    category: permissionsCategories.Work[0].category,  
                    open: categoryOpen,
                    items: permissionsCategories.Work
                });

            }

            if (!_.isEmpty(permissionsCategories.Schedule)) {
                let categoryOpen = false;

                if (!_.isEmpty(CatelogOptions)) {
                    if (!_.isEmpty(CatelogOptions.open_categories)) {
                        categoryOpen = CatelogOptions.open_categories.Schedule;
                    }
                }

                idealPermissions.push({
                    category: permissionsCategories.Schedule[0].category,  
                    open: categoryOpen,
                    items: permissionsCategories.Schedule
                });

            }

            if (!_.isEmpty(permissionsCategories.Customers)) {
                let categoryOpen = false;

                if (!_.isEmpty(CatelogOptions)) {
                    if (!_.isEmpty(CatelogOptions.open_categories)) {
                        categoryOpen = CatelogOptions.open_categories.Customers;
                    }
                }

                idealPermissions.push({
                    category: permissionsCategories.Customers[0].category,  
                    open: categoryOpen,
                    items: permissionsCategories.Customers
                });

            }


            if (!_.isEmpty(permissionsCategories.Billing)) {
                let categoryOpen = false;

                if (!_.isEmpty(CatelogOptions)) {
                    if (!_.isEmpty(CatelogOptions.open_categories)) {
                        categoryOpen = CatelogOptions.open_categories.Billing;
                    }
                }

                idealPermissions.push({
                    category: permissionsCategories.Billing[0].category,  
                    open: categoryOpen,
                    items: permissionsCategories.Billing
                });

            }

            if (!_.isEmpty(permissionsCategories.Team)) {
                let categoryOpen = false;

                if (!_.isEmpty(CatelogOptions)) {
                    if (!_.isEmpty(CatelogOptions.open_categories)) {
                        categoryOpen = CatelogOptions.open_categories.Team;
                    }
                }

                idealPermissions.push({
                    category: permissionsCategories.Team[0].category,  
                    open: categoryOpen,
                    items: permissionsCategories.Team
                });

            }

            if (!_.isEmpty(permissionsCategories.Reports)) {
                let categoryOpen = false;

                if (!_.isEmpty(CatelogOptions)) {
                    if (!_.isEmpty(CatelogOptions.open_categories)) {
                        categoryOpen = CatelogOptions.open_categories.Reports;
                    }
                }

                idealPermissions.push({
                    category: permissionsCategories.Reports[0].category,  
                    open: categoryOpen,
                    items: permissionsCategories.Reports
                });

            }

            result = idealPermissions;
        }

        return result;

    }

    UpdatePermissionCatelog(PermissionsCatelog, UpdateObject) {
        let newPermissionsCatelog = [...PermissionsCatelog];

        if (UpdateObject.action === 'updated_category') {
            let newPermissionCategory = {...UpdateObject.value};
            newPermissionCategory.open = !newPermissionCategory.open;

            for (let i in newPermissionsCatelog) {
                if (newPermissionsCatelog[i].category === newPermissionCategory.category) {
                    newPermissionsCatelog[i] = newPermissionCategory;
                }
            }
        } else if (UpdateObject.action === 'updated_permission') {
            let newPermission = {...UpdateObject.value};
            newPermission.selected = !newPermission.selected;

            for (let i in newPermissionsCatelog) {
                if (newPermissionsCatelog[i].category === newPermission.category) {
                    for (let j in newPermissionsCatelog[i].items) {
                        if (newPermissionsCatelog[i].items[j].group_identifier === newPermission.group_identifier) {
                            for (let k in newPermissionsCatelog[i].items[j].permissions) {
                                if (newPermissionsCatelog[i].items[j].permissions[k].id === newPermission.id) {
                                    newPermissionsCatelog[i].items[j].permissions[k] = newPermission;
                                }
                            }
                        }
                    }
                }
            }
        }

        return newPermissionsCatelog;
    }

    GetOpenCategories(PermissionsCatelog) {
        let openCategoriesObject = {};

        for (let i in PermissionsCatelog) {
            openCategoriesObject[PermissionsCatelog[i].category] = PermissionsCatelog[i].open;
        }

        return openCategoriesObject;
    }

    GetSelectedPermissionIDs(PermissionsCatelog) {
        let selectedPermissionIDs = [];

        for (let i in PermissionsCatelog) {
            for (let j in PermissionsCatelog[i].items) {
                for (let k in PermissionsCatelog[i].items[j].permissions) {
                    if (PermissionsCatelog[i].items[j].permissions[k].selected) {
                        selectedPermissionIDs.push(PermissionsCatelog[i].items[j].permissions[k].id);
                    }
                }
            }
        }

        return selectedPermissionIDs;
    }

    CreateAuthNavigatorCategories(AuthNavigatorCategories, auth) {
        let result = [ ...AuthNavigatorCategories ];

        if (!_.isEmpty(auth.role_permissions)) {
            if (!(auth.role_permissions.includes('jobs') || 
                auth.role_permissions.includes('requests') || 
                auth.role_permissions.includes('routes') || 
                auth.role_permissions.includes('assignments_personal_full') ||
                auth.role_permissions.includes('assignments_company_read'))) {
                    result = _.remove(result, (category) => {
                        return category.id !== 'Work';
                    })  
            }else {
                for (let i in result) {
                    if (result[i].id === 'Work') {
                        if (!(auth.role_permissions.includes('assignments_personal_full') || auth.role_permissions.includes('assignments_company_read'))) {
                            result[i].children = _.remove(result[i].children, (child_element) => {
                                return child_element.id !== 'Tasks';
                            });
                        }
                        if (!auth.role_permissions.includes('jobs')) {
                            result[i].children = _.remove(result[i].children, (child_element) => {
                                return child_element.id !== 'Jobs';
                            });
                        }
                        if (!auth.role_permissions.includes('requests')) {
                            result[i].children = _.remove(result[i].children, (child_element) => {
                                return child_element.id !== 'Requests';
                            });
                        }
                        if (!auth.role_permissions.includes('routes')) {
                            result[i].children = _.remove(result[i].children, (child_element) => {
                                return child_element.id !== 'Routes';
                            });
                        }
                    }
                }
            }

            if (!(auth.role_permissions.includes('schedule_personal') || 
                auth.role_permissions.includes('schedule_company'))) {
                    result = _.remove(result, (category) => {
                        return category.id !== 'Schedule';
                    });
            }
            if (!auth.role_permissions.includes('customers')) {
                    result = _.remove(result, (category) => {
                        return category.id !== 'Customers';
                    })
            }
            if (!(auth.role_permissions.includes('billing_estimates') || 
                auth.role_permissions.includes('billing_invoices') ||
                auth.role_permissions.includes('billing_items'))) {
                    result = _.remove(result, (category) => {
                        return category.id !== 'Billing';
                    })
            }else {
                for (let i in result) {
                    if (result[i].id === 'Billing') {
                        if (!auth.role_permissions.includes('billing_estimates')) {
                            result[i].children = _.remove(result[i].children, (child_element) => {
                                return child_element.id !== 'Estimates';
                            });
                        }
                        if (!auth.role_permissions.includes('billing_invoices')) {
                            result[i].children = _.remove(result[i].children, (child_element) => {
                                return child_element.id !== 'Invoices';
                            });
                        }
                        if (!auth.role_permissions.includes('billing_items')) {
                            result[i].children = _.remove(result[i].children, (child_element) => {
                                return child_element.id !== 'Items';
                            });
                        }
                    }
                }
            }
            if (!(auth.role_permissions.includes('team_users') || 
                auth.role_permissions.includes('team_roles'))) {
                    result = _.remove(result, (category) => {
                        return category.id !== 'Team';
                    })
            }else {
                for (let i in result) {
                    if (result[i].id === 'Team') {
                        if (!auth.role_permissions.includes('team_users')) {
                            result[i].children = _.remove(result[i].children, (child_element) => {
                                return child_element.id !== 'Users';
                            });
                        }
                        if (!auth.role_permissions.includes('team_roles')) {
                            result[i].children = _.remove(result[i].children, (child_element) => {
                                return child_element.id !== 'Roles';
                            });

                            // Need to Update After Creating New Permissions (Created: 06/01/20)
                            result[i].children = _.remove(result[i].children, (child_element) => {
                                return child_element.id !== 'Time Sheets';
                            });
                        }

                        
                    }
                }
            }
            if 
                (!auth.role_permissions.includes('reports_read')) {
                    result = _.remove(result, (category) => {
                        return category.id !== 'Reports';
                    })
            }
            if 
                (!(auth.role_permissions.includes('settings_company') || 
                auth.role_permissions.includes('settings_features') || 
                auth.role_permissions.includes('settings_prohelper') || 
                auth.role_permissions.includes('settings_data') || 
                auth.role_permissions.includes('settings_owner'))) {
                    result = _.remove(result, (category) => {
                        return category.id !== '';
                    })
            }
        }else {
            result = [];
        }

        return result;
    }

    CreateSettingsCategories(SettingsCategories, auth) {
        let result = [ ...SettingsCategories ];

        if (auth.role_permissions) {
            if (!auth.role_permissions.includes('settings_company')) {
                result = _.remove(result, (settings_category) => {
                    return settings_category.categoryIdentifier !== 'settings_prohelper';
                })
            }

            if (!auth.role_permissions.includes('settings_features')) {
                result = _.remove(result, (settings_category) => {
                    return settings_category.categoryIdentifier !== 'settings_features';
                })
            }

            if (!auth.role_permissions.includes('settings_prohelper')) {
                result = _.remove(result, (settings_category) => {
                    return settings_category.categoryIdentifier !== 'settings_prohelper';
                })
            }

            if (!auth.role_permissions.includes('settings_data')) {
                result = _.remove(result, (settings_category) => {
                    return settings_category.categoryIdentifier !== 'settings_data';
                })
            }

            if (!auth.role_permissions.includes('settings_owner')) {
                result = _.remove(result, (settings_category) => {
                    return settings_category.categoryIdentifier !== 'settings_owner';
                })
            }
        }

        return result;
    }
}