import gql from 'graphql-tag';

const GET_USER = gql`
  query GetUser($id:ID!){
    getUser(id: $id) {
        id
        user_id
        company_id
        sync_token
        email
        phone
        notes
        full_name
        display_name
        first_name
        last_name
        primary_address
        active
        account_status
        working_status
        profile_image_key
        profile_image_url
        preferred_communication_method
        preferred_language
        created_at
        last_updated_at
        time_zone
    }
  }
`;

const GET_USER_URL = gql`
  query GetUserURL($url_id:String!, $company_id: Int!){
    getUserURL(url_id: $url_id, company_id: $company_id) {
      id
      user_id
      url_id
      company_id
      email
      phone
      notes
      full_name
      display_name
      first_name
      last_name
      primary_address
      active
      account_status
      working_status
      profile_image_key
      profile_image_url
      preferred_communication_method
      preferred_language
      created_at
      last_updated_at
      last_active
      time_zone

      role {
        id
        name
        description
        url_id
        active
        permission_categories
        permissions_count
        permission_categories
        created_at
        last_updated_at
      }
    }
  }
`;

const GET_USER_CONTACT = gql`
  query GetUser($id:ID!){
    getUser(id: $id) {
        id
        email
        phone
        notes
        full_name
        display_name
        first_name
        last_name
        profile_image_key
        profile_image_url
        created_at
        last_updated_at
        last_active
    }
  }
`;

const GET_USER_ADDRESS = gql`
  query GetUser($id:ID!){
    getUser(id: $id) {
        id
        primary_address
    }
  }
`;

const GET_USERS = gql`
  query GetUsers($company_id:Int!){
    getUsers(company_id: $company_id) {
        id
        user_id
        url_id
        company_id
        email
        phone
        notes
        full_name
        display_name
        first_name
        last_name
        primary_address
        active
        account_status
        working_status
        profile_image_key
        profile_image_url
        preferred_communication_method
        created_at
        last_updated_at
        last_active
        time_zone

        role {
          id
          name
          description
          url_id
          active
          permission_categories
          permissions_count
          permission_categories
          created_at
          last_updated_at
        }
    }
  }
`;



export { GET_USER, GET_USER_URL, GET_USERS, GET_USER_CONTACT, GET_USER_ADDRESS };