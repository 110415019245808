import React from 'react';
import _ from 'lodash';
import clsx from 'clsx';
import capitalize from 'capitalize';
import EditIcon from '@material-ui/icons/Edit';


const RoleDetailUsersListCell = (props) => {

    if (props.data === null || _.isEmpty(props.data)) {
        return null;
    }


    // onChangeUserRoleClicked
    // showChangeRoleArea

    return (
        <div className='RoleDetailUsersListCell_Housing'>
            <div className="RoleDetailUsersListCell">
                <button className={clsx('Border--None', 'BackgroundColor--None', 'Display--Flex', 'Flex--Start', 'Pointer--Cursor')} onClick={props.onClick}>
                    <div className={clsx('Margin--Vertical--Auto')}>
                        <img
                            src={props.data.profile_image_url || process.env.PUBLIC_URL + '/profile_image.png'}
                            className='RoleDetailUsersListCell_ProfileImage'
                            alt = {props.data.full_name ? props.data.full_name + ' Profile Image' : 'Profile Image'}
                        />
                    </div>
                    <div className='RoleDetailUsersListCell_Contents'>
                        <p className={clsx('TextAlignLeft', 'SubTitleSmall', 'BlueTint', 'BoldText')}>
                            {props.data.full_name !== null ? capitalize.words(props.data.full_name) : 'No name available.'}
                        </p>
                        <p className={clsx('TextAlignLeft', 'BodyText', 'DarkBlueTint', 'NormalText')} >
                            {props.data.email || 'No email available.'}
                        </p>
                    </div>
                </button>
                <button className={clsx('Padding--Vertical--0', 'Margin--Vertical--Auto', 'Border--None', 'BackgroundColor--None', 'Pointer--Cursor')} onClick={() => props.onChangeUserRoleClicked(props.data)}>
                    {props.showChangeRoleArea ?
                    <EditIcon style={{ color: '#394453', fontSize: '30', fontWeight: '600', marginTop: '5px', marginBottom: '5px' }} />
                    : null}
                </button>
            </div>
        </div>
    );
};

export default RoleDetailUsersListCell;