// Import Modules
import React, { Component, Fragment } from 'react';
import * as ROUTES from '../../Constants/routes';
import { connect } from 'react-redux'; 
import * as ForgotPasswordActionTypes from '../../Redux/ActionTypes/Auth/ForgotPasswordActionTypes';
import ForgotPasswordBox1 from '../../Components/Boxes/ForgotPasswordBox1';
import ForgotPasswordBox2 from '../../Components/Boxes/ForgotPasswordBox2';
import { withRouter, Redirect } from 'react-router-dom';
import { Auth } from 'aws-amplify';


class ForgotPasswordScreen extends Component {

    constructor(props) {
        super(props)
        this.handleSubmitEmail = this.handleSubmitEmail.bind(this);
        this.handleSubmitPassword = this.handleSubmitPassword.bind(this);
        this.emailKeyPressChecker = this.emailKeyPressChecker.bind(this);
    }

    handleEmailChange = (event) => {
        this.props.setEmail(event.target.value);
    }

    handleVerifcationCodeChange = (event) => {
        this.props.setVerificationCode(event.target.value);
    }

    handlePasswordChange = (event) => {
        this.props.setPassword(event.target.value);
    }

    componentWillUnmount() {
        this.props.clearForgotPasswordInfo();
    }

    emailKeyPressChecker = (event) => {
        if (event.key === 'Enter') {
            this.handleSubmitEmail();
            event.preventDefault();
        }
    }

    newPasswordKeyPressChecker = (event) => {
        if (event.key === 'Enter') {
            this.handleSubmitPassword();
            event.preventDefault();
        }
    }


    async handleSubmitEmail() {
        try {
            await Auth.forgotPassword(this.props.email.toLowerCase());
            this.props.submitEmail(true);
            this.props.setError(null);
        }catch (err) {
            this.props.setError(err);
        }
    }

    async handleSubmitPassword() {
        try {
            const forgotPasswordData = await Auth.forgotPasswordSubmit(
                this.props.email.toLowerCase(), 
                this.props.verificationCode, 
                this.props.password
            );
            console.log(forgotPasswordData);
            this.props.submitPassword(true);
            this.props.setError(null);
            this.props.history.push(ROUTES.LOG_IN);
        }catch (err) {
            this.props.setError(err);
        }
    }


    checkIfValid = () => {
        if (this.props.isVerificationCodeSubmitted === true) {
            return (this.props.password === "");
        }else if (this.props.isEmailSubmitted === true) {
            return (this.props.verificationCode === "");
        }else {
            return (this.props.email === "");
        }
    }

    handleForm = (isInvalid) => {
        if (this.props.isVerificationCodeSubmitted === true) {
            return (
                <ForgotPasswordBox1
                    emailValue={this.props.email}
                    onEmailChange={(event) => { this.handleEmailChange(event) }}
                    newPasswordValue={this.props.newPassword}
                    onNewPasswordChange={(event) => { this.handleNewPasswordChange(event) }}
                    isInvalid={isInvalid}
                    handleSubmit={this.handleSubmitNewPassword}
                    emailKeyPressChecker={(event) => this.emailKeyPressChecker(event)}
                />
            );
        }else if (this.props.isEmailSubmitted === true) {
            return (
                <ForgotPasswordBox2
                    verificationCodeValue={this.props.verificationCode}
                    onVerificationCodeChange={(event) => { this.handleVerifcationCodeChange(event) }}
                    passwordValue={this.props.password}
                    onPasswordChange={(event) => { this.handlePasswordChange(event) }}
                    isInvalid={isInvalid}
                    handleSubmit={this.handleSubmitPassword}
                    errorValue={this.props.error}
                    newPasswordKeyPressChecker={(event) => this.newPasswordKeyPressChecker(event)}
                />
            );
        }else {
            return (
                <ForgotPasswordBox1
                    emailValue={this.props.email}
                    onEmailChange={(event) => { this.handleEmailChange(event) }}
                    isInvalid={isInvalid}
                    handleSubmit={this.handleSubmitEmail}
                    errorValue={this.props.error}
                    emailKeyPressChecker={(event) => this.emailKeyPressChecker(event)}
                />
            );
        }
    }

    render() {

        let loggedInRedirect = null;

        if (this.props.isLoggedIn) {
            loggedInRedirect = (
                <Redirect to={ROUTES.HOME} />
            );
        }

        const isInvalid = this.checkIfValid();
        const formArea = this.handleForm(isInvalid);

        return (
            <Fragment>
                {loggedInRedirect}
                <div className="BaseScreen">
                    {formArea}
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        email: state.auth.forgotPassword.email,
        isEmailSubmitted: state.auth.forgotPassword.submitEmail,
        verificationCode: state.auth.forgotPassword.verificationCode,
        isVerificationCodeSubmitted: state.auth.forgotPassword.submitVerificationCode,
        password: state.auth.forgotPassword.password,
        isPasswordSubmitted: state.auth.forgotPassword.submitPassword,
        error: state.auth.forgotPassword.error,
        user: state.auth.userInfo.user,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setEmail: (email) => dispatch({ type: ForgotPasswordActionTypes.SET_EMAIL, payload: { email: email } }),
        submitEmail: (submitEmail) => dispatch({ type: ForgotPasswordActionTypes.SUBMIT_EMAIL, payload: { submitEmail: submitEmail } }),
        setPassword: (password) => dispatch({ type: ForgotPasswordActionTypes.SET_PASSWORD, payload: { password: password } }),
        submitPassword: (submitPassword) => dispatch({ type: ForgotPasswordActionTypes.SUBMIT_PASSWORD, payload: { submitPassword: submitPassword } }),
        setVerificationCode: (verificationCode) => dispatch({ type: ForgotPasswordActionTypes.SET_VERIFICATION_CODE, payload: { verificationCode: verificationCode } }),
        submitVerificationCode: (submitVerificationCode) => dispatch({ type: ForgotPasswordActionTypes.SUBMIT_VERIFICATION_CODE, payload: { submitVerificationCode: submitVerificationCode } }),
        setError: (error) => dispatch({ type: ForgotPasswordActionTypes.SET_ERROR, payload: { error: error } }),
        clearForgotPasswordInfo: () => dispatch({ type: ForgotPasswordActionTypes.CLEAR_FORGOT_PASSWORD_INFO})
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ForgotPasswordScreen));