// Import Modules
import React, { Fragment, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';


// Import Custom Components
import { GET_ROLES } from '../../graphql/RoleQueries';

const RolesDataContainer = (props) => {

    /**
     * Props:
     * setLoading(loading)
     * setError(error)
     * setData(data)
     * auth
    */

    // Handle Get Users GraphQL Query
    const { loading, error, data } = useQuery(GET_ROLES, {
        variables: { company_id: props.auth.company_id }
    });


    useEffect(() => {
        const { setLoading, setError, setData } = props;
        setLoading(loading);
        setError(error);

        if (data) {
            setData(data.getRoles);
        }
        // eslint-disable-next-line
    }, [loading, error, data])

    
    return (
        <Fragment>
            {props.children}
        </Fragment>
    );
};

export default RolesDataContainer;