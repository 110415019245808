import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import clsx from 'clsx';
import PulseLoader from 'react-spinners/PulseLoader';

const RolesTableView = (props) => {


    const createTableHeader = () => {
        return (
            <thead className='RolesTableView_TableHeader'>
                <tr className='RolesTableView_TableHeaderRow'>
                    <th className='RolesTableView_TableHeaderCell'><p className={clsx('DarkGreyTint')}>Role</p></th>
                    <th className='RolesTableView_TableHeaderCell'><p className={clsx('DarkGreyTint')}>Permission Access</p></th>
                    <th className='RolesTableView_TableHeaderCell'><p className={clsx('DarkGreyTint')}>Users</p></th>
                    <th className='RolesTableView_TableHeaderCell'><p className={clsx('DarkGreyTint')}>Active</p></th>
                    <th className='RolesTableView_TableHeaderCell'><p className={clsx('DarkGreyTint')}>Last Updated</p></th>
                    <th className='RolesTableView_TableHeaderCell'><p className={clsx('DarkGreyTint')}>Created</p></th>
                </tr>
            </thead>
        );
    };

    const handlePermissionsText = (role) => {
        let permissionAccessText = 'No permissions available.';


        if (role.permission_categories !== null && role.permission_categories !== undefined) {
            permissionAccessText = '';
            let permissionCategoriesLength = role.permission_categories.length;
            role.permission_categories.forEach((value, index) => {
                permissionAccessText += value;

                if (index !== permissionCategoriesLength - 1) {
                    permissionAccessText += ', ';
                }
            });
        }

        return permissionAccessText;
    };


    const createTableRows = () => {

        return (
            <tbody className='RolesTableView_TableBody'>
                {props.data.map((role) => {
                    return (
                        <tr key={role.id} className='RolesTableView_TableBodyRow' onClick={() => props.onRoleClicked(role)}>
                            <td className='RolesTableView_TableDataCell'>
                                <p className={clsx('BoldText', 'DarkGreyTint')} style={{marginBottom: '5px'}}>{role.name || ''}</p>
                                <p className={clsx('NormalText', 'DarkGreyTint')}>{role.description || ''}</p>
                            </td>
                            <td className='RolesTableView_TableDataCell'><p className='DarkGreyTint'>{handlePermissionsText(role)}</p></td>
                            <td className='RolesTableView_TableDataCell'><p className='DarkGreyTint'>{_.upperFirst(_.toLower(role.user_amount || '0'))}</p></td>
                            <td className='RolesTableView_TableDataCell'><p className='DarkGreyTint'>{_.upperFirst(_.toLower(role.active || 'False'))}</p></td>
                            <td className='RolesTableView_TableDataCell-Date'><p className='DarkGreyTint'>{moment(new Date(role.last_updated_at), "YYYYMMDD").format('M-DD-YYYY') || ''}</p></td>
                            <td className='RolesTableView_TableDataCell-Date'><p className='DarkGreyTint'>{moment(new Date(role.created_at), "YYYYMMDD").format('M-DD-YYYY') || ''}</p></td>
                        </tr>
                    );
                })}
            </tbody>
        );
    };

    const showLoadingAnimation = () => {
        return (
            <PulseLoader
                css={`margin-top: 20px;`}
                size={20}
                sizeUnit={"px"}
                margin="5px"
                color={'#0A3F79'}
                loading={true}
            />
        );
    };

    const handleErrorArea = () => {
        if (props.error) {
            let splitMessage = props.error.message.split(':');
            let errorMessage  = 'Error:' + splitMessage[splitMessage.length - 1];
            return (
                <p className={clsx('Margin--Vertical--20', 'Margin--Horizontal--Auto', 'TextAlignCenter', 'SubTitle', 'SemiBoldText', 'RedTint')}>
                    {errorMessage}
                </p>
            );
        } else {
            return null;
        }
    };


    return (
        <div className="RolesTableView">
            {handleErrorArea()}
            {props.loading ? showLoadingAnimation() : null}
            {(props.data && !_.isEmpty(props.data)) ? 
                <table className='RolesTableView_Table'>
                    {createTableHeader()}
                    {createTableRows()}
                </table>
                :
                <p className={clsx('Margin--Vertical--20', 'Margin--Horizontal--Auto', 'TextAlignCenter', 'DarkGreyTint', 'SubTitleSmall', 'SemiBoldText')}>
                    No Roles Available
                </p>
            }
        </div>
    );
};

export default RolesTableView;
