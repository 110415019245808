// Import Modules
import React from 'react';
import clsx from 'clsx';


const DataListContainer = (props) => {
    
    return (
        <div className={clsx(
                'Border--All--1', 
                'BorderColor--DarkBlueTint', 
                'BorderRadius--All--10', 
                'BackgroundColor--WhiteTint', 
                'BoxShadow--1', 
                'Margin--Horizontal--0')}
        >
            <div className='DataListContainerTopHousing'>
                {props.topContents || null}
            </div>
            <div className={clsx('Padding--Horizontal--20', 'Padding--Vertical--15')}>
                <div className='DataListContainerHeaderLeftContentsHousing'>
                    {props.leftContents || null}
                </div>
                <div className='DataListContainerHeaderRightContentsHousing'>
                    {props.rightContents || null}
                </div>
            </div>
            <div className='SettingsBoxLine'/>
            {props.children}
        </div>
    );
};

export default DataListContainer;