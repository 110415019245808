import React from 'react';
import clsx from 'clsx';


const SubmitButton = (props) => {

    let isDisabled = props.isInvalid;

    if (props.isLoading !== null) {
        if (props.isLoading) {
            isDisabled = true;
        }
    }
    
    return (
        <button
            className={clsx({
                'SubmitButton' : true,
                'NotCompleted' : isDisabled,
                'Completed' : !isDisabled
            })}
            onClick={props.handleSubmit}
            type='button'
            disabled={isDisabled}

        >
            <p className="LargeButtonText">
                Submit
            </p>
        </button>
    );
};

export default SubmitButton;