import React from 'react';
import Box from './Box';
import clsx from 'clsx';

const DashboardBox1 = (props) => {

    return (
        <Box 
            className={clsx(
                'Box', 
                'Margin--Top--10', 
                'Margin--Bottom--20', 
                'Margin--Horizontal--20', 
                'Padding--Horizontal--20',
                'Padding--Vertical--20',
                'TextAlignLeft'
            )}
        >
            <p className={clsx('Title2', 'DarkBlueTint', 'BoldText', 'TextAlignLeft')}>
                {props.title}
            </p>
            <p className={clsx('BodyText', 'DarkBlueTint', 'TextAlignLeft')} style={{marginTop: '15px'}}>
                Welcome back to ProHelper.
            </p>
        </Box>
    );
};

export default DashboardBox1;