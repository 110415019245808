import * as ROUTES from './routes';

export default {
    categories: [
        {
            categoryTitle: 'Company',
            categoryIdentifier: 'settings_company',
            data: [
                {
                    screenTitle: 'Company Information',
                    screenDescription: 'Update your company details and tax information.',
                    route: ROUTES.SETTINGS_COMPANY
                },
                {
                    screenTitle: 'Branding',
                    screenDescription: 'Customize how your branding appears in emails, invoices, and in the customer community area.',
                    route: ROUTES.SETTINGS_COMPANY_BRANDING
                }
            ],
        },
        {
            categoryTitle: 'ProHelper Account',
            categoryIdentifier: 'settings_prohelper',
            data: [
                {
                    screenTitle: 'Overview',
                    screenDescription: 'View your account status, balance, and ProHelper information.',
                    route: ROUTES.SETTINGS_PROHELPER_OVERVIEW
                },
                {
                    screenTitle: 'Subscription',
                    screenDescription: 'Manage your current ProHelper subscription plan.',
                    route: ROUTES.SETTINGS_PROHELPER_SUBSCRIPTION
                },
                {
                    screenTitle: 'Plans',
                    screenDescription: 'Compare different ProHelper plans to fit your business.',
                    route: ROUTES.SETTINGS_PROHELPER_PLANS
                },
                {
                    screenTitle: 'Payment Methods',
                    screenDescription: 'Add, update, or delete payment methods for your ProHelper account.',
                    route: ROUTES.SETTINGS_PROHELPER_PAYMENT
                }
            ],
        },
        {
            categoryTitle: 'Features',
            categoryIdentifier: 'settings_features',
            data: [
                {
                    screenTitle: 'Work Settings',
                    screenDescription: 'Manage your settings for assignments, jobs, requests, and routes.',
                    route: ROUTES.SETTINGS_FEATURES_WORK
                },
                {
                    screenTitle: 'Schedule Settings',
                    screenDescription: 'Manage your schedule preferences and settings.',
                    route: ROUTES.SETTINGS_FEATURES_SCHEDULE
                },
                {
                    screenTitle: 'Billing Settings',
                    screenDescription: 'Manage your estimate, invoice, and products and services settings.',
                    route: ROUTES.SETTINGS_FEATURES_BILLING
                },
                {
                    screenTitle: 'Customer Settings',
                    screenDescription: 'Manage customer settings, defaults, and preferences.',
                    route: ROUTES.SETTINGS_FEATURES_CUSTOMERS
                },
                {
                    screenTitle: 'Team Settings',
                    screenDescription: 'Manage team settings, defaults, and preferences.',
                    route: ROUTES.SETTINGS_FEATURES_TEAM
                }
            ],
        },
        {
            categoryTitle: 'Data Settings',
            categoryIdentifier: 'settings_data',
            data: [
                {
                    screenTitle: 'QuickBooks Sync',
                    screenDescription: 'Integrate with QuickBooks Online to sync customers, products, and transcations with ProHelper.',
                    route: ROUTES.SETTINGS_DATA_QBO
                },
                {
                    screenTitle: 'Data Import',
                    screenDescription: 'Upload custom CSV files to import your previous data into ProHelper.',
                    route: ROUTES.SETTINGS_DATA_IMPORT
                }
            ],
        },
        {
            categoryTitle: 'Owner',
            categoryIdentifier: 'settings_owner',
            data: [
                {
                    screenTitle: 'Owner Settings',
                    screenDescription: 'Customize your company owner contact, adjust important settings, or delete your company permanently.',
                    route: ROUTES.SETTINGS_COMPANY_OWNER
                }
            ],
        },
    ],
    userSecuritySettingsList: [
        {
            screenTitle: 'Support',
            screenDescription: 'Send a message to our support team about bugs, feature requests, or help.',
            route: ROUTES.SETTINGS_SUPPORT
        },
        {
            screenTitle: 'Terms of Service',
            screenDescription: 'View our terms of service to learn more about our platform guidelines.',
            route: ROUTES.TOS
        },
        {
            screenTitle: 'Privacy Policy',
            screenDescription: 'View our privacy policy to learn more about how we protect and use your data.',
            route: ROUTES.PP
        }
    ]
};