// Import Modules
import React, { Fragment } from 'react';
import FormTextField from "../MaterialUI/FormTextField";
import SubmitButton from '../Buttons/LogInButton';
import PulseLoader from 'react-spinners/PulseLoader';


const NewPasswordForm = (props) => {

    let errorComponent = null;

    if (props.errorValue) {
        errorComponent = (
            <p className="ErrorBody">
                {(props.errorValue !== null) ? props.errorValue.message : null}
            </p>
        );
    }
    
    const override = `
        margin-top: 30px;
    `;

    let loadingArea = null;

    if (props.isLoading) {
        loadingArea = (
            <PulseLoader
                css={override}
                size={25}
                sizeUnit={"px"}
                margin="5px"
                color={'#0A3F79'}
                loading={true}
            />
        );
    }

    return (
        <Fragment>
            {loadingArea}
            <form
                className="NewPasswordForm"
                noValidate 
                autoComplete='off'
            >
                <div className='inputBoxes'>
                    {errorComponent}
                    <FormTextField
                        label='Email'
                        value={props.emailValue}
                        type='text'
                        variant="outlined"
                        fullWidth
                        margin='normal'
                        required
                        disabled={true}
                    />
                    <FormTextField
                        label='New Password (6 Characters Min.)'
                        value={props.newPasswordValue}
                        onChange={(event) => props.onNewPasswordChange(event)}
                        onKeyPress={(event) => props.newPasswordKeyPressChecker(event)}
                        type='password'
                        variant="outlined"
                        fullWidth
                        margin='normal'
                        required
                        disabled={props.isLoading}
                    />
                </div>

                <SubmitButton
                    handleSubmit={props.handleSubmit}
                    isInvalid={props.isInvalid}
                    isLoading={props.isLoading}
                />
            </form>
        </Fragment>
    );
};


export default NewPasswordForm;
