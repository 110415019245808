// Import Modules
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import clsx from 'clsx';
import PulseLoader from 'react-spinners/PulseLoader';
import { useMutation } from '@apollo/react-hooks';
import capitalize from 'capitalize';


// Import Custom Components
import NewItemBox from '../../../../Components/Boxes/NewItemBox';
import SettingsSelect from '../../../../Components/MaterialUI/SettingsSelect';
import RolesDataContainer from '../../../../Components/DataContainers/RolesDataContainer';
import { UPDATE_USER_ROLE } from '../../../../graphql/UserMutations';
import { GET_ROLE_URL, GET_ROLES } from '../../../../graphql/RoleQueries';

// props.source


const ChangeUserRolePopUp = (props) => {

    // Clientside Permissions validation
    const auth = useSelector(state => state.auth.userInfo.auth);
    const [role, setRole] = useState({ label: 'No Role Set Yet.', value: 'not-set' });
    const [dataContainerLoading, setDataContainerLoading] = useState(false);
    const [dataContainerError, setDataContainerError] = useState(null);
    const [rolesList, setRolesList] = useState([]);

    const [updateUserRoleAction, { loading: mutationLoading, error: mutationError }] = useMutation(UPDATE_USER_ROLE,
        {
            update(cache, { data: { updateUserRole } }) {


                try {
                    const newGetRoleURL = cache.readQuery({ query: GET_ROLE_URL, variables: { company_id: updateUserRole.company_id, url_id: updateUserRole.role.url_id } }).getRoleURL;
                    if (newGetRoleURL) {
                        if (newGetRoleURL.users) {
                            newGetRoleURL.users.push(updateUserRole);
                        } else {
                            newGetRoleURL.users = [updateUserRole];
                        }
                    }
                    cache.writeQuery({
                        query: GET_ROLE_URL,
                        variables: { company_id: updateUserRole.company_id, url_id: updateUserRole.url_id },
                        data: { getRoleURL: newGetRoleURL }
                    });
                }catch(error) {
                    // console.log(error);
                }

                try {
                    const oldGetRoleURL = cache.readQuery({ query: GET_ROLE_URL, variables: { company_id: updateUserRole.company_id, url_id: props.data.role.url_id } }).getRoleURL;
                    if (oldGetRoleURL) {
                        if (oldGetRoleURL.users) {
                            oldGetRoleURL.users = oldGetRoleURL.users.filter((user) => {
                                return user.url_id !== updateUserRole.url_id;
                            });
                        }
                    }
                    cache.writeQuery({
                        query: GET_ROLE_URL,
                        variables: { company_id: updateUserRole.company_id, url_id: props.data.role.url_id },
                        data: { getRoleURL: oldGetRoleURL }
                    });
                }catch(error) {
                    // console.log(error);
                }

                try {
                    const { getRoles } = cache.readQuery({ query: GET_ROLES, variables: { company_id: updateUserRole.company_id } });
                    let newGetRoles = [...getRoles];
                    if (newGetRoles) {
                        for (let i in newGetRoles) {
                            if (newGetRoles[i].id === updateUserRole.role.id) {
                                let updatedRole = { ...newGetRoles[i] };
                                updatedRole.user_amount += 1;
                                newGetRoles[i] = updatedRole;
                            }
    
                            if (newGetRoles[i].id === props.data.role.id) {
                                let updatedRole2 = { ...newGetRoles[i] };
                                updatedRole2.user_amount = updatedRole2.user_amount - 1;
    
                                newGetRoles[i] = updatedRole2;
                            }
                        }
                    }
                    cache.writeQuery({
                        query: GET_ROLES,
                        variables: { company_id: updateUserRole.company_id },
                        data: { getRoles: newGetRoles }
                    });
                }catch(error) {
                    // console.log(error);
                }

                
            }
        });

    useEffect(()=> {
        if (props.data.role) { 
            setRole({ label: props.data.role.name, value: props.data.role.id });
        }
        
    }, [props.data]);


    if (auth === null || auth === undefined || _.isEmpty(auth) || _.isEmpty(auth.role_permissions)) {
        return null;
    }

    if (!(props.open || false)) {
        return null;
    }

    // Functions Area

    const onRoleChange = (newRole) => {
        setRole(newRole);
    };

    const checkIsValid = () => {
        let result = false;

        if (role.value !== 'not-set') {

            if (props.data.role) {
                if (role.value !== props.data.role.id) {
                    result = true;
                }
            }else {
                if (role.value !== '') {
                    result = true;
                }
            }
        }
        return result;
    }

    const onSubmit = () => {
        let UpdatedUserInput = {
            id: props.data.user_id,
            company_id: auth.company_id,
            role_id: undefined
        };

        if (role.value !== '') {
            UpdatedUserInput.role_id = role.value;
        }
        
        updateUserRoleAction({ variables: UpdatedUserInput })
        .then((result) => {
            props.onChangeUserRole();
        })
        .catch((error) => {

        });
    }
    

    const handleLoadingArea = () => {
        if (dataContainerLoading || mutationLoading) {
            return (
                <PulseLoader
                    css={`margin-top: 20px;`}
                    size={20}
                    sizeUnit={"px"}
                    margin="5px"
                    color={'#0A3F79'}
                    loading={true}
                />
            );
        } else {
            return null;
        }
    };

    const handleErrorArea = () => {
        if (mutationError) {
            let splitMessage = mutationError.message.split(':');
            let errorMessage  = 'Error:' + splitMessage[splitMessage.length - 1];
            return (
                <p className={clsx('Margin--Bottom--10', 'Margin--Horizontal--Auto', 'TextAlignCenter', 'SubTitleSmall', 'SemiBoldText', 'RedTint')}>
                    {errorMessage}
                </p>
            );
        }else if (dataContainerError) {
            let splitMessage = dataContainerError.message.split(':');
            let errorMessage  = 'Error:' + splitMessage[splitMessage.length - 1];
            return (
                <p className={clsx('Margin--Bottom--10', 'Margin--Horizontal--Auto', 'TextAlignCenter', 'SubTitleSmall', 'SemiBoldText', 'RedTint')}>
                    {errorMessage}
                </p>
            );
        }else {
            return null;
        }
    };


    const onDataContainerUpdated = (newRolesData) => {

        let changedRoleList = newRolesData.map((role) => {
            return {
                label: role.name,
                value: role.id
            }
        });

        changedRoleList.push({label: 'No Role', value: ''})

        setRolesList(changedRoleList);
    };

    const handleHelperText = () => {

        let helperText = `*Changing the role will add/remove permissions for the user.`;
        if (!_.isEmpty(props.data)) {
            helperText = `*Changing the role will add/remove permissions for ${capitalize.words(props.data.full_name)}.`;
            if (props.data.role) {
                if (role.value !== '' && role.value !== props.data.role.id) {
                    helperText = `*Changing the role will add/remove permissions for ${capitalize.words(props.data.full_name)}. This action will switch ${capitalize.words(props.data.full_name)}'s role from ${props.data.role.name} to ${role.label} and will grant/limit access to the ProHelper Platform.`;
                }else if (role.value === '') {
                    helperText = `*Changing the role will remove all permissions for ${capitalize.words(props.data.full_name)}. This action will switch ${capitalize.words(props.data.full_name)}'s role from ${props.data.role.name} to No Role and will restrict all access to the ProHelper Platform.`;
                }
            } else {
                if (role.value !== '') {
                    helperText = `*Changing the role will add permissions for ${capitalize.words(props.data.full_name)}. This action will switch ${capitalize.words(props.data.full_name)}'s role from No Role to ${role.label} and will grant access to the ProHelper Platform.`;
                }
            }
        }

        return helperText;
    };

    return (
        <div className='CustomBackDrop' tabIndex={-1} >
            <RolesDataContainer
                setLoading={(loading)=> setDataContainerLoading(loading)}
                setError={(error)=> setDataContainerError(error)}
                setData={(data) => onDataContainerUpdated(data)}
                auth={auth}
            >
                <NewItemBox
                    title="Change User's Role"
                    isActionDisabled={!checkIsValid()}
                    onActionClicked={() => onSubmit()}
                    actionText='Update Role'
                    cancelText='Cancel'
                    onCancelClicked={props.onClose}
                >
                    {handleLoadingArea()}
                    {handleErrorArea()}
                    <SettingsSelect
                        label='Role'
                        value={role}
                        onChange={selectedOption =>onRoleChange(selectedOption)} 
                        options={rolesList}
                        containerStyle={{marginTop: '0px'}}
                        isSearchable={false}
                        tabIndex={3}
                    />
                    <p className={clsx('BodyText', 'BlackTint', 'TextAlignLeft', 'Margin--Top--15')} style={{lineHeight: 'normal'}}>
                        {handleHelperText()}
                    </p>
                </NewItemBox>
            </RolesDataContainer>
        </div>
    );
};

export default ChangeUserRolePopUp;
