//
// Pre Auth Routes
//
export const SIGN_UP = '/signup';
export const LOG_IN = '/login';
export const FORGOT_PASSWORD = '/forgot_password';
export const NEW_PASSWORD = '/new_password';
export const SETUP_ACCOUNT = '/setup_account';




//
// Post Auth Routes
//

export const HOME = '/';

// Work Area
export const WORK_OVERVIEW = '/work_overview';
export const TASKS = '/tasks';
export const JOBS = '/jobs';
export const REQUESTS = '/requests';
export const ROUTES = '/routes';

// Schedule Area
export const CALENDER = '/calendar';
export const PLANNER = '/planner';
export const BOARD = '/board';

// Billing Area
export const ALL_SALES = '/sales';
export const INVOICES = '/invoices';
export const ESTIMATES = '/estimates';
export const PAYMENTS = '/payments';
export const ITEMS = '/items';


// Customer Area
export const ACCOUNTS = '/accounts';
export const ACCOUNT_DETAIL_PREFIX = '/accounts/';
export const NEW_ACCOUNT = '/accounts/new';
export const CONTACTS = '/contacts';
export const CONTACT_DETAIL_PREFIX = '/contacts/';
export const NEW_CONTACT = '/contacts/new';
export const PROPERTIES = '/properties';
export const PROPERTY_DETAIL_PREFIX = '/properties/';
export const NEW_PROPERTY = '/properties/new';

// Team Area
export const TEAM_USERS = '/users';
export const TEAM_USERS_NEW = '/users/new';
export const TEAM_USER_DETAIL_PREFIX = '/users/';


export const TEAM_ROLES = '/roles';
export const TEAM_ROLES_NEW = '/roles/new';
export const TEAM_ROLE_DETAIL_PREFIX = '/roles/';

export const TEAM_TIMESHEETS = '/timesheets';
export const TEAM_TIMESHEETS_NEW = '/timesheets/new';
export const TEAM_TIMESHEETS_DETAIL_PREFIX = '/timesheets/';

// Reports Area
export const REPORTS = '/reports';

// Settings Area (General)
export const SETTINGS = '/settings';

// Settings Area (Company)
export const SETTINGS_COMPANY = '/settings/company';
export const SETTINGS_COMPANY_BRANDING = '/settings/company/branding';
export const SETTINGS_COMPANY_OWNER = '/settings/company/owner_settings';

// Settings Area (ProHelper)
export const SETTINGS_PROHELPER_OVERVIEW = '/settings/prohelper/overview';
export const SETTINGS_PROHELPER_SUBSCRIPTION = '/settings/prohelper/subscription';
export const SETTINGS_PROHELPER_PLANS = '/settings/prohelper/plans';
export const SETTINGS_PROHELPER_PAYMENT = '/settings/prohelper/payment_methods';

// Settings Area (Features)
export const SETTINGS_FEATURES_WORK = '/settings/features/work';
export const SETTINGS_FEATURES_SCHEDULE = '/settings/features/schedule';
export const SETTINGS_FEATURES_BILLING = '/settings/features/billing';
export const SETTINGS_FEATURES_CUSTOMERS = '/settings/features/customers';
export const SETTINGS_FEATURES_TEAM = '/settings/features/team';

// Settings Area (Data)
export const SETTINGS_DATA_QBO = '/settings/data/quickbooks_online';
export const SETTINGS_DATA_IMPORT = '/settings/data/import';

// Other
export const TOS = '/terms_of_service';
export const PP = '/privacy_policy';

export const CHANGE_EMAIL = '/change_email';
export const CHANGE_PASSWORD = '/change_password';
export const SETTINGS_SUPPORT = '/product_support';
export const SETTINGS_USER = '/user_settings';
