// Import Modules
import React, { Component }  from 'react';
import FontAwesome from 'react-fontawesome';
import clsx from 'clsx';
import GoBackButton from '../../../Components/Buttons/GoBackButton';
import ErrorSignOutButton from '../../../Components/Buttons/ErrorSignOutButton';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'; 
import { Auth } from 'aws-amplify';
import * as ROUTES from '../../../Constants/routes';
import * as UserInfoActionTypes from '../../../Redux/ActionTypes/Auth/UserInfoActionTypes';

class ErrorScreen extends Component {

    constructor(props) {
        super(props);
        this.signOut = this.signOut.bind(this);
      }

    goBack = () => {
        this.props.history.goBack();
    }

    async signOut() {
        try {
            await Auth.signOut();
            this.props.updateLoginStatus(false);
            this.props.removeUser();
            this.props.removeAuth();
            this.props.history.push(ROUTES.LOG_IN);

        } catch (err) {
            console.log(err);
        }
    }


    render() {
        return (
            <div className="BaseScreen">
                <div className='TitleContainer'>
                    <FontAwesome
                        name='exclamation-triangle'
                        hidden={false}
                        size="5x"
                        style={{ marginTop: '25px', marginBottom: '25px', color: '#002D47' }}
                    />
                    <p className={clsx('BigTitle', 'DarkBlueTint', 'TextAlignCentered')}>
                        You are not authorized.
                    </p>
                    <p className={clsx('SubTitleSmall', 'DarkBlueTint', 'TextAlignCentered')} style={{ margin: 'auto', marginTop: '20px', marginBottom: '40px', maxWidth: '85%' }}>
                        Your account is not authorized to view this screen. If you think this is an error please contact your company admin.
                    </p>
                    <div style={{display: 'inline', justifyContent: 'center'}}>
                        <GoBackButton 
                            handleSubmit={this.goBack}
                        />
                        <ErrorSignOutButton 
                            handleSubmit={this.signOut}
                        />
                    </div>
                </div>
            </div>
        );
    };
};



const mapStateToProps = state => {
    return {
        user: state.auth.userInfo.user
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateLoginStatus: (isLoggedIn) => dispatch({ type: UserInfoActionTypes.UPDATE_LOGIN_STATUS, payload: { isLoggedIn: isLoggedIn } }),
        removeUser: () => dispatch({ type: UserInfoActionTypes.REMOVE_USER }),
        removeAuth: () => dispatch({ type: UserInfoActionTypes.REMOVE_AUTH })
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ErrorScreen));
