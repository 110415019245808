import gql from 'graphql-tag';

const SETUP_ACCOUNT = gql`
  mutation SetupAccount($id:ID!, $fullName: String!, $firstName: String!, $lastName: String!, $phoneNumber: String!, $companyName: String!, $industryID: Int, $trafficSourceID: Int, $companySizeID: Int){
    setupAccount(id: $id, fullName: $fullName, firstName: $firstName, lastName: $lastName, phoneNumber: $phoneNumber, companyName: $companyName, industryID: $industryID, trafficSourceID: $trafficSourceID, companySizeID: $companySizeID) {
        id
        user_id
        user_full_name
        user_account_status
        user_active
        company_id
        company_status
        company_active
        company_name
        role_id
        role_name
        role_permissions
        role_permissions_screen_access
        role_permissions_action_access
    }
  }
`;

const CREATE_ACCOUNT = gql`
  mutation CreateAccount($id:ID!, $email: String!, $timeZone: String){
    createAccount(id: $id, email: $email, timeZone: $timeZone ) {
        id
        user_id
        user_full_name
        user_account_status
        user_active
        company_id
        company_status
        company_active
        company_name
        role_id
        role_name
        role_permissions
        role_permissions_screen_access
        role_permissions_action_access
    }
  }
`;

const ACTIVATE_ACCOUNT = gql`
  mutation ActivateAccount($id:ID!, $time_zone: String){
    activateAccount(id: $id, time_zone: $time_zone ) {
        id
        user_id
        user_full_name
        user_account_status
        user_active
        company_id
        company_status
        company_active
        company_name
        role_id
        role_name
        role_permissions
        role_permissions_screen_access
        role_permissions_action_access
    }
  }
`;

const DELETE_ACCOUNT = gql`
  mutation DeleteAccount($id:ID!, $company_id: Int!){
    deleteAccount(id: $id, company_id: $company_id) {
        id
        user_id
        user_full_name
        user_account_status
        user_active
        company_id
        company_status
        company_active
        company_name
        role_id
        role_name
        role_permissions
        role_permissions_screen_access
        role_permissions_action_access
    }
  }
`;



export { ACTIVATE_ACCOUNT, CREATE_ACCOUNT, SETUP_ACCOUNT, DELETE_ACCOUNT };