import React from 'react';
import MuiAlert from '@material-ui/lab/Alert';
import { styled } from '@material-ui/core/styles';

const StyledSuccessAlert = styled(MuiAlert)({
    backgroundColor: '#2D9639',
    fontWeight: '600'
});

export default function SuccessAlert(props) {
    return <StyledSuccessAlert elevation={6} variant="filled" {...props} />;
}