// Import Modules
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import ParseFullName from 'parse-full-name';
import validator from 'email-validator';
import clsx from 'clsx';
import PulseLoader from 'react-spinners/PulseLoader';
import { useMutation } from '@apollo/react-hooks';

// Import Custom Components
import ErrorScreen from '../../Other/ErrorScreen';
import NewItemBox from '../../../../Components/Boxes/NewItemBox';
import SettingsTextField from '../../../../Components/MaterialUI/SettingsTextField';
import SettingsSelect from '../../../../Components/MaterialUI/SettingsSelect';
import RolesDataContainer from '../../../../Components/DataContainers/RolesDataContainer';
import { NEW_USER } from '../../../../graphql/UserMutations';
import { GET_USERS } from '../../../../graphql/UserQueries';



const NewUserPopUp = (props) => {

    // Clientside Permissions validation
    const auth = useSelector(state => state.auth.userInfo.auth);
    const [fullName, setFullName] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [role, setRole] = useState({ label: 'No Role Set Yet.', value: '' });

    const [dataContainerLoading, setDataContainerLoading] = useState(false);
    const [dataContainerError, setDataContainerError] = useState(null);
    const [rolesList, setRolesList] = useState([]);

    const [createNewUserAction, { loading: mutationLoading, error: mutationError }] = useMutation(NEW_USER,
        {
            update(cache, { data: { newUser } }) {
                const { getUsers } = cache.readQuery({ query: GET_USERS, variables: {company_id: newUser.company_id} });
                cache.writeQuery({
                    query: GET_USERS,
                    variables: {company_id: newUser.company_id},
                    data: { getUsers: getUsers.concat([newUser]) },
                });
            }
        });

    const [newUserErrors] = useState({
        fullName: {error: false, message: ''},
        email: {error: false, message: ''},
        role: {error: false, message: ''}
    });

    if (auth === null || auth === undefined || _.isEmpty(auth) || _.isEmpty(auth.role_permissions)) {
        return null;
    }

    if (!(props.open || false)) {
        return null;
    }

    if (!auth.role_permissions.includes('team_user_create')) {
        return <ErrorScreen />;
    }


    // Functions Area

    const onFullNameChange = (newName) => {
        setFullName(newName);
    };

    const onEmailAddressChange = (newEmailAddress) => {
        setEmailAddress(newEmailAddress);
    };

    const onRoleChange = (newRole) => {
        setRole(newRole);
    };

    const checkIsValid = () => {
        let new_obj = Object.assign({}, newUserErrors);
        let result = true;


        // Empty Full Name
        if (fullName === '') {
            new_obj.fullName = {error: true, message: 'Please enter the full name of the new user.'};
            result = false;
        }
        else {
            let parsedFullName = ParseFullName.parseFullName(fullName);

            // Non Valid First Name
            if (parsedFullName.first === '' || null) {
                new_obj.fullName = {error: true, message: 'Please include your a first name in your full name.'};
                result = false;
            }
            // Non Valid Last Name
            if (parsedFullName.last === '' || null) {
                new_obj.fullName = {error: true, message: 'Please include your a last name in your full name.'};
                result = false;
            }

            // No Errors
            if (parsedFullName.first !== '' && parsedFullName.last !== '') {
                new_obj.fullName = {error: false, message: ''};
            }
        }

        // Empty Email Address
        if (emailAddress === '') {
            new_obj.email = {error: true, message: 'Please enter a valid email address.'};
            result = false;
        }
        else {
            // Non Valid Email
            if (!validator.validate(emailAddress)) {
                new_obj.email = {error: true, message: 'Please enter a valid email address.'};
                result = false;
            }
            // No Errors
            else {
                new_obj.email = {error: false, message: ''};
            }

        }


        // Empty Role
        if (role.value === '') {
            new_obj.role = {error: false, message: 'Please enter a valid role.'};
            result = false;
        }
        else {
            new_obj.role = {error: false, message: ''};
        }
        
        

        return result;
    }

    const onSubmit = () => {
        let new_obj = Object.assign({}, newUserErrors);
        let result = false;
        

        if (fullName !== '') {
            let parsedFullName = ParseFullName.parseFullName(fullName);



            if (parsedFullName.first === '' || null) {
                new_obj.fullName = {error: true, message: 'Please include your a first name in your full name.'};
            }
            if (parsedFullName.last === '' || null) {
                new_obj.fullName = {error: true, message: 'Please include your a last name in your full name.'};
            }

            if (parsedFullName.first !== '' && parsedFullName.last !== '') {
                new_obj.fullName = {error: false, message: ''};
                
                // Use Full Name (Validated)


                if (emailAddress !== '') {

                    if (role.value !== '') {
                        let newUserInput = {
                            company_id: auth.company_id,
                            full_name: fullName,
                            email: emailAddress,
                            role_id: role.value,
                            created_by_id: auth.user_id.toString()
                        };

                        console.log('auth.user_id', auth.user_id)
                        console.log('auth.user_id.toString()',auth.user_id.toString() )
        
                        new_obj.role = {error: false, message: ''};

                        createNewUserAction({variables: {newUserInput: newUserInput}})
                        .then((result) => {
                            props.onNewUserCreated();
                        })
                        .catch((error) => {

                        });

                    }else {
                        new_obj.role = {error: true, message: 'Please enter a valid role.'};
                    }
                }
                
            }
        }

        

        return result;
    }
    

    const handleLoadingArea = () => {
        if (dataContainerLoading || mutationLoading) {
            return (
                <PulseLoader
                    css={`margin-top: 20px;`}
                    size={20}
                    sizeUnit={"px"}
                    margin="5px"
                    color={'#0A3F79'}
                    loading={true}
                />
            );
        } else {
            return null;
        }
    };

    const handleErrorArea = () => {
        if (dataContainerError) {
            let splitMessage = dataContainerError.message.split(':');
            let errorMessage  = 'Error:' + splitMessage[splitMessage.length - 1];
            return (
                <p className={clsx('Margin--Bottom--10', 'Margin--Horizontal--Auto', 'TextAlignCenter', 'SubTitleSmall', 'SemiBoldText', 'RedTint')}>
                    {errorMessage}
                </p>
            );
        } else if (mutationError) {
            let splitMessage = mutationError.message.split(':');
            let errorMessage  = 'Error:' + splitMessage[splitMessage.length - 1];
            return (
                <p className={clsx('Margin--Bottom--10', 'Margin--Horizontal--Auto', 'TextAlignCenter', 'SubTitleSmall', 'SemiBoldText', 'RedTint')}>
                    {errorMessage}
                </p>
            );
        }else {
            return null;
        }
    };


    const onDataContainerUpdated = (newRolesData) => {

        let changedRoleList = newRolesData.map((role) => {
            return {
                label: role.name,
                value: role.id
            }
        });

        setRolesList(changedRoleList);
    };
    
    return (
        <div className='CustomBackDrop' tabIndex={-1} >
            <RolesDataContainer
                setLoading={(loading)=> setDataContainerLoading(loading)}
                setError={(error)=> setDataContainerError(error)}
                setData={(data) => onDataContainerUpdated(data)}
                auth={auth}
            >
                <NewItemBox
                    title='Invite New User'
                    description='Enter the full name and email of the person you would like to invite, and choose the role they should have.'
                    isActionDisabled={!checkIsValid()}
                    onActionClicked={() => onSubmit()}
                    actionText='Invite User'
                    cancelText='Cancel'
                    onCancelClicked={props.onClose}
                >
                    {handleLoadingArea()}
                    {handleErrorArea()}
                    <SettingsTextField
                        label='Full Name'
                        value={fullName}
                        onChange={event => onFullNameChange(event.target.value)}
                        type='text'
                        containerStyle={{ marginTop: '5px'}}
                        placeholder='Enter Full Name'
                        tabIndex={1}
                    />
                    <SettingsTextField
                        label='Email Address'
                        value={emailAddress}
                        onChange={event => onEmailAddressChange(event.target.value)}
                        type='text'
                        containerStyle={{ marginTop: '15px'}}
                        placeholder='Enter Work Email Address'
                        tabIndex={2}
                    />
                    <SettingsSelect
                        label='Role'
                        value={role}
                        onChange={selectedOption =>onRoleChange(selectedOption)} 
                        options={rolesList}
                        containerStyle={{marginTop: '15px'}}
                        isSearchable={false}
                        tabIndex={3}
                    />
                    <p className={clsx('BodyText', 'DarkGreyTint', 'TextAlignLeft')} style={{marginTop: '10px', lineHeight: 'normal'}}>
                        *This action will send an invitation email to the entered email address. Your company's subscription price will also increase by 1 seat upon the new user's activation.
                    </p>
                </NewItemBox>
            </RolesDataContainer>
        </div>
    );
};

export default NewUserPopUp;
