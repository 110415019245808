import React from 'react';
import clsx from 'clsx';


const ScreenHeaderActionButton = (props) => {

    let textArea = null;

    if (props.layoutWidth >= 600) {
        textArea = (
            <p 
                className={clsx(
                    'BodyText', 
                    'LineHeight--Inherit', 
                    'WhiteTint', 
                    'SemiBoldText', 
                    'TextAlignLeft', 
                    'Margin--Vertical--Auto', 
                    'Margin--Right--5'
                )}
            >
                {props.actionText || ''}
            </p>
        );
    }

    return (
        <button
            className={clsx({
                'ScreenHeaderActionButton' : true
            })}
            onClick={props.onActionClicked}
            type='button'
            disabled={props.isInvalid}

        >
            {textArea}
            {props.actionIcon || null}
        </button>
    );
};

export default ScreenHeaderActionButton;