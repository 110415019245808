// Import Required Modules
import React from 'react';
import ReactDOM from 'react-dom';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import Amplify, { Auth } from 'aws-amplify';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { ApolloClient } from 'apollo-client';
import { ApolloProvider } from '@apollo/react-hooks';
import { InMemoryCache } from 'apollo-cache-inmemory';
import App from './app';
import * as serviceWorker from './serviceWorker';
import reducer from './Redux/Reducers/reducer';
import "./Stylesheets/_main.scss";
import './index.css';

// Setup Amplify for Cognito and S3
Amplify.configure({
    Auth: {
        identityPoolId: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
        region: process.env.REACT_APP_AWS_REGION,
        identityPoolRegion: process.env.REACT_APP_AWS_REGION,
        userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_WEB_CLIENT_ID
    }
});


// Setup ProHelper Redux Store for cross screen data storage
const store = createStore(reducer);


// Create Apollo HTTP Link and SetContext with Cognito Credentials
const httpLink = createHttpLink({
    uri: process.env.REACT_APP_AWS_GRAPHQL_ENDPOINT
});

const authLink = setContext(async (_, { headers }) => {

    const token = await Auth.currentSession();

    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token.getIdToken().getJwtToken()}` : null,
        }
    }
});

// Create Apollo Client for the Apollo Provider
const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
});

// Setup Redux Provider and Apollo Provider
ReactDOM.render(
    <Provider store={store}>
        <ApolloProvider client={client}>
            <App />
        </ApolloProvider>
    </Provider>, 
    document.getElementById('root'));

serviceWorker.unregister();
