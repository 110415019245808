import React from 'react';
import clsx from 'clsx';

const ConfirmDeleteButtons = (props) => {

    return (
        <div className='ConfirmDeleteAccountButtonContainer'>
            <div className='ConfirmDeleteAccountButtonDiv'>
                <button
                    className='ConfirmDeleteAccountButtonBack'
                    onClick={props.onBackClicked}
                    type='button'
                >
                    <p 
                        className={clsx({ 
                            'SubTitle': true, 
                            'SemiBoldText': true, 
                            'TextAlignCenter': true,
                            'WhiteTint': true
                        })} 
                        style={{ paddingLeft: '12px', margin: '0px' }}
                    >
                        Go Back
                    </p>
                </button>
            </div>
            <div className='ConfirmDeleteAccountButtonDiv'>
                <button
                    className='ConfirmDeleteAccountButtonRed'
                    onClick={props.onConfirmDeleteClicked}
                    type='button'
                >
                    <p 
                        className={clsx({ 
                            'SubTitle': true, 
                            'SemiBoldText': true, 
                            'TextAlignCenter': true,
                            'WhiteTint': true
                        })} 
                        style={{ paddingLeft: '12px', margin: '0px' }}
                    >
                        Delete Account
                    </p>
                </button>
            </div>
        </div>
    );
};

export default ConfirmDeleteButtons;