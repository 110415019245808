// Import Modules
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';


// Import Custom Classes
import ScreenDetailHeader from '../../../../Components/UI/ScreenDetailHeader';
import * as ROUTES from '../../../../Constants/routes';
import ErrorScreen from '../../Other/ErrorScreen';


const ProHelperPlansScreen = (props) => {

    const auth = useSelector(state => state.auth.userInfo.auth);
    const layoutWidth = useSelector(state => state.ui.layout.layoutWidth);
    const history = useHistory();

    // Client-Side Authorization Checks
    if (auth === null || auth === undefined || _.isEmpty(auth)) {
        return null;
    }


    if (!auth.role_permissions.includes('settings_prohelper')) {
        return <ErrorScreen />;
    }


    const onBackClicked = () => {
        history.push(ROUTES.SETTINGS);
    };

    // screenDescription='Compare different ProHelper plans to fit your business.'

    return (
        <div className="BaseScreen" >
            <ScreenDetailHeader
                    screenTitle='ProHelper Plans'
                    backText='Back'
                    isActionHidden={true}
                    isActionDisabled={true}
                    isMoreHidden={true}
                    isMoreDisabled={true}
                    layoutWidth={layoutWidth}
                    onBackClicked={onBackClicked}
                />
        </div>
    );
}


export default ProHelperPlansScreen;