// Import Modules
import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import DashboardTopBox from '../../Components/Boxes/DashboardBox1';
import ScreenHeader from '../../Components/UI/ScreenHeader';
// import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import _ from 'lodash';

const HomeScreen = (props) => {

    // Get / Initialize Variables
    const auth = useSelector(state => state.auth.userInfo.auth);
    const layoutWidth = useSelector(state => state.ui.layout.layoutWidth);
    const user = useSelector(state => state.auth.userInfo.user);
    const [isFirstLoaded, setIsFirstLoaded] = useState(false);
    const [homeActionStatus, setHomeActionStatus] = useState(false);
    // const history = useHistory();
    // const dispatch = useDispatch();

    // Setup Roles Screen
    useEffect(() => {
        if (!isFirstLoaded) {
            if (auth !== null && auth !== undefined && !_.isEmpty(auth)) {
                if (!auth.role_permissions.includes('basic_access')) {
                    setHomeActionStatus(true);
                }
                setIsFirstLoaded(true);
            }
        }

    }, [isFirstLoaded, auth]);


    // Not sure if this will work properly (saved for later)
    // ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
    // ReactGA.pageview(this.props.location.pathname);

    const createWelcomeMessage = () => {
        let welcomeMessage = "Welcome back.";


        if (user !== null) {
            if (user.attributes !== undefined) {
                if (user.attributes.name !== undefined) {
                    welcomeMessage = `Hi ${user.attributes.name.split(" ")[0]},`;
                }
            }
        }

        return welcomeMessage;
    }


    const onNewItemClicked = () => {

    }


    return (
        <div className="BaseScreen">
            <ScreenHeader
                title='Home'
                actionText='New Item'
                onActionClicked={onNewItemClicked}
                isActionDisabled={homeActionStatus}
                isMoreDisabled={true}
                layoutWidth={layoutWidth}
            />
            <DashboardTopBox
                title={createWelcomeMessage()}
            />
        </div>
    );
};

// class DashboardScreen extends Component {

//     constructor(props) {
//         super(props);
//         ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
//         ReactGA.pageview(this.props.location.pathname);
//     }
    

//     createWelcomeMessage = () => {
//         let welcomeMessage = "Welcome back.";


//         if (this.props.user !== null) {
//             if (this.props.user.attributes !== undefined) {
//                 if (this.props.user.attributes.name !== undefined) {
//                     welcomeMessage = `Hi ${this.props.user.attributes.name.split(" ")[0]},`;
//                 }
//             }
//         }

//         return welcomeMessage;
//     }


//     onNewItemClicked = () => {

//     }

//     render() {

//         const welcomeMessage = this.createWelcomeMessage();

//         return (
//             <div className="BaseScreen">
//                 <ScreenHeader 
//                     title='Home'
//                     actionText='New Item'
//                     onActionClicked={this.onNewItemClicked}
//                     isActionDisabled={false}
//                     isMoreDisabled={true}
//                     layoutWidth={layoutWidth}
//                 />
//                 <DashboardTopBox
//                     title={welcomeMessage}
//                 />
//             </div>
//         );
//     }
// }

// const mapStateToProps = state => {
//     return {
//         user: state.auth.userInfo.user
//     };
// };

// const mapDispatchToProps = dispatch => {
//     return {
//         setUser: (user) => dispatch({ type: UserInfoActionTypes.UPDATE_USER, payload: { user: user } })
//     };
// };

export default withRouter(HomeScreen);