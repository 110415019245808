// Import Modules
import React, { Fragment } from 'react';
import FormTextField from "../MaterialUI/FormTextField";
import LogInButton from '../Buttons/LogInButton';
import ForgotPasswordButton from '../Buttons/ForgotPasswordButton';


const LogInForm = (props) => {

    let errorComponent = null;

    if (props.errorValue !== null) {
        errorComponent = (
            <p className="ErrorBody" style={{paddingTop: '15px'}}>
                {(props.errorValue !== null) ? props.errorValue.message : null}
            </p>
        );
    }

    return (
        <Fragment>

            <form
                className="LogInForm"
                noValidate
                autoComplete='off'
            >
                <div className='inputBoxes'>
                    {errorComponent}
                    <FormTextField
                        label='Email'
                        value={props.emailValue}
                        onChange={event => props.onEmailChange(event)}
                        type='email'
                        variant="outlined"
                        fullWidth
                        margin='normal'
                        required
                        autoComplete="true"
                        disabled={props.isLoading}
                    />
                    <FormTextField
                        label='Password'
                        value={props.passwordValue}
                        onChange={(event, newValue) => props.onPasswordChange(event, newValue)}
                        onSubmit={props.handleSubmit}
                        onKeyPress={(event) => props.passwordKeyPressChecker(event)}
                        type='password'
                        variant="outlined"
                        autoComplete="true"
                        fullWidth
                        margin='normal'
                        required
                        disabled={props.isLoading}
                    />
                </div>
                <div className="ButtonsArea">
                    <ForgotPasswordButton
                        handleSubmit={props.handleForgotPassword}
                    />

                    <LogInButton
                        handleSubmit={props.handleSubmit}
                        isInvalid={props.isInvalid}
                        isLoading={props.isLoading}
                    />
                </div>
            </form>
        </Fragment>
    );
};

export default LogInForm;
