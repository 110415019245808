// Import Modules
import React from 'react';
import clsx from 'clsx';

// Import Custom Classes
import Box from '../../Components/Boxes/Box';
import SignUpForm2 from "../../Components/Forms/SignUpForm2";


const SignUpScreenContents2 = (props) => {

    return (
        <div className="BaseScreen">
            <div className="Spacer--30" />
            <div className='TitleContainer'>
                <p className={clsx('BigTitle', 'DarkBlueTint', 'TextAlignCentered')}>
                    Confirm Sign Up
                </p>
                <p className={clsx('SubTitle', 'DarkBlueTint', 'TextAlignCentered')}>
                    Please enter the verification code sent to your email address.
                </p>
            </div>
            <Box className='SignUpBox'>
                <SignUpForm2
                    emailValue={props.emailValue}
                    verificationCode={props.verificationCode}
                    onVerificationCodeChange={(event) => { props.onVerificationCodeChange(event) }}
                    onVerificationCodeKeyPressChecker={(event) => { props.onVerificationCodeKeyPressChecker(event) }}
                    isLoading={props.isLoading}
                    isInvalid={props.isInvalid}
                    handleSubmit={props.handleSubmit}
                    handleResendCode={props.handleResendCode}
                    errorValue={props.errorValue}
                />
            </Box>
        </div >
    );
};
export default SignUpScreenContents2;