// Import Modules
import React, { Fragment } from 'react';
import FormTextField from "../MaterialUI/FormTextField";
import SubmitButton from '../Buttons/SubmitButton';


const ForgotPasswordForm1 = (props) => {

    let errorComponent = null;

    if (props.errorValue !== null) {
        errorComponent = (
            <p className="ErrorBody">
                {(props.errorValue !== null) ? props.errorValue.message : null}
            </p>
        );
    }

    return (
        <Fragment>

            <form
                className="ForgotPasswordForm"
                noValidate
                autoComplete='off'
            >
                {errorComponent}
                <div className='inputBoxes'>
                    <FormTextField
                        label='Email'
                        value={props.emailValue}
                        onChange={event => props.onEmailChange(event)}
                        type='text'
                        variant="outlined"
                        fullWidth
                        margin='normal'
                        required
                        autoComplete="true"
                        disabled={props.isLoading}
                        onKeyPress={(event) => props.emailKeyPressChecker(event)}
                    />
                </div>
                <SubmitButton
                    handleSubmit={props.handleSubmit}
                    isInvalid={props.isInvalid}
                    isLoading={props.isLoading}
                />
            </form>
        </Fragment>
    );
};

export default ForgotPasswordForm1;