import { TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const FormTextField = withStyles({
    root: {
        margin: 'auto',
        marginTop: '30px',
        padding: 'auto',
        alignSelf: 'center',
        borderRadius: '15px',
        fontSize: '16px',

        '& label.Mui-focused': {
            color: '#07689F',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#07689F',
        },
        '& .MuiOutlinedInput-root': {
            backgroundColor: "#fff",
            
            '& fieldset': {
                borderColor: '#000000',
            },
            '&:hover fieldset': {
                borderColor: '#07689F',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#07689F',
                borderWidth: '2px'
            },
        },
    }
})(TextField);

export default FormTextField;