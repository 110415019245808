import React from 'react';

const SignUpButton = (props) => {

    return (
        <button
            className="SignUpButton"
            onClick={props.handleSubmit}
            type='button'
        >
            <p className='LargeButtonText'>
                Sign Up
            </p>
        </button>
    );
};

export default SignUpButton;