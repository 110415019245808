// Log In Reducer
import * as actionTypes from '../../ActionTypes/Auth/LogInActionTypes';

const initialState =
{
  email: "",
  password: "",
  newPassword: "",
  error: null
};

const logInReducer = (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.SET_EMAIL:
        return {
          ...state,
          email: action.payload.email
      }
      case actionTypes.SET_PASSWORD:
        return {
          ...state,
          password: action.payload.password
      }
      case actionTypes.SET_NEW_PASSWORD:
        return {
          ...state,
          newPassword: action.payload.newPassword
      }
      case actionTypes.SET_ERROR:
        return {
          ...state,
          error: action.payload.error
      }
      case actionTypes.CLEAR_LOGIN_INFO:
        return {
          email: "",
          password: "",
          newPassword: "",
          error: null
        }
      default:
        return state
    }
  };
  
  export default logInReducer;