import gql from 'graphql-tag';

const NEW_ROLE = gql`
  mutation NewRole($newRoleInput: NewRoleInput!){
    newRole(newRoleInput: $newRoleInput) {
        id
        company_id
        url_id
        active
        name
        description
        created_at
        last_updated_at
        user_amount
        permissions_count
        permission_categories

        permissions {
            id
            name
            description
            active
            identifier
            screen_access
            action_access
            created_at
            last_updated_at
            category
            sub_category
            action_name
            action_order
            group_name
            group_identifier
        }

        users {
            id
            user_id
            url_id
            company_id
            email
            phone
            notes
            full_name
            display_name
            first_name
            last_name
            primary_address
            active
            account_status
            working_status
            profile_image_key
            profile_image_url
            preferred_communication_method
            created_at
            last_updated_at
            time_zone
        }
    }
  }
`;

const UPDATE_ROLE = gql`
  mutation UpdateRole($updateRoleInput: UpdateRoleInput!){
    updateRole(updateRoleInput: $updateRoleInput) {
        id
        company_id
        url_id
        active
        name
        description
        created_at
        last_updated_at
        user_amount
        permissions_count
        permission_categories

        permissions {
            id
            name
            description
            active
            identifier
            screen_access
            action_access
            created_at
            last_updated_at
            category
            sub_category
            action_name
            action_order
            group_name
            group_identifier
        }

        users {
            id
            user_id
            url_id
            company_id
            email
            phone
            notes
            full_name
            display_name
            first_name
            last_name
            primary_address
            active
            account_status
            working_status
            profile_image_key
            profile_image_url
            preferred_communication_method
            created_at
            last_updated_at
            time_zone
        }
    }
  }
`;


export { NEW_ROLE, UPDATE_ROLE };