import React from 'react';
import _ from 'lodash';
import clsx from 'clsx';

import PermissionsCategoryCell from '../Cells/PermissionCategoryCell';


const PermissionCategoriesContainer = (props) => {

    if (props.data === null || _.isEmpty(props.data)) {
        return null;
    }

    return (
        <div className={clsx('Padding--Vertical--0')} style={{paddingBottom: '15px'}}>
            {props.data.map((permissionCategory) => {
                return (
                    <PermissionsCategoryCell 
                        key={permissionCategory.category}
                        data={permissionCategory}
                        onCategoryClick={(permissionCategory) => props.onCategoryClick(permissionCategory)}
                        onPermissionButtonClicked={(permission) => props.onPermissionButtonClicked(permission)}
                        isEditable={props.isEditable}
                    />
                );
            })}
        </div>
    );
};

export default PermissionCategoriesContainer;
