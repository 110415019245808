import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import clsx from 'clsx';
import capitalize from 'capitalize';
import PulseLoader from 'react-spinners/PulseLoader';


const UsersTableView = (props) => {


    const createTableHeader = () => {
        return (
            <thead className='UsersTableView_TableHeader'>
                <tr className='UsersTableView_TableHeaderRow'>
                    <th className='UsersTableView_TableHeaderImageCell'><p className={clsx('DarkGreyTint')}>Image</p></th>
                    <th className='UsersTableView_TableHeaderCell'><p className={clsx('DarkGreyTint')}>User</p></th>
                    <th className='UsersTableView_TableHeaderCell'><p className={clsx('DarkGreyTint')}>Role Name</p></th>
                    <th className='UsersTableView_TableHeaderCell'><p className={clsx('DarkGreyTint')}>Account Status</p></th>
                    <th className='UsersTableView_TableHeaderCell'><p className={clsx('DarkGreyTint')}>Working Status</p></th>
                    <th className='UsersTableView_TableHeaderCell'><p className={clsx('DarkGreyTint')}>Last Active</p></th>
                    <th className='UsersTableView_TableHeaderCell'><p className={clsx('DarkGreyTint')}>Created At</p></th>
                </tr>
            </thead>
        );
    };


    const createTableRows = () => {

        return (
            <tbody className='UsersTableView_TableBody'>
                {props.data.map((user) => {

                    return (
                        <tr key={user.id} className='UsersTableView_TableBodyRow' onClick={()=>{props.onUserClicked(user)}}>
                            <td className='UsersTableView_TableDataImageCell'>
                                <img
                                    src={user.profile_image_url || process.env.PUBLIC_URL + '/profile_image.png'}
                                    className='UsersTableView_TableDataImage'
                                    alt = {props.data.full_name ? props.data.full_name + ' Profile Image' : 'Profile Image'}
                                />
                            </td>
                            <td className='UsersTableView_TableDataCell'>
                                <p className={clsx('BoldText', 'DarkGreyTint')} style={{ marginBottom: '3px' }}>
                                    {(_.upperFirst(user.first_name || '') + " " + _.upperFirst(user.last_name || ''))}
                                </p>
                                <p className={clsx('NormalText', 'DarkGreyTint')}>
                                    {user.email || ''}
                                </p>
                            </td>
                            <td className='UsersTableView_TableDataCell'><p className={clsx('DarkGreyTint')}>{user.role ? user.role.name : 'No Role'}</p></td>
                            <td className='UsersTableView_TableDataCell'><p className={clsx('DarkGreyTint')}>{capitalize.words(_.toLower(user.account_status || ''))}</p></td>
                            <td className='UsersTableView_TableDataCell'><p className={clsx('DarkGreyTint')}>{capitalize.words(_.toLower(user.working_status || ''))}</p></td>
                            <td className='UsersTableView_TableDataCell'><p className={clsx('DarkGreyTint')}>{capitalize.words(moment(new Date(user.last_active), "YYYYMMDD").fromNow()) || ''}</p></td>
                            <td className='UsersTableView_TableDataCell'><p className={clsx('DarkGreyTint')}>{moment(new Date(user.created_at), "YYYYMMDD").format('M-DD-YYYY')}</p></td>
                        </tr>
                    );
                })}
            </tbody>
        );
    };

    const showLoadingAnimation = () => {
        return (
            <PulseLoader
                css={`margin-top: 20px;`}
                size={20}
                sizeUnit={"px"}
                margin="5px"
                color={'#0A3F79'}
                loading={true}
            />
        );
    };

    return (
        <div className="UsersTableView">
            {props.loading ? showLoadingAnimation() : null}
            {(props.data && !_.isEmpty(props.data)) ? 
                <table className='UsersTableView_Table'>
                    {createTableHeader()}
                    {createTableRows()}
                </table>
                :
                <p className={clsx('Margin--Vertical--20', 'Margin--Horizontal--Auto', 'TextAlignCenter', 'DarkGreyTint', 'SubTitleSmall', 'SemiBoldText')}>
                    No Users Available
                </p>
            }
        </div>
    );
};

export default UsersTableView;
