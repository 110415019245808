// Import Modules
import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import clsx from 'clsx';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, Redirect } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import moment from 'moment-timezone';


import { ACTIVATE_ACCOUNT } from '../../graphql/AuthMutations';
import * as LogInActionTypes from '../../Redux/ActionTypes/Auth/LogInActionTypes';
import * as UserInfoActionTypes from '../../Redux/ActionTypes/Auth/UserInfoActionTypes';
import NewPasswordForm from '../../Components/Forms/NewPasswordForm';
import LogInBox from '../../Components/Boxes/LogInBox';
import * as ROUTES from '../../Constants/routes';

const NewPasswordScreen = (props) => {
    const email = useSelector(state => state.auth.login.email);
    const user = useSelector(state => state.auth.userInfo.user);
    const [isLoading, setIsLoading] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [error, setError] = useState(undefined);
    const history = useHistory();
    const dispatch = useDispatch();

    const [activateAccount, { loading: mutationLoading }] = useMutation(ACTIVATE_ACCOUNT);

    if (email === '') {
        return (<Redirect to={ROUTES.LOG_IN} />);
    }

    const onNewPasswordChange = (event) => {
        setNewPassword(event.target.value);
    }

    const newPasswordCheck = () => {
        let result = false;
            if (newPassword !== '') {
                if (newPassword.length >= 6) {
                    result = true;
                }

            }

        return result;
    }


    const handlePasswordKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSubmit()
        }
    }

    const handleErrorArea = () => {
        let errorComponent = null;

        if (error) {
            errorComponent = (
                <p className={clsx('ErrorBody', 'Margin--Vertical--10', 'Margin--Horizontal--Auto', 'Padding--Horizontal--20', 'TextAlignCenter')}>
                    {error.message || ''}
                </p>
            );
        }

        return errorComponent;
    }

    const handleSubmit = () => {

        if (newPasswordCheck()) {
            setIsLoading(true);

            Auth.completeNewPassword(user,newPassword, {email: email})
            .then((newUser) => {
                let newUserInput = {
                    id: user.username,
                    time_zone: moment.tz.guess()
                };
    
                activateAccount({variables: newUserInput})
                .then((result) => {
                    dispatch({ type: UserInfoActionTypes.UPDATE_USER, payload: { user: newUser } });
                    dispatch({ type: UserInfoActionTypes.UPDATE_LOGIN_STATUS, payload: { isLoggedIn: true } });
                    dispatch({ type: LogInActionTypes.CLEAR_LOGIN_INFO});

                    history.push(ROUTES.HOME)
                    setIsLoading(false);
                })
                .catch((error) => {
                    setError(error);
                    setIsLoading(false);
                });

            })
            .catch((error) => {
                setError(error);
                setIsLoading(false);
            });
        }

    }

    return (
        <div className="BaseScreen">
            <LogInBox>
                <p className={clsx('Title', 'DarkBlueTint')}>Change Password</p>
                <p className={clsx('BodyText', 'DarkBlueTint')} style={{ marginTop: '10px' }}>
                    For security reasons, please create a new password for your account.
                </p>
                {handleErrorArea()}
                <NewPasswordForm
                    emailValue={email}
                    newPasswordValue={newPassword}
                    onNewPasswordChange={(event) => { onNewPasswordChange(event) }}
                    newPasswordKeyPressChecker = {(event) => {handlePasswordKeyPress(event)}}
                    isInvalid={!newPasswordCheck()}
                    handleSubmit={handleSubmit}
                    isLoading={mutationLoading || isLoading}
                />
            </LogInBox>
        </div>
    );
};

export default NewPasswordScreen;