import React, {Fragment} from 'react';
import _ from 'lodash';
import clsx from 'clsx';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AnimateHeight from 'react-animate-height';
import { useSelector } from 'react-redux';
import PermissionGroupCell from './PermissionGroupCell';

const PermissionCategoryCell = (props) => {

    const layoutWidth = useSelector(state => state.ui.layout.layoutWidth);

    if (props.data === null || _.isEmpty(props.data)) {
        return null;
    }


    const handleIcon = () => {
        let iconOpen = false;

        if (props.data.open !== undefined) {
            iconOpen = props.data.open;
        }

        return (
            iconOpen ? 
            <ExpandLessIcon style={{ color: '#002D47', fontSize: '40', fontWeight: '600' }} /> : 
            <ExpandMoreIcon style={{ color: '#002D47', fontSize: '40', fontWeight: '600' }} />
        )
    };

    const handleCategoryPermissionsArea = () => {
        if (props.data.open === undefined) {
            return null;
        }

        let gridTemplateText = 'repeat(auto-fit, minmax(300px, 0.5fr))';

        if (layoutWidth <= 900) {
            gridTemplateText = 'repeat(auto-fit, auto)';
        }

        return (
            <Fragment>
                <AnimateHeight
                    duration={300}
                    height={props.data.open ? 'auto' : 0}
                >
                    <div 
                        className={clsx('Margin--Horizontal--20', 'Margin--Bottom--15')} 
                        style={{height: 'auto', display: 'grid', gridGap: '15px', gridTemplateColumns: gridTemplateText}}
                    >
                        {props.data.items.map((permissionGroup) => {
                            return (
                                <PermissionGroupCell 
                                    key={permissionGroup.group_identifier}
                                    onPermissionButtonClicked={(permission) => props.onPermissionButtonClicked(permission)}
                                    data={permissionGroup}
                                    isEditable={props.isEditable}
                                />
                                
                            );
                        })}
                    </div>
                </AnimateHeight>
            </Fragment>
        );


    }

    const handleCategoryTitle = () => {
        let categoryTitle = '';
        let categoryTitleSuffix = '';
        let categoryPermissionsSelected = 0;
        let categoryPermissionsAvailable = 0;

        if (props.data.category !== undefined) {
            categoryTitle = props.data.category;
        }

        if (!_.isEmpty(props.data.items)) {
            props.data.items.forEach((permissionGroup) => {
                if (!_.isEmpty(permissionGroup.permissions)) {
                    permissionGroup.permissions.forEach((permission) => {
                        categoryPermissionsAvailable += 1;
    
                        if (permission.selected === true) {
                            categoryPermissionsSelected += 1;
                        }
                    });
                }
    
            });
        }
        
        categoryTitleSuffix = ' (' + categoryPermissionsSelected + '/' + categoryPermissionsAvailable + ')';


        return (
            <Fragment>
                <p
                    className={clsx(
                        'Padding--Vertical--0',
                        'Padding--Horizontal--0',
                        'Margin--Vertical--Auto',
                        'Margin--Horizontal--0',
                        'TextAlignLeft',
                        'SubTitle',
                        'SemiBoldText',
                        'DarkGreyTint')}
                >
                    {categoryTitle}
                    <span className={clsx('SubTitleSmall', 'NormalText')}>
                        {categoryTitleSuffix}
                    </span>
                </p>
            </Fragment>
        );
    };



    return (
        <Fragment>
            <div className={clsx('Width--Full', 'Display--Flex', 'Flex--SpaceBetween')} >
                <button
                    className={clsx(
                        'Width--Full',
                        'BackgroundColor--WhiteTint',
                        'Border--None',
                        'Display--Flex',
                        'Flex--SpaceBetween',
                        'Padding--Vertical--10',
                        'Padding--Horizontal--20',
                        'Pointer--Cursor')}
                    onClick={() => props.onCategoryClick(props.data)}
                >
                    {handleCategoryTitle()}
                    {handleIcon()}
                </button>
            </div>
            {handleCategoryPermissionsArea()}
            <div className={clsx('Border--Bottom--1', 'BorderColor--DarkGreyTint', 'Margin--Horizontal--20')} />
        </Fragment>
    );
};

export default PermissionCategoryCell;