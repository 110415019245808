import React from 'react';
import clsx from 'clsx';

const BoxHeader = (props) => {


    return (
        <div className='BoxHeaderContainer'>
            <p className={clsx('SmallText', 'DarkBlueTint', 'SemiBoldText', 'TextAlignCenter')} style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                {props.text || ''}
            </p>
        </div>
    );
};

export default BoxHeader;