import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux'; 
import * as actionTypes from '../../Redux/ActionTypes/Auth/UserInfoActionTypes';
import PreAuthLayout from './PreAuth/PreAuthLayout';
import AuthLayout from './Auth/AuthLayout';



class LayoutContainer extends Component {

    render() {
        return (
            <Fragment>
                {!this.props.isLoggedIn ?
                    <PreAuthLayout> {this.props.children} </PreAuthLayout>
                    :
                    <AuthLayout> {this.props.children} </AuthLayout>
                }
            </Fragment>
        );
    }

}

const mapStateToProps = state => {
    return {
        currentUser: state.auth.userInfo.user,
        isLoggedIn: state.auth.userInfo.isLoggedIn
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onUpdateUser: (user) => dispatch({ type: actionTypes.UPDATE_USER, payload: { user: user } }),
        onRemoveUser: () => dispatch({ type: actionTypes.REMOVE_USER }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LayoutContainer);