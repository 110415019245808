import React from 'react';
import clsx from 'clsx';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';


const BoxSearch = (props) => {

    let iconArea = null;



    if ((props.isSearchFocused || false)) {
        iconArea = (
            <div style={{ 'marginTop': '11px', 'marginBottom': 'auto', width: '24px' }}>
                <button style={{ margin: '0px', padding: '0px', border: 'none', backgroundColor: 'white' }} onClick={props.onClearButtonClicked} >
                    <ClearIcon style={{ color: '#444444', fontSize: 'normal', fontWeight: '600' }} size='medium' />
                </button>
            </div>
        );
    } else {
        iconArea = (
            <div style={{ 'marginTop': '11px', 'marginBottom': 'auto', width: '24px' }}>
                <button style={{ margin: '0px', padding: '0px', border: 'none', backgroundColor: 'white', height: 'auto' }} onClick={props.onSearchButtonClicked} >
                    <SearchIcon style={{ color: '#444444', fontSize: 'normal', fontWeight: '600' }} size='medium' />
                </button>
            </div >
        );
    }

    return (
        <div className={clsx({'BoxSearchContainer': true, 'BoxSearchContainerFocused': (props.isSearchFocused || false)})} style={props.containerStyle || {}}>
            {iconArea}
            <input 
                type='text'
                value={props.searchValue} 
                onChange={(event) => props.onSearchValueChange(event)} 
                onFocus={props.onSearchFocused}
                onBlur={props.onSearchFocusedOut}
                className='BoxSearchInput'
                style={props.inputStyle || {}}
                placeholder={props.searchValuePlaceholder || ''}
            />
        </div>
    );
};

export default BoxSearch;