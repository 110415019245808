import React from 'react';

const Box = (props) => {

    return (
        <div className={props.className} style={props.style || {}}>
            {props.children}
        </div>
    );
};

export default Box;