// User Info Reducer
import * as actionTypes from '../../ActionTypes/UI/LayoutActionTypes';

const initialState =
{
  layoutWidth: 0,
  authNavigatorCategories: [],
  settingsCategories: []
};

const layoutReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_LAYOUT_WIDTH:
            return {
                ...state,
                layoutWidth: action.payload.layoutWidth
            };
        case actionTypes.SET_AUTH_NAVIGATOR_CATEGORIES:
            return {
                ...state,
                authNavigatorCategories: action.payload.authNavigatorCategories
            };
        case actionTypes.SET_SETTINGS_CATEGORIES:
            return {
                ...state,
                settingsCategories: action.payload.settingsCategories
            };
        default:
            return state;
    }
};

export default layoutReducer;