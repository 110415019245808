export default {
    UsersSortingOptions: [
        { label: 'First Name: A-Z', value: 'first_name_asc' },
        { label: 'First Name: Z-A', value: 'first_name_dec' },
        { label: 'Last Name: A-Z', value: 'last_name_asc' },
        { label: 'Last Name: Z-A', value: 'last_name_dec' },
        { label: 'Account Status', value: 'account_status_dec' },
        { label: 'Last Active', value: 'last_updated_dec' },
        { label: 'Created At', value: 'created_at_dec' },
    ],
    RolesSortingOptions: [
        { label: 'Role Name: A-Z', value: 'role_name_asc' },
        { label: 'Role Name: Z-A', value: 'role_name_dec' },
        { label: 'Number of Users', value: 'num_users_dec' },
        { label: 'Number of Permissions', value: 'num_permissions_dec' },
        { label: 'Last Updated At', value: 'last_updated_at_dec' },
        { label: 'Created At', value: 'created_at_dec' }
    ],
};