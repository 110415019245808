// Import Modules
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { Auth } from 'aws-amplify';
import * as ROUTES from '../../Constants/routes';
import * as UserInfoActionTypes from '../../Redux/ActionTypes/Auth/UserInfoActionTypes';
import SignUpScreenContents1 from '../ScreenContents/SignUpScreenContents1';
import SignUpScreenContents2 from '../ScreenContents/SignUpScreenContents2';
import { CREATE_ACCOUNT } from '../../graphql/AuthMutations';
import moment from 'moment-timezone';

const SignUpScreen = (props) => {

    const dispatch = useDispatch();
    const [firstLoaded, setFirstLoaded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [isSignUpSubmitted, setSignUpSubmitted] = useState(false);
    const [error, setError] = useState('');
    const isLoggedIn = useSelector(state => state.auth.userInfo.isLoggedIn);
    const isSignUpSubmittedRedux = useSelector(state => state.auth.userInfo.isSignUpSubmitted);
    const [createAccountAction, { loading: mutationLoading }] = useMutation(CREATE_ACCOUNT);
    const user = useSelector(state => state.auth.userInfo.user);
    const history = useHistory();


    if (!firstLoaded) {
        setSignUpSubmitted(isSignUpSubmittedRedux);
        setFirstLoaded(true);
    }
    
    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    }

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    }

    const handleVerificationCodeChange = (event) => {
        setVerificationCode(event.target.value);
    }

    const handleTOS = () => {
        history.push(ROUTES.TOS);
    }

    const handlePP = () => {
        history.push(ROUTES.PP);
    }

    const passwordKeyPressChecker = (event) => {
        if (event.key === 'Enter') {
            handleSignUp();
        }
    }

    const verificationCodeKeyPressChecker = (event) => {
        if (event.key === 'Enter') {
            handleConfirmSignUp();
        }
    }

    const handleResendCode = () => {
        setLoading(true);
        Auth.resendSignUp(email.toLowerCase())
            .then((result) => {
                setError(null);
                setLoading(false);
            }).catch((error) => {
                setError(error);
                setLoading(false);
            });
    }

    const handleSignUp = () => {
        setLoading(true);

        Auth.signUp({
            username: email.toLowerCase(),
            password: password,
            attributes: {
                'email': email.toLowerCase(),
                'name': '',
                'phone_number': '',
                'custom:company_id': '',
                'custom:user_id': ''
            }
        }).then((data) => {
            dispatch({ type: UserInfoActionTypes.UPDATE_USER, payload: { user: data } });
            setError(null);
            setLoading(false);
            setSignUpSubmitted(true);
        }).catch((error) => {
            setError(error);
            setLoading(false);
            setSignUpSubmitted(false);
        });
    }

    const handleConfirmSignUp = () => {
        setLoading(true);
        Auth.confirmSignUp( email.toLowerCase(), verificationCode)
        .then((data) => {

            Auth.signIn(email.toLowerCase(), password)
                .then((signedInUser) => {

                    createAccountAction({
                        variables: {
                            id: user.userSub,
                            email: email.toLowerCase(),
                            timeZone: moment.tz.guess()
                        }
                    })
                    .then((result) => {

                        let newAuthObject = result.data.createAccount;

                        Auth.updateUserAttributes(signedInUser, {
                            'custom:company_id': newAuthObject.company_id.toString(),
                            'custom:user_id': newAuthObject.user_id.toString()
                        })
                        .then((result) => {
                            dispatch({ type: UserInfoActionTypes.UPDATE_USER, payload: { user: signedInUser } });
                            dispatch({ type: UserInfoActionTypes.UPDATE_LOGIN_STATUS, payload: { isLoggedIn: true } });
                            dispatch({ type: UserInfoActionTypes.UPDATE_AUTH, payload: { auth: result.data.createAccount } });
                            history.push(ROUTES.HOME);
                        })
                        .catch((error) => {
                            setError(error);
                            setLoading(false);
                        });

                    }).catch((error) => {
                        setError(error);
                        setLoading(false);
                    });
                }).catch ((error) => {
                    setError(error);
                    setLoading(false);
                });

        }).catch((error) => {
            setError(error);
            setLoading(false);
        });
    }

    const checkIfValid = () => {
        if (isSignUpSubmitted === true) {
            return (verificationCode === "");
        }else {
            return ( email === '' || password === '');
        }
    }

    const isInvalid = checkIfValid();

    if (isLoggedIn) {
        return (
            <Redirect to={ROUTES.HOME} />
        );
    }


    if (isSignUpSubmitted !== true) {
        return (
            <SignUpScreenContents1
                emailValue={email}
                onEmailChange={(event) => { handleEmailChange(event) }}
                passwordValue={password}
                onPasswordChange={(event) => { handlePasswordChange(event) }}
                passwordKeyPressChecker= {(event) => {passwordKeyPressChecker(event)}}
                isInvalid={isInvalid}
                isLoading={isSignUpSubmitted ? mutationLoading : loading}
                handleSubmit={handleSignUp}
                handleTOS={handleTOS}
                handlePP={handlePP}
                errorValue={error}
            />
        );
    }else {
        return (
            <SignUpScreenContents2
                emailValue={email}
                verificationCode={verificationCode}
                onVerificationCodeChange={(event) => { handleVerificationCodeChange(event) }}
                onVerificationCodeKeyPressChecker= {(event) => {verificationCodeKeyPressChecker(event)}}
                isInvalid={isInvalid}
                isLoading={isSignUpSubmitted ? mutationLoading : loading}
                handleSubmit={handleConfirmSignUp}
                handleResendCode={handleResendCode}
                errorValue={error}
            />
        );
    }

};

export default SignUpScreen;
