import React from 'react';
import clsx from 'clsx';

const BoxFooterButton = (props) => {

    return (

        !props.isHidden ?
        <button 
            className={clsx({
                'Display--Flex': true, 
                'BackgroundColor--GreenTint': !props.isDisabled, 
                'BackgroundColor--GreyTint': props.isDisabled, 
                'Border--All--1': true, 
                'BorderColor--DarkBlueTint': true,
                'BorderRadius--All--5': true, 
                'Padding--Horizontal--12': true, 
                'Padding--Vertical--10': true, 
                'Margin--Vertical--Auto': true,
                'Pointer--Cursor': !props.isDisabled,
                'BoxShadow--0-5': !props.isDisabled
                })} 
            style={props.buttonStyles || {}}
            onClick={props.onClick}
            disabled={props.isDisabled}
        >
            <p className={clsx(['ButtonText','TextAlignLeft', 'Margin--Vertical--Auto', 'LineHeight--Inherit', ...props.titleClasses])} >
                {props.title || ''}
            </p>
            {props.icon ? props.icon : null}
        </button>
        : null 
    );
};

export default BoxFooterButton;