// Import Modules
import React from 'react';
import clsx from 'clsx';


const DataTableContainer = (props) => {
    
    return (
        <div className={clsx(
                'Border--All--1', 
                'BorderColor--DarkBlueTint', 
                'BorderRadius--All--10', 
                'BackgroundColor--WhiteTint', 
                'BoxShadow--1', 
                'Margin--Horizontal--20')}
        >
            <div className='DataListContainerTopHousing'>
                {props.topContents || null}
            </div>
            <div className='DataTableContainerHeaderHousing'>
                <div className='DataTableContainerHeaderLeftContentsHousing'>
                    {props.leftContents || null}
                </div>
                <div className='DataTableContainerHeaderRightContentsHousing'>
                    {props.rightContents || null}
                </div>
            </div>
            <div className='SettingsBoxLine'/>
            {props.children}
        </div>
    );
};

export default DataTableContainer;