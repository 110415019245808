// Import Modules
import React, { useState, Fragment } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import clsx from 'clsx';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import copy from 'copy-to-clipboard';

// Import Custom Components
import AlertConfirmationPopUp from '../../../../Components/UI/AlertConfirmationPopUp';


const ResetPasswordPopUp = (props) => {

    // Clientside Permissions validation
    const auth = useSelector(state => state.auth.userInfo.auth);
    const [resetClicked, setResetClicked] = useState(false);
    const [successPopUp, setSuccessPopUp] = useState(false);
    // Need to update this below
    const [resetUserPasswordResult] = useState('t3Ed5q');
    const [sendEmailCheck, setSendEmailCheck] = useState(true);
    // const [updateUserRoleAction, { loading: mutationLoading, error: mutationError }] = useMutation(UPDATE_USER_ROLE);

    // useEffect(()=> {
    //     if (props.data.role) { 
    //         setRole({ label: props.data.role.name, value: props.data.role.id });
    //     }
        
    // }, [props.data.role]);


    if (auth === null || auth === undefined || _.isEmpty(auth) || _.isEmpty(auth.role_permissions)) {
        return null;
    }

    if (!(props.open || false)) {
        return null;
    }

    // Functions Area


    const onClose = () => {
        setResetClicked(false);
        setSendEmailCheck(true);
        setSuccessPopUp(false);
        props.onClose();
    }

    // Need to Add
    // const onSubmit = () => {
        
    // }

    const onCopy = () => {
        setSuccessPopUp(true);
        copy(resetUserPasswordResult);
    }

    const createResetPasswordPopUp1Title = () => {
        if (!_.isEmpty(props.data)) {
            return (
                <p className={clsx('SubTitle', 'WhiteTint', 'NormalText')}>
                    Reset Password for <span className={clsx('BoldText')}>{props.data.full_name}</span>
                </p>
            );

        }else {
            return (
                <p className={clsx('SubTitle', 'WhiteTint', 'NormalText')}>
                    Reset Password
                </p>
            );
        }
    };

    const createResetPasswordPopUp1Content = () => {
        if (!_.isEmpty(props.data)) {
            return (
                <Fragment>
                    <p className={clsx('BodyText', 'DarkGreyTint', 'NormalText')}>
                        Resetting <span className={clsx('BoldText')}>{props.data.full_name}'s</span> password will auto-generate a new temporary password. On the next user log-in with the new password, the password will need to be changed.
                    </p>
                    <button className={clsx('Display--Flex', 'Flex--Start', 'Border--None', 'BackgroundColor--None', 'Margin--Top--20', 'Padding--Horizontal--0', 'Padding--Vertical--0')} onClick={() => setSendEmailCheck(!sendEmailCheck)}>
                        {sendEmailCheck ?
                            <RadioButtonCheckedIcon style={{ color: '#07689F', fontSize: '30', fontWeight: '600' }} /> :
                            <RadioButtonUncheckedIcon style={{ color: '#444444', fontSize: '30', fontWeight: '600' }} />
                        }
                        <p className={clsx('BodyText', 'DarkGreyTint', 'NormalText', 'TextAlignLeft', 'Margin--Horizontal--15', 'Pointer--Cursor')}>
                            By clicking this box, an email will be sent to <span className={clsx('BoldText')}>{props.data.email}</span> with their new password and instructions.
                        </p>
                    </button>
                </Fragment>
            );

        }else {
            return (
                <p className={clsx('BodyText', 'DarkGreyTint', 'NormalText')}>
                    Resetting this user's password will auto-generate a new temporary password. By clicking the box below, this will also send an email to the user with their new password.
                </p>
            );
        }
    };

    const createResetPasswordPopUp2Title = () => {
        if (!_.isEmpty(props.data)) {
            return (
                <p className={clsx('SubTitle', 'WhiteTint', 'NormalText')}>
                    Reset Password Successful for <span className={clsx('BoldText')}>{props.data.full_name}</span>
                </p>
            );

        }else {
            return (
                <p className={clsx('SubTitle', 'WhiteTint', 'NormalText')}>
                    Reset Password
                </p>
            );
        }
    };

    const createResetPasswordPopUp2Content = () => {
        return (
            <Fragment>
                <p className={clsx('SmallText', 'DarkGreyTint', 'SemiBoldText')}>
                    New Password
                </p>
                <div className={clsx('Display--Flex', 'Flex--Start')}>
                    <p className={clsx('Title', 'DarkGreyTint', 'BoldText')}>
                        {resetUserPasswordResult}
                    </p>
                </div>
                <button 
                    className={clsx(
                        'Display--Flex', 
                        'Flex--Start', 
                        'Border--None', 
                        'BackgroundColor--None', 
                        'Padding--Horizontal--0', 
                        'Padding--Vertical--0'
                    )} 
                    onClick={() => {onCopy()}}
                >
                        <p className={clsx(
                            'SmallText', 
                            'BlueTint', 
                            'BoldText', 
                            'TextAlignLeft', 
                            'Margin--Top--5', 
                            'Margin--Horizontal--0', 
                            'Pointer--Cursor'
                        )}>
                            Click to copy password
                        </p>
                    </button>
                    <div className="Spacer--10" />
                    <p className={clsx('BodyText', 'DarkGreyTint', 'NormalText')}>
                        {props.data.full_name}'s password has been successfully reset to the password shown above. Keep this password safe and share it only to the correct account owner.
                    </p>
            </Fragment>
        );
    };

    const handleSuccessSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSuccessPopUp(false);
    };


    return (
        <Fragment>
            <AlertConfirmationPopUp
                open={props.open}
                headerContent={resetClicked ? createResetPasswordPopUp2Title() : createResetPasswordPopUp1Title()}
                headerStyle={{backgroundColor: resetClicked ? '#2D9639' : '#07689F'}}
                description='Auto-generate a new temporary password for this user. The new password will be email to the user and shown to you.'
                contentArea={resetClicked ? createResetPasswordPopUp2Content() : createResetPasswordPopUp1Content()}
                actionText={resetClicked ? 'Done' : 'Reset'}
                actionColor={resetClicked ?  '#2D9639' : '#07689F'}
                isActionDisabled={false}
                isSecondaryDisabled={resetClicked}
                isSecondaryHidden={resetClicked}
                onSecondaryClicked={props.onClose}
                onActionClicked={!resetClicked ? () => setResetClicked(true) : () => onClose()}
            />
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={successPopUp}
                autoHideDuration={3000}
                onClose={handleSuccessSnackbarClose}
                action={
                    <IconButton size="small" aria-label="close" color="inherit" onClick={handleSuccessSnackbarClose}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                }
                message="Password Copied."
            />
        </Fragment>
    );
};

export default ResetPasswordPopUp;
