// User Info Reducer
import * as actionTypes from '../../ActionTypes/Auth/UserInfoActionTypes';

const initialState =
{
  user: null,
  isLoggedIn: true,
  auth: {}
};

const userInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_USER:
      return {
        ...state,
        user: action.payload.user
      }
    case actionTypes.REMOVE_USER:
      return {
        ...state,
        user: null
      }
    case actionTypes.UPDATE_LOGIN_STATUS:
      return {
        ...state,
        isLoggedIn: action.payload.isLoggedIn
      }
    case actionTypes.UPDATE_AUTH:
      return {
        ...state,
        auth: action.payload.auth
      }
    case actionTypes.REMOVE_AUTH:
      return {
        ...state,
        auth: {}
      }
    default:
      return state
  }
};

export default userInfoReducer;