import React from 'react';
import clsx from 'clsx';
import Avatar from '@material-ui/core/Avatar';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import AuthMenu from './AuthMenu';
import HelpMenu from './HelpMenu';
import { useSelector } from 'react-redux';


import { GET_LAYOUT_WIDTH } from '../../../Redux/ActionTypes/UI/LayoutActionTypes';
import * as CustomMaterials from '../../MaterialUI/CustomMaterials';

const drawerWidth = 220;

const styles = theme => ({
  secondaryBar: {
    zIndex: 0,
  },
  menuButton: {
    color: "#ffffff",
    paddingTop: 'auto',
    paddingBottom: 'auto'
  },
  menuIcon: {
    root: {
      padding: '0px  ',
      margin: 'auto'
    },
    height: '100%',
  },
  iconButtonAvatar: {
    padding: 4,
    color: "#fff"
  },
  avatar: {
    width: '32px',
    height: '32px',
  },
  appBar: {
    width: `calc(100%)`,
    marginLeft: drawerWidth,
    backgroundColor: "#07689F"
  },
  appBarMobile: {
    width: `calc(100%)`,
    backgroundColor: "#07689F",
  },
  logOut: {
      color: '#DA3A3B',
      fontWeight: 600
  },
  menuItem: {
    opacity: 1,
    color: '#002D47'
  }
});



function AuthHeader(props) {
  const { classes } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [helpAnchorEl, setHelpAnchorEl] = React.useState(null);

  const auth = useSelector(state => state.auth.userInfo.auth);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleHelpMenuClick = event => {
    setHelpAnchorEl(event.currentTarget);
  };

  const handleHelpMenuClose = () => {
    setHelpAnchorEl(null);
  };

  const handleProfile = () => {
    handleClose();
    props.handleProfile();
  };

  let companyName = '';
  let userFullName = '';
  let userProfileUrl = 'error';

  if (auth !== null && auth !== undefined) {
    if (auth.company_name !== undefined) {
      companyName = auth.company_name;

      if (companyName.length > 20) {
        if (props.layoutWidth < 500) {
          let charLength = 10;
          if (GET_LAYOUT_WIDTH <= 215) {
            charLength = 0;
          } else if (GET_LAYOUT_WIDTH > 215 && GET_LAYOUT_WIDTH <= 225) {
            charLength = 3;
          } else if (GET_LAYOUT_WIDTH > 225 && GET_LAYOUT_WIDTH <= 300) {
            charLength = 5;
          } else if (GET_LAYOUT_WIDTH > 300 && GET_LAYOUT_WIDTH <= 350) {
            charLength = 10;
          } else if (GET_LAYOUT_WIDTH > 350 && GET_LAYOUT_WIDTH <= 375) {
            charLength = 16;
          } else if (GET_LAYOUT_WIDTH > 375 && GET_LAYOUT_WIDTH <= 400) {
            charLength = 18;
          } else {
            charLength = auth.company_name.length;
          }

          if (auth.company_name.length > charLength) {
            companyName = auth.company_name.substring(0, charLength) + '...';
            if (charLength === 0) {
              companyName = '';
            }
          }
        } else {
          companyName = auth.company_name;
        }
      } else {
        companyName = auth.company_name;
      }

    };

    if (auth.user_full_name !== undefined) {
      userFullName = auth.user_full_name;
    };
  }

  const getOpenStatus = () => {
    let result = true;
    if (props.layoutWidth >= 1330) {
      result = props.desktopOpen; 

    }else if (props.layoutWidth < 1330 && props.layoutWidth >= 800) {
      result = false; 
    }else {
      result = false; 
    }
    return result;
  }

  const getSidebarCase = () => {
    let result = "full";

    if (props.layoutWidth >= 1330) {
      if (props.desktopOpen) {
        result = "full";
      }else {
        result = "medium";
      }
    }else if (props.layoutWidth < 1330 && props.layoutWidth > 800) {
      result = "medium";
      if (props.mobileOpen) {
        result = "popover-medium";
      }
    } else if (props.layoutWidth <= 800 && props.layoutWidth > 0) {
      result = "mobile";

      if (props.mobileOpen) {
        result = "popover-mobile";
      }
    }

    return result;
  }


  return (
    <div className="auth-header-container">
      <div 
        className={clsx({
          "auth-header-left-area": true, 
          "auth-header-left-area-full": "full" === getSidebarCase(), 
          "auth-header-left-area-medium": "medium" === getSidebarCase(),
          "auth-header-left-area-mobile": "mobile" === getSidebarCase(),
        })}
      >
        <button
          onClick={props.onMenuToggle}
          className={"auth-header-menu-button"}
        >
          <CustomMaterials.CustomMenuIcon fontSize="large" />
        </button>

        <div className={clsx({"auth-header-hideable-area": true, "auth-header-hideable-area-hidden": !getOpenStatus()})}>
          <p className={clsx('SubTitleSmall', "BoldText", "WhiteTint", "Margin--Left--10")}>
            ProHelper
          </p>
        </div>
      </div>

      <div className="auth-header-middle-area">
        <p className={clsx('SubTitleSmall', "SemiBoldText", "WhiteTint")}>
          {auth.company_name}
        </p>
      </div>

      <div className="auth-header-right-area">
        <CustomMaterials.CustomTooltip title="Tips, Guides, and Help"  >
          <IconButton onClick={handleHelpMenuClick} className={classes.iconButtonAvatar}>
            <HelpOutlineIcon />
          </IconButton>
        </CustomMaterials.CustomTooltip>
        <CustomMaterials.CustomTooltip title="Notifications" >
          <IconButton className={classes.iconButtonAvatar} style={{ 'marginLeft': '3px' }}>
            <NotificationsNoneIcon />
          </IconButton>
        </CustomMaterials.CustomTooltip>
        <HelpMenu
          anchorEl={helpAnchorEl}
          handleClose={handleHelpMenuClose}
        />
        <CustomMaterials.CustomTooltip title="Profile Settings" >
          <IconButton onClick={handleClick} className={classes.iconButtonAvatar}>
            <Avatar src={userProfileUrl} alt={userFullName} className={classes.avatar} />
          </IconButton>
        </CustomMaterials.CustomTooltip>
        <AuthMenu
          anchorEl={anchorEl}
          handleClose={handleClose}
          handleSignOut={props.handleSignOut}
          handleProfile={handleProfile}
        />
      </div>
    </div>
  );

}

export default withStyles(styles)(AuthHeader);