import React from 'react';
import clsx from 'clsx';
import moment from 'moment';
import _ from 'lodash';

const UserRoleDetailsView = (props) => {
    if (_.isEmpty(props.data)) {
        return (
            <div className='UserDetailRoleForm_Container'>
                <p className={clsx('SubTitleSmall', 'NormalText', 'TextAlignCenter', 'DarkGreyTint')}>
                    No Role Attached.
                </p>
            </div>
        );
    }

    const handlePermissionsText = () => {
        let permissionAccessText = 'No last updated date available.';

        if (props.data.permission_categories) {
            permissionAccessText = '';
            let permissionCategoriesLength = props.data.permission_categories.length;
            props.data.permission_categories.forEach((value, index) => {
                permissionAccessText += value;

                if (index !== permissionCategoriesLength - 1) {
                    permissionAccessText += ', ';
                }
            });
        }

        return permissionAccessText;
    }


    return (
        <div className={clsx('Margin--Top--10', 'Margin--Bottom--20', 'Margin--Horizontal--20', 'TextAlignLeft')}>
            <button className={clsx('Padding--Horizontal--0', 'Border--None', 'BackgroundColor--None', 'Pointer--Cursor')} onClick={() =>props.onRoleClicked(props.data)}>
                <p className={clsx('SubTitle', 'BoldText', 'TextAlignLeft', 'BlueTint')}>
                    {props.data.name || 'No name available.'}
                </p>
                <p className={clsx('BodyText', 'NormalText', 'TextAlignLeft', 'DarkBlueTint')}>
                    {props.data.description || 'No description available.'}
                </p>
            </button>
            <div className='UserDetailRoleForm_ContainerSpacer' />
            <div className='SettingsBoxLine'/>
            <div className='UserDetailRoleForm_ContainerSpacer' />
            <p className={clsx('SubTitleSmall', 'BoldText', 'TextAlignLeft', 'DarkGreyTint')}>
                Permission Access
            </p>
            <p className={clsx('BodyText', 'NormalText', 'TextAlignLeft', 'DarkGreyTint')}>
                {handlePermissionsText()}
            </p>
            <div className='Spacer--10' />
            <p className={clsx('SubTitleSmall', 'BoldText', 'TextAlignLeft', 'DarkGreyTint')}>
                Last Updated
            </p>
            <p className={clsx('BodyText', 'NormalText', 'TextAlignLeft', 'DarkGreyTint')}>
                {moment(new Date(props.data.last_updated_at), "YYYYMMDD").fromNow() || 'No last updated date available.'}
            </p>
            <div className='Spacer--10' />
            <p className={clsx('SubTitleSmall', 'BoldText', 'TextAlignLeft', 'DarkGreyTint')}>
                Created
            </p>
            <p className={clsx('BodyText', 'NormalText', 'TextAlignLeft', 'DarkGreyTint')}>
                {moment(new Date(props.data.created_at), "YYYYMMDD").format('M-DD-YYYY') || 'No created date available.'}
            </p>
        </div>
    );
};

export default UserRoleDetailsView;
