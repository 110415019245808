export default {
    companyPermissions: [
        { 
            id: 1, 
            name: 'Program Access',
            description: 'Gives a user basic access to the ProHelper Platform. Lets a user log in, update their profile, etc.',
            created_at: new Date(),
            last_updated_at: new Date(),
            screen_access: ['dashboard','user_settings','support'],
            action_access: ['GetAuth','GetUser','UpdateUserContact','UpdateUserAddress','UpdateUserPreferences'],
            identifier: 'basic_access',
            action_name: 'Basic Access',
            action_order: 1,
            group_identifier: 'settings_platform',
            group_name: 'Platform',
            version: 0.1,
            active: true,
            category: 'Settings',
            sub_category: 'Basic' 
        },
        { 
            id: 2, 
            name: 'Company Settings',
            description: 'Access to view and edit Company Settings. Includes public company details, company private information, and more.',
            created_at: new Date(),
            last_updated_at: new Date(),
            screen_access: ['settings','settings_company','settings_branding'],
            action_access: ['GetCompany','UpdateCompany','GetCompanyBranding','UpdateCompanyBranding'],
            identifier: 'settings_company',
            action_name: 'Company Settings',
            action_order: 1,
            group_identifier: 'settings_company',
            group_name: 'Company',
            version: 0.1,
            active: true,
            category: 'Settings',
            sub_category: 'Company' 
        },
        { 
            id: 3, 
            name: 'Features Settings',
            description: 'Access to view and edit Features Settings. Includes Work, Billing, Schedule, and Customer Settings.',
            created_at: new Date(),
            last_updated_at: new Date(),
            screen_access: ['settings_work','settings_schedule','settings_billing','settings_customers'],
            action_access: [],
            identifier: 'settings_features',
            action_name: 'Feature Settings',
            action_order: 2,
            group_identifier: 'settings_company',
            group_name: 'Company Settings',
            version: 0.1,
            active: true,
            category: 'Settings',
            sub_category: 'Company' 
        },
        { 
            id: 4, 
            name: 'ProHelper Account Settings',
            description: 'Access to view and edit the ProHelper Account Settings. Includes company subscription, payment methods, company plan, and more.',
            created_at: new Date(),
            last_updated_at: new Date(),
            screen_access: ['settings_prohelper_account','settings_subscription','settings_plan','settings_payment_methods'],
            action_access: [],
            identifier: 'settings_prohelper',
            action_name: 'Account Settings',
            action_order: 1,
            group_identifier: 'settings_owner',
            group_name: 'Owner',
            version: 0.1,
            active: true,
            category: 'Settings',
            sub_category: 'Owner' 
        },
        { 
            id: 5, 
            name: 'Data Settings',
            description: 'Access to view and edit the Data Settings. Able to connect to QuickBooks Online and import CSV data.',
            created_at: new Date(),
            last_updated_at: new Date(),
            screen_access: ['settings_qbo','settings_data_import'],
            action_access: [],
            identifier: 'settings_data',
            action_name: 'Data Settings',
            action_order: 3,
            group_identifier: 'settings_company',
            group_name: 'Company',
            version: 0.1,
            active: true,
            category: 'Settings',
            sub_category: 'Company' 
        },
        { 
            id: 6, 
            name: 'Owner Settings',
            description: 'Access to view and edit Owner Settings. Able to delete the company account and all users.',
            created_at: new Date(),
            last_updated_at: new Date(),
            screen_access: ['settings_owner'],
            action_access: [],
            identifier: 'settings_owner',
            action_name: 'Owner Settings',
            action_order: 2,
            group_identifier: 'settings_owner',
            group_name: 'Owner',
            version: 0.1,
            active: true,
            category: 'Settings',
            sub_category: 'Owner' 
        },
        { 
            id: 7, 
            name: 'Assignments - Personal',
            description: 'Access to the Assignments area. Lets a user view and update jobs assigned to them.',
            created_at: new Date(),
            last_updated_at: new Date(),
            screen_access: ['assignments'],
            action_access: [],
            identifier: 'assignments_personal',
            action_name: 'Personal (Full)',
            action_order: 1,
            group_identifier: 'assignments',
            group_name: 'Assignments',
            version: 0.1,
            active: true,
            category: 'Work',
            sub_category: 'Assignments' 
        },
        { 
            id: 8, 
            name: 'Assignments - Company (Read)',
            description: 'Access to the Assignments area. Lets a user view jobs assigned to anyone in the company.',
            created_at: new Date(),
            last_updated_at: new Date(),
            screen_access: ['assignments'],
            action_access: [],
            identifier: 'assignments_company_read',
            action_name: 'Company (Read)',
            action_order: 2,
            group_identifier: 'assignments',
            group_name: 'Assignments',
            version: 0.1,
            active: true,
            category: 'Work',
            sub_category: 'Assignments' 
        },
        { 
            id: 9, 
            name: 'Assignments - Company (Update)',
            description: 'Access to the Assignments area. Lets a user update jobs assigned to anyone in the company.',
            created_at: new Date(),
            last_updated_at: new Date(),
            screen_access: ['assignments'],
            action_access: [],
            identifier: 'assignments_company_update',
            action_name: 'Company (Update)',
            action_order: 3,
            group_identifier: 'assignments',
            group_name: 'Assignments',
            version: 0.1,
            active: true,
            category: 'Work',
            sub_category: 'Assignments' 
        },
        { 
            id: 10, 
            name: 'Jobs',
            description: 'Access to the Jobs area. Able to view all company jobs.',
            created_at: new Date(),
            last_updated_at: new Date(),
            screen_access: ['jobs'],
            action_access: [],
            identifier: 'jobs',
            action_name: 'Area Access',
            action_order: 1,
            group_identifier: 'jobs',
            group_name: 'Jobs',
            version: 0.1,
            active: true,
            category: 'Work',
            sub_category: 'Jobs' 
        },
        { 
            id: 11, 
            name: 'Job (Create)',
            description: 'Access to the New Job screen. Able to create new company jobs.',
            created_at: new Date(),
            last_updated_at: new Date(),
            screen_access: ['job_new'],
            action_access: [],
            identifier: 'job_create',
            action_name: 'Create',
            action_order: 2,
            group_identifier: 'jobs',
            group_name: 'Jobs',
            version: 0.1,
            active: true,
            category: 'Work',
            sub_category: 'Jobs' 
        },
        { 
            id: 12, 
            name: 'Job (Read)',
            description: 'Access to the Job Detail screen. Able to view any company job in detail.',
            created_at: new Date(),
            last_updated_at: new Date(),
            screen_access: ['job_detail'],
            action_access: [],
            identifier: 'job_read',
            action_name: 'Read',
            action_order: 3,
            group_identifier: 'jobs',
            group_name: 'Jobs',
            version: 0.1,
            active: true,
            category: 'Work',
            sub_category: 'Jobs' 
        },
        { 
            id: 13, 
            name: 'Job (Update)',
            description: 'Access to the Edit Job screen. Able to update any company job.',
            created_at: new Date(),
            last_updated_at: new Date(),
            screen_access: ['job_edit'],
            action_access: [],
            identifier: 'job_update',
            action_name: 'Update',
            action_order: 4,
            group_identifier: 'jobs',
            group_name: 'Jobs',
            version: 0.1,
            active: true,
            category: 'Work',
            sub_category: 'Jobs' 
        },
        { 
            id: 14, 
            name: 'Job (Delete)',
            description: 'Able to delete any company job.',
            created_at: new Date(),
            last_updated_at: new Date(),
            screen_access: [''],
            action_access: [],
            identifier: 'job_delete',
            action_name: 'Delete',
            action_order: 5,
            group_identifier: 'jobs',
            group_name: 'Jobs',
            version: 0.1,
            active: true,
            category: 'Work',
            sub_category: 'Jobs' 
        },
        { 
            id: 15, 
            name: 'Jobs - Billing Items (Use)',
            description: 'Access to view and use billing items on jobs.',
            created_at: new Date(),
            last_updated_at: new Date(),
            screen_access: [''],
            action_access: [],
            identifier: 'job_billing_items',
            action_name: 'Billing Items (Use)',
            action_order: 6,
            group_identifier: 'jobs',
            group_name: 'Jobs',
            version: 0.1,
            active: true,
            category: 'Work',
            sub_category: 'Jobs' 
        },
        { 
            id: 16, 
            name: 'Requests',
            description: 'Access to the Requests area. Able to view all company requests.',
            created_at: new Date(),
            last_updated_at: new Date(),
            screen_access: ['requests'],
            action_access: [],
            identifier: 'requests',
            action_name: 'Area Access',
            action_order: 1,
            group_identifier: 'requests',
            group_name: 'Requests',
            version: 0.1,
            active: true,
            category: 'Work',
            sub_category: 'Requests' 
        },
        { 
            id: 17, 
            name: 'Request (Create)',
            description: 'Access to the New Request screen. Able to create a new company request.',
            created_at: new Date(),
            last_updated_at: new Date(),
            screen_access: ['requests_new'],
            action_access: [],
            identifier: 'request_create',
            action_name: 'Create',
            action_order: 2,
            group_identifier: 'requests',
            group_name: 'Requests',
            version: 0.1,
            active: true,
            category: 'Work',
            sub_category: 'Requests' 
        },
        { 
            id: 18, 
            name: 'Request (Read)',
            description: "Access to the Request Detail screen. Able to view a request in detail.",
            created_at: new Date(),
            last_updated_at: new Date(),
            screen_access: ['requests_detail'],
            action_access: [],
            identifier: 'request_read',
            action_name: 'Read',
            action_order: 3,
            group_identifier: 'requests',
            group_name: 'Requests',
            version: 0.1,
            active: true,
            category: 'Work',
            sub_category: 'Requests' 
        },
        { 
            id: 19, 
            name: 'Request (Update)',
            description: "Access to the Edit Request screen. Able to update a request and convert it to another item.",
            created_at: new Date(),
            last_updated_at: new Date(),
            screen_access: ['requests_edit'],
            action_access: [],
            identifier: 'request_update',
            action_name: 'Update',
            action_order: 4,
            group_identifier: 'requests',
            group_name: 'Requests',
            version: 0.1,
            active: true,
            category: 'Work',
            sub_category: 'Requests' 
        },
        { 
            id: 20, 
            name: 'Request (Delete)',
            description: "Able to delete a request.",
            created_at: new Date(),
            last_updated_at: new Date(),
            screen_access: [''],
            action_access: [],
            identifier: 'request_delete',
            action_name: 'Delete',
            action_order: 5,
            group_identifier: 'requests',
            group_name: 'Requests',
            version: 0.1,
            active: true,
            category: 'Work',
            sub_category: 'Requests' 
        },
        { 
            id: 21, 
            name: 'Routes',
            description: "Access to the Routes area. Able to view all company routes.",
            created_at: new Date(),
            last_updated_at: new Date(),
            screen_access: ['routes'],
            action_access: [],
            identifier: 'routes',
            action_name: 'Area Access',
            action_order: 1,
            group_identifier: 'routes',
            group_name: 'Routes',
            version: 0.1,
            active: true,
            category: 'Work',
            sub_category: 'Routes' 
        },
        { 
            id: 22, 
            name: 'Routes (Create)',
            description: "Access to the New Route screen. Able to create a new company route.",
            created_at: new Date(),
            last_updated_at: new Date(),
            screen_access: ['route_new'],
            action_access: [],
            identifier: 'route_create',
            action_name: 'Create',
            action_order: 2,
            group_identifier: 'routes',
            group_name: 'Routes',
            version: 0.1,
            active: true,
            category: 'Work',
            sub_category: 'Routes' 
        },
        { 
            id: 23, 
            name: 'Routes (Read)',
            description: "Access to the Route Detail screen. Able to view a route, it's information, recurring jobs, and route schedule.",
            created_at: new Date(),
            last_updated_at: new Date(),
            screen_access: ['route_detail'],
            action_access: [],
            identifier: 'route_read',
            action_name: 'Read',
            action_order: 3,
            group_identifier: 'routes',
            group_name: 'Routes',
            version: 0.1,
            active: true,
            category: 'Work',
            sub_category: 'Routes' 
        },
        { 
            id: 24, 
            name: 'Route (Update)',
            description: "Access to the Edit Route screen. Able to update a route, it's information, recurring jobs, and route schedule.",
            created_at: new Date(),
            last_updated_at: new Date(),
            screen_access: ['route_edit'],
            action_access: [],
            identifier: 'route_update',
            action_name: 'Update',
            action_order: 4,
            group_identifier: 'routes',
            group_name: 'Routes',
            version: 0.1,
            active: true,
            category: 'Work',
            sub_category: 'Routes' 
        },
        { 
            id: 25, 
            name: 'Route (Delete)',
            description: "Able to delete a company route.",
            created_at: new Date(),
            last_updated_at: new Date(),
            screen_access: [''],
            action_access: [],
            identifier: 'route_delete',
            action_name: 'Delete',
            action_order: 5,
            group_identifier: 'routes',
            group_name: 'Routes',
            version: 0.1,
            active: true,
            category: 'Work',
            sub_category: 'Routes' 
        },
        { 
            id: 26, 
            name: 'Schedule - Personal',
            description: "Access to the Schedule section. Able to view any jobs, requests, or events in a schedule view. Only able to view items assigned to themselves.",
            created_at: new Date(),
            last_updated_at: new Date(),
            screen_access: ['schedule_day','schedule_week','schedule_month'],
            action_access: [],
            identifier: 'schedule_personal',
            action_name: 'Personal Schedule',
            action_order: 1,
            group_identifier: 'schedule',
            group_name: 'Schedule',
            version: 0.1,
            active: true,
            category: 'Schedule',
            sub_category: '' 
        },
        { 
            id: 27, 
            name: 'Schedule - Company',
            description: "Access to the Schedule section. Able to view any jobs, requests, or events in a schedule view. Able to view all company items.",
            created_at: new Date(),
            last_updated_at: new Date(),
            screen_access: ['schedule_day','schedule_week','schedule_month'],
            action_access: [],
            identifier: 'schedule_company',
            action_name: 'Company Schedule',
            action_order: 2,
            group_identifier: 'schedule',
            group_name: 'Schedule',
            version: 0.1,
            active: true,
            category: 'Schedule',
            sub_category: '' 
        },
        { 
            id: 28, 
            name: 'Users',
            description: "Access to the Users Screen. Able to view all company users.",
            created_at: new Date(),
            last_updated_at: new Date(),
            screen_access: ['users'],
            action_access: [],
            identifier: 'team_users',
            action_name: 'Access',
            action_order: 1,
            group_identifier: 'users',
            group_name: 'Users',
            version: 0.1,
            active: true,
            category: 'Team',
            sub_category: 'Users' 
        },
        { 
            id: 29, 
            name: 'User (Create)',
            description: "Access to the New User screen. Able to create a new user. Warning, new users increase your company's seat amount which increases your subscription price.",
            created_at: new Date(),
            last_updated_at: new Date(),
            screen_access: ['users_new'],
            action_access: [],
            identifier: 'team_user_create',
            action_name: 'Create Access',
            action_order: 2,
            group_identifier: 'users',
            group_name: 'Users',
            version: 0.1,
            active: true,
            category: 'Team',
            sub_category: 'Users' 
        },
        { 
            id: 30, 
            name: 'User (Read)',
            description: "Access to the User Detail screen. Able to view a user's information, contact details, and more.",
            created_at: new Date(),
            last_updated_at: new Date(),
            screen_access: ['users_detail'],
            action_access: [],
            identifier: 'team_user_read',
            action_name: 'Read Access',
            action_order: 3,
            group_identifier: 'users',
            group_name: 'Users',
            version: 0.1,
            active: true,
            category: 'Team',
            sub_category: 'Users' 
        },
        { 
            id: 31, 
            name: 'User (Update)',
            description: "Access to the Edit User screen. Able to update a user's information, contact details, and more.",
            created_at: new Date(),
            last_updated_at: new Date(),
            screen_access: ['users_edit'],
            action_access: [],
            identifier: 'team_user_update',
            action_name: 'Update Access',
            action_order: 4,
            group_identifier: 'users',
            group_name: 'Users',
            version: 0.1,
            active: true,
            category: 'Team',
            sub_category: 'Users' 
        },
        { 
            id: 32, 
            name: 'User (Delete)',
            description: "Able to delete or deactivate users. Deactivating an active user decreases your subscription total. Deleting a user permanently removes all user information.",
            created_at: new Date(),
            last_updated_at: new Date(),
            screen_access: [''],
            action_access: [],
            identifier: 'team_user_delete',
            action_name: 'Delete Access',
            action_order: 5,
            group_identifier: 'users',
            group_name: 'Users',
            version: 0.1,
            active: true,
            category: 'Team',
            sub_category: 'Users' 
        },
        { 
            id: 33, 
            name: 'Roles',
            description: "Access to the Roles area. Able to view all the company's roles.",
            created_at: new Date(),
            last_updated_at: new Date(),
            screen_access: ['roles'],
            action_access: [],
            identifier: 'team_roles',
            action_name: 'Area Access',
            action_order: 1,
            group_identifier: 'roles',
            group_name: 'Roles',
            version: 0.1,
            active: true,
            category: 'Team',
            sub_category: 'Roles' 
        },
        { 
            id: 34, 
            name: 'Role (Create)',
            description: "Access to the New Role screen. Able to create a new role and assign permissions.",
            created_at: new Date(),
            last_updated_at: new Date(),
            screen_access: ['role_new'],
            action_access: [],
            identifier: 'team_role_create',
            action_name: 'Create',
            action_order: 2,
            group_identifier: 'roles',
            group_name: 'Roles',
            version: 0.1,
            active: true,
            category: 'Team',
            sub_category: 'Roles' 
        },
        { 
            id: 35, 
            name: 'Role (Read)',
            description: "Access to the Role Detail screen. Able to view a role's information, permissions, and users associated with it.",
            created_at: new Date(),
            last_updated_at: new Date(),
            screen_access: ['role_detail'],
            action_access: [],
            identifier: 'team_role_read',
            action_name: 'Read',
            action_order: 3,
            group_identifier: 'roles',
            group_name: 'Roles',
            version: 0.1,
            active: true,
            category: 'Team',
            sub_category: 'Roles' 
        },
        { 
            id: 36, 
            name: 'Role (Update)',
            description: "Access to the Edit Role screen. Able to update a role's information, permissions, and users associated with it.",
            created_at: new Date(),
            last_updated_at: new Date(),
            screen_access: ['role_edit'],
            action_access: [],
            identifier: 'team_role_update',
            action_name: 'Update',
            action_order: 4,
            group_identifier: 'roles',
            group_name: 'Roles',
            version: 0.1,
            active: true,
            category: 'Team',
            sub_category: 'Roles' 
        },
        { 
            id: 37, 
            name: 'Role (Delete)',
            description: "Able to delete existing roles.",
            created_at: new Date(),
            last_updated_at: new Date(),
            screen_access: [''],
            action_access: [],
            identifier: 'team_role_delete',
            action_name: 'Delete',
            action_order: 5,
            group_identifier: 'roles',
            group_name: 'Roles',
            version: 0.1,
            active: true,
            category: 'Team',
            sub_category: 'Roles' 
        },
        { 
            id: 38, 
            name: 'Estimates',
            description: "Access to the Estimates area. Able to view all company estimates.",
            created_at: new Date(),
            last_updated_at: new Date(),
            screen_access: ['estimates'],
            action_access: [],
            identifier: 'billing_estimates',
            action_name: 'Area Access',
            action_order: 1,
            group_identifier: 'estimates',
            group_name: 'Estimates',
            version: 0.1,
            active: true,
            category: 'Billing',
            sub_category: 'Estimates' 
        },
        { 
            id: 39, 
            name: 'Estimate (Create)',
            description: "Access to the New Estimate screen. Able to create new estimates.",
            created_at: new Date(),
            last_updated_at: new Date(),
            screen_access: ['estimate_new'],
            action_access: [],
            identifier: 'billing_estimate_create',
            action_name: 'Create',
            action_order: 2,
            group_identifier: 'estimates',
            group_name: 'Estimates',
            version: 0.1,
            active: true,
            category: 'Billing',
            sub_category: 'Estimates' 
        },
        { 
            id: 40, 
            name: 'Estimate (Read)',
            description: "Access to the Estimate Detail screen. Able to view an estimate and it's associated information.",
            created_at: new Date(),
            last_updated_at: new Date(),
            screen_access: ['estimate_detail'],
            action_access: [],
            identifier: 'billing_estimate_read',
            action_name: 'Read',
            action_order: 3,
            group_identifier: 'estimates',
            group_name: 'Estimates',
            version: 0.1,
            active: true,
            category: 'Billing',
            sub_category: 'Estimates' 
        },
        { 
            id: 41, 
            name: 'Estimate (Update)',
            description: "Access to the Edit Estimate screen. Able to update an estimate and it's associated information.",
            created_at: new Date(),
            last_updated_at: new Date(),
            screen_access: ['estimate_edit'],
            action_access: [],
            identifier: 'billing_estimate_update',
            action_name: 'Update',
            action_order: 4,
            group_identifier: 'estimates',
            group_name: 'Estimates',
            version: 0.1,
            active: true,
            category: 'Billing',
            sub_category: 'Estimates' 
        },
        { 
            id: 42, 
            name: 'Estimate (Delete)',
            description: "Able to delete estimates.",
            created_at: new Date(),
            last_updated_at: new Date(),
            screen_access: [''],
            action_access: [],
            identifier: 'billing_estimate_delete',
            action_name: 'Delete',
            action_order: 5,
            group_identifier: 'estimates',
            group_name: 'Estimates',
            version: 0.1,
            active: true,
            category: 'Billing',
            sub_category: 'Estimates' 
        },
        { 
            id: 43, 
            name: 'Invoices',
            description: "Access to the Invoices area. Able to view all company invoices.",
            created_at: new Date(),
            last_updated_at: new Date(),
            screen_access: ['invoices'],
            action_access: [],
            identifier: 'billing_invoices',
            action_name: 'Access',
            action_order: 1,
            group_identifier: 'invoices',
            group_name: 'Invoices',
            version: 0.1,
            active: true,
            category: 'Billing',
            sub_category: 'Invoices' 
        },
        { 
            id: 44, 
            name: 'Invoice (Create)',
            description: "Access to the New Invoice screen. Able to create new invoices.",
            created_at: new Date(),
            last_updated_at: new Date(),
            screen_access: ['invoice_new'],
            action_access: [],
            identifier: 'billing_invoice_create',
            action_name: 'Create',
            action_order: 2,
            group_identifier: 'invoices',
            group_name: 'Invoices',
            version: 0.1,
            active: true,
            category: 'Billing',
            sub_category: 'Invoices' 
        },
        { 
            id: 45, 
            name: 'Invoice (Read)',
            description: "Access to the Invoice Detail screen. Able to view an invoice and it's associated information.",
            created_at: new Date(),
            last_updated_at: new Date(),
            screen_access: ['invoice_detail'],
            action_access: [],
            identifier: 'billing_invoice_read',
            action_name: 'Read',
            action_order: 3,
            group_identifier: 'invoices',
            group_name: 'Invoices',
            version: 0.1,
            active: true,
            category: 'Billing',
            sub_category: 'Invoices' 
        },
        { 
            id: 46, 
            name: 'Invoice (Update)',
            description: "Access to the Edit Invoice screen. Able to update an invoices and it's associated information.",
            created_at: new Date(),
            last_updated_at: new Date(),
            screen_access: ['invoice_edit'],
            action_access: [],
            identifier: 'billing_invoice_update',
            action_name: 'Update',
            action_order: 4,
            group_identifier: 'invoices',
            group_name: 'Invoices',
            version: 0.1,
            active: true,
            category: 'Billing',
            sub_category: 'Invoices' 
        },
        { 
            id: 47, 
            name: 'Invoice (Delete)',
            description: "Able to delete invoices.",
            created_at: new Date(),
            last_updated_at: new Date(),
            screen_access: [''],
            action_access: [],
            identifier: 'billing_invoice_delete',
            action_name: 'Delete',
            action_order: 5,
            group_identifier: 'invoices',
            group_name: 'Invoices',
            version: 0.1,
            active: true,
            category: 'Billing',
            sub_category: 'Invoices' 
        },
        { 
            id: 48, 
            name: 'Items',
            description: "Access to the Billing Items area. Able to view all products and services for the company.",
            created_at: new Date(),
            last_updated_at: new Date(),
            screen_access: ['items'],
            action_access: [],
            identifier: 'billing_items',
            action_name: 'Area Access',
            action_order: 1,
            group_identifier: 'items',
            group_name: 'Items',
            version: 0.1,
            active: true,
            category: 'Billing',
            sub_category: 'Items' 
        },
        { 
            id: 49, 
            name: 'Item (Create)',
            description: "Able to create new Billing Items around the platform. Access in Job Line Items, Estimate Line Items, Invoice Line Items, or the Items area.",
            created_at: new Date(),
            last_updated_at: new Date(),
            screen_access: ['item_new'],
            action_access: [],
            identifier: 'billing_item_create',
            action_name: 'Create',
            action_order: 2,
            group_identifier: 'items',
            group_name: 'Items',
            version: 0.1,
            active: true,
            category: 'Billing',
            sub_category: 'Items' 
        },
        { 
            id: 50, 
            name: 'Item (Read)',
            description: "Able to view Billing Items around the platform. Access in Job Line Items, Estimate Line Items, Invoice Line Items, or the Items area.",
            created_at: new Date(),
            last_updated_at: new Date(),
            screen_access: ['item_detail'],
            action_access: [],
            identifier: 'billing_item_read',
            action_name: 'Read',
            action_order: 3,
            group_identifier: 'items',
            group_name: 'Items',
            version: 0.1,
            active: true,
            category: 'Billing',
            sub_category: 'Items' 
        },
        { 
            id: 51, 
            name: 'Item (Update)',
            description: "Able to update Billing Items around the platform. Access in Job Line Items, Estimate Line Items, Invoice Line Items, or the Items area.",
            created_at: new Date(),
            last_updated_at: new Date(),
            screen_access: ['item_edit'],
            action_access: [],
            identifier: 'billing_item_update',
            action_name: 'Update',
            action_order: 4,
            group_identifier: 'items',
            group_name: 'Items',
            version: 0.1,
            active: true,
            category: 'Billing',
            sub_category: 'Items' 
        },
        { 
            id: 52, 
            name: 'Item (Delete)',
            description: "Able to delete a Billing Item.",
            created_at: new Date(),
            last_updated_at: new Date(),
            screen_access: [''],
            action_access: [],
            identifier: 'billing_item_delete',
            action_name: 'Delete',
            action_order: 5,
            group_identifier: 'items',
            group_name: 'Items',
            version: 0.1,
            active: true,
            category: 'Billing',
            sub_category: 'Items' 
        },
        { 
            id: 53, 
            name: 'Customers',
            description: "Access to the Customers section. Able to view all customers' accounts, contacts, and properties.",
            created_at: new Date(),
            last_updated_at: new Date(),
            screen_access: ['customers'],
            action_access: [],
            identifier: 'customers',
            action_name: 'Area Access',
            action_order: 1,
            group_identifier: 'customers',
            group_name: 'Customers',
            version: 0.1,
            active: true,
            category: 'Customers',
            sub_category: '' 
        },
        { 
            id: 54, 
            name: 'Customer (Create)',
            description: "Access to New Account, New Contact, and New Properties screens. Able to create a new customer's account, contact, or property.",
            created_at: new Date(),
            last_updated_at: new Date(),
            screen_access: ['customer_new'],
            action_access: [],
            identifier: 'customer_create',
            action_name: "Create",
            action_order: 2,
            group_identifier: 'customers',
            group_name: 'Customers',
            version: 0.1,
            active: true,
            category: 'Customers',
            sub_category: '' 
        },
        { 
            id: 55, 
            name: 'Customer (Read)',
            description: "Access to Account Detail, Contact Detail, and Property Detail screens. Able to view a customer's account, contact, or property in detail.",
            created_at: new Date(),
            last_updated_at: new Date(),
            screen_access: ['customer_detail'],
            action_access: [],
            identifier: 'customer_read',
            action_name: 'Read',
            action_order: 3,
            group_identifier: 'customers',
            group_name: 'Customers',
            version: 0.1,
            active: true,
            category: 'Customers',
            sub_category: '' 
        },
        { 
            id: 56, 
            name: 'Customer (Update)',
            description: "Access to Edit Account, Edit Contact, and Edit Property screens. Able to update a customer's account, contact, or property.",
            created_at: new Date(),
            last_updated_at: new Date(),
            screen_access: ['customer_edit'],
            action_access: [],
            identifier: 'customer_update',
            action_name: 'Update',
            action_order: 4,
            group_identifier: 'customers',
            group_name: 'Customers',
            version: 0.1,
            active: true,
            category: 'Customers',
            sub_category: '' 
        },
        { 
            id: 57, 
            name: 'Customer (Delete)',
            description: "Able to delete a customer's account, contact, or property.",
            created_at: new Date(),
            last_updated_at: new Date(),
            screen_access: [''],
            action_access: [],
            identifier: 'customer_delete',
            action_name: 'Delete',
            action_order: 5,
            group_identifier: 'customers',
            group_name: 'Customers',
            version: 0.1,
            active: true,
            category: 'Customers',
            sub_category: '' 
        },
        { 
            id: 58, 
            name: 'Reports',
            description: "Access to the Reports area. Able to view all company reports.",
            created_at: new Date(),
            last_updated_at: new Date(),
            screen_access: ['reports'],
            action_access: [],
            identifier: 'reports_read',
            action_name: 'Area Access',
            action_order: 1,
            group_identifier: 'reports',
            group_name: 'Reports',
            version: 0.1,
            active: true,
            category: 'Reports',
            sub_category: '' 
        }
    ]
}