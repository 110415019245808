// Import Modules
import React from 'react';

// Import Custom Classes
import SettingsBreadcrumbs from '../../../../Components/UI/SettingsBreadcrumbs';
import * as ROUTES from '../../../../Constants/routes';


const SupportSettingsScreen = (props) => {

    return (
        <div className="BaseScreen">
            <SettingsBreadcrumbs
                screenTitle='Product Support'
                screenDescription='Send a message to our support team about bugs, feature requests, or help.'
                route={ROUTES.PP}
                fromScreenTitle='User Settings'
                fromScreenRoute={ROUTES.SETTINGS_USER}
            />
        </div>
    );
};
export default SupportSettingsScreen;