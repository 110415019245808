import React, { Fragment } from 'react';
import clsx from 'clsx';

const DeleteSettingsBoxButton = (props) => {

    return (
        <Fragment>
            <div className='SettingsBoxLine' style={{ marginBottom: '0px', marginTop: '20px' }} />
            <div style={{ textAlign: 'right' }} className={clsx('Margin--Right--15', 'Margin--Vertical--15')}>
                <button
                    className={clsx({
                        "DeleteSettingsBoxButton": true,
                        'DisabledButton': (props.isDisabled),
                        'NotDisabledButton': (!props.isDisabled)
                    })}
                    onClick={props.handleSubmit}
                    disabled={props.isDisabled}
                    type='button'
                >
                    <p
                        className={clsx({
                            'TextButton': true,
                            'BodyText': true,
                            'BoldText': !props.isDisabled,
                            'SemiBoldText': props.isDisabled,
                            'WhiteTint': !props.isDisabled,
                            'DirtyWhiteTint': props.isDisabled
                        })}
                        style={{ padding: '5px' }}
                    >
                        Delete Account
                    </p>
                </button>
            </div>

        </Fragment>
    );
};

export default DeleteSettingsBoxButton;