import React from 'react';
import _ from 'lodash';
import clsx from 'clsx';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';

import HelpPopOver from '../MaterialUI/HelpPopOver';


const PermissionsGroupCell = (props) => {



    if (props.data === null || _.isEmpty(props.data)) {
        return null;
    }

    const handlePermissionsArea = () => {

        return props.data.permissions.map((permission) => {


            return (
                <div key={permission.id} className={clsx('Margin--Horizontal--10', 'Padding--Vertical--5', 'Display--Flex', 'Flex--SpaceBetween')}>
                    <div className={clsx('Display--Flex', 'Flex--Start')}>
                            <p className={clsx('Margin--Vertical--Auto', 'SemiBoldText', 'BlackTint')} style={{marginRight: '5px'}}>
                                {permission.action_name || ''}
                            </p>
                            <HelpPopOver 
                                helpText={permission.description}
                            />
                        </div>
                    <button 
                        className={clsx({
                            'BackgroundColor--None' : true, 
                            'Border--None' : true, 
                            'Border--All--None' : true, 
                            'Padding--Vertical--0' : true, 
                            'Padding-Horizontal--0' : true, 
                            'Pointer--Cursor' : props.isEditable,
                            'Opacity--100' : props.isEditable,
                            'Opacity--070' : !props.isEditable
                            })}
                        onClick={() => props.onPermissionButtonClicked(permission)}
                        disabled={!props.isEditable}
                    >
                        {(permission.selected) ?
                            <RadioButtonCheckedIcon style={{ color: '#07689F', fontSize: '30', fontWeight: '600' }} /> :
                            <RadioButtonUncheckedIcon style={{ color: '#444444', fontSize: '30', fontWeight: '600' }} />
                        }

                    </button>
                </div>
            );
        });
    };



    return (
        <div className={clsx(
            'Margin--Horizontal--0',
            'Margin--Bottom--0', 
            'TextAlignLeft', 
            'Border--All--1', 
            'BorderColor--DarkBlueTint', 
            'BorderWidth--All--1', 
            'BorderRadius--All--10', 
            'Overflow--Hidden')} 
        >
            
            <p className={clsx(
                'DarkGreyTint', 
                'BodyText', 
                'SemiBoldText', 
                'Padding--Horizontal--10', 
                'Padding--Vertical--5', 
                'BackgroundColor--GreyTint2')}
            >
                Permission Group
            </p>
            <div className={clsx('Border--Bottom--1', 'BorderColor--DarkGreyTint')} />
            <div className={clsx('Padding--Vertical--15')} >
                <p className={clsx('DarkBlueTint', 'SubTitle', 'BoldText', 'Padding--Horizontal--10')}>
                    {props.data.group_name}
                </p>
            </div>
            <div className={clsx('Border--Bottom--1', 'BorderColor--DarkGreyTint')} />
            <p className={clsx(
                'DarkGreyTint', 
                'BodyText', 
                'SemiBoldText', 
                'Padding--Horizontal--10', 
                'Padding--Vertical--5', 
                'BackgroundColor--GreyTint2')}
            >
                Permissions
            </p>
            <div className={clsx('Border--Bottom--1', 'BorderColor--DarkGreyTint')} />
            <div className={'Padding--Vertical--5'}>
                {handlePermissionsArea()}
            </div>
        </div>
    );
};

export default PermissionsGroupCell;