import React from 'react';
import clsx from 'clsx';
import FontAwesome from 'react-fontawesome';

const SettingsOutlinedButton = (props) => {

    return (
        <button
            className={ clsx({ 
                "BackgroundColor--None": true, 
                "BorderHover--All--Blue": true, 
                "BorderRadius--All--5": true,
                "Width--Full-40": true,
                "Margin--Vertical--10": true,
                "Margin--Horizontal--15": true,
                "Padding--Vertical--0": true,
                "Padding--Horizontal--20": true,
                'DisabledButton': (props.disabled), 
                'NotDisabledButton': (!props.disabled) 
            })}
            onClick={props.onClick}
            disabled={props.disabled}
            type='button'
        >
            <div className={clsx('Display--Flex', 'Flex--SpaceBetween', 'Width--Full')}>
                <p 
                    className={clsx({ 
                        'TextButton': true, 
                        'BodyText': true, 
                        'SemiBoldText': true, 
                        'TextAlignLeft': true,
                        'DarkBlueTint': !props.disabled, 
                        'GreyTint': props.disabled ,
                        'Margin--Vertical--15': true,
                        'Margin--Horizontal--0': true
                    })} 
                >
                        {props.label}
                </p>
                <FontAwesome 
                    name='angle-right'
                    hidden={false}
                    size="3x"
                    style={{color: props.color || '#002D47'}}
                />
            </div>
            
        </button>
    )
};

export default SettingsOutlinedButton;