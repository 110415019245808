// Import Modules
import React, { Fragment } from 'react';
import FormTextField from "../MaterialUI/FormTextField";
import SubmitButton from '../Buttons/SubmitButton';
import ResendVerificationCodeButton from '../Buttons/ResendVerificationCodeButton';
import PulseLoader from 'react-spinners/PulseLoader';

const SignUpForm2 = (props) => {

    // Handle Error Area
    let errorComponent = null;

    if (props.errorValue) {
        errorComponent = (
            <p className="ErrorBody">
                {(props.errorValue !== null) ? props.errorValue.message : null}
            </p>
        );
    }

    // Handle Loading Animation
    let loadingArea = null;

    if (props.isLoading) {
        loadingArea = (
            <PulseLoader
                css={`margin-top: 20px;`}
                size={18}
                sizeUnit={"px"}
                margin="5px"
                color={'#0A3F79'}
                loading={true}
            />
        );
    }

    return (
        <Fragment>
            {loadingArea}
            <form
                className="SignUpForm"
                noValidate
                autoComplete='off'
            >
                <div className='inputBoxes'>
                    {errorComponent}

                    <FormTextField
                        label='Email'
                        value={props.emailValue}
                        type='email'
                        variant="outlined"
                        fullWidth
                        margin='normal'
                        required
                        disabled={true}
                    />

                    <FormTextField
                        label='Verification Code'
                        value={props.passwordValue}
                        onChange={event => props.onVerificationCodeChange(event)}
                        onKeyPress={(event) => props.onVerificationCodeKeyPressChecker(event)}
                        type='number'
                        variant="outlined"
                        fullWidth
                        autoComplete='false'
                        margin='normal'
                        required
                    />
                </div>

                <div className="ButtonsArea" style={{display: 'grid'}}>
                    <ResendVerificationCodeButton
                        handleSubmit={props.handleResendCode}
                    />

                    <SubmitButton
                        handleSubmit={props.handleSubmit}
                        isInvalid={props.isInvalid}
                        isLoading={props.isLoading}
                    />
                </div>

            </form>
        </Fragment>
    );
};

export default SignUpForm2;