// Import Modules
import React from 'react';
import FontAwesome from 'react-fontawesome';
import clsx from 'clsx';
import GoBackButton from '../../../Components/Buttons/GoBackButton';
import DashboardButton from '../../../Components/Buttons/DashboardButton';
import { useHistory } from 'react-router-dom';
import * as ROUTES from '../../../Constants/routes';

const NotFoundScreen = (props) => {

    let history = useHistory();

    const goBack = () => {
        history.goBack();
    }

    const goToHome = () => {
        history.push(ROUTES.HOME);
    }

    return (
        <div className="BaseScreen">
            <div className='TitleContainer'>
                <FontAwesome
                    name='ban'
                    hidden={false}
                    size="5x"
                    style={{ marginTop: '25px', marginBottom: '25px', color: props.color || '#002D47' }}
                />
                {/* <ErrorIcon style={{ color: props.color || '#002D47', fontSize: '36', fontWeight: '600', marginTop: '25px', marginBottom: '25px' }} /> */}
                <p className={clsx('BigTitle', 'DarkBlueTint', 'TextAlignCentered')}>
                    Page not found.
                </p>
                <p className={clsx('SubTitleSmall', 'DarkBlueTint', 'TextAlignCentered')} style={{ margin: 'auto', marginTop: '20px', marginBottom: '40px', maxWidth: '85%' }}>
                    The link you followed is broken or removed. If you think this is an error please contact your company's admin or product support.
                </p>
                <div style={{display: 'inline', justifyContent: 'center'}}>
                    <GoBackButton 
                        handleSubmit={goBack}
                    />
                    <DashboardButton 
                        handleSubmit={goToHome}
                    />
                </div>
            </div>
        </div>
    );
};

export default NotFoundScreen;
