// Import Modules
import React from 'react';
import clsx from 'clsx';

// Import Custom Components
import Box from './Box';
import BoxFooterButton from '../Buttons/BoxFooterButton';


const NewItemBox = (props) => {

    return (
        <Box className='NewItemBox'>
            <div className={clsx('Padding--Vertical--15', 'Padding--Horizontal--20')}>
                <p className={clsx('SubTitle', 'DarkBlueTint', 'SemiBoldText', 'TextAlignLeft')} >
                    {props.title || ''}
                </p>
                {props.description ? 
                    <p className={clsx('BodyText', 'DarkGreyTint', 'NormalText', 'TextAlignLeft', 'Margin--Top--5')}>
                        {props.description}
                    </p>
                : null}
            </div>
            <div className='SettingsBoxLine'/>
            <div className='NewItemBox_Contents'>
                {props.children}
            </div>
            <div className='SettingsBoxLine'/>
            <div className='NewItemBox_Footer'>
                <BoxFooterButton 
                    title={props.cancelText || ''}
                    titleClasses={['DarkBlueTint', 'SemiBoldText']}
                    buttonStyles={{backgroundColor: 'white'}}
                    onClick={props.onCancelClicked}
                    isDisabled={props.isCancelDisabled || false}
                />
                <BoxFooterButton 
                    title={props.actionText || ''}
                    titleClasses={['WhiteTint', 'BoldText']}
                    buttonStyles={{marginLeft: '8px'}}
                    onClick={props.onActionClicked}
                    isDisabled={props.isActionDisabled || false}
                />
            </div>
        </Box>
    );
};

export default NewItemBox;