import React from 'react';
import clsx from 'clsx';
import _ from 'lodash';
import InfiniteScroll from 'react-infinite-scroller';
import PulseLoader from 'react-spinners/PulseLoader';


import RoleDetailUsersListCell from '../../Components/Cells/RoleDetailUsersListCell';

const RoleDetailUsersView = (props) => {

    if (props.data === null || _.isEmpty(props.data)) {
        return (
            <div className='RoleDetailUsersView' style={{backgroundColor: 'white'}}>
                <p 
                    className={clsx('SubTitleSmall', 'NormalText', 'TextAlignCenter', 'DarkGreyTint')} 
                    style={{marginTop: '10px', marginBottom: '10px'}}
                >
                    No users with this role.
                </p>
            </div>
        );
    }


    let showChangeRoleArea = false;

    if (props.auth) {
        if (props.auth.role_permissions.includes('team_user_update')) {
            showChangeRoleArea = true;
        }
    }



    return (
        <div className='RoleDetailUsersView'>
            <InfiniteScroll
                pageStart={0}
                loadMore={props.loadMore}
                hasMore={false}
                loader={
                    <PulseLoader
                        key='pulserLoaderKey'
                        css={`margin: 10px;`}
                        size={20}
                        sizeUnit={"px"}
                        margin="5px"
                        color={'#0A3F79'}
                        loading={true}
                    />
                }
            >
                {props.data.map((user) => {
                    return (
                        <RoleDetailUsersListCell
                            key={user.id}
                            data={user}
                            onClick={() => props.onUserClicked(user)}
                            onChangeUserRoleClicked={(user) => props.onChangeUserRoleClicked(user)}
                            showChangeRoleArea={showChangeRoleArea}
                        />
                    );
                })}
            </InfiniteScroll>
        </div>
    );
};

export default RoleDetailUsersView;
