import React from 'react';
import clsx from 'clsx';


const SignUp1Button = (props) => {

    let isDisabled = props.isInvalid;

    if (props.isLoading !== null) {
        if (props.isLoading) {
            isDisabled = true;
        }
    }
    return (
        <button
            className={ clsx("SignUp1Button", "Completed") }
            onClick={props.handleSubmit}
            type='button'
            disabled={isDisabled}

        >
            <p className="LargeButtonText">
                Start Free Trial
            </p>
        </button>
    );
};

export default SignUp1Button;