import React, { Fragment } from 'react';
import clsx from 'clsx';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { LinkContainer } from 'react-router-bootstrap';
import Link from '@material-ui/core/Link';
import * as ROUTES from '../../Constants/routes';


const SettingsBreadcrumbs = (props) => {

    return (
        <Fragment>
            <div className='SettingsBreadCrumbsContainer'>
                <Breadcrumbs>
                    <LinkContainer to={props.fromScreenRoute || ROUTES.SETTINGS} exact>
                        <Link className={clsx('SubTitle')}>
                            <span className={clsx('NormalText', 'DarkBlueTint')}>{props.fromScreenTitle || 'Settings'}</span>
                        </Link>
                    </LinkContainer>
                    <LinkContainer to={props.route}>
                        <Link className={clsx('SubTitle', 'SemiBoldText', 'BlueTint')}>
                            {props.screenTitle}
                        </Link>
                    </LinkContainer>
                </Breadcrumbs>
                <p className={clsx('BodyText', 'DarkBlueTint', 'TextAlignLeft')} style={{paddingTop: '10px', paddingBottom: '20px', maxWidth: '80%'}}>
                    {props.screenDescription}
                </p>
            </div>
            <div className='SettingsBreadCrumbDivider'/>
        </Fragment>
    );
};

export default SettingsBreadcrumbs;