import React, { useState, Fragment } from 'react';
import clsx from 'clsx';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Edit from '@material-ui/icons/Edit';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const ScreenDetailHeader = (props) => {

    const [anchorEl, setAnchorEl] = useState(null);

    const onMoreButtonClicked = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const onMoreButtonClosed = () => {
        setAnchorEl(null);
    };

    /**
     * Props(
     * screenTitle: String,
     * actionText: String,
     * isActionHidden: Boolean
     * isActionDisabled: Boolean,
     * isMoreDisabled: Boolean,
     * moreText: String,
     * backText: String,
     * onBackClicked: Function,
     * onActionClicked: Function,
     * layoutWidth: Int,
     * moreMenuActions: [{text: String, onItemClick: ()=>{}}]
     * )
    */


    const handleBackButton = () => {
        let resultButton = null;

        if (props.layoutWidth >= 500) {
            resultButton = (
                <button 
                    className={clsx(
                        'Display--Flex', 
                        'BackgroundColor--BlueTint', 
                        'Border--All--1', 
                        'BorderRadius--All--5', 
                        'BorderColor--ButtonGreyTint',
                        'Padding--Horizontal--12', 
                        'Padding--Vertical--10', 
                        'Margin--Vertical--Auto',
                        'Pointer--Cursor',
                        'BoxShadow--0-5')}
                    onClick={props.onBackClicked}
                >
                    <ArrowBackIosIcon style={{ color: 'white', fontSize: '19', fontWeight: '600', marginTop: 'auto', marginBottom: 'auto' }} />
                    <p className={clsx('ButtonText', 'WhiteTint', 'SemiBoldText', 'TextAlignLeft', 'Margin--Vertical--Auto', 'LineHeight--Inherit')}>
                        {props.backText || 'Back'}
                    </p>
                </button>
            );
        } else {
            resultButton = (
                <button 
                    className={clsx(
                        'Display--Flex', 
                        'BackgroundColor--BlueTint', 
                        'Border--All--1', 
                        'BorderRadius--All--Half', 
                        'BorderColor--ButtonGreyTint',
                        'Padding--Horizontal--12', 
                        'Padding--Vertical--12', 
                        'Margin--Vertical--Auto',
                        'Pointer--Cursor',
                        'BoxShadow--0-5')}
                    onClick={props.onBackClicked}
                >
                    <ArrowBackIcon style={{ color: 'white', fontSize: '24', fontWeight: '600', marginTop: 'auto', marginBottom: 'auto' }} />
                </button>
            );
        }

        return resultButton;
    }

    const handleActionButton = () => {
        let resultButton = null;

        if (props.layoutWidth >= 500) {
            if (!props.isActionHidden) {
                resultButton = (
                    <button 
                        className={clsx({
                            'Display--Flex': true, 
                            'BackgroundColor--GreenTint': !props.isActionDisabled, 
                            'BackgroundColor--GreyTint': props.isActionDisabled, 
                            'Border--All--1': true, 
                            'BorderColor--ButtonGreyTint': true,
                            'BorderRadius--All--5': true, 
                            'Padding--Horizontal--12': true, 
                            'Padding--Vertical--10': true, 
                            'Margin--Vertical--Auto': true,
                            'Pointer--Cursor': !props.isActionDisabled,
                            'BoxShadow--0-5': !props.isActionDisabled
                            })} 
                        style={{ minWidth: '120px' }}
                        onClick={props.onActionClicked}
                        disabled={props.isActionDisabled}
                    >
                        <p className={clsx('ButtonText', 'WhiteTint', 'SemiBoldText', 'TextAlignLeft', 'Margin--Vertical--Auto', 'LineHeight--Inherit', 'Margin--Right--5')} >
                            {props.actionText || ''}
                        </p>
                        {props.actionIcon ? props.actionIcon : <Edit style={{ color: 'white', fontSize: '19', fontWeight: '600', marginTop: 'auto' }} />}
                    </button>
                );
            }
        } else {
            if (!props.isActionHidden) {
                resultButton = (
                    <button 
                        className={clsx({
                            'Display--Flex': true, 
                            'BackgroundColor--GreenTint': true, 
                            'Border--All--1': true, 
                            'BorderColor--ButtonGreyTint': true,
                            'BorderRadius--All--Half': true,
                            'Padding--Horizontal--12': true, 
                            'Padding--Vertical--12': true, 
                            'Margin--Vertical--Auto': true,
                            'Pointer--Cursor': !props.isActionDisabled,
                            'BoxShadow--0-5': !props.isActionDisabled
                            })}
                        onClick={props.onActionClicked}
                        disabled={props.isActionDisabled}
                    >
                        {props.actionIcon ? props.actionIcon : <Edit style={{ color: 'white', fontSize: '24', fontWeight: '600', marginTop: 'auto', marginBottom: 'auto' }} />}
                    </button>
                );
            }
        }

        return resultButton;
    }

    const handleMoreButton = () => {
        let resultButton = null;

        if (props.layoutWidth >= 500) {
            if (!props.isMoreDisabled) {
                resultButton = (
                    <Fragment>
                        <div className='Spacer--Width--20' />

                        <button
                            className={clsx(
                                'Display--Flex',
                                'BackgroundColor--WhiteTint',
                                'Border--All--1',
                                'BorderRadius--All--5',
                                'BorderColor--ButtonGreyTint',
                                'Padding--Horizontal--12',
                                'Padding--Vertical--10',
                                'Margin--Vertical--Auto',
                                'Pointer--Cursor',
                                'BoxShadow--0-5')}
                            style={{ minWidth: '155px' }}
                            onClick={(event) => onMoreButtonClicked(event)}
                        >

                            <p 
                                className={clsx(
                                    'ButtonText', 
                                    'BlueTint', 
                                    'SemiBoldText', 
                                    'TextAlignLeft', 
                                    'Margin--Vertical--Auto', 
                                    'LineHeight--Inherit', 
                                    'Margin--Right--5'
                                )}
                            >
                                {props.moreText || ''}
                            </p>

                            <MoreHorizIcon 
                                style={{ 
                                    color: '#07689F', 
                                    fontSize: '19', 
                                    fontWeight: '600', 
                                    marginTop: 'auto', 
                                    marginBottom: 'auto'
                                }} 
                            />

                        </button>
                    </Fragment>
                );
            }
        } else {
            if (!props.isMoreDisabled) {
                resultButton = (
                    <Fragment>
                        <div className='Spacer--Width--20' />
                        
                        <button
                            className={clsx(
                                'Display--Flex',
                                'BackgroundColor--WhiteTint',
                                'Border--All--1',
                                'BorderRadius--All--Half',
                                'BorderColor--BlueTint',
                                'Padding--Horizontal--12',
                                'Padding--Vertical--12',
                                'Margin--Vertical--Auto',
                                'BoxShadow--0-5')}
                            onClick={(event) => onMoreButtonClicked(event)}
                        >

                            <MoreHorizIcon 
                                style={{ 
                                    color: '#07689F', 
                                    fontSize: '24', 
                                    fontWeight: '600', 
                                    marginTop: 'auto', 
                                    marginBottom: 'auto' 
                                }} 
                            />

                        </button>
                    </Fragment>
                );
            }
        }

        return resultButton;
    }

    const handleMenu = () => {
        let resultMenu = null;

        if (!props.isMoreDisabled) {
            if (props.menuData) {
                resultMenu = (
                    <Menu
                        id="menu-appbar"
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        getContentAnchorEl={null}
                        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                        transformOrigin={{ vertical: "top", horizontal: "left" }}
                        keepMounted
                        onClose={onMoreButtonClosed}
                    >
                        {props.menuData.map((menuItem) => {
                            return (
                                <MenuItem 
                                    key={menuItem.text} 
                                    onClick={()=> {  
                                        onMoreButtonClosed();
                                        menuItem.onItemClicked();
                                    }}
                                >
                                    {menuItem.text}
                                </MenuItem>
                            );
                        })}
                    </Menu>
                );
            }
        }

        return resultMenu;
    }


    return (
        <div className={clsx('Padding--Vertical--20', 'Padding--Horizontal--20', 'Display--Flex', 'Flex--SpaceBetween')}>
            <div className={clsx('Display--Flex','Flex--Start')}>
                {handleBackButton()}
                <p 
                    className={clsx(
                        'SubTitle', 
                        'DarkGreyTint', 
                        'BoldText', 
                        'TextAlignLeft', 
                        'Margin--Vertical--Auto', 
                        'Padding--Horizontal--15', 
                        'LineHeight--Inherit', 
                        'TextBreak')}
                >
                    {props.screenTitle || ''}
                </p>
            </div>
            <div className={clsx('Display--Flex', 'Flex--End')}>
                {handleActionButton()}
                {handleMoreButton()}
                {handleMenu()}
            </div>
        </div>
    );
};

export default ScreenDetailHeader;