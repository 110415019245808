// Import Modules
import React from 'react';

// Import Custom Classes
import SettingsBreadcrumbs from '../../../../Components/UI/SettingsBreadcrumbs';
import * as ROUTES from '../../../../Constants/routes';


const ChangePasswordScreen = (props) => {

    return (
        <div className="BaseScreen">
            <SettingsBreadcrumbs
                screenTitle='Change Password'
                screenDescription='Change your account password here.'
                route={ROUTES.CHANGE_PASSWORD}
                fromScreenTitle='User Settings'
                fromScreenRoute={ROUTES.SETTINGS_USER}
            />
        </div>
    );
};
export default ChangePasswordScreen;