import React, { Fragment, useState, useEffect } from 'react';
import Masonry from 'react-masonry-css';
import clsx from 'clsx';
import PulseLoader from 'react-spinners/PulseLoader';
import { useSelector } from 'react-redux';
import SettingsTextField from "../MaterialUI/SettingsTextField";
import EditSettingsBoxButton from '../Buttons/EditSettingsBoxButton';
import SettingsSelect from "../MaterialUI/SettingsSelect";
import USStates from '../../Constants/USStates';

let countryOptions = [
    {value: 'US', label: 'United States'},
];


const ContactAddressForm = (props) => {

    // React Hooks State Management
    const [line1, setLine1] = useState('');
    const [line2, setLine2] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState({value: '', label: ''});
    const [zipCode, setZipCode] = useState('');
    const [country, setCountry] = useState({value: 'US', label: 'United States'});

    // Get Auth for ID
    const auth = useSelector(state => state.auth.userInfo.auth);
    const layoutWidth = useSelector(state => state.ui.layout.layoutWidth);


    // Handle Loading Animation
    let loadingArea = null;

    if (props.loading) {
        loadingArea = (
            <PulseLoader
                css={`margin-top: 20px; margin-bottom: 10px;`}
                size={20}
                sizeUnit={"px"}
                margin="5px"
                color={'#0A3F79'}
                loading={true}
            />
        );
    }


    useEffect(() => {

        // Handle Setup
        if (props.data) {
            if (props.data.primary_address) {
                if (props.data.primary_address.line1) {
                    setLine1(props.data.primary_address.line1);
                }
        
                if (props.data.primary_address.line2) {
                    setLine2(props.data.primary_address.line2);
                }
        
                if (props.data.primary_address.city) {
                    setCity(props.data.primary_address.city);
                }
        
                if (props.data.primary_address.state) {
                    const currentState = USStates.stateOptions.find(obj => obj.value === props.data.primary_address.state);
                    setState(currentState);
                }
        
                
                if (props.data.primary_address.zipCode) {
                    if (props.data.primary_address.zipCode !== "") {
                        setZipCode(parseInt(props.data.primary_address.zipCode));
                    }
                }
        
                if (props.data.primary_address.country) {
                    const country = countryOptions.find(obj => obj.value === props.data.primary_address.country);
                    setCountry(country);
                }
            }
        }
    }, [props.data]);



    let errorComponent = null;

    // Show any errors at top of the form
    if (props.error) {
        errorComponent = (
            <p className={clsx('BodyText', 'RedTint')} style={{ paddingTop: '15px' }}>
                {props.error.message}
            </p>
        );
    }

    // Handle Form Actions
    const onLine1Change = (line1) => {
        setLine1(line1);
    };

    const onLine2Change = (line2) => {
        setLine2(line2);
    };

    const onCityChange = (city) => {
        setCity(city);
    };

    const onStateChange = (state) => {
        setState(state);
    };

    const onZipCodeChange = (zipCode) => {
        setZipCode(zipCode);
    };

    const onCountryChange = (country) => {
        setCountry(country);
    };



    const onSubmit = () => {

        if (checkIsValid()) {
            let new_primary_address = {
                line1: line1 || '',
                line2: line2 || '',
                city: city || '',
                state: state.value || '',
                zipCode: zipCode || '',
                country: country.value || 'US'
            }
    
            let contactInput = {
                id: props.data.user_id,
                company_id: auth.company_id,
                first_name: undefined,
                last_name: undefined,
                full_name: undefined,
                display_name: undefined,
                phone: undefined,
                notes: undefined,
                primary_address: new_primary_address,
                preferred_language: undefined,
                time_zone: undefined,
                last_updated_by_id: auth.user_id.toString()
            };
    
            props.onUpdateUser(contactInput);
        }

        
    };

    const checkIsValid = () => {
        let result = false;

        if (props.data) {
            if (props.data.primary_address) {

                if ((props.data.primary_address.line1 || '') !== line1 && city !== '' && state.value !== '' && zipCode !== '') {
                    return true;
                }

                if ((props.data.primary_address.line2 || '') !== line2 && line1 !== '' && city !== '' && state.value !== '' && zipCode !== '') {
                    return true;
                }

                if (props.data.primary_address.city !== city && line1 !== '' && state.value !== '' && zipCode !== '') {
                    return true;
                }

                if (props.data.primary_address.state !== state.value && line1 !== '' && city !== '' && zipCode !== '') {
                    return true;
                }


                if (props.data.primary_address.line1 !== line1 &&
                    props.data.primary_address.city !== city &&
                    props.data.primary_address.state !== state.value ) {
                    if (parseInt(props.data.primary_address.zipCode) !== zipCode) {
                        return true;
                    }
                }

            }else {
                if (line1 !== '' && city !== '' && state !== '' && zipCode !== '') {
                    return true;
                }

            }
        }

        return result;
    }

    return (
        <Fragment>
            {loadingArea}
            {errorComponent}
            <form
                className="ExtraUserInformationForm"
                noValidate
                autoComplete='off'
            >
                <div className='SettingsInputFormArea'>
                    <SettingsTextField
                        label='Line 1'
                        value={line1}
                        onChange={event => onLine1Change(event.target.value)}
                        type='text'
                        tabIndex={11}
                    />
                    <SettingsTextField
                        label='Line 2'
                        value={line2}
                        onChange={event => onLine2Change(event.target.value)}
                        type='text'
                        tabIndex={12}
                    />
                    <Masonry
                        breakpointCols={{ default: 2, 1100: 2, 1000: 1, 500: 1 }}
                        className="settings-masonry-grid"
                        columnClassName="settings-masonry-grid_column"
                    >
                        <SettingsTextField
                            label='City'
                            value={city}
                            onChange={event => onCityChange(event.target.value)}
                            type='text'
                            containerStyle={{marginRight: ((layoutWidth || 0) <= 1000) ? '0px' : '5px'}}
                            placeholder=''
                            tabIndex={13}
                        />
                        <SettingsSelect
                            label='State'
                            value={state}
                            onChange={selectedOption =>onStateChange(selectedOption)} 
                            options={USStates.stateOptions}
                            containerStyle={{marginLeft: ((layoutWidth || 0) <= 1000) ? '0px' : '5px'}}
                            isSearchable={true}
                            tabIndex={14}
                        />
                        <SettingsTextField
                            label='Zip Code'
                            value={zipCode}
                            onChange={event => onZipCodeChange(event.target.value)}
                            type='number'
                            containerStyle={{marginRight: ((layoutWidth || 0) <= 1000) ? '0px' : '5px'}}
                            placeholder=''
                            tabIndex={15}
                        />
                        <SettingsSelect
                            label='Country'
                            value={country}
                            onChange={selectedOption =>onCountryChange(selectedOption)} 
                            options={countryOptions}
                            containerStyle={{marginLeft: ((layoutWidth || 0) <= 1000) ? '0px' : '5px'}}
                            isSearchable={false}
                            tabIndex={16}
                        />
                    </Masonry>
                    
                </div>
                <EditSettingsBoxButton 
                    isDisabled={!checkIsValid()}
                    handleSubmit={onSubmit}
                />
            </form>
        </Fragment>
    );
};

export default ContactAddressForm;
