// Import Modules
import React, { useState } from 'react';
import clsx from 'clsx';
import { isMobileOnly } from 'react-device-detect';
import { AsYouType, parsePhoneNumberFromString } from 'libphonenumber-js'
import Masonry from 'react-masonry-css';
import { useSelector, useDispatch } from 'react-redux';
import Backdrop from '@material-ui/core/Backdrop';
import { useMutation } from '@apollo/react-hooks';
import ParseFullName from 'parse-full-name';
import PulseLoader from 'react-spinners/PulseLoader';
import { Auth } from 'aws-amplify';

import Box from '../../Boxes/Box';
import SetupAccountLists from '../../../Constants/SetupAccountLists';
import { SETUP_ACCOUNT } from '../../../graphql/AuthMutations';
import * as UserInfoActionTypes from '../../../Redux/ActionTypes/Auth/UserInfoActionTypes';
import SetupAccountButton from '../../Buttons/SetupAccountButton';
import SettingsTextField from "../../MaterialUI/SettingsTextField";
import SettingsSelect from "../../MaterialUI/SettingsSelect";

const SetupAccountCheck = (props) => {


    // React Hooks State Management
    const [setupAccountErrors, setSetupAccountErrors] = useState({
        fullName: {error: false, message: ''},
        phoneNumber: {error: false, message: ''},
        companyName: {error: false, message: ''},
        companySize: {error: false, message: ''},
        trafficSource: {error: false, message: ''},
        industry: {error: false, message: ''}
    });
    const [isLoading, setIsLoading] = useState(false);
    const [fullName, setFullName] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [companySize, setCompanySize] = useState({value: '', label: ''});
    const [phoneNumber, setPhoneNumber] = useState('');
    const [trafficSource, setTrafficSource] = useState({value: '', label: ''});
    const [industry, setIndustry] = useState({value: '', label: ''});


    // Get Auth for ID
    const auth = useSelector(state => state.auth.userInfo.auth);

    // Handle Update Contact GraphQL Mutation
    const [setupAccountAction, { loading: mutationLoading, error: mutationError }] = useMutation(SETUP_ACCOUNT);

    const dispatch = useDispatch();

    if (auth !== {}) {
        if (auth.company_status !== 'setup') {
            return props.children;
        }
    }

    let loadingArea = null;

    if (mutationLoading) {
        loadingArea = (
            <PulseLoader
                css={`margin-top: 20px;`}
                size={25}
                sizeUnit={"px"}
                margin="5px"
                color={'#0A3F79'}
                loading={true}
            />
        );
    }

    let libAsYouType = new AsYouType('US');
    let errorComponent = null;

    // Show any errors at top of the form
    if (mutationError) {
        errorComponent = (
            <p className="ErrorBody" style={{ paddingTop: '15px' }}>
                {mutationError.message}
            </p>
        );
    }

    // Handle Form Actions
    const onFullNameChange = (newFullName) => {
        setFullName(newFullName);
    };

    const onPhoneNumberChange = (newPhoneNumber) => {
        setPhoneNumber(libAsYouType.input(newPhoneNumber));
    };

    const onCompanySizeChange = (companySize) => {
        setCompanySize(companySize);
    };

    const onCompanyNameChange = (newCompanyName) => {
        setCompanyName(newCompanyName);
    };

    const onTrafficSourceChange = (trafficSource) => {
        setTrafficSource(trafficSource);
    };

    const onIndustryChange = (industry) => {
        setIndustry(industry);
    };

    const onSubmit = () => {
        setIsLoading(true);

        let new_obj = Object.assign({}, setupAccountErrors);
        let setupAccountData = {
            id: auth.id,
            fullName: null,
            firstName: null,
            lastName: null,
            phoneNumber: null,
            companyName: null,
            companySizeID: null,
            trafficSourceID: null,
            industryID: null
        };

        if (fullName === '') {
            new_obj['fullName'] = {error: true, message: 'Please enter your full name with a first and last name.'};
        }else {
            let parsedFullName = ParseFullName.parseFullName(fullName);

            if (parsedFullName.first === '' || null) {
                new_obj['fullName'] = {error: true, message: 'Please include your a first name in your full name.'};
            }
            if (parsedFullName.last === '' || null) {
                new_obj['fullName'] = {error: true, message: 'Please include your a last name in your full name.'};
            }

            if (parsedFullName.first !== '' && parsedFullName.last !== '') {
                new_obj['fullName'] = {error: false, message: ''};
                setupAccountData.fullName = fullName;
                setupAccountData.firstName = parsedFullName.first;
                setupAccountData.lastName = parsedFullName.last;
            }
        }

        if (phoneNumber === '') {
            new_obj['phoneNumber'] = {error: true, message: 'Please enter a valid phone number.'};
        }else {
            let inputPhoneNumber = parsePhoneNumberFromString(phoneNumber, 'US');
            if (inputPhoneNumber) {
                if (!inputPhoneNumber.isValid()) {
                    new_obj['phoneNumber'] = {error: true, message: 'Please enter a valid phone number.'};
                }else {
                    new_obj['phoneNumber'] = {error: false, message: ''};
                    setupAccountData.phoneNumber = inputPhoneNumber.nationalNumber;
                }
            }else {
                new_obj['phoneNumber'] = {error: true, message: 'Please enter a valid phone number.'};
            }
        }

        if (companyName === '') {
            new_obj['companyName'] = {error: true, message: 'Please enter a valid company name.'};
        }else {
            new_obj['companyName'] = {error: false, message: ''};
            setupAccountData.companyName = companyName;
        }

        if (companySize.value === '') {
            new_obj['companySize'] = {error: true, message: 'Please select a company size.'};
        }else {
            new_obj['companySize'] = {error: false, message: ''};
            setupAccountData.companySizeID = companySize.value;
        }

        if (trafficSource.value === '') {
            new_obj['trafficSource'] = {error: true, message: 'Please select how you heard about us.'};
        }else {
            new_obj['trafficSource'] = {error: false, message: ''};
            setupAccountData.trafficSourceID = trafficSource.value;
        }

        if (industry.value === '') {
            new_obj['industry'] = {error: true, message: 'Please select which industry your company is in.'};
        }else {
            new_obj['industry'] = {error: false, message: ''};
            setupAccountData.industryID = industry.value;
        }

        setSetupAccountErrors(new_obj);

        if (checkObjIsValid(new_obj)) {

            setupAccountAction({
                variables: setupAccountData
            }).then((setupAccountResult) => {

                Auth.currentAuthenticatedUser()
                    .then((currentUser) => {
                        let inputPhoneNumber = parsePhoneNumberFromString(phoneNumber, 'US');



                        Auth.updateUserAttributes(currentUser, {
                            'name': setupAccountData.fullName,
                            'phone_number': inputPhoneNumber.number
                        })
                        .then((updateAttributesResult) => {
                            dispatch({type: UserInfoActionTypes.UPDATE_AUTH, payload: {auth: setupAccountResult.data.setupAccount}});
                            setIsLoading(false);
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                    })
                    .catch((error) => {
                        console.log(error);
                    });

            }).catch((error) => {
                console.log(error);
            });
        }else {
            setIsLoading(false);
        }
    };

    const checkObjIsValid = (object) => {
        let result = true;

        if (object['fullName']['error']) {
            result = false;
        }

        if (object['phoneNumber']['error']) {
            result = false;
        }

        if (object['companyName']['error']) {
            result = false;
        }

        if (object['companySize']['error']) {
            result = false;
        }

        if (object['trafficSource']['error']) {
            result = false;
        }

        if (object['industry']['error']) {
            result = false;
        }

        return result;
    }


    return (
        <Backdrop open={true} style= {{
            zIndex: 1000000000,
            color: '#fff',
            backgroundColor: 'rgba(0, 0, 0, 0.6)'
        }}>
            <Box className='SetupAccountBox' style={{paddingBottom: isMobileOnly ? '20px' : '40px'}}>
                <p className={clsx('Title', 'TextAlignCentered', 'DarkBlueTint', 'BoldText')} style={{margin: '20px'}}>
                    Setup Your Account.
                </p>
                {errorComponent}
                {loadingArea}
                <form
                    className="SetupAccountForm"
                    noValidate
                    autoComplete='off'
                >
                    <div className='SetupAccountInputFormArea'>
                        <Masonry
                            breakpointCols={{ default: 2, 1100: 2, 900: 1, 500: 1 }}
                            className="settings-masonry-grid"
                            columnClassName="settings-masonry-grid_column"
                        >
                            <SettingsTextField
                                label='Full Name'
                                value={fullName}
                                onChange={event => onFullNameChange(event.target.value)}
                                type='text'
                                containerStyle={{ marginRight: isMobileOnly ? '0px' : '10px' }}
                                placeholder='Enter Full Name'
                                error={setupAccountErrors.fullName.error}
                                helperText={setupAccountErrors.fullName.message}
                            />
                            <SettingsTextField
                                label='Phone Number'
                                value={phoneNumber}
                                onChange={event => onPhoneNumberChange(event.target.value)}
                                type='text'
                                containerStyle={{ marginLeft: isMobileOnly ? '0px' : '10px' }}
                                placeholder='Enter Phone Number'
                                error={setupAccountErrors.phoneNumber.error}
                                helperText={setupAccountErrors.phoneNumber.message}
                            />
                            <SettingsTextField
                                label='Company Name'
                                value={companyName}
                                onChange={event => onCompanyNameChange(event.target.value)}
                                type='text'
                                containerStyle={{ marginRight: isMobileOnly ? '0px' : '10px' }}
                                placeholder='Enter Company Name'
                                error={setupAccountErrors.companyName.error}
                                helperText={setupAccountErrors.companyName.message}
                            />
                            <SettingsSelect
                                label='Company Size'
                                value={companySize}
                                onChange={selectedOption =>onCompanySizeChange(selectedOption)} 
                                options={SetupAccountLists.companySizeOptions}
                                containerStyle={{marginLeft: isMobileOnly ? '0px' : '10px'}}
                                isSearchable={true}
                                error={setupAccountErrors.companySize.error}
                                helperText={setupAccountErrors.companySize.message}
                            />
                            <SettingsSelect
                                label='How did you hear about us?'
                                value={trafficSource}
                                onChange={selectedOption =>onTrafficSourceChange(selectedOption)} 
                                options={SetupAccountLists.trafficSourceOptions}
                                containerStyle={{ marginRight: isMobileOnly ? '0px' : '10px' }}
                                isSearchable={true}
                                error={setupAccountErrors.trafficSource.error}
                                helperText={setupAccountErrors.trafficSource.message}
                            />
                            <SettingsSelect
                                label='Industry'
                                value={industry}
                                onChange={selectedOption =>onIndustryChange(selectedOption)} 
                                options={SetupAccountLists.industryOptions}
                                containerStyle={{marginLeft: isMobileOnly ? '0px' : '10px'}}
                                isSearchable={true}
                                error={setupAccountErrors.industry.error}
                                helperText={setupAccountErrors.industry.message}
                            />
                        </Masonry>
                    </div>
                    <SetupAccountButton
                        isDisabled={isLoading}
                        handleSubmit={onSubmit}
                    />
                </form>
            </Box>
        </Backdrop>
    );
};

export default SetupAccountCheck;
