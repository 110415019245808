import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { GET_AUTH } from '../graphql/AuthQueries';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import * as UserInfoActionTypes from '../Redux/ActionTypes/Auth/UserInfoActionTypes';
import * as LayoutActionTypes from '../Redux/ActionTypes/UI/LayoutActionTypes';
import ErrorScreen from '../Screens/PostAuth/Other/ErrorScreen';
import PermissionsHelper from '../Helpers/PermissionsHelper';
import AuthNavigatorCategories from '../Constants/AuthNavigatorCategories';

function AuthenticationCheck(props) {
  if (props.user === undefined || props.isLoggedIn === false) {
    return <Redirect to='/login' />
  }

  return (
    <PermissionsAuthenticationCheck user={props.user} isLoggedIn={props.isLoggedIn}>
      {props.children}
    </PermissionsAuthenticationCheck>
  );

};


function PermissionsAuthenticationCheck(props) {

  // Start query for Auth object, use cognito user id to get Auth
  const { loading, error, data } = useQuery(GET_AUTH, {
    variables: { id: props.user.username || '' }
  });

  const permissions_helper = new PermissionsHelper();
  const authNavigatorCategories = useSelector(state => state.ui.layout.authNavigatorCategories);
  const dispatch = useDispatch();
  
  useEffect(() => {
    if (data !== undefined && !_.isEmpty(data)) {
      if (data.getAuth !== null) {
        dispatch({type: UserInfoActionTypes.UPDATE_AUTH, payload: {auth: data.getAuth}});
        
        if (_.isEmpty(authNavigatorCategories)) {
          dispatch({ type: LayoutActionTypes.SET_AUTH_NAVIGATOR_CATEGORIES, payload: { authNavigatorCategories: permissions_helper.CreateAuthNavigatorCategories(AuthNavigatorCategories.all_auth_categories, data.getAuth)}});
        }
      }
    }
  }, [data, authNavigatorCategories, dispatch, permissions_helper ]);

  // Create Better Loading Animation
  if (loading) return null;

  // Create better error screen
  if (error) return `Error: ${error.message}`;

  // Dispatch to Redux to be used in Auth Screens.
  if (data.getAuth) {
    if (data.getAuth.role_id && data.getAuth.role_permissions) {
      if (data.getAuth.role_permissions.includes('basic_access')) {
        return (props.children);
      }
    }
  }
  
  return (<ErrorScreen />);

  
};


export default AuthenticationCheck;
