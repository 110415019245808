import React from 'react';
import Box from './Box';
import clsx from 'clsx';

const SettingsBox = (props) => {

    return (
        <Box className='SettingsBox'>
            <p className={clsx('SubTitle', 'DarkBlueTint', 'SemiBoldText', 'TextAlignLeft', 'Margin--Horizontal--20', 'Margin--Vertical--15')}>
                {props.title || ''}
            </p>
            <div className='SettingsBoxLine'/>
            {props.children}
        </Box>
    );
};

export default SettingsBox;