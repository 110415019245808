// Import Modules
import React, { Component } from 'react';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import { connect } from 'react-redux';
import { Auth } from 'aws-amplify';

// Import Custom Components and Items
import * as actionTypes from './Redux/ActionTypes/Auth/UserInfoActionTypes';
import * as ROUTES from './Constants/routes';
import ForgotPasswordScreen from './Screens/PreAuth/ForgotPasswordScreen';
import AuthenticationCheck from "./Session/AuthenticationCheck";
import LoginScreen from './Screens/PreAuth/LoginScreen';
import SignUpScreen from './Screens/PreAuth/SignUpScreen';
import NewPasswordScreen from './Screens/PreAuth/NewPasswordScreen';
import TOSScreen from './Screens/PreAuth/TOSScreen';
import PPScreen from './Screens/PreAuth/PPScreen';
import HomeScreen from './Screens/PostAuth/HomeScreen';
import LayoutContainer from './Components/Layout/LayoutContainer';
import SettingsScreen from './Screens/PostAuth/Settings/SettingsScreen';
import CompanySettingsScreen from './Screens/PostAuth/Settings/Company/CompanySettingsScreen';
import BrandingSettingsScreen from './Screens/PostAuth/Settings/Company/BrandingSettingsScreen';
import OwnerSettingsScreen from './Screens/PostAuth/Settings/Owner/OwnerSettingsScreen';
import UserSettingsScreen from './Screens/PostAuth/Settings/User/UserSettingsScreen';
import ProHelperOverviewScreen from './Screens/PostAuth/Settings/ProHelper/ProHelperOverviewScreen';
import ProHelperSubscriptionScreen from './Screens/PostAuth/Settings/ProHelper/ProHelperSubscriptionScreen';
import ProHelperPlansScreen from './Screens/PostAuth/Settings/ProHelper/ProHelperPlansScreen';
import ProHelperPaymentMethodsScreen from './Screens/PostAuth/Settings/ProHelper/ProHelperPaymentMethodsScreen';
import WorkSettingsScreen from './Screens/PostAuth/Settings/Features/WorkSettingsScreen';
import ScheduleSettingsScreen from './Screens/PostAuth/Settings/Features/ScheduleSettingsScreen';
import BillingSettingsScreen from './Screens/PostAuth/Settings/Features/BillingSettingsScreen';
import CustomerSettingsScreen from './Screens/PostAuth/Settings/Features/CustomerSettingsScreen';
import QBOSettingsScreen from './Screens/PostAuth/Settings/Data/QBOSettingsScreen';
import DataImportScreen from './Screens/PostAuth/Settings/Data/DataImportScreen';
import SupportSettingsScreen from './Screens/PostAuth/Settings/User/SupportSettingsScreen';
import ChangeEmailScreen from './Screens/PostAuth/Settings/User/ChangeEmailScreen';
import ChangePasswordScreen from './Screens/PostAuth/Settings/User/ChangePasswordScreen';
import NotFoundScreen from './Screens/PostAuth/Other/NotFoundScreen';
import UsersScreen from './Screens/PostAuth/Team/Users/UsersScreen';
import UserDetailScreen from './Screens/PostAuth/Team/Users/UserDetailScreen';
import RolesScreen from './Screens/PostAuth/Team/Roles/RolesScreen';
import RoleDetailScreen from './Screens/PostAuth/Team/Roles/RoleDetailScreen';
import NewRoleScreen from './Screens/PostAuth/Team/Roles/NewRoleScreen';
import EditUserScreen from './Screens/PostAuth/Team/Users/EditUserScreen';
import EditRoleScreen from './Screens/PostAuth/Team/Roles/EditRoleScreen';
import AccountsScreen from './Screens/PostAuth/Customers/AccountsScreen';
import ContactsScreen from './Screens/PostAuth/Customers/ContactsScreen';
import PropertiesScreen from './Screens/PostAuth/Customers/PropertiesScreen';
import ScrollToTop from './Components/Other/ScrollToTop';

class App extends Component {

  state = {
    isAuthLoaded: false
  }

  componentDidMount() {
    this.authenticate();
    
  }
  componentWillUnmount() {

  }

  // Cognito Authentication
  async authenticate() {
    try {
      let currentUser = await Auth.currentAuthenticatedUser();
      if (currentUser !== null && currentUser !== undefined && currentUser !== {}) {
        this.props.onUpdateUser(currentUser);
        this.setState({isAuthLoaded: true});
        this.props.updateLoginStatus(currentUser.challengeName === undefined);

      }
    } catch (err) {
      this.props.updateLoginStatus(false);
      this.setState({isAuthLoaded: true});
    }
  }

  render() {

    // Don't show anything until we check cognito auth
    if (this.state.isAuthLoaded === false) return null

    // Return React Router Screen in LayoutContainer. Authorized routes should be inside of AuthenticationCheck. 
    return (
      <div className="App">
        <Router>
          <ScrollToTop>
            <LayoutContainer>
              <Switch>
                <Route
                  exact
                  path={ROUTES.SIGN_UP}
                  component={SignUpScreen}
                />
                <Route
                  exact
                  path={ROUTES.LOG_IN}
                  component={LoginScreen}
                />
                <Route
                  exact
                  path={ROUTES.FORGOT_PASSWORD}
                  component={ForgotPasswordScreen}
                />
                <Route
                  exact
                  path={ROUTES.NEW_PASSWORD}
                  component={NewPasswordScreen}
                />
                <Route
                  exact
                  path={ROUTES.TOS}
                  component={TOSScreen}
                />
                <Route
                  exact
                  path={ROUTES.PP}
                  component={PPScreen}
                />

                <AuthenticationCheck user={this.props.currentUser} isLoggedIn={this.props.isLoggedIn} >
                  <Switch>
                    <Route
                      exact
                      path={ROUTES.HOME}
                      component={HomeScreen}
                    />



                    <Route
                      exact
                      path={ROUTES.ACCOUNTS}
                      component={AccountsScreen}
                    />
                    <Route
                      exact
                      path={ROUTES.CONTACTS}
                      component={ContactsScreen}
                    />
                    <Route
                      exact
                      path={ROUTES.PROPERTIES}
                      component={PropertiesScreen}
                    />
                    <Route
                      exact
                      path={ROUTES.TEAM_USERS}
                      component={UsersScreen}
                    />
                    <Route
                      exact
                      path={ROUTES.TEAM_USER_DETAIL_PREFIX + ':id/edit'}
                      component={EditUserScreen}
                    />
                    <Route
                      exact
                      path={ROUTES.TEAM_USER_DETAIL_PREFIX + ':id'}
                      component={UserDetailScreen}
                    />

                    <Route
                      exact
                      path={ROUTES.TEAM_ROLES}
                      component={RolesScreen}
                    />

                    <Route
                      exact
                      path={ROUTES.TEAM_ROLES_NEW}
                      component={NewRoleScreen}
                    />

                    <Route
                      exact
                      path={ROUTES.TEAM_ROLE_DETAIL_PREFIX + ':id/edit'}
                      component={EditRoleScreen}
                    />

                    <Route
                      exact
                      path={ROUTES.TEAM_ROLE_DETAIL_PREFIX + ':id'}
                      component={RoleDetailScreen}
                    />


                    <Route
                      exact
                      path={ROUTES.SETTINGS_COMPANY}
                      component={CompanySettingsScreen}
                    />
                    <Route
                      exact
                      path={ROUTES.SETTINGS_COMPANY_BRANDING}
                      component={BrandingSettingsScreen}
                    />
                    <Route
                      exact
                      path={ROUTES.SETTINGS_COMPANY_OWNER}
                      component={OwnerSettingsScreen}
                    />


                    <Route
                      exact
                      path={ROUTES.SETTINGS_USER}
                      component={UserSettingsScreen}
                    />
                    <Route
                      exact
                      path={ROUTES.SETTINGS_SUPPORT}
                      component={SupportSettingsScreen}
                    />
                    <Route
                      exact
                      path={ROUTES.CHANGE_EMAIL}
                      component={ChangeEmailScreen}
                    />
                    <Route
                      exact
                      path={ROUTES.CHANGE_PASSWORD}
                      component={ChangePasswordScreen}
                    />
                    <Route
                      exact
                      path={ROUTES.SETTINGS_PROHELPER_OVERVIEW}
                      component={ProHelperOverviewScreen}
                    />
                    <Route
                      exact
                      path={ROUTES.SETTINGS_PROHELPER_SUBSCRIPTION}
                      component={ProHelperSubscriptionScreen}
                    />
                    <Route
                      exact
                      path={ROUTES.SETTINGS_PROHELPER_PLANS}
                      component={ProHelperPlansScreen}
                    />
                    <Route
                      exact
                      path={ROUTES.SETTINGS_PROHELPER_PAYMENT}
                      component={ProHelperPaymentMethodsScreen}
                    />


                    <Route
                      exact
                      path={ROUTES.SETTINGS_FEATURES_WORK}
                      component={WorkSettingsScreen}
                    />
                    <Route
                      exact
                      path={ROUTES.SETTINGS_FEATURES_SCHEDULE}
                      component={ScheduleSettingsScreen}
                    />
                    <Route
                      exact
                      path={ROUTES.SETTINGS_FEATURES_BILLING}
                      component={BillingSettingsScreen}
                    />
                    <Route
                      exact
                      path={ROUTES.SETTINGS_FEATURES_CUSTOMERS}
                      component={CustomerSettingsScreen}
                    />


                    <Route
                      exact
                      path={ROUTES.SETTINGS_DATA_QBO}
                      component={QBOSettingsScreen}
                    />
                    <Route
                      exact
                      path={ROUTES.SETTINGS_DATA_IMPORT}
                      component={DataImportScreen}
                    />


                    <Route
                      exact
                      path={ROUTES.SETTINGS}
                      component={SettingsScreen}
                    />

                    <Route
                      component={NotFoundScreen}
                    />
                  </Switch>
                </AuthenticationCheck>
              </Switch>
            </LayoutContainer>
          </ScrollToTop>
        </Router>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentUser: state.auth.userInfo.user,
    isLoggedIn: state.auth.userInfo.isLoggedIn
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUpdateUser: (user) => dispatch({ type: actionTypes.UPDATE_USER, payload: { user: user } }),
    updateLoginStatus: (isLoggedIn) => dispatch({ type: actionTypes.UPDATE_LOGIN_STATUS, payload: { isLoggedIn: isLoggedIn } }),
  };
};



export default connect(mapStateToProps, mapDispatchToProps)(App);