import React from 'react';
import Select from 'react-select';
import { isMobile } from 'react-device-detect';



const BoxSortSelect = (props) => {

    const customStyles = {
        option: (styles, state) => ({
            ...styles,
            color: '#000',
            backgroundColor: '#fff',
            padding: '20px 15px',
            fontWeight: 500,
            '&:hover': {backgroundColor: '#E3E7EB'},
        }),
        control: (styles) => ({
            ...styles,
            border: '1px solid #394453',
            borderRadius: '10px',
            marginTop:0,
            height: 48,
            '&:hover': {border: '2px solid #07689F'},
            '&:active': {border: '1px solid #07689F'}
        }),
        input: (styles) => ({
            ...styles,
            paddingLeft: '7px'
        }),
        indicatorSeparator: (styles) => ({
            ...styles,
            backgroundColor: '#002D47',
            '&:hover': {backgroundColor: '#07689F'}
        }),
        dropdownIndicator: (styles) => ({
            ...styles,
            color: '#002D47',
            '&:hover': {color: '#07689F'}
        }),
        menu: (styles) => ({
            ...styles,
            border: '1px solid #394453',
            padding: '0px',
            margin: '0px'
        }),
        singleValue: (styles, state) => {
            return {
                ...styles,
                fontWeight: 500,
                fontSize: (isMobile ? '14px' : '16px'),
                overflow: 'auto',
                padding: '5px', 
                color: '#000'
            };
        }
    }


    return (
        <div className={props.containerClassName || 'BoxSortSelectContainer'} style={props.containerStyle || {}}>
            <Select 
                value={props.value} 
                defaultValue={props.defaultValue}
                options={props.options}
                onChange={selectedOption =>props.onChange(selectedOption)} 
                disabled={props.disabled || false}
                styles={customStyles}
                isSearchable={props.isSearchable || false}
            />
        </div>
    );
};

export default BoxSortSelect;