// Import Modules
import React from 'react';
import ForgotPasswordForm from '../Forms/ForgotPasswordForm2';
import Box from './Box';
import clsx from 'clsx';


const ForgotPasswordBox2 = (props) => {

    return (
        <Box className='ForgotPasswordBox'>
            <p className={clsx('Title', 'DarkBlueTint', 'Bold')}>
                Verify Your Email
            </p>
            <p className={clsx('BodyText', 'DarkBlueTint')} style={{marginTop: '15px', paddingLeft: '10%', paddingRight: '10%'}}>
                Please enter the verification code we sent to your email and a new password.
            </p>
            <ForgotPasswordForm
                verificationCodeValue={props.verificationCodeValue}
                onVerificationCodeChange={(event) => { props.onVerificationCodeChange(event) }}
                passwordValue={props.passwordValue}
                onPasswordChange={(event) => { props.onPasswordChange(event) }}
                errorValue={props.errorValue}
                isInvalid={props.isInvalid}
                handleSubmit={props.handleSubmit}
                newPasswordKeyPressChecker={(event) => props.newPasswordKeyPressChecker(event)}
            />
        </Box>
    );
};

export default ForgotPasswordBox2;