import React from 'react';
import PropTypes from 'prop-types';
import { createMuiTheme, ThemeProvider, withStyles } from '@material-ui/core/styles';
import PreAuthHeader from './PreAuthHeader';


let theme = createMuiTheme({
    palette: {
        primary: {
            light: '#63ccff',
            main: '#07689F',
            dark: '#07689F',
        },
    },
    typography: {
        fontFamily: "Open Sans",
        h5: {
            fontWeight: 500,
            fontSize: 26,
            letterSpacing: 0.5,
        },
    },
    shape: {
        borderRadius: 8,
    },
    props: {
        MuiTab: {
            disableRipple: true,
        },
    },
    mixins: {
        toolbar: {
            minHeight: 58,
            paddingLeft: "24px"
        },
    },
});

theme = {
    ...theme,
    overrides: {
        MuiDrawer: {
            paper: {
                backgroundColor: '#DFDFDF',
            },
            paperAnchorDockedLeft: {
                borderRightColor: "#07689F"
            }
        },
        MuiButton: {
            label: {
                textTransform: 'none',
            },
            contained: {
                boxShadow: 'none',
                '&:active': {
                    boxShadow: 'none',
                },
            },
        },
        MuiIconButton: {
            root: {
                padding: theme.spacing(1),
            },
        },
        MuiTooltip: {
            tooltip: {
                borderRadius: 4,
            },
        },
        MuiDivider: {
            root: {
                backgroundColor: '#979797',
            },
        },
        MuiListItemText: {
            primary: {
                fontWeight: theme.typography.fontWeightMedium,
                color: '#444'
            },
        },
        MuiListItemIcon: {
            root: {
                color: '#444',
                marginRight: 0,
                '& svg': {
                    fontSize: 20,
                },
            },
        },
        MuiAvatar: {
            root: {
                width: 32,
                height: 32,
            },
        },
    },
};

const drawerWidth = 256;

const styles = {
    root: {
        display: 'flex',
        minHeight: '100vh'
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0
        },
    },
    app: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    main: {
        flex: 1,
        background: '#E3E7EB',
    },
    footer: {
        padding: theme.spacing(2),
        background: '#E3E7EB',
    }
};

function PreAuthLayout(props) {
    const { classes } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    return (
        <ThemeProvider theme={theme}>
            <PreAuthHeader onDrawerToggle={handleDrawerToggle} />
            <main className={classes.main} style={{ marginTop: "50px" }}>
                {props.children}
            </main>
        </ThemeProvider>
    );
}

PreAuthLayout.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PreAuthLayout);