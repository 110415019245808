import React from 'react';
import clsx from 'clsx';
import TextareaAutosize from 'react-textarea-autosize';


const SettingsTextFieldMultiLine = (props) => {

    let helperText = null;

    if (props.error !== null) {
        if (props.helperText !== null || props.helperText !== '') {
            helperText = (
                <p className={clsx({'SettingsTextFieldHelperText': true, 'GreyTint': props.disabled || false})}>{props.helperText}</p>
            );
        }
    }

    return (
        <div className={props.containerClassName || 'SettingsTextFieldContainerMultiLine'} style={props.containerStyle || {}}>
            <label className={clsx({'SettingsTextFieldLabel': true, 'DarkBlueTint':true})} >
                {props.label || ''}
            </label>
            <TextareaAutosize 
                value={props.value} 
                onChange={props.onChange} 
                disabled={props.disabled || false}
                className={props.inputLabelClassName || 'SettingsTextFieldInputMultiLine'}
                style={props.inputStyle || {}}
                placeholder={props.placeholder || ''}
                minRows={3}
                tabIndex={props.tabIndex || null}
            />
            {helperText}
        </div>
    );
};

export default SettingsTextFieldMultiLine;