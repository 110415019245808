// Import Modules
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import clsx from 'clsx';
import AddIcon from '@material-ui/icons/Add';
import { useMutation } from '@apollo/react-hooks';
import PulseLoader from 'react-spinners/PulseLoader';


// Import Custom Components
import ErrorScreen from '../../Other/ErrorScreen';
import ScreenDetailHeader from '../../../../Components/UI/ScreenDetailHeader';
import * as ROUTES from '../../../../Constants/routes';
import ActionBox from '../../../../Components/Boxes/ActionBox';
import SettingsTextField from '../../../../Components/MaterialUI/SettingsTextField';
import SettingsTextFieldMultiLine from '../../../../Components/MaterialUI/SettingsTextFieldMultiLine';
import BoxFooterButton from '../../../../Components/Buttons/BoxFooterButton';
import PermissionCategoriesContainer from '../../../../Components/Containers/PermissionCategoriesContainer';
import PermissionsHelper from '../../../../Helpers/PermissionsHelper';
import ProHelperPermissions from '../../../../Constants/ProHelperPermissions';
import RoleDataContainer from '../../../../Components/DataContainers/RoleDataContainer';
import { NEW_ROLE } from '../../../../graphql/RoleMutations';
import { GET_ROLES } from '../../../../graphql/RoleQueries';


function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const NewRoleScreen = (props) => {

    // Clientside Permissions validation
    const auth = useSelector(state => state.auth.userInfo.auth);
    const [roleName, setRoleName] = useState('');
    const [roleDescription, setRoleDescription] = useState('');
    const layoutWidth = useSelector(state => state.ui.layout.layoutWidth);
    const history = useHistory();
    const query = useQuery();
    const duplicate_role_url_id = query.get('duplicate');
    const [duplicateRoleData, setDuplicateRoleData] = useState({});
    const [dataContainerLoading, setDataContainerLoading] = useState(false);
    const [dataContainerError, setDataContainerError] = useState(undefined);

    const permissionsHelper = new PermissionsHelper();
    const [permissionCategories, setPermissionCategories] = useState(
        permissionsHelper.CreatePermissionCatelog(
            ProHelperPermissions.companyPermissions, 
            {open_categories: {'Settings': true, 'Customers': true, 'Work': true, 'Schedule': true, 'Billing': true, 'Team': true, 'Reports': true}}
        )
    );

    // Setup Role Detail Screen
    useEffect(()=> {
        if (auth !== null && auth !== undefined && !_.isEmpty(auth)) { 
            if (!_.isEmpty(duplicateRoleData)) {
                setPermissionCategories(permissionsHelper.CreatePermissionCatelog(
                    ProHelperPermissions.companyPermissions, 
                    {open_categories: {'Settings': true, 'Customers': true, 'Work': true, 'Schedule': true, 'Billing': true, 'Team': true, 'Reports': true}, role: duplicateRoleData}
                ));
                setRoleName(duplicateRoleData.name + " Copy");
                setRoleDescription(duplicateRoleData.description);
            }
        }
        
    }, [auth, duplicateRoleData, permissionsHelper]);

    const [createNewRoleAction, { loading: mutationLoading, error: mutationError }] = useMutation(NEW_ROLE,
        {
            update(cache, { data: { newRole } }) {
                const { getRoles } = cache.readQuery({ query: GET_ROLES, variables: {company_id: newRole.company_id} });
                cache.writeQuery({
                    query: GET_ROLES,
                    variables: {company_id: newRole.company_id},
                    data: { getRoles: getRoles.concat([newRole]) },
                });
            }
        });

    if (auth === null || auth === undefined || _.isEmpty(auth) || _.isEmpty(auth.role_permissions)) {
        return null;
    }

    if (!auth.role_permissions.includes('team_role_create')) {
        return <ErrorScreen />;
    }




    const onSetRoleName = (newRoleName) => {
        setRoleName(newRoleName);
    };

    const onSetRoleDescription = (newRoleDescription) => {
        setRoleDescription(newRoleDescription);
    };

    const onCancelClicked = () => {
        
    };

    const onCreateRoleClicked = () => {
        let newRoleInput = {
            role_name: roleName, 
            role_description: roleDescription, 
            permissions: permissionsHelper.GetSelectedPermissionIDs(permissionCategories), 
            user_id: auth.id,  
            company_id: auth.company_id
        }

        createNewRoleAction({variables: {newRoleInput: newRoleInput}})
            .then((result) => {
                history.push(ROUTES.TEAM_ROLES);
            })
            .catch((error) => {

            });
    };

    const onBackClicked = () => {
        history.push(ROUTES.TEAM_ROLES);
    };

    const onPermissionsCategoryClicked = (permissionCategory) => {
        const updateObject = {
            action: 'updated_category',
            value: permissionCategory
        };

        let newPermissionsCategories = permissionsHelper.UpdatePermissionCatelog(permissionCategories, updateObject);

        setPermissionCategories(newPermissionsCategories);
    };

    const onPermissionButtonClicked = (permission) => {
        const updateObject = {
            action: 'updated_permission',
            value: permission
        };

        let newPermissionsCategories = permissionsHelper.UpdatePermissionCatelog(permissionCategories, updateObject);

        setPermissionCategories(newPermissionsCategories);
    };

    const checkIsValid = () => {
        let result = false;

        if (roleName !== '') {
            let selectedPermissions = permissionsHelper.GetSelectedPermissionIDs(permissionCategories);

            if (selectedPermissions.length > 0) {
                result = true;
            } 
        }

        return result;
    };

    const handleLoadingArea = () => {
        if (dataContainerLoading || mutationLoading) {
            return (
                <PulseLoader
                    css={`margin-bottom: 20px;`}
                    size={20}
                    sizeUnit={"px"}
                    margin="5px"
                    color={'#0A3F79'}
                    loading={true}
                />
            );
        } else {
            return null;
        }
    };

    const handleErrorArea = () => {
        if (dataContainerError) {
            let splitMessage = dataContainerError.message.split(':');
            let errorMessage  = 'Error:' + splitMessage[splitMessage.length - 1];
            return (
                <p className={clsx('Margin--Bottom--20', 'Margin--Horizontal--Auto', 'TextAlignCenter', 'SubTitle', 'SemiBoldText', 'RedTint')}>
                    {errorMessage}
                </p>
            );
        } else if (mutationError) {
            let splitMessage = mutationError.message.split(':');
            let errorMessage  = 'Error:' + splitMessage[splitMessage.length - 1];
            return (
                <p className={clsx('Margin--Bottom--20', 'Margin--Horizontal--Auto', 'TextAlignCenter', 'SubTitle', 'SemiBoldText', 'RedTint')}>
                    {errorMessage}
                </p>
            );
        }else {
            return null;
        }
    };

    const onDataContainerUpdated = (data) => {
        setDuplicateRoleData(data);

    };


    
    if (duplicate_role_url_id) {
        return (
            <div className={clsx('BaseScreen')}>
                <ScreenDetailHeader
                    screenTitle='New Role'
                    backText='Roles'
                    actionText='Create Role'
                    actionIcon={<AddIcon style={{ color: 'white', fontSize: '24', fontWeight: '600', marginTop: 'auto', marginBottom: 'auto' }} />}
                    onActionClicked={() => { onCreateRoleClicked() }}
                    isActionHidden={false}
                    isActionDisabled={!checkIsValid()}
                    isMoreDisabled={true}
                    layoutWidth={layoutWidth}
                    onBackClicked={onBackClicked}
                />
                <RoleDataContainer
                    setLoading={(loading) => setDataContainerLoading(loading)}
                    setError={(error) => setDataContainerError(error)}
                    setData={(data) => onDataContainerUpdated(data)}
                    auth={auth}
                    url_id={duplicate_role_url_id}
                >
                    {handleLoadingArea()}
                    {handleErrorArea()}
                    <div className={clsx('Padding--Horizontal--20', 'Margin--Bottom--20')}>
                        <ActionBox
                            title='Role Details'
                        >
                            <div className={clsx('Padding--Horizontal--20')}>
                                <SettingsTextField
                                    label='Role Name'
                                    value={roleName}
                                    onChange={event => onSetRoleName(event.target.value)}
                                    type='text'
                                    containerStyle={{}}
                                    placeholder='Enter Role Name'
                                    tabIndex={1}
                                />
                                <SettingsTextFieldMultiLine
                                    label='Role Description'
                                    value={roleDescription}
                                    onChange={event => onSetRoleDescription(event.target.value)}
                                    type='text'
                                    containerStyle={{}}
                                    placeholder='Enter Role Description (Optional)'
                                    tabIndex={2}
                                />
                                <div className='Spacer--20' />
                            </div>

                        </ActionBox>

                        <div className='Spacer--20' />

                        <ActionBox
                            title='Permissions'
                        >
                            <PermissionCategoriesContainer
                                data={permissionCategories}
                                onCategoryClick={(permissionCategory) => onPermissionsCategoryClicked(permissionCategory)}
                                onPermissionButtonClicked={(permission) => onPermissionButtonClicked(permission)}
                                isEditable={true}
                            />
                        </ActionBox>

                        <div className='Spacer--20' />

                        <div className={clsx(
                            'BackgroundColor--WhiteTint',
                            'BorderRadius--All--10',
                            'Border--All--1',
                            'BorderColor--DarkBlueTint',
                            'BoxShadow--1',
                            'TextAlignLeft',
                            'Padding--Horizontal--20',
                            'Padding--Vertical--15',
                            'Display--Flex',
                            'Flex--End')}
                        >
                            <BoxFooterButton
                                title='Cancel'
                                titleClasses={['DarkBlueTint', 'SemiBoldText']}
                                buttonStyles={{ backgroundColor: 'white', minHeight: '46px' }}
                                onClick={onCancelClicked}
                                isDisabled={false}
                            />
                            <BoxFooterButton
                                title='Create Role'
                                titleClasses={['WhiteTint', 'BoldText']}
                                buttonStyles={{ marginLeft: '8px' }}
                                onClick={onCreateRoleClicked}
                                icon={<AddIcon style={{ color: 'white', fontSize: '24', fontWeight: '600', marginTop: 'auto', marginBottom: 'auto', marginLeft: "5px", padding: '0px' }} />}
                                isDisabled={!checkIsValid()}
                            />
                        </div>
                    </div>
                </RoleDataContainer>
            </div>
        );
    } else {
        return (
            <div className={clsx('BaseScreen')}>
                <ScreenDetailHeader
                    screenTitle='New Role'
                    backText='Roles'
                    actionText='Create Role'
                    actionIcon={<AddIcon style={{ color: 'white', fontSize: '24', fontWeight: '600', marginTop: 'auto', marginBottom: 'auto' }} />}
                    onActionClicked={() => { onCreateRoleClicked() }}
                    isActionHidden={false}
                    isActionDisabled={!checkIsValid()}
                    isMoreDisabled={true}
                    layoutWidth={layoutWidth}
                    onBackClicked={onBackClicked}
                />
                {handleLoadingArea()}
                <div className={clsx('Padding--Horizontal--20', 'Margin--Bottom--20')}>
                    <ActionBox
                        title='Role Details'
                    >
                        <div className={clsx('Padding--Horizontal--20')}>
                            <SettingsTextField
                                label='Role Name'
                                value={roleName}
                                onChange={event => onSetRoleName(event.target.value)}
                                type='text'
                                containerStyle={{}}
                                placeholder='Enter Role Name'
                                tabIndex={1}
                            />
                            <SettingsTextFieldMultiLine
                                label='Role Description'
                                value={roleDescription}
                                onChange={event => onSetRoleDescription(event.target.value)}
                                type='text'
                                containerStyle={{}}
                                placeholder='Enter Role Description (Optional)'
                                tabIndex={2}
                            />
                            <div className='Spacer--20' />
                        </div>

                    </ActionBox>

                    <div className='Spacer--20' />

                    <ActionBox
                        title='Permissions'
                    >
                        <PermissionCategoriesContainer
                            data={permissionCategories}
                            onCategoryClick={(permissionCategory) => onPermissionsCategoryClicked(permissionCategory)}
                            onPermissionButtonClicked={(permission) => onPermissionButtonClicked(permission)}
                            isEditable={true}
                        />
                    </ActionBox>

                    <div className='Spacer--20' />

                    <div className={clsx(
                        'BackgroundColor--WhiteTint',
                        'BorderRadius--All--10',
                        'Border--All--1',
                        'BorderColor--DarkBlueTint',
                        'BoxShadow--1',
                        'TextAlignLeft',
                        'Padding--Horizontal--20',
                        'Padding--Vertical--15',
                        'Display--Flex',
                        'Flex--End')}
                    >
                        <BoxFooterButton
                            title='Cancel'
                            titleClasses={['DarkBlueTint', 'SemiBoldText']}
                            buttonStyles={{ backgroundColor: 'white', minHeight: '46px' }}
                            onClick={onCancelClicked}
                            isDisabled={false}
                        />
                        <BoxFooterButton
                            title='Create Role'
                            titleClasses={['WhiteTint', 'BoldText']}
                            buttonStyles={{ marginLeft: '8px' }}
                            onClick={onCreateRoleClicked}
                            icon={<AddIcon style={{ color: 'white', fontSize: '24', fontWeight: '600', marginTop: 'auto', marginBottom: 'auto', marginLeft: "5px", padding: '0px' }} />}
                            isDisabled={!checkIsValid()}
                        />
                    </div>
                </div>
            </div>
        );
    }

    
    
};

export default NewRoleScreen;
