import Tooltip from '@material-ui/core/Tooltip';
import MenuIcon from '@material-ui/icons/Menu';
import { withStyles } from '@material-ui/core/styles';


const CustomTooltip = withStyles({
    tooltip: {
      fontFamily: "Open Sans",
      fontSize: '12px'
    }
  })(Tooltip);


const CustomMenuIcon = withStyles({
    root: {
        padding: '0px',
        margin: 'auto',
        height: '100%'
    },
})(MenuIcon);


export { CustomTooltip, CustomMenuIcon } ;