// Sign Up Reducer
import * as actionTypes from '../../ActionTypes/Auth/ForgotPasswordActionTypes';

const initialState =
{
    email: "",
    submitEmail: false,
    verificationCode: "",
    submitVerificationCode: false,
    password: "",
    submitPassword: false,
    error: null
};

const ForgotPasswordReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_EMAIL:
            return {
                ...state,
                email: action.payload.email
            }
        case actionTypes.SUBMIT_EMAIL:
            return {
                ...state,
                submitEmail: action.payload.submitEmail
            }
        case actionTypes.SET_VERIFICATION_CODE:
            return {
                ...state,
                verificationCode: action.payload.verificationCode
            }
        case actionTypes.SUBMIT_VERIFICATION_CODE:
            return {
                ...state,
                submitVerificationCode: action.payload.submitVerificationCode
            }
        case actionTypes.SET_PASSWORD:
            return {
                ...state,
                password: action.payload.password
            }
        case actionTypes.SUBMIT_PASSWORD:
                return {
                    ...state,
                    submitPassword: action.payload.submitPassword
                }
        case actionTypes.SET_ERROR:
            return {
                ...state,
                error: action.payload.error
            }
        case actionTypes.CLEAR_FORGOT_PASSWORD_INFO:
            return {
                email: "",
                verificationCode: "",
                password: "",
                error: null
            }
        default:
            return state
    }
};

export default ForgotPasswordReducer;