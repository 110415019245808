import React from 'react';
import _ from 'lodash';
import clsx from 'clsx';
import PulseLoader from 'react-spinners/PulseLoader';
import InfiniteScroll from 'react-infinite-scroller';

import UsersListCell from '../Cells/UsersListCell';


const UsersListView = (props) => {

    const showLoadingAnimation = () => {
        return (
            <PulseLoader
                css={`margin-top: 20px;`}
                size={18}
                sizeUnit={"px"}
                margin="5px"
                color={'#0A3F79'}
                loading={true}
            />
        );
    };

    return (
        <div className='UsersListView'>
            {props.loading ? showLoadingAnimation() : null}
            {(props.data && !_.isEmpty(props.data)) ?
                <InfiniteScroll
                    pageStart={0}
                    loadMore={props.loadMore}
                    hasMore={false}
                    loader={
                        <PulseLoader
                            key='pulserLoaderKey'
                            css={`margin: 10px;`}
                            size={20}
                            sizeUnit={"px"}
                            margin="5px"
                            color={'#0A3F79'}
                            loading={true}
                        />
                    }
                >
                    {props.data.map((user) => {
                        return (
                            <UsersListCell
                                key={user.id}
                                data={user}
                                onClick={() => props.onUserClicked(user)}
                            />
                        );
                    })}
                </InfiniteScroll>
                :
                <p className={clsx('Margin--Vertical--20', 'Margin--Horizontal--Auto', 'TextAlignCenter', 'DarkGreyTint', 'SubTitleSmall', 'SemiBoldText')}>
                    No Users Available.
                </p>
            }
        </div>
    );
};

export default UsersListView;
