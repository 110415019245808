import React, { Fragment } from 'react';
import clsx from 'clsx';

const EditSettingsBoxButton = (props) => {

    return (
        <Fragment>
            <div className='SettingsBoxLine' style={{ marginBottom: '0px', marginTop: '20px' }} />
            <div style={{ marginTop: '15px', marginBottom: '10px', marginRight: '20px', textAlign: 'right' }}>
                <button
                    className={clsx({
                        "EditSettingsBoxButton": true,
                        'DisabledButton': (props.isDisabled),
                        'NotDisabledButton': (!props.isDisabled),
                        'Pointer--Cursor': true,
                        'BoxShadow--0-5': (!props.isDisabled)
                    })}
                    onClick={props.handleSubmit}
                    disabled={props.isDisabled}
                    type='button'
                >
                    <p
                        className={clsx({
                            'TextButton': true,
                            'BodyText': true,
                            'BoldText': !props.isDisabled,
                            'SemiBoldText': props.isDisabled,
                            'WhiteTint': !props.isDisabled,
                            'DirtyWhiteTint': props.isDisabled
                        })}
                        style={{ padding: '5px' }}
                    >
                        Save
                    </p>
                </button>
            </div>

        </Fragment>
    );
};

export default EditSettingsBoxButton;